import { store } from 'store'
import { getMessagesQuery, getChannelItem, getAutoReplyList } from 'utils/protobuf/query'
import { AutoReplyFirstSubmit, closeLoader } from 'utils/localDispatcher'
import { genChannelPrefix, ProfileParamConverter, getChannelIdFromUrl, getProfileRootPath } from 'utils/snippet'
import { ResponseTypes, NewMessageTypes } from 'types/ChatTypes'
import { openToast } from 'utils/localDispatcher'
import { sendMessageToSW } from 'utils/workerMessageParser'
import { autoReplyStore, lastPushInfo, muteStore } from 'utils/cacheStore'
import { NORMAL_MESSAGE_NOTIFICATION, BIZ_PROFILE_MESSAGE_NOTIFICATION, getLocalStorage } from 'utils/localStorage'
import { unreadCountSend } from 'utils/socket/socketDispatcher'
import { forceRouting } from 'utils/localDispatcher'

function chatResParser(res: ResponseTypes, socket: WebSocket) {
  if (res.status === 1) {
  } else if (res.status === 4) {
    if (res.statusMessage) openToast(res.statusMessage)
  } else if (res.status === 5) {
    openToast(res.statusMessage || '잘못된 요청이에요')
  } else if (res.status === 12) {
    openToast('대화 상대에게 차단되어 메시지를 보낼 수 없어요.')
  } else if (res.status === 13) {
    openToast('차단한 사용자에게는 메시지를 보낼 수 없어요.')
  } else if (res.status === 15) {
    store.dispatch({ type: 'utils/setIsBlocked', payload: true })
    return false
  } else {
    if (store.getState().authStore.selectType === 'business') {
      const uid = store.getState().authStore.selectUser
      new Error(`other status: ${res.status}, message: ${res.statusMessage}, uid: ${uid}`)
    }
  }
  switch (res.name) {
    case 'GetChannelsResponse': {
      const flag = store.getState().chatStore.channelAddFlag
      if (flag) {
        store.dispatch({
          type: 'chat/addChannelList',
          payload: {
            channelList: res.getChannels?.channelsList,
            nextPagingKey: res.getChannels?.nextPagingKey,
          },
        })
      } else {
        store.dispatch({
          type: 'chat/setChannelList',
          payload: {
            channelList: res.getChannels?.channelsList,
            nextPagingKey: res.getChannels?.nextPagingKey,
          },
        })
      }
      store.dispatch({
        type: 'chat/setUnreadObj',
        payload: res.getChannels?.unreadCount,
      })
      closeLoader()
      break
    }
    case 'GetMessagesResponse': {
      if ((res.getMessages?.messagesList || []).length > 0) {
        store.dispatch({
          type: 'chat/appendMessageList',
          payload: res.getMessages?.messagesList.reverse(),
        })
      }
      closeLoader()
      break
    }
    case 'SendMessageResponse': {
      const { status } = res
      if (status === 1) {
        store.dispatch({
          type: 'chat/sendMessageReceive',
          payload: res.sendMessage?.message,
        })
      }
      closeLoader()
      break
    }
    case 'NewMessageEvent': {
      const message = res.newMessage
      if (message) {
        getNewMessage(socket, message.channelId, message)
      }
      store.dispatch({
        type: 'chat/setUnreadObj',
        payload: res.newMessage?.unreadCount,
      })
      break
    }
    case 'ReadMessageEvent': {
      // 상대방이 메시지 읽은 것
      const targetChannelId = res.readMessage?.channelId
      store.dispatch({
        type: 'chat/readMessageNoti',
        payload: res.readMessage,
      })
      if (targetChannelId) {
        unreadCountSend()
      }
      break
    }
    case 'MarkAsReadResponse': {
      const userId = store.getState().authStore.user.id
      store.dispatch({
        type: 'chat/readChannelMessage',
        payload: { ...res.markAsRead, userId: Number(userId) },
      })
      store.dispatch({
        type: 'chat/setUnreadObj',
        payload: res.markAsRead?.unreadCount,
      })
      break
    }
    case 'GetChannelResponse': {
      const targetChannel = res.getChannel?.channel
      const channelList = store.getState().chatStore.channelList
      const selectChannelId = getChannelIdFromUrl()
      if (targetChannel?.id === selectChannelId) {
        store.dispatch({
          type: 'chat/setSelectChannel',
          payload: targetChannel,
        })
      } else if (channelList.some((channelItem) => channelItem.id === targetChannel?.id)) {
        store.dispatch({
          type: 'chat/setChannelData',
          payload: targetChannel,
        })
      } else {
        store.dispatch({
          type: 'chat/addChannelItem',
          payload: targetChannel,
        })
      }

      const persistentMenu = res.getChannel?.persistentMenu
      if (persistentMenu) {
        store.dispatch({
          type: 'utils/setReplyQuestion',
          payload: persistentMenu.itemsList,
        })
      } else {
        store.dispatch({ type: 'utils/setReplyQuestion', payload: [] })
      }
      break
    }
    case 'LeaveChannelResponse': {
      // channel id 데이터 전달받아서 나가기?
      break
    }
    case 'GetStickersResponse': {
      if (res.getStickers?.stickersList) {
        store.dispatch({
          type: 'chat/setStickerList',
          payload: res.getStickers.stickersList || [],
        })
      }
      break
    }
    case 'GetBizAccountsResponse': {
      const bizAccountList = res.getBizAccounts?.bizAccountsList
      store.dispatch({
        type: 'user/setBizAccount',
        payload: bizAccountList || [],
      })
      store.dispatch({
        type: 'chat/setBizRequestPending',
        payload: false,
      })
      if (bizAccountList) {
        bizAccountList.forEach((bizItem) => {
          const message = getAutoReplyList(bizItem.id).serializeBinary()
          socket.send(message)
        })
      }
      break
    }
    case 'JoinMemberEvent': {
      const joinMember = res.joinMember
      const me = store.getState().authStore.user
      if (joinMember?.member?.id !== Number(me.id)) {
        store.dispatch({
          type: 'chat/changeMemberList',
          payload: {
            type: 'add',
            message: joinMember,
          },
        })
      }
      break
    }
    case 'LeaveMemberEvent': {
      const leaveMember = res.leaveMember
      const me = store.getState().authStore.user
      if (leaveMember?.member?.id !== Number(me.id)) {
        store.dispatch({
          type: 'chat/changeMemberList',
          payload: {
            type: 'remove',
            message: leaveMember,
          },
        })
      } else {
        store.dispatch({
          type: 'chat/leaveChannel',
          payload: { channelId: leaveMember.channelId },
        })
        const selectChannelId = getChannelIdFromUrl()
        if (selectChannelId === leaveMember.channelId) {
          const targetPath = getProfileRootPath()
          forceRouting(targetPath)
        }
      }
      break
    }
    case 'UpdateBlockStatusResponse': {
      store.dispatch({
        type: 'chat/setChannelBlock',
      })
      break
    }
    case 'UpdateUserChannelResponse': {
      const channel = res.updateUserChannel?.channel
      if (channel) {
        store.dispatch({
          type: 'chat/setChannelUpdate',
          payload: channel,
        })
      }
      break
    }
    case 'GetUnreadCountResponse': {
      store.dispatch({ type: 'chat/setUnreadObj', payload: res.getUnreadCount?.unreadCount })
      break
    }
    case 'GetChannelListHeaderResponse': {
      const channelListHeader = res?.getChannelListHeader?.channelListHeader
      if (channelListHeader) {
        store.dispatch({ type: 'chat/setChannelHeader', payload: channelListHeader })
      }
      break
    }
    case 'CloseChannelListHeaderResponse': {
      store.dispatch({ type: 'chat/closeChannelHeader' })
      break
    }
    case 'DeleteMessageResponse': {
      if (res.deleteMessage?.message) {
        const messageItem = res.deleteMessage?.message
        store.dispatch({ type: 'chat/messageUpdate', payload: messageItem })
      }
      break
    }
    case 'UpdateMessageEvent': {
      store.dispatch({ type: 'chat/messageUpdate', payload: res.updateMessage?.message })
      break
    }
    case 'ListAutoRepliesResponse': {
      store.dispatch({ type: 'utils/setReplyList', payload: res.listAutoReplies?.autoRepliesList || [] })
      if (res.listAutoReplies?.autoRepliesList) {
        autoReplyStore.set(res.bizAccountId, res.listAutoReplies?.autoRepliesList)
      }
      break
    }
    case 'CreateAutoReplyResponse': {
      AutoReplyFirstSubmit()
      break
    }
    case 'DeleteAutoReplyResponse': {
      const message = getAutoReplyList().serializeBinary()
      socket.send(message)
      break
    }
    case 'GetAutoReplyMetadataResponse': {
      const metaData = { ...res.getAutoReplyMetadata?.autoReplyMetadata }
      store.dispatch({
        type: 'utils/setReplyMeta',
        payload: {
          active: metaData.active || false,
        },
      })
      break
    }
    case 'UpdateAutoReplyMetadataResponse': {
      const metaData = { ...res.updateAutoReplyMetadata?.autoReplyMetadata }
      store.dispatch({
        type: 'utils/setReplyMeta',
        payload: {
          active: metaData.active || false,
        },
      })
      break
    }
    default: {
    }
  }
}

function getNewMessage(socket: WebSocket, channelId: string, newMessage: NewMessageTypes) {
  const channelList = store.getState().chatStore.channelList
  const selectChannelId = store.getState().chatStore.selectChannelId
  const selectUser = store.getState().authStore.user
  const bizAccountList = store.getState().authStore.bizAccount
  const senderId = newMessage?.senderId
  const receiverId = newMessage?.receiver?.id
  const newMsgText = newMessage.content.split(`${newMessage.senderNickname} : `)[1]
  const silenceCheck = autoReplyStore.dataList[newMessage?.bizAccountId]?.find(
    (replyItem) => replyItem.question?.text === newMsgText,
  )
  if (senderId === receiverId) {
  } else if (!getLocalStorage(NORMAL_MESSAGE_NOTIFICATION) && receiverId === selectUser.id) {
  } else if (newMessage.silence && silenceCheck) {
  } else if (
    !getLocalStorage(BIZ_PROFILE_MESSAGE_NOTIFICATION) &&
    bizAccountList.some((item) => item.id === receiverId)
  ) {
  } else if (newMessage.channelId === selectChannelId && document.hasFocus()) {
  } else if (muteStore.dataList[newMessage.channelId]) {
  } else {
    const title = newMessage.senderNickname
    const body = `${newMessage.sender?.nickname}: ${newMessage.notification?.message}`
    const image = ProfileParamConverter(newMessage.senderProfile)
    lastPushInfo.set(title, body, image, newMessage.messageId, newMessage.channelId)

    const receiverId = Number(newMessage.receiver?.id)
    const channelUrl = generateChannelUrl(newMessage.channelId, receiverId)
    sendMessageToSW({
      type: 'notificationToSW',
      data: {
        pathname: channelUrl,
        cid: newMessage.channelId,
        mid: newMessage.messageId,
      },
    })
  }
  const channelExist = channelList.find((x) => x.id === channelId)
  if (channelExist) {
    const query = getMessagesQuery({ channelId: channelId, messageId: newMessage.messageId, limit: 1 })
    socket.send(query.serializeBinary())
  } else {
    const selectUser = store.getState().authStore.selectUser
    // receiverId 와 selectUser가 같지만, 우연히 hoian Id와 businessId가 같은 경우도 존재
    if (newMessage.receiver?.id === selectUser) {
      store.dispatch({
        type: 'chat/addNotExistChnanelMessage',
        payload: newMessage,
      })
      const query = bizAccountList.some((item) => item.id === receiverId)
        ? getChannelItem(channelId, receiverId)
        : getChannelItem(channelId)
      socket.send(query.serializeBinary())
    }
  }
}

const generateChannelUrl = (channelId: string, userId: number) => {
  const bizAccountList = store.getState().authStore.bizAccount.map((bizAccountItem) => bizAccountItem.id)
  const targetBizId = bizAccountList.find(bizId => bizId === userId)
  const urlPrefix = targetBizId ? `business/${targetBizId}` : `room`
  return `/${urlPrefix}/${genChannelPrefix((channelId?.length || 0) * 7)}${window.btoa(channelId)}`
}

export { chatResParser }
