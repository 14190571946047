import React, { useState, useEffect } from 'react'

import { ChannelTypes } from 'types/ChatTypes'

import ChannelListPreview from './ChannelListPreview'
import ChannelListOption from './ChannelListOption'
import { ChannelPreviewWrapper } from './ChannelListStyle'

interface ChannelListBodyProps {
  channelList: ChannelTypes[]
  linkPath: string
  unreadCheck: boolean
  scroll: React.RefObject<HTMLUListElement>
}
const ChannelListBody = ({ channelList, linkPath, unreadCheck, scroll }: ChannelListBodyProps) => {
  const [filteredChannel, setFillteredChannel] = useState<ChannelTypes[]>([])
  useEffect(() => {
    if (unreadCheck) {
      setFillteredChannel(channelList.filter((chatItem) => chatItem.unreadCount > 0))
    } else {
      setFillteredChannel(channelList)
    }
  }, [unreadCheck, channelList])
  return (
    <>
      {channelList.length > 0 ? (
        filteredChannel.map((chatItem: ChannelTypes) => {
          return (
            <ChannelPreviewWrapper key={chatItem.id}>
              <ChannelListPreview chatItem={chatItem} linkPath={linkPath} />
              <ChannelListOption channelId={chatItem.id} isFavorite={chatItem.isFavorite} resourceType={chatItem.resourceType} scroll={scroll} />
            </ChannelPreviewWrapper>
          )
        })
      ) : (
        <li className="not-exist-channelList">
          <div className="title">채팅 목록이 비어있어요.</div>
          <div className="description">
            <div>당근 앱에서 중고거래 이웃 또는</div>
            <div>동네가게와 채팅을 시작해보세요.</div>
          </div>
        </li>
      )}
    </>
  )
}

export default ChannelListBody
