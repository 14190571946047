import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'

import ExitChannel from 'components/chatMain/chatOption/ExitChannel'

import { StoreState } from 'store/ducks'
import { SIDEBAR_WIDTH } from 'constants/resource'
import { ChannelListOptionWrapper } from './ChannelListItemStyle'
import { EllipsisIcon } from 'utils/SVGIcons'

import { openPopup, closePopup, openModal, closeModal } from 'utils/localDispatcher'
import { muteControl } from 'utils/localDispatcher'
import { muteStore } from 'utils/cacheStore'
import { favoriteControl } from 'utils/socket/socketDispatcher'
import { gtagEmit } from 'utils/eventUtils'

interface ChannelListOptionProps {
  channelId: string
  isFavorite: boolean
  resourceType: string
  scroll: React.RefObject<HTMLUListElement>
}
const ChannelListOption = ({ channelId, isFavorite, resourceType, scroll }: ChannelListOptionProps) => {
  const [isShow, setIsShow] = useState(false)
  const handleLeaveChannel = (e: React.SyntheticEvent) => {
    e.preventDefault()
    openModal({
      isShow: true,
      content: <ExitChannel closeModal={closeModal} channelId={channelId} />,
    })
    gtagEmit('event', 'click_pc_channellist_more', { button_action: 'leave', resource_type: resourceType})
  }
  const isMute = muteStore.dataList[channelId] ? true : false
  useSelector((state: StoreState) => state.utilStore.muteChange)
  
  const handleMute = (control: boolean) => {
    muteControl(channelId, control)
    closePopup()
    const action_name = control ? 'mute' : 'unmute'
    gtagEmit('event', 'click_pc_channellist_more', { button_action: action_name, resource_type: resourceType})
  }
  const handleFavorite = (control: boolean) => {
    favoriteControl(channelId, control)
    closePopup()
    const action_name = control ? 'pin' : 'unpin'
    gtagEmit('event', 'click_pc_channellist_more', { button_action: action_name, resource_type: resourceType})
  }
  const handleShow = (e: React.MouseEvent<HTMLDivElement>) => {
    // e.stopPropagation()
    const { currentTarget } = e
    setTimeout(() => {
      setIsShow((_) => true)
      const { offsetHeight, offsetWidth } = currentTarget
      const coordX = currentTarget.offsetLeft + SIDEBAR_WIDTH + offsetWidth / 2
  
      const rect = currentTarget.getBoundingClientRect()
      const liHeight = currentTarget.parentElement?.clientHeight || 0
      // 64:header
      const currentY = rect.y - 64 + offsetHeight + offsetHeight * 0.5
      const isTop = currentY > 400
      const calcY = isTop ? currentY - liHeight : currentY - 1
      openPopup({
        content: (
          <div onClick={(e) => e.stopPropagation()} className={`popup-wrapper ${isTop ? 'reverse' : ''}`}>
            {isMute ? (
              <div className="popup-item" onClick={() => handleMute(false)}>
                알림음 켜기
              </div>
            ) : (
              <div className="popup-item" onClick={() => handleMute(true)}>
                알림음 끄기
              </div>
            )}
            {isFavorite ? (
              <div className="popup-item" onClick={() => handleFavorite(false)}>
                채팅방 상단 고정 해제
              </div>
            ) : (
              <div className="popup-item" onClick={() => handleFavorite(true)}>
                채팅방 상단 고정
              </div>
            )}
            <div className="popup-item" onClick={handleLeaveChannel}>
              채팅방 나가기
            </div>
            <div className={isTop ? 'add-arrow down' : 'add-arrow'} />
          </div>
        ),
        isShow: true,
        coord: [coordX, calcY],
      })
      gtagEmit('event', 'click_pc_channellist_more', { button_action: 'more', resource_type: resourceType})
    }, 0)
  }
  const handleClosePopup = () => {
    closePopup()
    setIsShow(false)
  }
  const closeFromScroll = () => {
    closePopup()
    setIsShow(false)
  }
  const scrollTarget = scroll.current
  useEffect(() => {
    if (isShow) {
      window.addEventListener('click', handleClosePopup)
      if (scrollTarget) {
        scrollTarget.addEventListener('scroll', closeFromScroll)
      }
    }
    return () => {
      window.removeEventListener('click', handleClosePopup)
      if (scrollTarget) {
        scrollTarget.removeEventListener('scroll', closeFromScroll)
      }
    }
  }, [isShow, scrollTarget])
  return (
    <ChannelListOptionWrapper onClick={handleShow} className={`common-bg-hover ${!isShow ? 'only-hover' : ''}`}>
      <span className="option-controller">
        <EllipsisIcon />
      </span>
    </ChannelListOptionWrapper>
  )
}

export default memo(ChannelListOption)
