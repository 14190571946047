import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from 'store/ducks'
import { openToast } from 'utils/localDispatcher'
import { ChatMacroForm } from './ChatSendStyle'

interface ChatMacroProps {
  setShowMacro: React.Dispatch<React.SetStateAction<boolean>>
}
const ChatMacro = ({ setShowMacro }: ChatMacroProps) => {
  const macroList = useSelector((state: StoreState) => state.utilStore.macroList)
  const dispatch = useDispatch()
  const [macroForm, setMacroForm] = useState({
    ...macroList,
  })
  const handleSetMacro = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setMacroForm((origin) => ({
      ...origin,
      [name]: value,
    }))
  }
  const handleSaveMacro = (e: React.SyntheticEvent) => {
    e.preventDefault()
    dispatch({
      type: 'utils/setMacroList',
      payload: { ...macroForm },
    })
    setShowMacro(false)
    openToast('저장되었습니다.')
  }
  const closeMacro = useCallback(() => setShowMacro(false), [setShowMacro])
  useEffect(() => {
    window.addEventListener('click', closeMacro)
    return () => window.removeEventListener('click', closeMacro)
  }, [closeMacro])
  return (
    <ChatMacroForm id="chatMacroForm" onSubmit={handleSaveMacro} onClick={(e) => e.stopPropagation()}>
      <div className="form-body">
        <div className="title-wrapper">
          <div className="title">자주 쓰는 문구</div>
          <div className="description">자주 쓰는 문구를 단축키로 저장하고, 채팅창에서 해당 단축키를 눌러보세요.</div>
        </div>
        {Object.keys(macroForm).map((idx) => (
          <div className="macro-item" key={idx}>
            <span className="macro-label">{`alt + ${Number(idx) % 10}`}</span>
            <textarea className="textarea" name={idx} value={macroForm[idx]} onChange={handleSetMacro} />
          </div>
        ))}
      </div>
      <div className="form-footer">
        <button className="button cancel" type="button" onClick={() => setShowMacro(false)}>
          취소
        </button>
        <button className="button save" type="submit">
          저장
        </button>
      </div>
    </ChatMacroForm>
  )
}

export default ChatMacro
