import styled from '@emotion/styled'

const ChatOptionStyle = styled.div`
  .option-container {
    position: absolute;
    right: 24px;
    top: 53px;
    width: 200px;
    border: 1px solid var(--seed-scale-color-gray-600);
    border-radius: 8px;
    padding: 10px;

    font-size: 14px;
    line-height: 150%;
    background-color: var(--seed-scale-color-gray-50);
    color: var(--seed-scale-color-gray-700);

    will-change: opacity;
    animation: fadeIn ease-in 0.3s;
    z-index: 999;
    .option-item {
      border-radius: 6px;
      padding: 10px;
      & ~ .option-item {
        margin-top: 4px;
      }
    }
  }
  .more-button-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 24px;
    height: 24px;
  }
`
const OptionModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 336px;
  min-height: 160px;
  border-radius: 10px;

  font-size: 16px;
  line-height: 150%;

  .content {
    margin-bottom: 24px;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .description {
    margin-top: 8px;
    font-size: 16px;
  }
`

export { ChatOptionStyle, OptionModalStyle }
