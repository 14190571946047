import { ButtonInModal } from 'components/common/commonStyle'
import { deleteToken } from 'utils/auth/tokenUtils'
import { LogoutModalBody } from './UserAreaStyle'
import { logLevel } from 'utils/consoleLevel'
import React from 'react'
import { DAANGN_BUSINESS_URL, WEB_CHAT_URL } from 'constants/config'

interface LogoutModalCompProps {
  closeModal: Function
}
const LogoutModalComp = ({ closeModal }: LogoutModalCompProps) => {
  const handleClose = () => closeModal()
  const handleLogout = (e: React.SyntheticEvent) => {
    try {
      e.preventDefault()
      deleteToken()
      handleClose()
      window.location.href = `${DAANGN_BUSINESS_URL}/logout?redirect=${encodeURIComponent(WEB_CHAT_URL)}`
    } catch {
      logLevel.error('LogoutModalComp handleLogout error')
    }
  }
  return (
    <LogoutModalBody>
      <div>로그아웃하시면 다시 사용할 때, 인증 과정을 거쳐야해요. 정말 로그아웃 하시겠어요?</div>
      <div className="button-wrapper">
        <ButtonInModal type="button" className="common-bg-hover" onClick={handleClose}>
          취소
        </ButtonInModal>
        <ButtonInModal type="button" className="confirm" onClick={handleLogout}>
          확인
        </ButtonInModal>
      </div>
    </LogoutModalBody>
  )
}

export default LogoutModalComp
