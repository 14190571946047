import styled from '@emotion/styled'
import { HEADER_HEIGHT, APP_PADDING_TOP } from 'constants/resource'

const AppWrapper = styled.div`
  padding-top: ${APP_PADDING_TOP};
  max-height: 100vh;
  overflow: auto;
  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - ${HEADER_HEIGHT});
  }
`

const customScrollForWindow = `${navigator?.appVersion?.indexOf('Mac') > -1 ? '' : `
::-webkit-scrollbar {
  opacity: 0;
  width: 7px;
  height: 7px;
  appearance: auto;
  -webkit-appearance: auto;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-resizer {
  background-color: transparent;
}`}`

export {
  AppWrapper,
  customScrollForWindow
}