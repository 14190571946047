import { useNavigateRef } from 'hooks/useNavigateRef'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { StoreState } from 'store/ducks'

const FakeRouter = () => {
  const navigate = useNavigateRef()
  const route = useSelector((state: StoreState) => state.utilStore.forceRoute)
  const location = useLocation()
  const [lastPathname, setLastPathname] = useState('')

  useEffect(() => {
    if (lastPathname !== location.pathname) {
      setLastPathname(location.pathname)
    } else if (route && lastPathname !== route.path) {
      navigate.current(route.path, { replace: false })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, navigate, location.pathname])
  return <div></div>
}

export default FakeRouter