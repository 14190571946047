import dayjs from 'dayjs'
import { ONE_DAY, ONE_WEEK, ONE_YEAR } from 'constants/dateUnit'

// 디폴트는 기준을 각 유닛으로 한다
// 7일은 초
// 7일~28일은 초
// 달은 month
// 이후는 초
export const agoFormatDate = (targetDateNumber: number | string) => {
  const currentDateNumber = new Date().getTime()
  const differenceTime = currentDateNumber - Number(targetDateNumber)
  const targetDate = dayjs(targetDateNumber).format('YYYY-MM-DD')
  const todayDate = dayjs(currentDateNumber).format('YYYY-MM-DD')
  const [currentYear, targetYear] = [todayDate.split('-')[0], targetDate.split('-')[0]]
  const [currentMonth, targetMonth] = [todayDate.split('-')[1], targetDate.split('-')[1]]

  const subtract = new Date(todayDate).getTime() - new Date(targetDate).getTime()
  if (targetDate === todayDate && differenceTime < ONE_DAY) {
    return dayjs(targetDateNumber).format('a hh:mm')
  } else if (differenceTime < ONE_WEEK) {
    return `${subtract / ONE_DAY}일 전`
  } else if (differenceTime < ONE_WEEK * 4 || currentMonth === targetMonth) {
    return `${Math.floor(subtract / ONE_WEEK)}주 전`
  } else if (differenceTime < ONE_YEAR || currentYear === targetYear) {
    const calcMonth = Number(currentMonth) + 12 - Number(targetMonth)
    return `${calcMonth > 12 ? calcMonth % 12 : calcMonth}달 전`
  } else {
    return `${Number(currentYear) - Number(targetYear)}년 전`
  }
}
