import { useState, useEffect } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { Global, css } from '@emotion/react'

import Header from 'components/header/Header'
import Router from 'components/Router'
import LoadingSpinner from 'components/common/LoadingSpinner'
import BlockUser from 'components/empty/BlockedUser'
import { wrapperTokenRefresh } from 'utils/auth/tokenAPI'
import Maintenance from 'components/empty/Maintenance'

import {
  getAccToken,
  jwtDecoder,
  validateToken,
  deleteToken,
  // saveRefreshToken,
  getDeocdeRefreshToken,
  getRefToken,
} from 'utils/auth/tokenUtils'
import { AppWrapper, customScrollForWindow } from './AppStyle'
import { loadOnboading, getSupportRegion } from 'utils/snippet'
import { gtagEmit } from 'utils/eventUtils'
import { AuthAPI, businessAuthAPI } from 'utils/httpAPI'
import { handleVisibilityChange, lastActionUpdate } from 'utils/auth/sessionManager'
import { register } from 'serviceWorker'
import { handleSWMessage } from 'utils/workerMessageParser'
import FakeRouter from 'components/FakeRouter'
import '@seed-design/stylesheet/global.css'
import { useNavigateRef } from 'hooks/useNavigateRef'
import { store } from 'store'

register()

function App() {
  const navigateRef = useNavigateRef()
  useEffect(() => {
    lastActionUpdate()
    appInit(navigateRef.current)
  }, [navigateRef])
  useEffect(() => {
    window.addEventListener('offline', () => {})
    window.addEventListener('online', () => {
      window.location.reload()
    })
  }, [])

  const [maintenanceMsg, setMaintenanceMsg] = useState('')
  useEffect(() => {
    const allowRegion = getSupportRegion()
    AuthAPI.tokenRefresh('', allowRegion)
      .then((res) => {
        const { status } = res.data
        if (status.code === 'status_maintenance') {
          setMaintenanceMsg(status.message)
        }
      })
      .catch((err) => {
        const res = err.response
        if (res?.status?.code === 'status_maintenance') {
          setMaintenanceMsg(res.status.message)
        }
      })
    document.addEventListener('visibilitychange', handleVisibilityChange, false)
    window.addEventListener('focus', handleVisibilityChange, false)
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handleSWMessage)
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', handleVisibilityChange)
      if ('serviceWorker' in navigator) {
        navigator?.serviceWorker.removeEventListener('message', handleSWMessage)
      }
    }
  }, [])
  return (
    <AppWrapper>
      <Global
        styles={css`
          ${customScrollForWindow}
        `}
      />
      <Header />
      {maintenanceMsg ? (
        <Maintenance message={maintenanceMsg} />
      ) : (
        <>
          <main className="body">
            <Router />
          </main>
          <LoadingSpinner />
          <BlockUser />
          <FakeRouter />
        </>
      )}
    </AppWrapper>
  )
}

const appInit = async (navigate: NavigateFunction) => {
  const accessToken = getAccToken()
  const decodedAccToken = jwtDecoder(accessToken)
  let refreshToken = getDeocdeRefreshToken()
  if (window.location.pathname === '/login') return false

  if (decodedAccToken && validateToken(decodedAccToken)) {
    gtagEmit('user', 'custom_client_id', {
      custom_client_id: decodedAccToken.uid,
    })
  } else if (refreshToken && validateToken(refreshToken)) {
    const encodeRefToken = getRefToken()
    wrapperTokenRefresh(encodeRefToken)
  } else {
    try {
      const res = await businessAuthAPI.getBizToken()
      const { status, data } = res
      if (status === 200) {
        store.dispatch({ type: 'user/bizSetToken', payload: data.data?.token })
      }
    } catch (_) {
      deleteToken()
      const onboardingDate = loadOnboading()
      if (onboardingDate) {
        navigate(`/login`, { replace: true })
      } else {
        navigate('/onboarding', { replace: true })
      }
    }
  }
}

export default App
