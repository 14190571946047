import { useCallback, useEffect } from 'react'
import ChatReplyQuestion from './ChatReplyQuestion'
import { ChatReplyPopup } from './chatReplyStyle'

interface ChatReplyMissionProps {
  setShowUserReply: React.Dispatch<React.SetStateAction<boolean>>
}
const ChatReplyUserview = ({ setShowUserReply }: ChatReplyMissionProps) => {
  const closeAutoReply = useCallback(() => {
    setShowUserReply(false)
  }, [setShowUserReply])

  useEffect(() => {
    window.addEventListener('click', closeAutoReply)
    return () => window.removeEventListener('click', closeAutoReply)
  }, [closeAutoReply])
  return (
    <ChatReplyPopup onClick={(e) => e.stopPropagation()}>
      <ChatReplyQuestion />
    </ChatReplyPopup>
  )
}

export default ChatReplyUserview
