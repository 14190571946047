import styled from '@emotion/styled'

export const EmptyWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--seed-semantic-color-paper-default);

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .empty-image {
    width: 240px;
    height: 240px;
  }
  .empty-text-wrapper {
    width: 305px;
    .text-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 135%;
      letter-spacing: -0.03em;
      color: var(--seed-scale-color-gray-700);
      opacity: 0.8;
    }
    .text-body {
      margin-top: 8px;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-700);
      opacity: 0.8;
    }
  }
`

export const EmptyBizAccount = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  width: 100vw;
  background-color: var(--seed-semantic-color-paper-default);
  svg {
    fill: var(--seed-scale-color-gray-500);
  }
  color: var(--seed-scale-color-gray-600);
  .empty-biz-textarea {
    margin-top: 28px;
    text-align: center;
    .empty-biz-title {
      font-weight: 700;
      font-size: 26px;
      line-height: 130%;
      letter-spacing: -0.01em;
    }
    .empty-biz-subtitle {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: -0.01em;
    }
    .empty-biz-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      border: 1px solid var(--seed-scale-color-gray-300);
      border-radius: 6px;
      padding: 0 20px;
      height: 48px;

      font-weight: 700;
      font-size: 18px;
      color: var(--seed-scale-color-gray-900);
      letter-spacing: -0.02em;
    }
  }
`
