import styled from '@emotion/styled'

export const ChatReplyPopup = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 48px;
  left: 94px;
  border: 1px solid var(--seed-scale-color-gray-600);
  border-radius: 10px;
  width: 520px;
  height: 600px;
  background-color: var(--seed-scale-color-gray-00);
  .reply-header {
    padding: 24px;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.01em;
    .reply-subtitle {
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
      color: var(--seed-scale-color-gray-700)
    }
  }
`
export const ReplyFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--seed-semantic-color-divider-1);
  padding: 0 22px;
  width: 100%;
  height: 72px;
  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .checkbox-text {
    margin-left: 8px;
  }
  .with-icon-button-text {
    margin-left: 4px;
  }
  svg {
    fill: var(--seed-semantic-color-on-primary);
  }
  .reply-last-button {
    margin-left: 12px;
  }
  .disable-button {
    background-color: var(--seed-scale-color-gray-300);
    color: var(--seed-scale-color-gray-500);
    cursor: not-allowed;
  }
`
export const ReplyBody = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  .reply-body-wrapper {
    padding: 0 24px;
  }
  .reply-label-wrapper {
    position: relative;
    display: block;
    margin-bottom: 24px;
    .reply-label-text {
      display: flex;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
    }
    .reply-input {
      display: flex;
      align-items: center;
      border: 1px solid var(--seed-scale-color-gray-400);
      border-radius: 5px;
      padding: 16px;
      width: 100%;
      height: 48px;
      ::placeholder {
        color: var(--seed-scale-color-gray-500);
      }
      &.invalid-input {
        outline-color: var(--seed-semantic-color-danger);
        background-color: var(--seed-scale-color-red-alpha-50);
      }
    }
    .reply-textarea {
      border: 1px solid var(--seed-scale-color-gray-400);
      border-radius: 5px;
      padding: 12px 16px 32px 16px;
      width: 100%;
      height: 156px;
      resize: none;
      background-color: var(--seed-scale-color-gray-00);
      color: var(--seed-scale-color-gray-900);
      ::placeholder {
        color: var(--seed-scale-color-gray-500);
      }
      &.invalid-textarea {
        outline-color: var(--seed-semantic-color-danger);
        background-color: var(--seed-scale-color-red-alpha-50);
      }
    }
    .reply-text-limit {
      position: absolute;
      right: 16px;
      bottom: 12px;
      font-size: 12px;
      color: var(--seed-scale-color-gray-600);
    }
    .reply-file-wrapper {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--seed-scale-color-gray-400);
      border-radius: 6px;
      width: 72px;
      height: 72px;
      cursor: pointer;
      overflow: hidden;
      .reply-input-file {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
      }
      svg {
        fill: var(--seed-scale-color-gray-900);
      }
      .reply-file-text {
        font-size: 13px;
      }
      .reply-input-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .remove-icon-wrapper {
        position: absolute;
        left: 62px;
        bottom: 64px;
        width: 20px;
        height: 20px;
      }
    }
    .invalid-row {
      display: flex;
      align-items: center;
      margin-top: 4px;
      font-size: 13px;
      color: var(--seed-semantic-color-danger);
      svg {
        margin-right: 4px;
        fill: var(--seed-semantic-color-danger);
      }
    }
  }

  .reply-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--seed-semantic-color-divider-1);
    padding: 20px 24px;
    height: 62px;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: var(--seed-semantic-color-gray-hover);
    }
    svg {
      fill: var(--seed-scale-color-gray-600);
    }
  }

  .reply-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .empty-header {
      font-weight: 700;
      font-size: 20px;
      color: var(--seed-scale-color-gray-600);
    }
    .empty-subheader {
      margin-top: 8px;
      letter-spacing: -0.02em;
      font-size: 14px;
      color: var(--seed-scale-color-gray-600);
    }
  }
`

export const ReplyPreview = styled.div`
  padding: 32px 28px 36px 28px;
  border-radius: 16px;
  width: 468px;
  height: 775px;
  .preview-header {
    display: flex;
    justify-content: space-between;

    color: var(--seed-scale-color-gray-900);
    font-size: 24px;
    letter-spacing: -0.01em;
    .preview-close-wrapper {
      cursor: pointer;
      svg {
        fill: var(--seed-scale-color-gray-500);
      }
    }
  }
  .preview-body {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    border: 1px solid var(--seed-semantic-color-divider-2);
    border-radius: 10px;
    height: 640px;
    .virtual-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--seed-semantic-color-divider-3);
      padding: 0 16px;
      height: 56px;
      min-height: 56px;
      svg {
        fill: var(--seed-scale-color-gray-900);
      }
      .virtual-header-text {
        display: block;
        margin-left: 16px;

        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.04em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .virtual-body {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      padding: 20px 16px 0 16px;
      overflow: auto;
      .preview-question {
        align-self: flex-end;
        border-radius: 20px;
        padding: 8px 14px;
        max-width: 230px;
        background-color: var(--seed-scale-color-carrot-500);

        white-space: pre-wrap;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
      .preview-answer {
        display: flex;
        margin-top: 12px;
        .answer-profile {
          margin-right: 8px;
          border: 1px solid var(--seed-scale-color-gray-alpha-50);
          border-radius: 50%;
          width: 32px;
          max-width: 32px;
          height: 32px;
        }
        .answer-message {
          border-radius: 20px;
          padding: 8px 14px;
          max-width: 230px;
          background-color: var(--seed-scale-color-gray-100);

          letter-spacing: -0.02em;
          white-space: pre-wrap;
        }
        .answer-image-wrapper {
          border: 1px solid var(--seed-semantic-color-divider-1);
          border-radius: 20px;
          width: 230px;
          overflow: hidden;
          .answer-image-item {
            width: 230px;
            object-fit: cover;
            height: 172.5px;
            &.answer-squre {
              height: 230px;
            }
          }
          .with-image-message {
            padding: 16px 14px 14px 14px;
            font-size: 16px;
            letter-spacing: -0.02em;
            white-space: pre-wrap;
          }
        }
      }
    }
    .virtual-footer {
      .virtual-send-wrapper {
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 52px;
        svg {
          fill: var(--seed-scale-color-gray-600);
        }
        .virtual-send-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1 1 0;
          margin: 0 14px 0 10px;
          border-radius: 20px;
          padding: 0 12px;
          height: 40px;

          background-color: var(--seed-scale-color-gray-100);
          letter-spacing: -0.02em;
          color: var(--seed-scale-color-gray-500);
        }
        .send-icon-wrapper {
          svg {
            fill: var(--seed-scale-color-carrot-500);
          }
        }
      }
      .footer-origin-text {
        display: flex;
        align-items: center;
        margin-top: 6px;
        height: 50px;
        padding: 0 16px;
        letter-spacing: -0.02em;
        color: var(--seed-scale-color-gray-900);
      }
    }
  }
`

export const ReplyIntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 68px 36px 36px 36px;
  height: 100%;
  .intro-header {
    text-align: center;
    .intro-title {
      font-weight: 700;
      font-size: 24px;
      letter-spacing: -0.01em;
    }
    .intro-subtitle {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-600);
    }
  }
  .intro-body {
    margin-top: 60px;
    flex: 1 1 0;
    .first-case {
      padding-left: 100px;
      .send-message {
        margin-right: 20px;
      }
    }
    .second-case {
      margin-top: 20px;
      padding-left: 20px;
      .send-message {
        margin-right: 100px;
      }
    }
    .message-row {
      display: flex;
      align-items: center;
      .profile-image {
        margin-right: 8px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        max-height: 32px;
      }
      .message-item {
        display: inline-block;
        padding: 8px 14px;
        border-radius: 20px;
        background-color: var(--seed-scale-color-gray-100);
        color: var(--seed-scale-color-gray-900);
        &.send-message {
          background-color: var(--seed-scale-color-carrot-400);
          color: #ffffff;
        }
      }
      & ~ .message-row {
        margin-top: 12px;
        justify-content: flex-end;
      }
    }
  }

  .intro-footer {
    .intro-button {
      justify-content: center;
      width: 100%;
    }
  }
`
