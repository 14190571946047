import styled from '@emotion/styled'
import { MAIN_WIDTH } from 'constants/resource'

export const ButtonInModal = styled.button`
  border: 1px solid var(--seed-scale-color-gray-300);
  border-radius: 5px;
  height: 40px;
  width: 140px;
  color: var(--seed-scale-color-gray-900);
  font-size: 16px;
  line-height: 140%;
  &.confirm {
    border: none;
    background-color: var(--seed-scale-color-carrot-500);
    color: #ffffff;
    transition: background-color 0.6s;
    &:hover {
      background-color: var(--karrot-hover-color);
    }
  }
`

export const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid var(--seed-semantic-color-divider-1);
  background-color: var(--seed-semantic-color-paper-default);
  .footer-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${MAIN_WIDTH};
    height: 66px;

    color: var(--seed-scale-color-gray-600);
    font-size: 13px;
    line-height: 150%;
    letter-spacing: -0.02em;
    font-weight: 700;
    .link-seperator {
      margin: 0 5px;
    }
    .privacy-policy {
      color: var(--seed-scale-color-gray-800);
    }
    .footer-link {
      margin-left: 12px;
    }
  }
`

export const SwitchCheckboxWrapper = styled.span`
  position: relative;
  display: inline-flex;
  border-radius: 18px;
  width: 30px;
  height: 18px;
  background-color: var(--seed-scale-color-gray-500);
  cursor: pointer;
  .input-chekcbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    background-color: var(--seed-semantic-color-paper-default);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    transition: background-color 0.3s;
  }
  &.chekc-true {
    background-color: var(--seed-scale-color-carrot-500);
    &::after {
      transform: translateX(calc(100% - 2px));
    }
  }
`

export const CommonButton = styled.button`
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--seed-scale-color-gray-300);
  border-radius: 5px;
  padding: 9px 12px;
  min-height: 40px;

  font-weight: 700;
  font-size: 16px;
  color: var(--seed-scale-color-gray-900);
  &.on-primary {
    border: none;
    color: var(--seed-semantic-color-on-primary);
    background-color: var(--seed-semantic-color-primary);
  }
  &.disabled {
    border: none;
    background-color: var(--seed-scale-color-gray-300);
    color: var(--seed-scale-color-gray-500);
    cursor: not-allowed;
    svg {
      fill: var(--seed-scale-color-gray-500);
    }
  }
`

export const CommonPopup = styled.div`
  border-radius: 16px;
  padding: 24px;
  width: 330px;
  .popup-header {
    font-weight: 700;
    font-size: 20px;
  }
  .popup-body {
    margin: 8px 0 16px 0;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.01em;
  }
  .popup-footer {
    display: flex;
    justify-content: space-between;
    .footer-button {
      width: 100%;
      justify-content: center;
      & ~ .footer-button {
        margin-left: 8px;
      }
    }
  }
`