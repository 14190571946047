import { createReducer, createAction } from '@reduxjs/toolkit'

//#region action
export const setQRContent = createAction<string>('qrlogin/setQRContent')
export const setRemainTime = createAction<number>('qrlogin/setRemainTime')
export const minusRemainTime = createAction<null>('qrlogin/minusRemainTime')
//#endregion

//#region store
interface initialStateProps {
  qrcode: string
  remainTime: number
}
const initialState: initialStateProps = {
  qrcode: '',
  remainTime: 60
}
//#endregion
export default createReducer(initialState, (builder) => {
  builder.addCase(setQRContent, (state, action) => {
    state.qrcode = action.payload
  }).addCase(setRemainTime, (state, action) => {
    state.remainTime = action.payload
  }).addCase(minusRemainTime, (state) => {
    state.remainTime -= 1
  })
})
