import { store } from 'store'
import { showBrowserPushNotification } from 'utils/browserPush'
import { lastPushInfo } from 'utils/cacheStore'
import { forceRouting } from 'utils/localDispatcher'
import { audioPlay } from 'utils/snippet'

export const sendMessageToSW = (data: any) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(data)
  }
}

export const handleSWMessage = (event: MessageEvent) => {
  const message = event.data
  if (message.type === 'notificationToClient' && message.data.mid === lastPushInfo.messageId) {
    audioPlay()
    // requestPushPermission() 이제 메시지 왔을 때가 아니라 배너를 통해 알림 허용
    store.dispatch({ type: 'utils/setPermissionChange' })
    if (Notification?.permission === 'granted') {
      showBrowserPushNotification({
        title: lastPushInfo.title,
        body: lastPushInfo.body,
        icon: lastPushInfo.icon,
        data: {
          messageId: lastPushInfo.messageId,
          channelId: lastPushInfo.channelId,
        },
      })
    }
  } else if (message.type === 'clientRoute') {
    forceRouting(message.payload.path)
  }
}
