import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'

//#region send noti
const ToastNoti = styled.div`
  position: absolute;
  bottom: 157px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--seed-scale-color-gray-900);
  opacity: 0.8;
  border-radius: 100px;
  padding: 12px 28px;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: -0.02em;
  color: var(--seed-semantic-color-paper-default);
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
  &.fade-out {
    animation: fadeOut 0.5s;
  }
`
//#endregion send noti

const ToastPopup = () => {
  const sendToast = useSelector((state: StoreState) => state.utilStore.sendToast)
  const [exist, setExist] = useState(false)
  useEffect(() => {
    if (sendToast.isShow) {
      setExist(true)
    }
  }, [sendToast.isShow])
  const endAnimation = () => {
    if (!sendToast.isShow) {
      setExist(false)
    }
  }
  return exist ? (
    <ToastNoti onAnimationEnd={endAnimation} className={sendToast.isShow ? '' : 'fade-out'}>
      <div>{sendToast.content}</div>
    </ToastNoti>
  ) : null
}

export default ToastPopup
