import { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StoreState } from 'store/ducks'
import ChannelList from 'components/channelList/ChannelList'
import { ChatWrapperLayout } from 'components/common/layoutStyle'
import { chatSocketInit } from 'utils/socket/chatSocket'
import { socketStore } from 'utils/socket/socketStore'
import { jwtDecoder, validateToken, getAccToken } from 'utils/auth/tokenUtils'
import { tokenUpdateInterval } from 'utils/auth/tokenAPI'
import { getSupportRegion } from 'utils/snippet'

import ChatMain from 'components/chatMain/ChatMain'
import Footer from 'components/footer/Footer'
import { AuthAPI, businessAuthAPI } from 'utils/httpAPI'
import ReduxPortalPopup from 'components/common/ReduxPortalPopup'
import ReduxModal from 'components/common/ReduxModal'
import ToastPopup from 'components/common/ToastPopup'

const MainForSPA = () => {
  const dispatch = useDispatch()
  const storeAccessToken = useSelector((state: StoreState) => state.authStore.storeAccessToken)
  const bizToken = useSelector((state: StoreState) => state.authStore.bizToken)
  useEffect(() => {
    const accessToken = getAccToken()
    const decodedAccToken = jwtDecoder(accessToken)
    if (decodedAccToken && validateToken(decodedAccToken)) {
      dispatch({
        type: 'user/setOnlyUserData',
        payload: { id: decodedAccToken?.uid, nick: decodedAccToken?.unk },
      })
      if (socketStore.chatSocket === null) {
        const allowRegion = getSupportRegion()
        socketStore.chatSocket = chatSocketInit({ access_token: accessToken, region: allowRegion })
        tokenUpdateInterval()
      }
    } else if (bizToken) {
      if (socketStore.chatSocket === null) {
        const allowRegion = getSupportRegion()
        socketStore.chatSocket = chatSocketInit({ bizToken, region: allowRegion })
      }
    }
  }, [dispatch, bizToken, storeAccessToken])
  useEffect(() => {
    const accessToken = getAccToken()
    if (accessToken) {
      const allowRegion = getSupportRegion()
      AuthAPI.getUserInfo(accessToken, allowRegion).then((res) => {
        const { data } = res
        const proImage = data.profile_image
        if (proImage) {
          dispatch({
            type: 'user/setProfileImage',
            payload: proImage,
          })
        }
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (bizToken) {
      businessAuthAPI
        .getProfiles()
        .then((res) => {
          const businessUser = res.data?.data?.businessUser
          if (businessUser) {
            dispatch({
              type: 'user/setOnlyUserData',
              payload: { nick: businessUser.myInfo?.nickname || businessUser.email || 'unknown' },
            })
          }
        })
        .catch((res) => {})
    }
  }, [bizToken, dispatch])
  return (
    <Fragment>
      <ChatWrapperLayout>
        <div>
          <ReduxPortalPopup />
          <ReduxModal />
        </div>
        <ChannelList />
        <ChatMain />
        <ToastPopup />
      </ChatWrapperLayout>
      <Footer />
    </Fragment>
  )
}

export default MainForSPA
