import styled from '@emotion/styled'

const AnnoMessage = styled.div`
  font-size: 14px;
  color: var(--seed-scale-color-gray-600);
  text-align: center;
`

const SystMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  .syst-box {
    padding: 20px;
    border-radius: 10px;
    font-size: 14px;
    letter-spacing: -0.02em;

    &.info {
      background-color: var(--seed-scale-color-blue-50);
      color: var(--seed-scale-color-blue-800);
    }
    &.alert {
      background-color: var(--seed-scale-color-yellow-50);
      color: var(--seed-scale-color-yellow-800);
    }
    &.warning {
      background-color: var(--seed-scale-color-red-50);
      color: var(--seed-scale-color-red-800);
    }

    .syst-title {
      font-weight: 700;
      margin-right: 4px;
    }
    .syst-button {
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

interface ChildProps {
  senderChat: boolean
}
const MessageWrapper = styled.div<ChildProps>`
  display: flex;
  justify-content: ${(props) => (props.senderChat ? 'start' : 'flex-end')};
  padding: 4px;
  .nickname {
    font-size: 14px;
  }
  &.sequnce-message {
    .message-box {
      border-radius: 20px;
    }
  }
  &.template-message {
    margin-bottom: 16px;
  }
  .message-box {
    display: inline-flex;
    margin: 0;
    padding: 10px 14px;
    max-width: 484px;
    word-break: break-word;
    white-space: pre-wrap;

    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    ${(props) =>
      props.senderChat
        ? `
        border-radius: 2px 20px 20px 20px;
        background-color: var(--seed-scale-color-gray-200);
        color: var(--seed-scale-color-gray-900);`
        : `
        border-radius: 20px 2px 20px 20px;
        background-color: var(--seed-scale-color-carrot-500);
        color: #fff;
    `}
    &.deleted-message {
      ${(props) =>
        props.senderChat ? 'color: var(--seed-scale-color-gray-500);' : 'color: var(--seed-scale-color-carrot-200);'}
      display: flex;
      align-items: center;
      .info-icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .message-wrapper {
    position: relative;
    ${(props) => (props.senderChat ? 'padding-right: 4px;' : 'padding-left: 4px;')}
    .toolbox {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      /* right: calc(100% + 4px); */
      ${(props) => (props.senderChat ? 'left: 100%;' : 'right: 100%;')}

      align-items: center;
      justify-content: center;

      border: 1px solid var(--seed-scale-color-gray-300);
      border-radius: 4px;
      width: 36px;
      height: 36px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
      background-color: var(--seed-scale-color-gray-00);
      transition: background-color 0.3s;
      &:hover {
        background-color: var(--seed-scale-color-gray-200);
      }
    }
    &:hover .toolbox {
      display: flex;
    }
    .message-menu-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      ${(props) => (props.senderChat ? 'left: calc(100% + 44px);' : 'right: calc(100% + 44px);')}
      z-index: 100;

      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      border-radius: 8px;
      background-color: var(--seed-scale-color-gray-900);
      color: var(--seed-scale-color-gray-00);
      .message-menu-item {
        padding: 8px 12px;
        font-size: 12px;
        cursor: pointer;
        & ~ .message-menu-item {
          border-left: 1px solid var(--seed-scale-color-gray-500);
        }
        &.receiver-none {
          ${(props) => props.senderChat && 'display: none;'}
        }
      }
    }
    .message-menu-box + .toolbox {
      display: flex;
    }
  }
`

const MessageProfile = styled.div`
  margin-right: 8px;
  .profile-image {
    border: 1px solid var(--profile-image-border);
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  &.sequnce-message {
    margin-right: 40px;
    .profile-image {
      display: none;
    }
  }
`
const StickerElement = styled.img`
  width: 140px;
  height: 140px;
  object-fit: contain;
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  min-height: 110px;
  max-height: 221px;
  min-width: 110px;
  max-width: 221px;
  .gallery-img-wrap {
    position: relative;
    width: 110px;
    height: 110px;
    .hover-effect {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      opacity: 0;
      pointer-events: none;
      transition: background-color 0.3s, opacity 0.3s;
    }
    &:hover .hover-effect {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
  &.single-gallery {
    .gallery-image {
      width: 100%;
      height: 100%;
    }
  }
  &.multi-gallery {
    flex-wrap: wrap;
    .gallery-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .only-third-image {
      width: 100%;
      height: 110px;
      object-fit: cover;
    }
    .overlay {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;

      font-size: 18px;
      color: #ffffff;
      line-height: 135%;
      font-weight: bold;
      letter-spacing: -0.03em;
      pointer-events: none;
    }
  }
`
const ImagTypeWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
  overflow: hidden;
  .image-element {
    width: 100%;
    height: 100%;
    object-fit: contain;
    &.extension-png {
      background-color: #f1f1f1;
    }
  }
  .hover-effect {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    pointer-events: none;
    transition: background-color 0.3s, opacity 0.3s;
  }
  &:hover .hover-effect {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.15);
  }
`
const TempMessageWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 250px;
  font-size: 0;
  .temp-image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 186px;
    object-fit: cover;
    &.aspect-ratio-suqre {
      height: 248px;
    }
  }
  .temp-message-wrap {
    display: block;
    padding: 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    .pre-text {
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
  .temp-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 12px;
    height: 36px;
    line-height: 135%;
    border-radius: 5px;
    background: var(--seed-scale-color-gray-100);
    white-space: nowrap;

    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: var(--seed-scale-color-gray-700);
  }
  .thumbnail-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .thumbnail-image {
      border: 1px solid var(--seed-semantic-color-divider-1);
      border-radius: 4px;
      width: 48px;
      height: 48px;
      object-fit: cover;
      &.start-thumbnail {
        margin-right: 12px;
      }
      &.end-thumbnail {
        margin-left: 12px;
      }
    }
    .content-wrapper {
      flex-grow: 1;
      .content-title {
        font-weight: 700;
      }
      .content-text {
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
  }
  &.no-support-message {
    width: auto;
  }
`
const InlineTemplateStyle = styled.div`
  .inline-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
`
const IconTemplate = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--seed-semantic-color-divider-1);
  border-radius: 20px;
  width: 190px;
  height: 52px;
  .icon-image {
    margin: 0 19px;
    width: 18px;
    height: 18px;
  }
`
const MessageSubInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 4px;
  &.sender {
    align-items: flex-end;
  }
  .unread-dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--seed-scale-color-carrot-500);
  }
  .unread-text {
    font-size: 12px;
    color: var(--seed-scale-color-gray-600);
  }
  .message-date {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: var(--seed-scale-color-gray-600);
  }
`

const ChatMessageModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 336px;
  height: 160px;
  border-radius: 10px;

  color: var(--seed-scale-color-gray-900);
  font-size: 16px;
  line-height: 150%;

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    &.one-button {
      justify-content: center;
    }
  }
`

const ImageGalleryWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 600px;
  text-align: center;

  overflow: hidden;

  .image-wrapper {
    display: flex;
    @media (prefers-reduced-motion: no-preference) {
      transition: transform ease-in-out 0.5s;
    }
  }
  .gallery-image {
    width: 600px;
    min-width: 600px;
    height: 600px;
    object-fit: contain;
  }
  .page-text {
    color: rgba(255, 255, 255, 0.5);
    .current-page {
      color: #fff;
    }
  }
`
const MoveButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 300;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  &.next-button {
    left: 310px;
  }
  &.prev-button {
    right: 310px;
    transform: rotateZ(180deg);
  }
`

export {
  AnnoMessage,
  SystMessage,
  MessageWrapper,
  MessageProfile,
  StickerElement,
  ImagTypeWrapper,
  ImageWrapper,
  TempMessageWrapper,
  InlineTemplateStyle,
  IconTemplate,
  MessageSubInfo,
  ChatMessageModal,
  ImageGalleryWrapper,
  MoveButton,
}
