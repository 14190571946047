import { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getChannelsQuery, getChannelListHeader } from 'utils/protobuf/query'

import { StoreState } from 'store/ducks'
import { ChannelListWrapper, FAQWrapper, ChannelWrapper, MoreLoadButton } from './ChannelListStyle'
import ChannelListBody from './ChannelListBody'
import ChannelListHeader from './ChannelListHeader'
import ChannelListSidebar from './ChannelListSidebar'
import ChannelListNotice from './ChannelListNotice'
import OptionUnread from './OptionUnread'
import { socketStore } from 'utils/socket/socketStore'
import { QuestionIcon } from 'utils/SVGIcons'
import { FAQ_URL } from 'constants/resource'
import { IS_PROD } from 'constants/config'
import { useBizAccountCheck } from 'hooks/useBizAccountCheck'
import { useNavigateRef } from 'hooks/useNavigateRef'


const ChannelList = () => {
  const dispatch = useDispatch()
  const channelList = useSelector((state: StoreState) => state.chatStore.channelList)

  // select channel reset when access root or check unread
  const selectUser = useSelector((state: StoreState) => state.authStore.selectUser)
  const isBizRequestPending = useSelector((state: StoreState) => state.chatStore.isBizRequestPending)
  const [unreadCheck, setUnreadCheck] = useState(false)
  useEffect(() => {
    if (socketStore.chatSocket?.readyState !== WebSocket.OPEN) return
    if (isBizRequestPending) return

    dispatch({
      type: 'chat/setChannelAddFlag',
      payload: false,
    })
    socketStore.chatSocket.send(getChannelsQuery({unreadCheck}).serializeBinary())

    if (IS_PROD) return
    socketStore.chatSocket.send(getChannelListHeader().serializeBinary())
  }, [selectUser, isBizRequestPending, unreadCheck])

  const pageKey = useSelector((state: StoreState) => state.chatStore.nextPagingKey)
  const [scrollPause, setScrollPause] = useState(false)
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (scrollPause) return
      if (!pageKey) return
      if (socketStore.chatSocket?.readyState !== WebSocket.OPEN) return

      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
      if (scrollTop + clientHeight !== scrollHeight) return

      setScrollPause(true)
      dispatch({
        type: 'chat/setChannelAddFlag',
        payload: true,
      })
      socketStore.chatSocket.send(getChannelsQuery({ pageKey, unreadCheck }).serializeBinary())
    },
    [scrollPause, pageKey, unreadCheck],
  )

  const onClick = useCallback(() => {
    if (!pageKey) return 
    if (socketStore.chatSocket?.readyState !== WebSocket.OPEN) return

    dispatch({
      type: 'chat/setChannelAddFlag',
      payload: true,
    })
    socketStore.chatSocket.send(getChannelsQuery({ pageKey, unreadCheck }).serializeBinary())
  }, [pageKey, unreadCheck])

  useEffect(() => {
    setScrollPause(false)
  }, [channelList])
  const scrollRef = useRef<HTMLUListElement>(null)
  const [, channelType, channelName] = window.location.pathname.split('/')
  const linkPath = channelType === 'business' ? `${channelType}/${channelName}` : 'room'

  const navigateRef = useNavigateRef()
  const { bizToken, bizAccount } = useBizAccountCheck()
  useEffect(() => {
    if(window.location.pathname === '/' && bizToken && bizAccount.length > 0) {
      navigateRef.current(`/business/${bizAccount[0].id}`, { replace: true })
    }
  }, [bizToken, bizAccount, navigateRef])

  return (
    <ChannelListWrapper>
      {bizToken && bizAccount.length === 0 ? null : (
        <>
          <ChannelListSidebar />
          <FAQWrapper>
            <ChannelListHeader />
            <OptionUnread unreadCheck={unreadCheck} setUnreadCheck={setUnreadCheck} />
            {IS_PROD ? null : <ChannelListNotice />}
            <ChannelWrapper onScroll={onScroll} tabIndex={0} role="list" aria-label={'내 채널 리스트'} ref={scrollRef}>
              <ChannelListBody
                channelList={channelList}
                linkPath={linkPath}
                unreadCheck={unreadCheck}
                scroll={scrollRef}
              />
              {(channelList.length > 0 && pageKey) ? (
                <MoreLoadButton onClick={onClick}>
                  더보기
                </MoreLoadButton>
                ) : (null)}
            </ChannelWrapper>
            <div className="faq-container">
              <a className="faq-content common-bg-hover" href={FAQ_URL} target="_blank" rel="noreferrer">
                <span className="faq-test">자주묻는 질문</span>
                <QuestionIcon />
              </a>
            </div>
          </FAQWrapper>
        </>
      )}
    </ChannelListWrapper>
  )
}

export default ChannelList
