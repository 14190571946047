import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'

export const useBizAccountCheck = () => {
  const bizToken = useSelector((store: StoreState) => store.authStore.bizToken)
  const bizAccount = useSelector((store: StoreState) => store.authStore.bizAccount)
  return {
    bizToken,
    bizAccount,
  }
}
