import styled from '@emotion/styled'
import { FOOTER_HEIGHT } from 'constants/resource'

export const FooterWrapper = styled.div`
  
`

export const FooterBannerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${FOOTER_HEIGHT};

  font-size: 14px;
  color: var(--seed-scale-color-blue-800);
  background-color: var(--seed-scale-color-blue-50);
  .link-strong {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
  .close-wrapper {
    position: absolute;
    right: 30px;
    cursor: pointer;
    svg {
      fill: var(--seed-scale-color-blue-500);
    }
  }
`
