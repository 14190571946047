import { createReducer, createAction } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage, MACRO_NAME } from 'utils/localStorage'
import { ReduxPopup } from 'types/ModalTypes'
import { autoReplyItem, autoReplyMetaProps, autoReplyQuestion } from 'types/PersistentTypes'

//#region action
export const setMacroList = createAction<{}>('utils/setMacroList')
export const setLoader = createAction<boolean>('utils/setLoader')
export const setIsBlocked = createAction<boolean>('utils/setIsBlocked')
export const setLoginFail = createAction<{ type: string }>('utils/setLoginFail')
export const toggleSound = createAction<{}>('utils/toggleSound')
export const setSendToast = createAction<sendToastProps>('utils/setSendToast')

export const setPopup = createAction<ReduxPopup>('utils/setPopup')
export const setModal = createAction<ReduxPopup>('utils/setModal')
export const setForceRoute = createAction<string>('utils/setForceRoute')
export const setVisible = createAction<boolean>('utils/setVisible')
export const setMuteChangeCount = createAction<number>('utils/setMuteChangeCount')
export const setPermissionChange = createAction('utils/setPermissionChange')
export const setReplyList = createAction<autoReplyItem[]>('utils/setReplyList')
export const setReplyQuestion = createAction<autoReplyQuestion[]>('utils/setReplyQuestion')
export const setReplyMeta = createAction<autoReplyMetaProps>('utils/setReplyMeta')
//#endregion

if (!getLocalStorage(MACRO_NAME)) {
  const list = [...Array(9)].reduce((acc, _, idx) => {
    acc[idx + 1] = ''
    return acc
  }, {})
  setLocalStorage({ key: MACRO_NAME, value: list })
}

interface sendToastProps {
  isShow: boolean
  content: string
}
interface initialStateProps {
  macroList: {
    [key: string]: string
  }
  showLoader: boolean
  isBlocked: boolean
  loginFailCount: number
  soundNoti: boolean
  sendToast: sendToastProps
  popup: ReduxPopup
  modal: ReduxPopup
  forceRoute: {
    path: string
  } // component 바깥에서 route를 하는 용도
  visibleTrue: number // 잃었던 포커스가 돌아오면 변경을 감지해주는 용도
  muteChange: number // 채널 음소거를 변경했을 때 채널 리스트에게 이를 알리기 위한 용도
  permissionChange: number
  autoReplyList: autoReplyItem[]
  autoReplyMeta: autoReplyMetaProps
  autoReplyQuestion: autoReplyQuestion[]
}
const initialState: initialStateProps = {
  macroList: getLocalStorage(MACRO_NAME),
  showLoader: false,
  isBlocked: false,
  loginFailCount: 0,
  soundNoti: true,
  sendToast: {
    isShow: false,
    content: '',
  },
  popup: {
    isShow: false,
  },
  modal: {
    isShow: false,
  },
  forceRoute: {
    path: '',
  },
  visibleTrue: 0,
  muteChange: 0,
  permissionChange: 0,
  autoReplyList: [],
  autoReplyMeta: {
    active: false,
  },
  autoReplyQuestion: [],
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setMacroList, (state, action) => {
      const list = action.payload
      state.macroList = list
      setLocalStorage({ key: MACRO_NAME, value: list })
    })
    .addCase(setLoader, (state, action) => {
      state.showLoader = action.payload
    })
    .addCase(setIsBlocked, (state, action) => {
      state.isBlocked = action.payload
    })
    .addCase(setLoginFail, (state, action) => {
      const type = action.payload.type
      if (type === 'add') {
        state.loginFailCount += 1
      } else if (type === 'init') {
        state.loginFailCount = 0
      }
    })
    .addCase(toggleSound, (state, _) => {
      state.soundNoti = !state.soundNoti
    })
    .addCase(setSendToast, (state, action) => {
      state.sendToast = {
        ...state.sendToast,
        ...action.payload,
      }
    })
    .addCase(setPopup, (state, action) => {
      const res = action.payload
      if (res.isShow) {
        state.popup = { ...res }
      } else {
        const channelId = state.popup.channelId
        state.popup = { ...res, channelId }
      }
    })
    .addCase(setModal, (state, action) => {
      const res = action.payload
      state.modal = { ...res }
    })
    .addCase(setForceRoute, (state, action) => {
      state.forceRoute = { path: action.payload }
    })
    .addCase(setVisible, (state) => {
      state.visibleTrue += 1
    })
    .addCase(setMuteChangeCount, (state) => {
      state.muteChange += 1
    })
    .addCase(setPermissionChange, (state) => {
      state.permissionChange += 1
    })
    .addCase(setReplyList, (state, action) => {
      state.autoReplyList = [...action.payload]
    })
    .addCase(setReplyQuestion, (state, action) => {
      state.autoReplyQuestion = [...action.payload]
    })
    .addCase(setReplyMeta, (state, action) => {
      if (action.payload.active !== state.autoReplyMeta.active) {
        state.autoReplyMeta = { ...action.payload }
      }
    })
})
