import { IS_PROD } from 'constants/config'
import { store } from 'store'

interface gtagParamProps {
  [key: string]: string | number | boolean | undefined
}
export const gtagEmit = (type: string, eventName: string, paramObj: gtagParamProps) => {
  if (typeof window?.gtag === 'function' && IS_PROD) {
    window.gtag(type, eventName, paramObj)
  }
}

export function gtagChannelWrapper(buttonAction: string, messageType: string, messageId: number) {
  const selectChannelobj = store.getState().chatStore.selectChannelobj
  gtagEmit('event', 'click_pc_message', {
    button_action: buttonAction,
    message_type: messageType,
    message_id: messageId,
    resource_type: selectChannelobj?.resourceType,
    channel_id: selectChannelobj?.id,
  })
}
