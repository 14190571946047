import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { MessageTypes } from 'types/ChatTypes'
import { StoreState } from 'store/ducks'
import ChatMessageItem from './chatMessage/ChatMessageItem'
import { ChatMessageMain } from './ChatMainStyle'
import ChatHeader from './ChatHeader'
import ScrollCreator from './ScrollCreator'
import { MessageReadCheck } from 'utils/protobuf/query'
import { socketStore } from 'utils/socket/socketStore'
import { NoticeWrapper } from 'components/chatMain/ChatHeaderStyle'
import Modal from 'components/common/Modal'
import InlineTemplate from 'components/chatMain/chatMessage/InlineTemplate'

const checkReadableMessage = (type: string) => {
  return type === 'TEXT' || type === 'STIC' || type === 'IMAG' || type === 'GALL'
}

const ChatMessageList: React.FC = () => {
  const messageList = useSelector((state: StoreState) => state.chatStore.selectChannelMessage)
  const selectChannelobj = useSelector((state: StoreState) => state.chatStore.selectChannelobj)
  const userId = useSelector((state: StoreState) => state?.authStore?.user?.id)
  const visibleTrue = useSelector((state: StoreState) => state.utilStore.visibleTrue)

  const socket = socketStore.chatSocket
  useEffect(() => {
    if (socket?.readyState === 1 && document.visibilityState === 'visible' && document.hasFocus()) {
      if (messageList.length > 0) {
        const lastMessage = messageList[messageList.length - 1]
        const targetSeen = lastMessage.seenMap.find((seenItem) => seenItem[0] === Number(userId))
        if (targetSeen && targetSeen[1].seconds <= 0) {
          socket.send(
            MessageReadCheck({
              channelId: `${selectChannelobj?.id}`,
            }).serializeBinary(),
          )
        }
      } else if ((selectChannelobj?.unreadCount || 0) > 0) {
        socket.send(
          MessageReadCheck({
            channelId: `${selectChannelobj?.id}`,
          }).serializeBinary(),
        )
      }
    }
  }, [messageList, userId, selectChannelobj, socket, visibleTrue])

  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const closeModal = () => setModalObj((origin) => ({ ...origin, isShow: false }))
  const lastReadableMessage =
    messageList.length > 0 ? messageList.filter((messageItem) => checkReadableMessage(messageItem.type)) : []
  return (
    <ChatMessageMain>
      {selectChannelobj ? (
        <>
          <ChatHeader selectChannelobj={selectChannelobj} />
          <ScrollCreator>
            {selectChannelobj.notice?.type === 'info' ? (
              <NoticeWrapper>
                <div className="info">
                  <InlineTemplate richText={selectChannelobj.notice.message || ''} />
                </div>
              </NoticeWrapper>
            ) : null}
            {messageList.map((messageItem: MessageTypes, index: number) => {
              const [today, yesterday] = dayDividerCalc(messageItem, messageList[index - 1])
              const [isSequence, isDate] = sequenceCalc(messageItem, messageList[index - 1], messageList[index + 1])
              const isLast =
                lastReadableMessage.length > 0
                  ? lastReadableMessage[lastReadableMessage.length - 1].id === messageItem.id
                  : false
              return (
                <React.Fragment key={`${selectChannelobj?.id}-${messageItem.id}`}>
                  {today !== yesterday ? (
                    <div className="day-divider">
                      <div className="date-text">{today}</div>
                    </div>
                  ) : null}
                  <ChatMessageItem
                    messageItem={messageItem}
                    selectChannelobj={selectChannelobj}
                    isSequence={isSequence}
                    isDate={isDate}
                    isLast={isLast}
                    setModalObj={setModalObj}
                  />
                </React.Fragment>
              )
            })}
          </ScrollCreator>
          <Modal className="full-size" isVisible={modalObj.isShow} onClose={closeModal} content={modalObj.content} />
        </>
      ) : null}
    </ChatMessageMain>
  )
}

function dayDividerCalc(currentMessage: MessageTypes, prevMessage: MessageTypes | undefined) {
  const prevTime = new Date((Number(prevMessage?.createTime?.seconds) || 0) * 1000)
  const yesterday = `${prevTime.getFullYear()}년 ${prevTime.getMonth() + 1}월${prevTime.getDate()}일`
  const currentTime = new Date((Number(currentMessage?.createTime?.seconds) || 0) * 1000)
  const today = `${currentTime.getFullYear()}년 ${currentTime.getMonth() + 1}월${currentTime.getDate()}일`
  return [today, yesterday]
}

function sequenceCalc(
  currentMessage: MessageTypes,
  prevMessage: MessageTypes | undefined,
  nextMessage: MessageTypes | undefined,
) {
  const prevUserId = Number(prevMessage?.sender?.id) || 0
  const currentUserId = Number(currentMessage.sender?.id)
  const prevTime = Number(prevMessage?.createTime?.seconds) || 0
  const prevMinute = prevTime - (prevTime % (1 * 60))
  const currentTime = Number(currentMessage?.createTime?.seconds) || 0
  const currentMinute = currentTime - (currentTime % (1 * 60))

  const nextUserId = Number(nextMessage?.sender?.id) || 0
  const sequenceCheck = prevUserId === currentUserId && prevMinute === currentMinute
  const nextTime = Number(nextMessage?.createTime?.seconds) || 0
  const nextMinute = nextTime - (nextTime % (1 * 60))
  const isDate = nextUserId !== currentUserId || currentMinute !== nextMinute
  return [sequenceCheck, isDate]
}

export default ChatMessageList
