import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'
import { autoReplyFormProps } from 'types/PersistentTypes'
import { gtagAutoReplyWrapper } from 'utils/eventWrapper'
import { BizProfileParamConverter } from 'utils/snippet'
import { AddPlusRegular, BackArrowRegular, CloseIcon, EmoticonRegular, MissionRegular } from 'utils/SVGIcons'
import { ReplyPreview } from './chatReplyStyle'

interface ChatReplyPreviewProps extends autoReplyFormProps {
  modalClose: () => void
}
const ChatReplyPreview = ({ question, answer, imageObj, modalClose }: ChatReplyPreviewProps) => {
  const selectChannelobj = useSelector((state: StoreState) => state.chatStore.selectChannelobj)
  useEffect(() => {
    gtagAutoReplyWrapper('show_chat_autoreply_preview', {})
  }, [])
  return (
    <ReplyPreview>
      <div className="preview-header">
        자동응답 미리보기
        <span className="preview-close-wrapper" onClick={modalClose}>
          <CloseIcon size={24} />
        </span>
      </div>
      <div className="preview-body">
        <div className="virtual-header">
          <BackArrowRegular />
          <div className="virtual-header-text">{selectChannelobj?.bizAccount?.name}</div>
        </div>
        <div className="virtual-body">
          <div className="preview-question">{question}</div>
          <div className="preview-answer">
            <img
              className="answer-profile"
              src={BizProfileParamConverter(selectChannelobj?.bizAccount?.imageUrl || '')}
              alt={'사장님 프로필'}
            />
            {imageObj.id ? (
              <div className="answer-image-wrapper">
                <img className={`answer-image-item ${imageObj.height < imageObj.width ? '' : 'answer-squre'}`} src={imageObj.url} alt="" />
                <div className="with-image-message">{answer}</div>
              </div>
            ) : (
              <div className="answer-message">
                <div>{answer}</div>
              </div>
            )}
          </div>
        </div>
        <div className="virtual-footer">
          <div className="virtual-send-wrapper">
            <AddPlusRegular />
            <div className="virtual-send-text">
              <span>메시지 보내기</span>
              <EmoticonRegular />
            </div>
            <span className="send-icon-wrapper">
              <MissionRegular />
            </span>
          </div>
          <div className="footer-origin-text">{question}</div>
        </div>
      </div>
    </ReplyPreview>
  )
}

export default ChatReplyPreview
