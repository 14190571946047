import { Routes, Route } from 'react-router-dom'
import LoginWrapper from 'components/login/LoginWrapper'
import OnboadingWrapper from 'components/login/OnboadingWrapper'
import Main from 'components/Main'


const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginWrapper />} />
      <Route path="/onboarding" element={<OnboadingWrapper />} />
      <Route path="/" element={<Main />} />
      <Route path="/room/:channelId" element={<Main />} />
      <Route path="/business/:bizId/:channelId" element={<Main />} />
      <Route path="/business/:bizId" element={<Main />} />
    </Routes>
  )
}

export default Router
