import { useSelector } from 'react-redux'
import { store } from 'store'

import { StoreState } from 'store/ducks'
import { gtagAutoReplyWrapper } from 'utils/eventWrapper'
import { questionAutoReply } from 'utils/protobuf/query'
import { socketStore } from 'utils/socket/socketStore'

import { ReplyBody } from './chatReplyStyle'

const ChatReplyQuestion = () => {
  const autoReplyQuestion = useSelector((state: StoreState) => state.utilStore.autoReplyQuestion)
  const socket = socketStore.chatSocket

  const handleSendQuestion = (payload: string, index:number) => {
    if (socket?.readyState === 1 && payload) {
      const selectChannel = store.getState().chatStore.selectChannelobj
      const message = questionAutoReply(`${selectChannel?.id}`, payload).serializeBinary()
      socket.send(message)
      gtagAutoReplyWrapper('click_chat_persistent_menu', {
        index: index,
        label: autoReplyQuestion[index].label,
        action_type: 'postback'
      })
    }
  }

  return (
    <>
      <div className="reply-header">자동응답 목록</div>
      <ReplyBody>
        {autoReplyQuestion.length > 0 ? (
          autoReplyQuestion.map((replyItem, idx) => (
            <div className="reply-row" key={idx} onClick={() => handleSendQuestion(`${replyItem.postback?.payload}`, idx)}>
              <div>{replyItem.label}</div>
            </div>
          ))
        ) : (
          <div className="reply-empty">
            <div className="empty-header">등록된 자동응답이 없어요.</div>
          </div>
        )}
      </ReplyBody>
    </>
  )
}

export default ChatReplyQuestion
