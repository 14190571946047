import { ReactNode, useRef, useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { StoreState } from 'store/ducks'
import { ScrollWrapper } from './ChatMainStyle'
import { socketStore } from 'utils/socket/socketStore'
import { getMessagesQuery } from 'utils/protobuf/query'
import { DownArrow } from 'utils/SVGIcons'

interface ScrollCreatorProps {
  children: ReactNode
}
const ScrollCreator = ({ children }: ScrollCreatorProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollBottom = useSelector((state: StoreState) => state.chatStore.scrollBottom)
  const scrollUp = useSelector((state: StoreState) => state.chatStore.scrollUp)
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [scrollRef])
  const [lastTopMessageId, setLastTopMessageId] = useState(0)
  const [scrollPause, setScrollPause] = useState(false)
  const socket = socketStore.chatSocket
  const channelId = useSelector((state: StoreState) => state.chatStore.selectChannelId)
  const messageFirstId = useSelector((state: StoreState) => state.chatStore.messageFirstId)
  const [scrollButton, setScrollButton] = useState(false)
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
      if (!scrollPause) {
        if (scrollTop === 0 && scrollHeight > clientHeight) {
          if (socket?.readyState === 1 && channelId && messageFirstId) {
            setLastTopMessageId(messageFirstId)
            const requestMessageId = messageFirstId - 1
            if (requestMessageId > 0) {
              setScrollPause(true)
              const query = getMessagesQuery({
                channelId: channelId.toString(),
                messageId: requestMessageId,
                // messageId: 10,
              })
              socket.send(query.serializeBinary())
            }
          }
        }
      }
      if (scrollTop + clientHeight + 600 < scrollHeight) {
        setScrollButton(true)
      } else if (scrollTop + clientHeight + 600 > scrollHeight) {
        setScrollButton(false)
      }
    },
    [socket, channelId, messageFirstId, scrollPause],
  )

  useEffect(() => {
    document.querySelector(`#for-scroll-${lastTopMessageId}`)?.scrollIntoView()
  }, [scrollUp, lastTopMessageId])
  useEffect(() => {
    scrollToBottom()
  }, [scrollBottom, scrollToBottom])
  useEffect(() => {
    setScrollPause(false)
  }, [messageFirstId])
  return (
    <ScrollWrapper ref={scrollRef} onScroll={onScroll} tabIndex={0} role="region" aria-label={"메시지 리스트"}>
      <>
        {children}
        {scrollButton ? (
          <button type="button" className="to-bottom-button" onClick={scrollToBottom}>
            <DownArrow />
          </button>
        ) : null}
      </>
    </ScrollWrapper>
  )
}

export default ScrollCreator
