import { ButtonInModal } from 'components/common/commonStyle'
import { OptionModalStyle } from '../chatOption/ChatOptionStyle'
import { closeModal } from 'utils/localDispatcher'

interface MeesageDeletePopupProps {
  deleteMessage: () => void
}
const MessageDeletePopup = ({ deleteMessage }: MeesageDeletePopupProps) => {
  return (
    <OptionModalStyle>
      <div>
        <div className="title">메시지를 삭제할까요?</div>
        <div className="description">모든 사람에게 안 보이게 돼요.</div>
      </div>
      <div className="button-wrapper">
        <ButtonInModal type="button" className="common-bg-hover" onClick={closeModal}>
          취소
        </ButtonInModal>
        <ButtonInModal type="button" className="confirm" onClick={deleteMessage}>
          삭제
        </ButtonInModal>
      </div>
    </OptionModalStyle>
  )
}

export default MessageDeletePopup
