import { Response } from 'external/rocket-chat/api/api_pb'

import { getStickerQuery, getBizAccountQuery } from 'utils/protobuf/query'

import { ROCKET_CHAT_URL, IS_SERVER } from 'constants/config'
import { SOCKET_RETRY_DEFAULT_TIME, SOCKET_RETRY_MAX_TIME } from 'constants/resource'
import { chatResParser } from './socketDataParser'
import { openLoader } from 'utils/localDispatcher'
import { store } from 'store'
import { socketStore } from 'utils/socket/socketStore'
import { logLevel } from 'utils/consoleLevel'
import { deleteToken } from 'utils/auth/tokenUtils'

const encode = (obj: object) => new URLSearchParams({ ...obj }).toString()

let backOffRetryTime = SOCKET_RETRY_DEFAULT_TIME
interface socketGeneratorArgs {
  token?: string
  region: string
  access_token?: string
  bizToken?: string
}
function chatSocketInit({ token, region, access_token, bizToken }: socketGeneratorArgs) {
  if (!IS_SERVER) {
    logLevel.log(`socket generate`)
    openLoader()
    const tokenQueryParams = `${token ? encode({ token }) : encode({ access_token })}`
    const bizParams = `${encode({
      x_business_web_login_token: bizToken,
    })}`
    const url = `${ROCKET_CHAT_URL.replace(':region', region)}?${token || access_token ? tokenQueryParams : bizParams}`
    // const url = `${ROCKET_CHAT_URL.replace(':region', region)}?${token ? encode({ token }) : encode({ access_token })}`
    const socket: WebSocket = new WebSocket(url)
    socket.binaryType = 'arraybuffer'

    socket.onmessage = (event) => {
      const unit8 = new Uint8Array(event.data)
      const res = Response.toObject(false, Response.deserializeBinary(unit8))
      chatResParser(res, socket)
      logLevel.log(res)
    }

    socket.onopen = () => {
      store.dispatch({ type: 'chat/setBizRequestPending', payload: true })
      socket.send(getStickerQuery().serializeBinary())
      socket.send(getBizAccountQuery().serializeBinary())
      store.dispatch({ type: 'socket/socketReadyState', payload: socket.readyState })
    }
    socket.onclose = (e) => {
      logLevel.log(`socket is closed!`)
      logLevel.log(e)
      logLevel.log({ socket, socketStore })
      store.dispatch({ type: 'socket/socketReadyState', payload: socket.readyState })
     if (e.code === 1002) {
        if (e.reason === "unauthorized") {
          console.log(`socket unauthorized.`)
          deleteToken()
          alert("로그아웃 되었어요.")
        }
        window.location.href = '/login';
      } else {
        setTimeout(() => {
          const storeAccessToken = store.getState().authStore.storeAccessToken
          socketStore.chatSocket = chatSocketInit({ token, region, access_token: storeAccessToken })
          backOffRetryTime = Math.min(backOffRetryTime * 2, SOCKET_RETRY_MAX_TIME)
        }, backOffRetryTime)
      }
    }
    return socket
  } else {
    return null
  }
}

export { chatSocketInit }
