import React from 'react'
import { CommonButton } from './commonStyle'

interface KarrotButtonProps {
  children: React.ReactNode
  onClick?: (e: React.SyntheticEvent) => void
  variant?: 'primary'
  className?: string
  buttonType?: 'submit' | 'button' | 'reset'
}
const KarrotButton = ({ children, onClick, variant, className, buttonType }: KarrotButtonProps) => {
  return (
    <CommonButton
      className={`${className ? className : ''} ${variant === 'primary' ? 'on-primary' : ''}`}
      onClick={onClick}
      type={buttonType || 'button'}
    >
      {children}
    </CommonButton>
  )
}

export default KarrotButton
