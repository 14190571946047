import { useSelector } from 'react-redux'

import { StoreState } from 'store/ducks'
import InlineTemplate from 'components/chatMain/chatMessage/InlineTemplate'
import { ChannelListNoticeWrapper } from './ChannelListStyle'
import { CloseIcon } from 'utils/SVGIcons'
import { socketStore } from 'utils/socket/socketStore'
import { closeChannelListHeader } from 'utils/protobuf/query'

const ChannelListNotice = () => {
  const channelHeader = useSelector((state: StoreState) => state.chatStore.channelHeader)
  const handleClose = () => {
    if (socketStore.chatSocket?.readyState === 1 && channelHeader.id) {
      socketStore.chatSocket.send(closeChannelListHeader(channelHeader.id).serializeBinary())
    }
  }
  return channelHeader.id ? (
    <ChannelListNoticeWrapper>
      <div className="notice-area">
        <div className="content-area">
          <InlineTemplate richText={channelHeader.notice?.richText || ''} />
        </div>
        <div className="close-wrapper">
          <span className="icon-wrapper" onClick={handleClose}>
            <CloseIcon />
          </span>
        </div>
      </div>
    </ChannelListNoticeWrapper>
  ) : null
}

export default ChannelListNotice
