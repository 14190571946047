import React, { useState, memo, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { store } from 'store'
import { StoreState } from 'store/ducks'
import { MessageTypes } from 'types/ChatTypes'
import { gtagChannelWrapper } from 'utils/eventUtils'
import { openModal, openToast, closeModal } from 'utils/localDispatcher'
import { deleteMessageItem } from 'utils/protobuf/query'
import { textToClipboard } from 'utils/snippet'
import { socketStore } from 'utils/socket/socketStore'
import MessageDeletePopup from './MessageDeletePopup'
import MessageHidePopup from './MessageHidePopup'

interface MessageHoverProps {
  messageItem: MessageTypes
}
const MessageHover = ({ messageItem }: MessageHoverProps) => {
  const selectChannelobj = useSelector((state: StoreState) => state.chatStore.selectChannelobj)
  const channelOwner =
    (selectChannelobj?.type === 2 && selectChannelobj?.me?.id === selectChannelobj?.owner?.id && messageItem.senderId !== selectChannelobj?.me?.id)
  const [showMenu, setShowMenu] = useState(false)
  const closeMenu = () => setShowMenu(false)
  const handleClick = (e: React.SyntheticEvent) => {
    // e.stopPropagation()
    setTimeout(() => {
      if (!showMenu) {
        gtagChannelWrapper('more', messageItem.type, messageItem.id)
      }
      setShowMenu(!showMenu)
    }, 0)
  }

  const deleteMessage = useCallback(() => {
    if (socketStore.chatSocket?.readyState === 1) {
      // const messageType = 0
      const messageType = selectChannelobj?.type === 2 ? 1 : 0
      const protoMsg = deleteMessageItem(messageItem.channelId, messageItem.id, messageType)
      socketStore.chatSocket.send(protoMsg.serializeBinary())
    }
    closeMenu()
    closeModal()
  }, [messageItem, selectChannelobj])
  const handleDelete = useCallback(() => {
    openModal({
      isShow: true,
      content: <MessageDeletePopup deleteMessage={deleteMessage} />,
    })
    gtagChannelWrapper('delete', messageItem.type, messageItem.id)
  }, [deleteMessage, messageItem])
  
  const handleHide = useCallback(() => {
    openModal({
      isShow: true,
      content: <MessageHidePopup deleteMessage={deleteMessage} />,
    })
    gtagChannelWrapper('delete', messageItem.type, messageItem.id)
  }, [deleteMessage, messageItem])

  const handleCopy = useCallback(() => {
    textToClipboard(messageItem.content)
    closeMenu()
    openToast('메시지를 복사했어요.')
    gtagChannelWrapper('copy', messageItem.type, messageItem.id)
  }, [messageItem])

  const registOftenText = () => {
    const macroList = store.getState().utilStore.macroList
    const target = Object.keys(macroList).find((key) => macroList[key] === '')
    if (target) {
      store.dispatch({
        type: 'utils/setMacroList',
        payload: {
          ...macroList,
          [target.toString()]: messageItem.content,
        },
      })
      openToast('자주쓰는문구에 추가했어요.')
    } else {
      openToast('최대 10개까지 추가할 수 있어요.')
    }
    gtagChannelWrapper('favorite_text', messageItem.type, messageItem.id)
  }
  useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => {
      window.removeEventListener('click', closeMenu)
    }
  }, [])
  return (
    <>
      {showMenu ? (
        channelOwner ? (
          <div className="message-menu-box" onClick={(e) => e.stopPropagation()}>
            {messageItem.type === 'TEXT' ? (
              <div className="message-menu-item" onClick={handleCopy}>
                복사
              </div>
            ) : null}
            <div className="message-menu-item" onClick={handleHide}>
              가리기
            </div>
          </div>
        ) : (
          <div className="message-menu-box" onClick={(e) => e.stopPropagation()}>
            {messageItem.type === 'TEXT' ? (
              <div className="message-menu-item" onClick={handleCopy}>
                복사
              </div>
            ) : null}
            {messageItem.type === 'TEXT' ? (
              <div className="message-menu-item receiver-none" onClick={registOftenText}>
                자주쓰는문구 추가
              </div>
            ) : null}
            {(messageItem.createTime?.seconds || 0) * 1000 + 1000 * 60 * 5 < new Date().getTime() ? null : (
              <div className="message-menu-item receiver-none" onClick={handleDelete}>
                삭제
              </div>
            )}
          </div>
        )
      ) : null}
      <button type="button" className="toolbox" onClick={handleClick}>
        <img src="/icons/more_horiz.svg" alt="more" />
      </button>
    </>
  )
}

export default memo(MessageHover)
