import Cookies, { CookieAttributes } from 'js-cookie'

import { IS_PROD } from 'constants/config'
import { openToast } from './localDispatcher'

interface cookieSaveProps {
  name: string
  value: string
  expires?: number
  cookieType: 'shared' | 'origin'
}

export function cookieSave({ name, value, expires, cookieType }: cookieSaveProps) {
  if (!navigator.cookieEnabled) {
    openToast('쿠키가 비활성화 되어 있습니다. 쿠키를 활성화 해주세요.')
    new Error('Cookie is not enabled')
    return 1
  }
  const domain = cookieType === 'shared' ? `${IS_PROD ? '' : '.alpha'}.daangn.com` : window.location.hostname

  // localhost process
  if (window.location.protocol === 'http:') {
    try {
      const options: CookieAttributes = { domain }
      if (expires) options.expires = expires
      Cookies.set(name, value, options)
    } catch (e) {}
  } else {
    const options: CookieAttributes = {
      domain,
      sameSite: 'strict',
      secure: true,
    }
    if (expires) options.expires = expires
    try {
      Cookies.set(name, value, options)
    } catch (e) {}
  }
}
