import { createReducer, createAction } from '@reduxjs/toolkit'
import {
  ChannelTypes,
  ChannelHeaderTypes,
  MessageTypes,
  ReadMessageTypes,
  StickerList,
  UserTypes,
  ChangeMembertypes,
  channelListObj,
  NewMessageTypes,
  UnreadCountTypes,
  MessageObjectTypes,
} from 'types/ChatTypes'
import { PromiseCheck } from 'utils/snippet'
import { TIMESTAMP_DIGIT } from 'constants/resource'
import { GetChannelsRequest } from 'external/rocket-chat/api/api_pb'

//#region action
interface channelListWrapper {
  channelList: ChannelTypes[]
  nextPagingKey: GetChannelsRequest.PagingKey.AsObject
}
export const setChannelList = createAction<channelListWrapper>('chat/setChannelList')
export const setChannelHeader = createAction<ChannelHeaderTypes>('chat/setChannelHeader')
export const setChannelClose = createAction('chat/closeChannelHeader')
export const setSelectChannel = createAction<ChannelTypes>('chat/setSelectChannel')
export const addChannelItem = createAction<ChannelTypes>('chat/addChannelItem')
export const appendMessageList = createAction<MessageTypes[]>('chat/appendMessageList')
export const sendMessageReceive = createAction<MessageTypes>('chat/sendMessageReceive')
export const resetSelectedChannel = createAction('chat/resetSelectedChannel')
export const readMessageNoti = createAction<ReadMessageTypes>('chat/readMessageNoti')
export const readChannelMessage = createAction<{ channelId: string; unreadCount: number; userId: number }>(
  'chat/readChannelMessage',
)
export const addChannelList = createAction<channelListWrapper>('chat/addChannelList')
export const setChannelData = createAction<ChannelTypes>('chat/setChannelData')
export const leaveChannel = createAction<{ channelId: string }>('chat/leaveChannel')
export const setStickerList = createAction<StickerList[]>('chat/setStickerList')
export const setChannelAddFlag = createAction<boolean>('chat/setChannelAddFlag')
export const setChannelBlock = createAction<boolean>('chat/setChannelBlock')
export const setChannelUpdate = createAction<ChannelTypes>('chat/setChannelUpdate')
export const addNotExistChnanelMessage = createAction<NewMessageTypes>('chat/addNotExistChnanelMessage')
export const setBizRequestPending = createAction<boolean>('chat/setBizRequestPending')

export const setUnreadObj = createAction<UnreadCountTypes>('chat/setUnreadObj')

export const messageUpdate = createAction<MessageTypes>('chat/messageUpdate')

// for group chat
// export const setMemberList = createAction<UserTypes[]>('chat/setMemberList')
export const changeMemberList = createAction<ChangeMembertypes>('chat/changeMemberList')
//#endregion

//#region store
interface initialStateProps {
  channelList: ChannelTypes[]
  channelHeader: ChannelHeaderTypes
  nextPagingKey: GetChannelsRequest.PagingKey.AsObject
  selectChannelMessage: MessageTypes[]
  notExistChannelMessage: NewMessageTypes[]
  selectChannelobj: ChannelTypes | null
  selectChannelId: string
  messageLastId: number
  messageFirstId: number
  scrollUp: number
  scrollBottom: number
  stickerList: StickerList[]

  channelBlock: boolean
  isMute: boolean
  isBizRequestPending: boolean
  channelAddFlag: boolean

  unreadObj: {
    [key: number]: number
  }
}
const initialState: initialStateProps = {
  channelList: [],
  channelHeader: {} as ChannelHeaderTypes,
  nextPagingKey: {} as GetChannelsRequest.PagingKey.AsObject,
  selectChannelMessage: [],
  notExistChannelMessage: [],
  selectChannelobj: null,
  selectChannelId: '',
  // 이를 위한 플래그들
  messageLastId: 0,
  messageFirstId: 0,
  // send 연속으로 못하게 하기. 추후엔 연속으로 보내놓고 응답 오지 않는것은 별도 표시하도록.
  scrollUp: 0,
  scrollBottom: 0,
  stickerList: [],
  channelBlock: false,
  isMute: false,
  isBizRequestPending: true,

  // initChannelsReqFlag
  channelAddFlag: false,

  unreadObj: {},
}
//#endregion
export default createReducer(initialState, (builder) => {
  builder
    .addCase(setChannelList, (state, action) => {
      state.channelList = [...(action.payload.channelList || [])]
      state.nextPagingKey = action.payload.nextPagingKey
    })
    .addCase(setChannelHeader, (state, action) => {
      state.channelHeader = action.payload
    })
    .addCase(setChannelClose, (state) => {
      state.channelHeader = {} as ChannelHeaderTypes
    })
    .addCase(setSelectChannel, (state, action) => {
      const channel = action.payload
      state.selectChannelobj = { ...(channel || {}) }
      state.selectChannelId = channel?.id || ''
      state.selectChannelMessage = []
      state.messageLastId = 0
      state.channelBlock = (channel?.receiver?.userFlaggedByMeList || []).length > 0
      state.isMute = channel?.isMute || false
    })
    .addCase(addChannelItem, (state, action) => {
      const targetChannel = action.payload
      const targetmessage = state.notExistChannelMessage.find(
        (messageItem) => messageItem.channelId === targetChannel?.id,
      )
      if (targetmessage) {
        const senderNick = targetmessage.senderNickname
        const newMessage = {
          ...targetmessage,
          content: targetmessage.content.split(`${senderNick} : `).slice(1).join(''),
        } as any
        const newChannel = {
          ...targetChannel,
          lastMessage: newMessage,
        }
        state.channelList = channelSort([newChannel, ...state.channelList])
        state.notExistChannelMessage = state.notExistChannelMessage.filter(
          (messageItem) => messageItem.channelId !== targetChannel.id,
        )
      }
    })
    .addCase(appendMessageList, (state, action) => {
      const message = action.payload
      // 채팅방 나가기 등의 특별한 이유로 빈 메시지가 오는 경우 예외처리
      if (message.length < 1) {
        return state
      }
      const lastMessage = message[message.length - 1]
      // 현재 채널의 메시지가 온 것인지 확인
      if (state.selectChannelId === message[0].channelId) {
        // 현재 채널에서 스크롤 한 것인지 확인
        if (state.selectChannelMessage[0]?.id === message[0].id) {
        } else if (state.selectChannelMessage[0]?.id > message[0].id) {
          state.scrollUp += 1
          const newList = [...(message || []), ...state.selectChannelMessage]
          state.selectChannelMessage = messageToUnique(newList)
        } else {
          state.scrollBottom += 1
          state.selectChannelMessage = messageToUnique([...state.selectChannelMessage, ...(message || [])])
        }
        state.messageFirstId = Math.min(state.selectChannelMessage[0].id, message[0].id)
        state.messageLastId = lastMessage.id
      }
      const targetChannelId = lastMessage.channelId
      const targetChannel = state.channelList.filter((channelItem) => channelItem.id === targetChannelId)
      if (targetChannel.length > 0) {
        if (targetChannel[0].lastMessage?.id !== lastMessage.id) {
          state.channelList = channelListUpdateIntoNewMessage({
            message: lastMessage,
            channelList: [...state.channelList],
            selectChannelId: state.selectChannelId,
          })
        }
      }
    })
    .addCase(sendMessageReceive, (state, action) => {
      // 내가 보낸 메시지에 대한 응답
      const message = { ...action.payload }
      if (message.channelId === state.selectChannelId) {
        state.selectChannelMessage = messageToUnique([...state.selectChannelMessage, message])
        state.messageLastId = message.id
        state.scrollBottom += 1
      }
      // 채널 리스트 갱신하기
      state.channelList = channelListUpdateIntoNewMessage({
        message,
        channelList: [...state.channelList],
        selectChannelId: state.selectChannelId,
      })
    })
    .addCase(resetSelectedChannel, (state) => {
      state.messageFirstId = 0
      state.messageLastId = 0
      state.scrollBottom = 0
      state.selectChannelId = ''
      state.selectChannelobj = null
      state.isMute = false
    })
    .addCase(readMessageNoti, (state, action) => {
      const message = action.payload
      const target = message.reader
      if (state.selectChannelId === message.channelId) {
        state.selectChannelMessage = messageReadProcess(state.selectChannelMessage, target)
      } else {
        state.channelList = channelReadPorcess(state.channelList, message.channelId)
      }
    })
    .addCase(readChannelMessage, (state, action) => {
      const { userId, channelId } = action.payload
      state.channelList = channelReadPorcess(state.channelList, channelId)
      if (state.selectChannelobj) {
        state.selectChannelobj.unreadCount = 0
      }
      // state.selectChannelMessage = messageReadProcess(state.selectChannelMessage)
      const readedMessageList = [
        ...state.selectChannelMessage.map((messageItem) => {
          messageItem.seenMap = messageItem.seenMap.map((seenItem) => {
            if (seenItem[0] === userId && seenItem[1].seconds <= 0) {
              seenItem[1].seconds = new Date().getTime() / 1000
            }
            return seenItem
          })
          return messageItem
        }),
      ]
      state.selectChannelMessage = messageToUnique(readedMessageList)
    })
    .addCase(addChannelList, (state, action) => {
      const newChannelList = [...action.payload.channelList]
      const reduceInitObj: channelListObj = {}
      const channelObj = state.channelList.reduce((acc, cur) => {
        acc[cur.id] = acc[cur.id] ? acc[cur.id] : cur
        return acc
      }, reduceInitObj)
      const newList = newChannelList.filter((channelItem) => !channelObj[channelItem.id])
      state.channelList = [...state.channelList, ...newList]
      state.channelAddFlag = newList.length > 0
      state.nextPagingKey = action.payload.nextPagingKey
    })
    .addCase(setChannelData, (state, action) => {
      const channel = { ...action.payload }
      if (state.channelList.some((channelItem) => channelItem.id === channel.id)) {
        state.channelList = [
          ...state.channelList.map((channelItem) => (channelItem.id === channel.id ? { ...channel } : channelItem)),
        ]
      } else {
        state.channelList = [channel, ...state.channelList]
      }
    })
    .addCase(leaveChannel, (state, action) => {
      const { channelId } = action.payload
      state.channelList = state.channelList.filter((channelItem) => channelItem.id !== channelId)
    })
    .addCase(setStickerList, (state, action) => {
      state.stickerList = [...action.payload]
    })
    .addCase(changeMemberList, (state, action) => {
      state.channelList = changeMemberProcess(state.channelList, action.payload)
    })
    .addCase(setChannelAddFlag, (state, action) => {
      state.channelAddFlag = action.payload
    })
    .addCase(setChannelBlock, (state) => {
      state.channelBlock = !state.channelBlock
    })
    .addCase(setChannelUpdate, (state, action) => {
      const targetChannel = action.payload
      state.isMute = targetChannel.isMute
      state.channelList = channelSort([
        ...state.channelList.map((channelItem) => {
          if (channelItem.id === targetChannel.id) {
            return {
              ...channelItem,
              isMute: targetChannel.isMute,
              isFavorite: targetChannel.isFavorite,
              favoriteTime: targetChannel.favoriteTime,
            }
          } else {
            return channelItem
          }
        }),
      ])
    })
    .addCase(addNotExistChnanelMessage, (state, action) => {
      const newMessage = action.payload
      state.notExistChannelMessage = [...state.notExistChannelMessage, newMessage]
    })
    .addCase(setUnreadObj, (state, action) => {
      const unreadObj = action.payload
      if (unreadObj) {
        const myObj: {
          [key: number]: number
        } = {}
        const newObj = unreadObj?.bizMessageMap.reduce((acc, cur) => {
          const [bid, count] = cur
          acc[bid] = count
          return acc
        }, myObj)
        if (unreadObj?.userMessage || unreadObj.userMessage === 0) {
          newObj[-1] = unreadObj.userMessage
        }
        state.unreadObj = { ...newObj }
      }
    })
    .addCase(setBizRequestPending, (state, action) => {
      state.isBizRequestPending = action.payload
    })
    .addCase(messageUpdate, (state, action) => {
      state.selectChannelMessage = state.selectChannelMessage.map((messageItem) => {
        return messageItem.id === action.payload.id ? { ...messageItem, ...action.payload } : messageItem
      })
      const findIndex = state.channelList.findIndex(
        (channelItem) =>
          channelItem.id === action.payload.channelId && channelItem.lastMessage?.id === action.payload.id,
      )
      if (findIndex) {
        state.channelList = state.channelList.map((channelItem) => {
          if (channelItem.id === action.payload.channelId && channelItem.lastMessage?.id === action.payload.id) {
            channelItem.lastMessage = { ...channelItem.lastMessage, ...action.payload }
          }
          return channelItem
        })
      }
    })
})

interface channelUpdateProps {
  message: MessageTypes
  channelList: ChannelTypes[]
  selectChannelId: string
}
interface channelHashProps {
  [index: number]: ChannelTypes
}
// 새로운 메시지가 오면 채널 리스트에서 해당 채널을 갱신하고, 정렬을 다시 해주는 함수
function channelListUpdateIntoNewMessage({
  message,
  channelList,
  selectChannelId,
}: channelUpdateProps): ChannelTypes[] {
  const originIndex = channelList.findIndex((channelItem) => channelItem.id === message.channelId)
  const originLastMessage = channelList[originIndex]?.lastMessage
  const lastMessage = message.id > (originLastMessage?.id || 0) ? message : originLastMessage
  const lastMessageTime = message.id > (originLastMessage?.id || 0) ? message.createTime : originLastMessage?.createTime
  const updateChannel = {
    ...channelList[originIndex],
    lastMessageTime: lastMessageTime,
    lastMessage: lastMessage,
  }
  // 내가 보고 있는 채널이 아니면 가산
  if (message.channelId !== selectChannelId && updateChannel.me?.id !== message.senderId) {
    updateChannel.unreadCount += 1
  }

  const filterdChannel = channelList.filter((_, idx) => idx !== originIndex)
  return channelSort([updateChannel, ...filterdChannel])
}

function messageReadProcess(messageList: MessageTypes[], target: UserTypes | undefined) {
  if (target) {
    return messageList.map((messageItem) => {
      messageItem.seenMap = messageItem.seenMap.map((seenItem) => {
        if (seenItem[0] === target.id) {
          seenItem[1].seconds = new Date().getTime() / 1000
          return seenItem
        } else {
          return seenItem
        }
      })
      return messageItem
    })
  } else {
    // 1:1 이기 때문에 모두 읽음 처리
    return messageList.map((messageItem) => {
      messageItem.seenMap = messageItem.seenMap.map((seenItem) => {
        seenItem[1].seconds = new Date().getTime() / 1000
        return seenItem
      })
      return messageItem
    })
  }
}

function channelReadPorcess(channelList: ChannelTypes[], targetChannelId: string) {
  return channelList.map((channelItem) => {
    if (channelItem.id === targetChannelId) {
      return {
        ...channelItem,
        unreadCount: 0,
      }
    } else {
      return channelItem
    }
  })
}

function channelSort(channelList: ChannelTypes[]) {
  const channelHash = channelList.reduce((acc: channelHashProps, cur: ChannelTypes) => {
    const baseScore = cur.lastMessageTime?.seconds || 0
    const isPromise = PromiseCheck(cur)
    const promiseScore = isPromise ? TIMESTAMP_DIGIT * 100 : 0
    if (cur.isFavorite) {
      const favoriteTime = cur.favoriteTime?.seconds || 0
      const favoriteScore = cur.isFavorite ? TIMESTAMP_DIGIT * 200 : 0
      acc[favoriteTime + favoriteScore] = cur
    } else {
      acc[baseScore + promiseScore] = cur
    }
    return acc
  }, {})
  return Object.keys(channelHash)
    .reverse()
    .sort((a, b) => Number(b) - Number(a))
    .map((key) => channelHash[Number(key)])
}

function messageToUnique(messageList: MessageTypes[]) {
  const messageObject = messageList.reduce((acc: MessageObjectTypes, messageItem) => {
    if (messageItem?.id) {
      acc[messageItem.id] = messageItem
    }
    return acc
  }, {})
  return Object.values(messageObject).sort((a, b) => Number(a.id) - Number(b.id))
}

function changeMemberProcess(channelList: ChannelTypes[], payload: ChangeMembertypes) {
  const message = { ...payload.message }
  const type = payload.type
  return channelList.map((channelItem) => {
    if (channelItem.id === message.channelId) {
      if (message.member) {
        const memberList =
          type === 'add'
            ? [...channelItem.membersList, message.member]
            : [...channelItem.membersList].filter((memberItem) => memberItem.id === message.member?.id)
        return {
          ...channelItem,
          membersList: memberList,
        }
      }
    }
    return channelItem
  })
}
