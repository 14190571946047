import { ChatForm, ChannelTextArea, ChatSendButton } from './ChatSendStyle'
import { StickerWrapper } from './ChatSendStyle'
import { AddFile, MacroIcon } from 'utils/SVGIcons'
import { StickerIcon } from 'utils/SVGIcons'

interface ChatFakeSendProps {
  placeholder: string
}
const ChatFakeSend = ({ placeholder }: ChatFakeSendProps) => {
  return (
    <ChatForm
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <ChannelTextArea placeholder={placeholder} readOnly={true} />
      <div className="chatform-option-area">
        <div className="chatform-submenu">
          <label className="option-wrapper">
            {`이미지 전송하기`}
            <AddFile />
            <input type="file" />
          </label>
          <label className={`option-wrapper`}>
            {`자주 쓰는 문구 매크로 설정하기`}
            <MacroIcon />
          </label>
          <label className={`option-wrapper`}>
            {`스티커 전송하기`}
            <StickerWrapper>
              <button className="sticker-button" type="button">
                <StickerIcon />
              </button>
            </StickerWrapper>
          </label>
        </div>
        <ChatSendButton>전송</ChatSendButton>
      </div>
      <span className="text-length">{`0/1000`}</span>
    </ChatForm>
  )
}

export default ChatFakeSend
