export const MACRO_NAME = 'macro'
export const ACCESS_TOKEN_NAME = 'access'
export const REFRESH_TOKEN_NAME = 'refresh'
export const LAST_ACTION = 'lastAction'
export const LAST_SOUND = 'lastSound'
const FIRST_DEPTH_NOTIFICATION = 'firstDepthNotification'
export const NORMAL_MESSAGE_NOTIFICATION = 'normalMessageNotification'
export const BIZ_PROFILE_MESSAGE_NOTIFICATION = 'bizMessageNotification'
export const CHANNEL_MUTE_LIST = 'channelMuteList'
export const NOTIFICATION_BANNER = 'notificationBanner'
export const AUTO_REPLY_INTRO = 'autoReplyIntro'

if (getLocalStorage(FIRST_DEPTH_NOTIFICATION) === true || getLocalStorage(NORMAL_MESSAGE_NOTIFICATION) === undefined) {
  setLocalStorage({ key: NORMAL_MESSAGE_NOTIFICATION, value: true })
  setLocalStorage({ key: BIZ_PROFILE_MESSAGE_NOTIFICATION, value: true })
  removeLocalStorage(FIRST_DEPTH_NOTIFICATION)
} else if (getLocalStorage(FIRST_DEPTH_NOTIFICATION) === false) {
  setLocalStorage({ key: NORMAL_MESSAGE_NOTIFICATION, value: false })
  setLocalStorage({ key: BIZ_PROFILE_MESSAGE_NOTIFICATION, value: false })
  removeLocalStorage(FIRST_DEPTH_NOTIFICATION)
}

interface localStorageTypes {
  key: string
  value: unknown
}
export function setLocalStorage({ key, value }: localStorageTypes) {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch {}
}
export function getLocalStorage(key: string) {
  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : undefined
  } catch {}
}
export function removeLocalStorage(key: string) {
  try {
    localStorage.removeItem(key)
  } catch {}
}
