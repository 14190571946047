import styled from '@emotion/styled'
//#region ChatSend
const DisableSendForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--layout-line);
  height: 56px;
  padding: 8px 16px;
  a {
    display: block;
    width: 100%;
  }
  .qna-button {
    display: flex;
    align-items: center;
    line-height: 140%;
    border-radius: 5px;
    height: 40px;
    width: 100%;

    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.02em;
    background: var(--seed-scale-color-carrot-500);
    color: #fff;
  }
`

const ChatForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 16px;
  border: 1px solid var(--seed-scale-color-gray-900);
  border-radius: 8px;
  height: 125px;
  justify-content: space-between;

  .chatform-option-area {
    display: flex;
    justify-content: space-between;
    margin: 8px 10px;
    .chatform-submenu {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }
  }
  .option-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    font-size: 0;
    &:hover,
    &.selected {
      border-radius: 4px;
      background-color: var(--seed-scale-color-gray-300);
    }
    svg {
      fill: var(--seed-scale-color-gray-600);
      &:hover {
        fill: var(--seed-scale-color-gray-700);
      }
    }
    &:hover {
      .option-tooltip {
        position: absolute;
        bottom: 52px;
        border-radius: 5px;
        padding: 7px 10px;
        background-color: var(--seed-scale-color-gray-900);
        opacity: 0.95;
        color: var(--seed-scale-color-gray-00);
        font-weight: 700;
        white-space: nowrap;
        font-size: 13px;
      }
    }
  }
  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  .text-length {
    position: absolute;
    right: 84px;
    bottom: 11px;
    font-size: 12px;
    line-height: 150%;
    color: var(--seed-scale-color-gray-500);
  }
`
const ChannelTextArea = styled.textarea`
  margin: 12px 12px 0 12px;
  width: calc(100% - 24px);
  height: 63px;
  line-height: 150%;
  padding: 0;
  resize: none;
  font-size: 14px;
  border: none;
  outline: none;
  color: var(--seed-scale-color-gray-900);
  background-color: var(--seed-semantic-color-paper-default);
  &::placeholder {
    color: var(--seed-scale-color-gray-500);
  }
`
const ChatSendButton = styled.button`
  border-radius: 4px;
  width: 64px;
  height: 32px;
  line-height: 150%;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--seed-scale-color-carrot-500);
  color: #fff;
  transition: background-color 0.5s, color 0.5s;
  &.disable {
    background-color: var(--seed-scale-color-gray-300);
    color: #ffffff;
  }
`
//#endregion

//#region file-upload
const FileUploadBack = styled.div`
  position: absolute;
  background-color: var(--profile-image-border);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  point-events: none;
`
//#endregion

//#region macro
const ChatMacroForm = styled.form`
  position: absolute;
  bottom: 64px;
  left: 18px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--seed-scale-color-gray-600);
  border-radius: 10px;
  width: 482px;
  height: 434px;
  background-color: var(--seed-semantic-color-paper-default);
  will-change: opacity;
  animation: fadeIn ease-in 0.3s;
  .form-body {
    height: 370px;
    padding: 24px 0 24px 24px;
    overflow-y: auto;
    overflow-x: hidden;
    .macro-info-icon {
      margin-left: 6.5px;
    }
    .title-wrapper {
      margin-bottom: 24px;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 135%;
        color: var(--seed-scale-color-gray-900);
      }
      .description {
        margin-top: 12px;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: var(--seed-scale-color-gray-600);
      }
    }
  }
  .form-footer {
    border-top: 1px solid var(--seed-semantic-color-divider-1);
    height: 63px;
    padding: 14px 11px 13px;
    text-align: right;
    .button {
      width: 72px;
      height: 36px;
      border-radius: 4px;

      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #ffffff;
      &.cancel {
        border-color: var(--seed-scale-color-gray-400);
        color: var(--seed-scale-color-gray-600);
      }
      &.save {
        margin-left: 12px;
        background-color: var(--seed-scale-color-carrot-500);
      }
    }
  }
  .macro-item {
    display: flex;
    & ~ .macro-item {
      margin-top: 10px;
    }
    .macro-label {
      margin-top: 9px;
      margin-right: 9px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      align-items: center;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-600);
    }
    .textarea {
      width: 380px;
      height: 62px;
      line-height: 150%;
      border: 1px solid #d1d3d8;
      border-radius: 6px;
      padding: 10px 0 10px 12px;
      background-color: var(--seed-semantic-color-paper-default);

      font-size: 14px;
      color: var(--seed-scale-color-gray-500);
      resize: none;
      overflow-x: hidden;
      overflow-y: auto;
      outline: none;
      &:focus {
        border: 1px solid var(--seed-scale-color-gray-900);
        color: var(--seed-scale-color-gray-900);
      }
    }
  }
`
//#endregion

//#region sticker
const StickerWrapper = styled.div`
  align-items: center;
  height: 20px;
  .sticker-button {
    padding: 0;
    font-size: 0;
    width: 20px;
    height: 20px;
  }
`
const StickerPreview = styled.ul`
  position: absolute;
  bottom: 49px;
  left: 48px;
  border: 1px solid var(--seed-scale-color-gray-600);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 22px 10px;
  width: 482px;
  height: 420px;

  font-size: 0;
  background-color: var(--seed-scale-color-gray-50);
  overflow-y: auto;
  overflow-x: hidden;

  will-change: opacity;
  animation: fadeIn ease-in 0.3s;
`
const StickerItem = styled.li`
  display: inline-block;
  .sticker-img {
    border-radius: 8px;
    width: 112px;
    height: 112px;
    &.select-sticker {
      background-color: var(--seed-scale-color-gray-200);
    }
  }
`
//#endregion

const BadWordsWrapper = styled.div`
  padding: 24px;
  width: 280px;
  border-radius: 10px;

  .content-text {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
  }
  .modal-full-button {
    border: 1px solid var(--seed-scale-color-gray-300);
    border-radius: 5px;
    height: 40px;
    width: 100%;
    & ~ .modal-full-button {
      margin-top: 8px;
    }
    &.edit-button {
      background-color: var(--seed-scale-color-carrot-500);
      color: #fff;
      transition: background-color 0.6s;
      &:hover {
        background-color: var(--karrot-hover-color);
      }
    }
  }
`

export {
  DisableSendForm,
  ChatForm,
  ChannelTextArea,
  ChatSendButton,
  FileUploadBack,
  ChatMacroForm,
  StickerWrapper,
  StickerPreview,
  StickerItem,
  BadWordsWrapper,
}
