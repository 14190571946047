import { ChannelTypes } from 'types/ChatTypes'
import { HeaderRapper, SubHeader } from './ChatHeaderStyle'
import { DAANGN_WEBAPP_URL } from 'constants/config'
import ChatOptions from './chatOption/ChatOptions'
import dayjs from 'dayjs'
import { numberInputComma, ProfileParamConverter, ArticleParamConverter } from 'utils/snippet'
import ChatHeaderNotice from './ChatHeaderNotice'
import { CalendarIcon, OfficialCertIcon } from 'utils/SVGIcons'
import { getCustomerUrl } from 'constants/resource'

interface statusToTextProps {
  [key: string]: string
}
const statusToText: statusToTextProps = {
  ongoing: '판매중',
  reserved: '예약중',
  closed: '거래완료',
}
interface ChatHeaderProps {
  selectChannelobj: ChannelTypes
}
const ChatHeader = ({ selectChannelobj }: ChatHeaderProps) => {
  const { receiver } = selectChannelobj
  const articleUrl = selectChannelobj.resourceId ? `${DAANGN_WEBAPP_URL}/articles/${selectChannelobj.resourceId}` : ''
  const { notice } = selectChannelobj
  if (selectChannelobj.type === 2) {
    return (
      <HeaderRapper>
        <div className="group-title">
          <span>{selectChannelobj?.title}</span>
          <span className="title-member-count">{selectChannelobj?.membersList.length || 0}</span>
        </div>
        <ChatOptions selectChannelobj={selectChannelobj} />
      </HeaderRapper>
    )
  } else if (receiver?.type === 2 && selectChannelobj.resourceType === 'SMBA') {
    const name = `${selectChannelobj.article?.smb?.name}`
    return (
      <HeaderRapper>
        <div className="chat-header-profile">
          <img
            className="chat-header-image"
            src={ProfileParamConverter(selectChannelobj.article?.smb?.firstImageUrl || '')}
            alt={name}
          />
          <div>
            <div className="main-title">
              <span>{name}</span>
              <span className="title-category">{`${selectChannelobj.article?.smb?.categoryText}`}</span>
            </div>
            <div>
              <span>후기 {Number(selectChannelobj.article?.smb?.smbReviewsCount || 0)}</span>
              <span>관심 {Number(selectChannelobj.article?.smb?.likesCount || 0)}</span>
            </div>
          </div>
        </div>
        <ChatOptions selectChannelobj={selectChannelobj} />
      </HeaderRapper>
    )
  } else if (receiver?.type === 2 && selectChannelobj.resourceType === 'BIZA') {
    const name = selectChannelobj.receiver?.nickname
    return (
      <HeaderRapper>
        <div className="chat-header-profile">
          <img
            className="chat-header-image"
            src={ProfileParamConverter(selectChannelobj.receiver?.profileImage || '')}
            alt={name}
          />
          <div>
            <div className="main-title">
              <span className="biz-title">{name}</span>
            </div>
            <div className="biz-option">
              <span className="biz-review">후기 {selectChannelobj.bizAccount?.reviewsCount}</span>
              <span className="biz-regular"> · 단골 {selectChannelobj.bizAccount?.followersCount}</span>
            </div>
          </div>
        </div>
        <ChatOptions selectChannelobj={selectChannelobj} />
      </HeaderRapper>
    )
  } else if (selectChannelobj.resourceType === 'BIZA') {
    const name = selectChannelobj.receiver?.nickname
    const temperature =
      selectChannelobj.isReadOnly || selectChannelobj.isDisabled ? null : receiver?.temperature?.toFixed(1)
    const senderBaId = selectChannelobj.bizAccount?.id || 0
    const receiverId = selectChannelobj.receiver?.id || 0
    return (
      <HeaderRapper>
        <a
          href={getCustomerUrl({ baId: senderBaId, receiverId })}
          target="_blank"
          rel="noreferrer"
          className="chat-header-profile"
        >
          <img
            className="chat-header-image"
            src={ProfileParamConverter(selectChannelobj.receiver?.profileImage || '')}
            alt={name}
          />
          <div>
            <div className="main-title">
              <span className="biz-title">{name}</span>
              {temperature ? <span className="temperature">{`${temperature}°C`}</span> : null}
            </div>
          </div>
        </a>
        <ChatOptions selectChannelobj={selectChannelobj} />
      </HeaderRapper>
    )
  } else if (selectChannelobj.resourceType === 'FLEA') {
    const name = receiver?.nickname
    const isPromise = selectChannelobj.promise?.time?.seconds && !selectChannelobj.promise.deleted
    const articleImage = selectChannelobj.article?.firstImageUrl
    const status = selectChannelobj.article?.status || ''
    const productPrice = selectChannelobj.article?.price
    const temperature =
      selectChannelobj.isReadOnly || selectChannelobj.isDisabled ? null : receiver?.temperature?.toFixed(1)
    return (
      <>
        <HeaderRapper>
          <div className="chat-header-profile">
            <img className="chat-header-image" src={ProfileParamConverter(receiver?.profileImage || '')} alt={name} />
            <div className="main-title">
              <span>{name}</span>
              {temperature ? <span className="temperature">{`${temperature}°C`}</span> : null}
            </div>
          </div>
          <ChatOptions selectChannelobj={selectChannelobj} />
        </HeaderRapper>
        <SubHeader href={articleUrl} target="_blank" rel="noreferrer">
          <div className="reserved-wrapper common-bg-hover">
            {articleImage ? (
              <img
                className="article-image"
                src={ArticleParamConverter(articleImage)}
                alt={selectChannelobj.article?.title}
              />
            ) : null}
            <div className="reserved-main">
              <div>{selectChannelobj.article?.title}</div>
              <div className="reserved-price">
                {productPrice === 0 ? '가격없음' : `${numberInputComma(productPrice)}원`}
              </div>
            </div>
            <div>{status ? <span className={`reserve-button ${status}`}>{`${statusToText[status]}`}</span> : null}</div>
          </div>
          {isPromise ? (
            <div className="alram-header">
              <CalendarIcon />
              <span className="alram-content">{`${dayjs((selectChannelobj.promise?.time?.seconds || 0) * 1000).format(
                'M/D일(dd) A h:mm',
              )} 거래 약속`}</span>
            </div>
          ) : null}
        </SubHeader>
        {notice?.type === 'warnning' ? <ChatHeaderNotice notice={notice} /> : null}
      </>
    )
  } else {
    const name = receiver?.nickname
    const temperature =
      selectChannelobj.isReadOnly || selectChannelobj.isDisabled ? null : receiver?.temperature?.toFixed(1)
    return (
      <>
        <HeaderRapper>
          <div className="chat-header-profile">
            <img className="chat-header-image" src={ProfileParamConverter(receiver?.profileImage || '')} alt={name} />
            <div className="main-title">
              <span>{name}</span>
              {receiver?.isVerified ? (
                <span className="official-badge-wrapper">
                  <OfficialCertIcon />
                </span>
              ) : null}
              {temperature ? <span className="temperature">{`${temperature}°C`}</span> : null}
            </div>
          </div>
          <ChatOptions selectChannelobj={selectChannelobj} />
        </HeaderRapper>
        {notice ? <ChatHeaderNotice notice={notice} /> : null}
      </>
    )
  }
}

export default ChatHeader
