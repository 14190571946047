import Compressor from 'compressorjs'

export const getImageInfo = async (fileData: File | Blob) => {
  const { width, height } = await new Promise<{
    width: number
    height: number
  }>((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(fileData)
    reader.onload = () => {
      const trickImage: HTMLImageElement = new Image()
      trickImage.onload = () => {
        resolve({
          width: trickImage.width,
          height: trickImage.height,
        })
      }
      trickImage.src = `${reader.result}`
    }
  })
  return {
    width,
    height,
  }
}

export const imageCompress = (file: File) => {
  const options = {
    quality: 0.8,
    maxWidth: 1440,
    maxHeight: 1440,
  }
  if (file.size > 1024 * 1024) {
    options.quality = Math.floor(file.size / (1024 * 1024))
    return new Promise<File | Blob>((resolve) => {
      new Compressor(file, {
        ...options,
        success(result) {
          resolve(result)
        },
        error() {
          resolve(file)
        },
      })
    })
  } else {
    return file
  }
}
