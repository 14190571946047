import { CommonPopup } from 'components/common/commonStyle'
import KarrotButton from 'components/common/KarrotButton'

interface ReplyClosePopupProps {
  modalClose: () => void
  setShowReply: React.Dispatch<React.SetStateAction<boolean>>
}
const ReplyClosePopup = ({ modalClose, setShowReply }: ReplyClosePopupProps) => {
  return (
    <CommonPopup>
      <div className="popup-header">자동응답 작성을 종료하시겠어요?</div>
      <div className="popup-body">종료하면, 작성 중인 내용이 저장되지 않아요.</div>
      <div className="popup-footer">
        <KarrotButton className="footer-button" onClick={modalClose}>
          취소
        </KarrotButton>
        <KarrotButton
          className="footer-button"
          variant="primary"
          onClick={() => {
            modalClose()
            setShowReply(false)
          }}
        >
          종료
        </KarrotButton>
      </div>
    </CommonPopup>
  )
}

export default ReplyClosePopup
