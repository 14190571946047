import { combineReducers, Action } from '@reduxjs/toolkit'

import chatStore from './chatStore'
import authStore from './authStore'
import utilStore from './utilStore'
import qrloginStore from './qrloginStore'
import socketStore from './socketStore'

const combinedReducer = combineReducers({ chatStore, authStore, utilStore, qrloginStore, socketStore })

export type StoreState = ReturnType<typeof combinedReducer>

const rootReducer = (state: StoreState = {} as any, action: Action) => {
  switch (action.type) {
    default:
      return combinedReducer(state, action)
  }
}

export default rootReducer
