import React, { memo } from 'react'
import { UnreadMenuWrapper } from './ChannelListStyle'
import { UnreadIconUnSelect, UnreadIconSelect } from 'utils/SVGIcons'

interface OptionUnreadProps {
  unreadCheck: boolean
  setUnreadCheck: React.Dispatch<React.SetStateAction<boolean>>
}
const OptionUnread = ({ unreadCheck, setUnreadCheck }: OptionUnreadProps) => {
  return (
    <UnreadMenuWrapper>
      <label className="unread-label common-bg-hover">
        <span className="unread-description">안읽은 메시지만 보기</span>
        <input
          className="checkbox"
          type="checkbox"
          checked={unreadCheck}
          onChange={() => setUnreadCheck((origin) => !origin)}
        />
        {unreadCheck ? <UnreadIconSelect /> : <UnreadIconUnSelect />}
      </label>
    </UnreadMenuWrapper>
  )
}

export default memo(OptionUnread)
