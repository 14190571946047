import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit'

import rootReducer from './ducks'
import { IS_PROD } from 'constants/config'

const composeEnhancers =
  (!IS_PROD && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const enhancer = composeEnhancers(applyMiddleware())
export const store = createStore(rootReducer, enhancer)
