import styled from '@emotion/styled'

const ChatWrapperLayout = styled.div`
  display: flex;
  overflow-x: auto;
  position: relative;
  margin: 0 auto;
  height: 100%;
`

const ChatInnerLayout = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--seed-semantic-color-divider-2);
  min-width: 812px;
  max-width: 812px;
  background-color: var(--seed-semantic-color-paper-default);
  .chat-normal-room {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .empty-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .empty-description {
      margin-top: 34px;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-700);
    }
  }
  .block-state {
    position: absolute;
    top: 64px;
    width: 100%;
    height: calc(100% - 64px);
    background-color: rgba(255, 255, 255, 0.5);
  }
  .block-only-send {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(125px + 32px);
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export { ChatWrapperLayout, ChatInnerLayout }