import { gtagEmit } from './eventUtils'
import { store } from 'store'

interface gtagParamProps {
  [key: string]: string | number | undefined
}
export const gtagWrapper = (type: string, eventName: string, paramObj: gtagParamProps) => {
  const uid = store.getState().authStore.user.id
  gtagEmit(type, eventName, {
    uid: uid,
    ...paramObj,
  })
}

type InputBoxButtionAction = 'picture' | 'favorite_phrase' | 'persistent_menu' | 'sticker'
export const gtagInputBoxWrapper = (buttonAction: InputBoxButtionAction) => {
  const selectedChannel = store.getState().chatStore.selectChannelobj
  const isBizUser = selectedChannel?.me?.id === selectedChannel?.bizAccount?.id
  gtagEmit('event', 'click_chat_input_box', {
    button_action: buttonAction,
    channelId: selectedChannel?.id,
    resource_type: selectedChannel?.resourceType,
    is_biz_user: isBizUser,
  })
}

export const gtagAutoReplyWrapper = (eventName: string, paramObj: gtagParamProps) => {
  const selectedChannel = store.getState().chatStore.selectChannelobj
  gtagEmit('event', eventName, {
    channelId: selectedChannel?.id,
    resource_type: selectedChannel?.resourceType,
    ...paramObj,
  })
}
