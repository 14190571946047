import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { SIDEBAR_WIDTH } from 'constants/resource'

//#region channel list
const ChannelListWrapper = styled.div`
  display: flex;
  .sidebar {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--seed-semantic-color-divider-1);
    border-right: 1px solid var(--seed-semantic-color-divider-1);
    padding: 20px 13px;
    width: ${SIDEBAR_WIDTH}px;
    max-width: ${SIDEBAR_WIDTH}px;
    /* max-height: 100%; */
    background-color: var(--seed-scale-color-gray-200);
    /* overflow: hidden; */
    scrollbar-gutter: stable;

    .anchor {
      position: relative;
      display: inline-block;
      width: 44px;
      height: 44px;
      & ~ .anchor {
        margin-top: 20px;
      }
    }

    .profile-image {
      border: 1px solid var(--profile-image-border);
      border-radius: 50%;
      width: 44px;
      height: 44px;
      &.selected {
        box-shadow: 0px 0px 0 2px var(--seed-scale-color-gray-200), 0px 0px 0 4px rgb(255, 126, 54);
      }
      &:hover ~ .tooltip {
        display: block;
        opacity: 0.95;
      }
    }
    .tooltip {
      position: absolute;
      display: none;
      top: 50%;
      transform: translate(0, -50%);
      left: calc(100% + 6px);
      padding: 7px 10px;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      white-space: nowrap;

      color: #ffffff;
      font-weight: bold;
      font-size: 13px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.02em;
      background-color: var(--seed-scale-color-gray-900);

      opacity: 0;
      z-index: 10;
    }
    .unread-count {
      position: absolute;
      top: 0;
      right: 0;
      border: 2px solid var(--seed-scale-color-gray-200);
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: var(--seed-scale-color-carrot-500);
    }
  }
`

const faq_height = '56px'
const FAQWrapper = styled.nav`
  display: flex;
  border-right: 1px solid var(--seed-semantic-color-divider-2);
  width: 312px;
  min-width: 312px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--seed-semantic-color-paper-default);
  .faq-container {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--seed-semantic-color-divider-2);
    padding-left: 10px;
    height: ${faq_height};
    min-height: ${faq_height};
    .faq-content {
      display: inline-flex;
      align-items: center;
      padding: 6px;
      border-radius: 6px;

      font-size: 14px;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-600);
    }
    .faq-test {
      margin-right: 4px;
    }
  }
`
const ChannelWrapper = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  height: calc(100% - ${faq_height});
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--seed-semantic-color-paper-sheet);
  .not-exist-channelList {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    width: 100%;

    text-align: center;
    color: var(--seed-scale-color-gray-700);
    transform: translateY(-50%);
    .title {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 18px;
      line-height: 135%;
      letter-spacing: -0.03em;
    }
    .description {
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      opacity: 0.8;
    }
  }
`

const MoreLoadButton = styled.button`
  display: block;
  width: 100%;
  height: 44px;
  border: none;
  background-color: var(--seed-semantic-color-paper-default);
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--seed-scale-color-carrot-500);
  cursor: pointer;
  &:hover {
    background-color: var(--seed-scale-color-gray-100);
  }
`

const SidebarHeader = styled.div`
  position: relative;
  display: flex;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);
  padding: 0 20px;
  align-items: center;
  .nickname-area {
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
  }
`

const ChannelPreviewWrapper = styled.li`
  position: relative;
  .only-hover {
    display: none;
  }
  &:hover > .only-hover {
    display: flex;
  }
`
const ChannelPreviewStyle = styled(Link)`
  display: flex;
  padding: 16px;
  height: 72px;
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: var(--seed-semantic-color-paper-default);
  transition: background-color 0.6s, background-size 0.6s;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
  background-position: center;
  contain: content;
  &.selected {
    background-color: var(--seed-scale-color-gray-100);
  }
  &.favorite {
    border-left: 3px solid var(--seed-scale-color-carrot-500);
  }
  &:hover {
    background: var(--seed-scale-color-gray-200) radial-gradient(circle, transparent 1%, var(--seed-scale-color-gray-200) 1%) center/15000%;
  }
  &:active {
    background-color: var(--seed-scale-color-gray-400);
    background-size: 100%;
    transition: background-color 0s;
  }
  &.destroyed {
    opacity: 0.5;
  }
  .preview-image {
    margin-left: 8px;
    border: 1px solid var(--profile-image-border);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  &:hover {
    background-color: var(--seed-scale-color-gray-100);
  }
  .profile-wrapper {
    margin-right: 8px;
    height: 40px;
    .profile-image {
      width: 40px;
      min-height: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid var(--profile-image-border);
    }
  }
  .unread-count-wrapper {
    margin-left: 12px;
    border-radius: 50%;
    height: 26px;
    line-height: 26px;
    width: 26px;
    letter-spacing: -0.02em;

    font-size: 13px;
    text-align: center;
    color: #fff;
    background-color: var(--seed-scale-color-carrot-500);
  }
`

const GroupProfileImg = styled.div`
  position: relative;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  .profile-image {
    position: absolute;
    border: 1px solid var(--profile-image-border);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: var(--seed-semantic-color-paper-default);
    &.first-image {
      left: 0;
      top: 0;
    }
    &.second-image {
      outline: 1px solid var(--seed-semantic-color-paper-default);
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
`
const PreviewText = styled.div`
  flex: 1 0;
  width: 0;
  .preview-title-wrap {
    display: flex;
    align-items: center;
    .preview-nickname {
      height: 20px;
      font-weight: bold;
      font-size: 13px;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-900);

      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .badge-wrapper {
      margin-left: 2px;
    }
    .sub-text {
      margin-left: 4px;
      color: var(--seed-scale-color-gray-600);
      font-size: 12px;
      white-space: nowrap;
    }
    .member-count {
      margin-left: 4px;
      height: 20px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.55);
      font-weight: 700;
    }
  }
  .preview-description {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 13px;
    color: var(--seed-scale-color-gray-700);
    .promise-tag {
      margin-right: 4px;
      padding: 0 3px;
      border: 1px solid var(--seed-scale-color-carrot-500);
      border-radius: 12px;
      color: var(--seed-scale-color-carrot-500);
      font-size: 11px;
      white-space: nowrap;
    }
    .reserve-tag {
      margin-right: 4px;
      padding: 0 3px;
      border: 1px solid var(--seed-scale-color-green-500);
      border-radius: 12px;
      color: var(--seed-scale-color-green-500);
      font-size: 11px;
      white-space: nowrap;
    }
    .mute-wrapper {
      min-width: 18px;
      svg {
        margin-right: 4px;
        vertical-align: middle;
      }
    }
    .description-text {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

//#endregion channel list

//#region channel list header
const HeaderWrapper = styled.div`
  display: flex;
  .header-item {
    display: inline-flex;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    width: 50%;
    height: 30px;
    justify-content: center;
    align-items: center;
    &.selected {
      border-color: #000;
    }
  }
`
const UnreadMenuWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 44px;
  min-height: 44px;
  padding: 0 6px;
  align-items: center;
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);
  font-size: 12px;
  align-items: center;
  .unread-label {
    display: flex;
    padding: 6px;
    border-radius: 6px;

    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: var(--seed-scale-color-gray-600);

    .unread-description {
      margin-right: 4px;
    }

    .checkbox {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
    }
    .checkbox:checked + svg {
      fill: var(--seed-scale-color-carrot-500);
    }
  }
`
export const ChannelListNoticeWrapper = styled.div`
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);
  .notice-area {
    display: flex;
    margin: 12px 16px 4px 16px;
    padding: 18px 16px 18px 18px;
    border-radius: 10px;
    background-color: var(--seed-scale-color-blue-50);
    .content-area {
      letter-spacing: -0.02em;
      font-size: 14px;
      line-height: 20px;
      color: var(--seed-scale-color-blue-800);
    }
    .close-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      width: 24px;
      min-width: 24px;
      .icon-wrapper {
        cursor: pointer;
      }
    }
  }
`
//#endregion channel list header

export {
  ChannelListWrapper,
  FAQWrapper,
  ChannelWrapper,
  SidebarHeader,
  ChannelPreviewWrapper,
  ChannelPreviewStyle,
  GroupProfileImg,
  PreviewText,
  HeaderWrapper,
  UnreadMenuWrapper,
  MoreLoadButton,
}
