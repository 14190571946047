import styled from '@emotion/styled'

//#region ChatHeader
const HeaderRapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid var(--seed-semantic-color-divider-1);
  padding: 0 20px;
  .chat-header-profile {
    display: flex;
    align-items: center;
  }
  .chat-header-image {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    border: 1px solid var(--profile-image-border);
    border-radius: 50%;
  }
  .main-title {
    display: inline-flex;
    align-items: center;
    .title-category {
      margin-left: 10px;
      font-size: 12px;
      color: var(--title-sub-color);
    }
    .temperature {
      margin-left: 5px;
      border: 1px solid var(--seed-semantic-color-primary);
      border-radius: 1000px;
      padding: 0 5px;
      font-size: 11px;
      color: var(--seed-semantic-color-primary);
    }
    .biz-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.02em;
    }
    .official-badge-wrapper {
      margin-left: 2px;
    }
  }
  .biz-option {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
    .biz-review {
      font-weight: bold;
      color: var(--seed-scale-color-carrot-500);
    }
    .biz-regular {
      color: var(--seed-scale-color-gray-600);
    }
  }
  .group-title {
    font-weight: bold;
    .title-member-count {
      margin-left: 4px;
      color: var(--seed-scale-color-gray-600);
    }
  }
`
const SubHeader = styled.a`
  .reserved-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 20px;
    font-size: 14px;
    border-bottom: 1px solid var(--seed-semantic-color-divider-1);
    .reserved-price {
      font-weight: bold;
    }
    .article-image {
      margin-right: 12px;
      border: 1px solid var(--profile-image-border);
      border-radius: 4px;
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
    .reserved-main {
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: var(--seed-scale-color-gray-900);
      .flea-price {
        font-weight: 700;
      }
    }
    .reserve-button {
      display: flex;
      align-items: center;
      padding: 0 14px;
      border: 1px solid var(--seed-scale-color-gray-600);
      box-sizing: border-box;
      border-radius: 6px;
      height: 32px;

      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      &.reserved {
        color: var(--seed-scale-color-green-500);
      }
    }
  }
  .alram-header {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 20px;
    border-bottom: 1px solid var(--seed-semantic-color-divider-1);
    .alram-content {
      margin-left: 6px;
      font-weight: bold;
      font-size: 13px;
      line-height: 150%;
      letter-spacing: -0.02em;
    }
  }
`
const NoticeWrapper = styled.div`
  .warning {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 0;

    background: var(--seed-static-color-red-800);
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: var(--seed-semantic-color-paper-default);
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border-radius: 10px;
    padding: 20px;

    background: var(--seed-scale-color-gray-100);
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: var(--seed-scale-color-gray-700);
  }
`
//#endregion

export { HeaderRapper, SubHeader, NoticeWrapper }
