import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { closePopup } from 'utils/localDispatcher'
import { StoreState } from 'store/ducks'

const ModalWrapper = styled.div`
  transform: translateX(-50%);
  z-index: 9999;

  .popup-wrapper {
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    width: 240px;
    color: #000;
    background-color: var(--seed-semantic-color-paper-default);
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    &.reverse {
      transform: translateY(-100%);
    }
    .popup-item {
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding-left: 8px;
      height: 40px;
      cursor: pointer;
      transition: background-color 0.3s;
      color: var(--seed-scale-color-gray-900);
      &:hover {
        background-color: var(--seed-scale-color-gray-200);
      }
    }
    .add-arrow {
      position: absolute;
      display: inline-block;
      top: -8px;
      left: 50%;
      border-top: 1px solid;
      border-left: 1px solid;
      width: 16px;
      height: 16px;
      color: #dcdcdc;
      background-color: var(--seed-semantic-color-paper-default);
      transform: translateX(-50%) rotate(45deg);
      &.down {
        border: none;
        border-bottom: 1px solid;
        border-right: 1px solid;
        top: auto;
        bottom: -8px;
      }
    }
  }
`

const ReduxPortalPopup = () => {
  const popupObj = useSelector((state: StoreState) => state.utilStore.popup)
  const keydownHelper = (e: KeyboardEvent) => {
    if (e.key === 'Escape') closePopup()
  }
  useEffect(() => {
    document.addEventListener('keydown', keydownHelper)
    return () => document.removeEventListener('keydown', keydownHelper)
  }, [])
  return popupObj.isShow && popupObj.coord ? (
    <ModalWrapper
      style={{
        position: 'absolute',
        transform: `translate(${popupObj.coord[0]}px, ${popupObj.coord[1]}px) translate(-50%, 0px)`,
      }}
    >
      {popupObj.content}
    </ModalWrapper>
  ) : null
}

export default ReduxPortalPopup
