import { createReducer, createAction } from '@reduxjs/toolkit'

export const setSocketReadyState = createAction<number>('socket/socketReadyState')

interface socketStoreProps {
  chatSocket: number
  qrSocket: number
}
const initialState:socketStoreProps = {
  chatSocket: 0,
  qrSocket: 0
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setSocketReadyState, (state, action) => {
    const readState = action.payload
    state.chatSocket = readState
  })
})