// source: api/model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var validate_validate_pb = require('../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.api.Announcement', null, global);
goog.exportSymbol('proto.api.Article', null, global);
goog.exportSymbol('proto.api.ArticleTemplate', null, global);
goog.exportSymbol('proto.api.AutoReply', null, global);
goog.exportSymbol('proto.api.AutoReplyAnswer', null, global);
goog.exportSymbol('proto.api.AutoReplyMetadata', null, global);
goog.exportSymbol('proto.api.AutoReplyQuestion', null, global);
goog.exportSymbol('proto.api.Badge', null, global);
goog.exportSymbol('proto.api.BizAccount', null, global);
goog.exportSymbol('proto.api.BizAccount.Type', null, global);
goog.exportSymbol('proto.api.BizAccountUser', null, global);
goog.exportSymbol('proto.api.BlockNode', null, global);
goog.exportSymbol('proto.api.BlockNode.BlockCase', null, global);
goog.exportSymbol('proto.api.BlockTemplate', null, global);
goog.exportSymbol('proto.api.BubbleGroup', null, global);
goog.exportSymbol('proto.api.Button', null, global);
goog.exportSymbol('proto.api.Button.ActionCase', null, global);
goog.exportSymbol('proto.api.Button.Type', null, global);
goog.exportSymbol('proto.api.ButtonPostbackAction', null, global);
goog.exportSymbol('proto.api.ButtonPostbackAction.ActionCase', null, global);
goog.exportSymbol('proto.api.ButtonPostbackPayload', null, global);
goog.exportSymbol('proto.api.ButtonPostbackPayload.HoianPromise', null, global);
goog.exportSymbol('proto.api.ButtonPostbackPayload.Type', null, global);
goog.exportSymbol('proto.api.ButtonPostbackPayload.WebLogout', null, global);
goog.exportSymbol('proto.api.Channel', null, global);
goog.exportSymbol('proto.api.Channel.EnabledFeature', null, global);
goog.exportSymbol('proto.api.Channel.Input', null, global);
goog.exportSymbol('proto.api.Channel.Input.Text', null, global);
goog.exportSymbol('proto.api.Channel.Input.TypeCase', null, global);
goog.exportSymbol('proto.api.Channel.Type', null, global);
goog.exportSymbol('proto.api.ChannelListHeader', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.BasicBanner', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.BasicBanner.Button', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.BasicBanner.Button.Type', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.BasicBanner.Color', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.Notice', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.NoticeWithLink', null, global);
goog.exportSymbol('proto.api.ChannelListHeader.TemplateCase', null, global);
goog.exportSymbol('proto.api.Emoji', null, global);
goog.exportSymbol('proto.api.Filter', null, global);
goog.exportSymbol('proto.api.FilterID', null, global);
goog.exportSymbol('proto.api.GenericTemplate', null, global);
goog.exportSymbol('proto.api.IconTemplate', null, global);
goog.exportSymbol('proto.api.Image', null, global);
goog.exportSymbol('proto.api.ImageAspectRatio', null, global);
goog.exportSymbol('proto.api.ImageButton', null, global);
goog.exportSymbol('proto.api.InlineTemplate', null, global);
goog.exportSymbol('proto.api.Introduction', null, global);
goog.exportSymbol('proto.api.Introduction.Row', null, global);
goog.exportSymbol('proto.api.Link', null, global);
goog.exportSymbol('proto.api.LocationTemplate', null, global);
goog.exportSymbol('proto.api.Message', null, global);
goog.exportSymbol('proto.api.Message.TemplateCase', null, global);
goog.exportSymbol('proto.api.MessageContext', null, global);
goog.exportSymbol('proto.api.MoreMenu', null, global);
goog.exportSymbol('proto.api.MoreMenu.ValueCase', null, global);
goog.exportSymbol('proto.api.NativeAction', null, global);
goog.exportSymbol('proto.api.Notice', null, global);
goog.exportSymbol('proto.api.PersistentMenu', null, global);
goog.exportSymbol('proto.api.PersistentMenuItem', null, global);
goog.exportSymbol('proto.api.PersistentMenuItem.ActionCase', null, global);
goog.exportSymbol('proto.api.PersistentMenuItem.OpenURLAction', null, global);
goog.exportSymbol('proto.api.PersistentMenuItem.PostbackAction', null, global);
goog.exportSymbol('proto.api.Promise', null, global);
goog.exportSymbol('proto.api.Promise.Location', null, global);
goog.exportSymbol('proto.api.Reaction', null, global);
goog.exportSymbol('proto.api.ReactionSummary', null, global);
goog.exportSymbol('proto.api.ReactionTarget', null, global);
goog.exportSymbol('proto.api.ReactionTarget.MessageID', null, global);
goog.exportSymbol('proto.api.ReactionTarget.ValueCase', null, global);
goog.exportSymbol('proto.api.Region', null, global);
goog.exportSymbol('proto.api.SearchAnalyzerType', null, global);
goog.exportSymbol('proto.api.SearchMessageHit', null, global);
goog.exportSymbol('proto.api.SearchPagingKey', null, global);
goog.exportSymbol('proto.api.SearchPagingKey.ValueCase', null, global);
goog.exportSymbol('proto.api.SearchResultChannel', null, global);
goog.exportSymbol('proto.api.SearchResultMessage', null, global);
goog.exportSymbol('proto.api.SearchResultMessage.Token', null, global);
goog.exportSymbol('proto.api.SearchResultMessage.Token.TypeCase', null, global);
goog.exportSymbol('proto.api.SearchResultReactionSummary', null, global);
goog.exportSymbol('proto.api.SearchResultUser', null, global);
goog.exportSymbol('proto.api.SearchSortOption', null, global);
goog.exportSymbol('proto.api.SectionBlock', null, global);
goog.exportSymbol('proto.api.Smb', null, global);
goog.exportSymbol('proto.api.Snackbar', null, global);
goog.exportSymbol('proto.api.SystemMessage', null, global);
goog.exportSymbol('proto.api.SystemMessage.Type', null, global);
goog.exportSymbol('proto.api.TemplateType', null, global);
goog.exportSymbol('proto.api.TextNode', null, global);
goog.exportSymbol('proto.api.TextNode.ContentCase', null, global);
goog.exportSymbol('proto.api.TextNode.Markdown', null, global);
goog.exportSymbol('proto.api.TextNode.PlainText', null, global);
goog.exportSymbol('proto.api.TextShortcut', null, global);
goog.exportSymbol('proto.api.ToolboxButton', null, global);
goog.exportSymbol('proto.api.ToolboxButton.ActionCase', null, global);
goog.exportSymbol('proto.api.TopBar', null, global);
goog.exportSymbol('proto.api.TopBar.BadgeCase', null, global);
goog.exportSymbol('proto.api.UnreadCount', null, global);
goog.exportSymbol('proto.api.User', null, global);
goog.exportSymbol('proto.api.User.KarrotScore', null, global);
goog.exportSymbol('proto.api.User.Role', null, global);
goog.exportSymbol('proto.api.User.ScoreCase', null, global);
goog.exportSymbol('proto.api.User.Type', null, global);
goog.exportSymbol('proto.api.Video', null, global);
goog.exportSymbol('proto.api.Video.Playback', null, global);
goog.exportSymbol('proto.api.VideoTemplate', null, global);
goog.exportSymbol('proto.api.Visible', null, global);
goog.exportSymbol('proto.api.WebView', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Message.repeatedFields_, proto.api.Message.oneofGroups_);
};
goog.inherits(proto.api.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Message.displayName = 'proto.api.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MessageContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MessageContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MessageContext.displayName = 'proto.api.MessageContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Link.displayName = 'proto.api.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Promise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Promise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Promise.displayName = 'proto.api.Promise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Promise.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Promise.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Promise.Location.displayName = 'proto.api.Promise.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Image.displayName = 'proto.api.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Video = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Video, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Video.displayName = 'proto.api.Video';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Video.Playback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Video.Playback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Video.Playback.displayName = 'proto.api.Video.Playback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.VideoTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.VideoTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.VideoTemplate.displayName = 'proto.api.VideoTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Channel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Channel.repeatedFields_, null);
};
goog.inherits(proto.api.Channel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Channel.displayName = 'proto.api.Channel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Channel.EnabledFeature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Channel.EnabledFeature.repeatedFields_, null);
};
goog.inherits(proto.api.Channel.EnabledFeature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Channel.EnabledFeature.displayName = 'proto.api.Channel.EnabledFeature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Channel.Input = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.Channel.Input.oneofGroups_);
};
goog.inherits(proto.api.Channel.Input, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Channel.Input.displayName = 'proto.api.Channel.Input';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Channel.Input.Text = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Channel.Input.Text, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Channel.Input.Text.displayName = 'proto.api.Channel.Input.Text';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Article = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Article, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Article.displayName = 'proto.api.Article';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Smb = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Smb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Smb.displayName = 'proto.api.Smb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.User.repeatedFields_, proto.api.User.oneofGroups_);
};
goog.inherits(proto.api.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.User.displayName = 'proto.api.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.User.KarrotScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.User.KarrotScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.User.KarrotScore.displayName = 'proto.api.User.KarrotScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Region = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Region, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Region.displayName = 'proto.api.Region';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Notice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Notice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Notice.displayName = 'proto.api.Notice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UnreadCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UnreadCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UnreadCount.displayName = 'proto.api.UnreadCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SystemMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SystemMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SystemMessage.displayName = 'proto.api.SystemMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GenericTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GenericTemplate.repeatedFields_, null);
};
goog.inherits(proto.api.GenericTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GenericTemplate.displayName = 'proto.api.GenericTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ArticleTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ArticleTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ArticleTemplate.displayName = 'proto.api.ArticleTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LocationTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.LocationTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LocationTemplate.displayName = 'proto.api.LocationTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.IconTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.IconTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.IconTemplate.displayName = 'proto.api.IconTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.InlineTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.InlineTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.InlineTemplate.displayName = 'proto.api.InlineTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Button = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.Button.oneofGroups_);
};
goog.inherits(proto.api.Button, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Button.displayName = 'proto.api.Button';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ButtonPostbackPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ButtonPostbackPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ButtonPostbackPayload.displayName = 'proto.api.ButtonPostbackPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ButtonPostbackPayload.HoianPromise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ButtonPostbackPayload.HoianPromise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ButtonPostbackPayload.HoianPromise.displayName = 'proto.api.ButtonPostbackPayload.HoianPromise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ButtonPostbackPayload.WebLogout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ButtonPostbackPayload.WebLogout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ButtonPostbackPayload.WebLogout.displayName = 'proto.api.ButtonPostbackPayload.WebLogout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ButtonPostbackAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.ButtonPostbackAction.oneofGroups_);
};
goog.inherits(proto.api.ButtonPostbackAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ButtonPostbackAction.displayName = 'proto.api.ButtonPostbackAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Snackbar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Snackbar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Snackbar.displayName = 'proto.api.Snackbar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BizAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BizAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BizAccount.displayName = 'proto.api.BizAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BizAccountUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BizAccountUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BizAccountUser.displayName = 'proto.api.BizAccountUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.WebView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.WebView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.WebView.displayName = 'proto.api.WebView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TextShortcut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.TextShortcut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TextShortcut.displayName = 'proto.api.TextShortcut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BubbleGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.BubbleGroup.repeatedFields_, null);
};
goog.inherits(proto.api.BubbleGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BubbleGroup.displayName = 'proto.api.BubbleGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Introduction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.Introduction.repeatedFields_, null);
};
goog.inherits(proto.api.Introduction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Introduction.displayName = 'proto.api.Introduction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Introduction.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Introduction.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Introduction.Row.displayName = 'proto.api.Introduction.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ImageButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ImageButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ImageButton.displayName = 'proto.api.ImageButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ToolboxButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.ToolboxButton.oneofGroups_);
};
goog.inherits(proto.api.ToolboxButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ToolboxButton.displayName = 'proto.api.ToolboxButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ChannelListHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.ChannelListHeader.oneofGroups_);
};
goog.inherits(proto.api.ChannelListHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ChannelListHeader.displayName = 'proto.api.ChannelListHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ChannelListHeader.Notice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ChannelListHeader.Notice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ChannelListHeader.Notice.displayName = 'proto.api.ChannelListHeader.Notice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ChannelListHeader.NoticeWithLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ChannelListHeader.NoticeWithLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ChannelListHeader.NoticeWithLink.displayName = 'proto.api.ChannelListHeader.NoticeWithLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ChannelListHeader.BasicBanner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ChannelListHeader.BasicBanner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ChannelListHeader.BasicBanner.displayName = 'proto.api.ChannelListHeader.BasicBanner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ChannelListHeader.BasicBanner.Button = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ChannelListHeader.BasicBanner.Button, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ChannelListHeader.BasicBanner.Button.displayName = 'proto.api.ChannelListHeader.BasicBanner.Button';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PersistentMenuItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.PersistentMenuItem.oneofGroups_);
};
goog.inherits(proto.api.PersistentMenuItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PersistentMenuItem.displayName = 'proto.api.PersistentMenuItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PersistentMenuItem.PostbackAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.PersistentMenuItem.PostbackAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PersistentMenuItem.PostbackAction.displayName = 'proto.api.PersistentMenuItem.PostbackAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PersistentMenuItem.OpenURLAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.PersistentMenuItem.OpenURLAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PersistentMenuItem.OpenURLAction.displayName = 'proto.api.PersistentMenuItem.OpenURLAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.PersistentMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.PersistentMenu.repeatedFields_, null);
};
goog.inherits(proto.api.PersistentMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.PersistentMenu.displayName = 'proto.api.PersistentMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AutoReplyMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AutoReplyMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AutoReplyMetadata.displayName = 'proto.api.AutoReplyMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AutoReplyQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AutoReplyQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AutoReplyQuestion.displayName = 'proto.api.AutoReplyQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AutoReplyAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.AutoReplyAnswer.repeatedFields_, null);
};
goog.inherits(proto.api.AutoReplyAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AutoReplyAnswer.displayName = 'proto.api.AutoReplyAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AutoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AutoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AutoReply.displayName = 'proto.api.AutoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Announcement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Announcement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Announcement.displayName = 'proto.api.Announcement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SectionBlock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SectionBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SectionBlock.displayName = 'proto.api.SectionBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TextNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.TextNode.oneofGroups_);
};
goog.inherits(proto.api.TextNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TextNode.displayName = 'proto.api.TextNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TextNode.Markdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.TextNode.Markdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TextNode.Markdown.displayName = 'proto.api.TextNode.Markdown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TextNode.PlainText = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.TextNode.PlainText, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TextNode.PlainText.displayName = 'proto.api.TextNode.PlainText';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BlockNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.BlockNode.oneofGroups_);
};
goog.inherits(proto.api.BlockNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BlockNode.displayName = 'proto.api.BlockNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BlockTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.BlockTemplate.repeatedFields_, null);
};
goog.inherits(proto.api.BlockTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BlockTemplate.displayName = 'proto.api.BlockTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MoreMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.MoreMenu.oneofGroups_);
};
goog.inherits(proto.api.MoreMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MoreMenu.displayName = 'proto.api.MoreMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TopBar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.TopBar.oneofGroups_);
};
goog.inherits(proto.api.TopBar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TopBar.displayName = 'proto.api.TopBar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ReactionTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.ReactionTarget.oneofGroups_);
};
goog.inherits(proto.api.ReactionTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ReactionTarget.displayName = 'proto.api.ReactionTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ReactionTarget.MessageID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ReactionTarget.MessageID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ReactionTarget.MessageID.displayName = 'proto.api.ReactionTarget.MessageID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Emoji = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Emoji, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Emoji.displayName = 'proto.api.Emoji';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ReactionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ReactionSummary.repeatedFields_, null);
};
goog.inherits(proto.api.ReactionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ReactionSummary.displayName = 'proto.api.ReactionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Reaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Reaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Reaction.displayName = 'proto.api.Reaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Filter.displayName = 'proto.api.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchResultChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SearchResultChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchResultChannel.displayName = 'proto.api.SearchResultChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchResultUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SearchResultUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchResultUser.displayName = 'proto.api.SearchResultUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchResultReactionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SearchResultReactionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchResultReactionSummary.displayName = 'proto.api.SearchResultReactionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchResultMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.SearchResultMessage.repeatedFields_, null);
};
goog.inherits(proto.api.SearchResultMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchResultMessage.displayName = 'proto.api.SearchResultMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchResultMessage.Token = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.SearchResultMessage.Token.oneofGroups_);
};
goog.inherits(proto.api.SearchResultMessage.Token, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchResultMessage.Token.displayName = 'proto.api.SearchResultMessage.Token';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchMessageHit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SearchMessageHit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchMessageHit.displayName = 'proto.api.SearchMessageHit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchPagingKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.SearchPagingKey.oneofGroups_);
};
goog.inherits(proto.api.SearchPagingKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchPagingKey.displayName = 'proto.api.SearchPagingKey';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Message.repeatedFields_ = [20,23];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.Message.oneofGroups_ = [[50,51,52,53,54,55,56]];

/**
 * @enum {number}
 */
proto.api.Message.TemplateCase = {
  TEMPLATE_NOT_SET: 0,
  ARTICLE_TEMPLATE: 50,
  GENERIC_TEMPLATE: 51,
  LOCATION_TEMPLATE: 52,
  ICON_TEMPLATE: 53,
  INLINE_TEMPLATE: 54,
  BLOCK_TEMPLATE: 55,
  VIDEO_TEMPLATE: 56
};

/**
 * @return {proto.api.Message.TemplateCase}
 */
proto.api.Message.prototype.getTemplateCase = function() {
  return /** @type {proto.api.Message.TemplateCase} */(jspb.Message.computeOneofCase(this, proto.api.Message.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    senderId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    seenMap: (f = msg.getSeenMap()) ? f.toObject(includeInstance, proto.google.protobuf.Timestamp.toObject) : [],
    data: jspb.Message.getFieldWithDefault(msg, 8, ""),
    receiverId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    imageId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    link: (f = msg.getLink()) && proto.api.Link.toObject(includeInstance, f),
    promise: (f = msg.getPromise()) && proto.api.Promise.toObject(includeInstance, f),
    pictureId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    systemMessage: (f = msg.getSystemMessage()) && proto.api.SystemMessage.toObject(includeInstance, f),
    templateType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    visible: jspb.Message.getFieldWithDefault(msg, 17, 0),
    sender: (f = msg.getSender()) && proto.api.User.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && proto.api.User.toObject(includeInstance, f),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.api.Image.toObject, includeInstance),
    deleteTime: (f = msg.getDeleteTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    messageContext: (f = msg.getMessageContext()) && proto.api.MessageContext.toObject(includeInstance, f),
    reactionsList: jspb.Message.toObjectList(msg.getReactionsList(),
    proto.api.ReactionSummary.toObject, includeInstance),
    articleTemplate: (f = msg.getArticleTemplate()) && proto.api.ArticleTemplate.toObject(includeInstance, f),
    genericTemplate: (f = msg.getGenericTemplate()) && proto.api.GenericTemplate.toObject(includeInstance, f),
    locationTemplate: (f = msg.getLocationTemplate()) && proto.api.LocationTemplate.toObject(includeInstance, f),
    iconTemplate: (f = msg.getIconTemplate()) && proto.api.IconTemplate.toObject(includeInstance, f),
    inlineTemplate: (f = msg.getInlineTemplate()) && proto.api.InlineTemplate.toObject(includeInstance, f),
    blockTemplate: (f = msg.getBlockTemplate()) && proto.api.BlockTemplate.toObject(includeInstance, f),
    videoTemplate: (f = msg.getVideoTemplate()) && proto.api.VideoTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Message}
 */
proto.api.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Message;
  return proto.api.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Message}
 */
proto.api.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 7:
      var value = msg.getSeenMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Timestamp.deserializeBinaryFromReader, 0, new proto.google.protobuf.Timestamp());
         });
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImageId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 12:
      var value = new proto.api.Link;
      reader.readMessage(value,proto.api.Link.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    case 13:
      var value = new proto.api.Promise;
      reader.readMessage(value,proto.api.Promise.deserializeBinaryFromReader);
      msg.setPromise(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureId(value);
      break;
    case 15:
      var value = new proto.api.SystemMessage;
      reader.readMessage(value,proto.api.SystemMessage.deserializeBinaryFromReader);
      msg.setSystemMessage(value);
      break;
    case 16:
      var value = /** @type {!proto.api.TemplateType} */ (reader.readEnum());
      msg.setTemplateType(value);
      break;
    case 17:
      var value = /** @type {!proto.api.Visible} */ (reader.readEnum());
      msg.setVisible(value);
      break;
    case 18:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 19:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 20:
      var value = new proto.api.Image;
      reader.readMessage(value,proto.api.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteTime(value);
      break;
    case 22:
      var value = new proto.api.MessageContext;
      reader.readMessage(value,proto.api.MessageContext.deserializeBinaryFromReader);
      msg.setMessageContext(value);
      break;
    case 23:
      var value = new proto.api.ReactionSummary;
      reader.readMessage(value,proto.api.ReactionSummary.deserializeBinaryFromReader);
      msg.addReactions(value);
      break;
    case 50:
      var value = new proto.api.ArticleTemplate;
      reader.readMessage(value,proto.api.ArticleTemplate.deserializeBinaryFromReader);
      msg.setArticleTemplate(value);
      break;
    case 51:
      var value = new proto.api.GenericTemplate;
      reader.readMessage(value,proto.api.GenericTemplate.deserializeBinaryFromReader);
      msg.setGenericTemplate(value);
      break;
    case 52:
      var value = new proto.api.LocationTemplate;
      reader.readMessage(value,proto.api.LocationTemplate.deserializeBinaryFromReader);
      msg.setLocationTemplate(value);
      break;
    case 53:
      var value = new proto.api.IconTemplate;
      reader.readMessage(value,proto.api.IconTemplate.deserializeBinaryFromReader);
      msg.setIconTemplate(value);
      break;
    case 54:
      var value = new proto.api.InlineTemplate;
      reader.readMessage(value,proto.api.InlineTemplate.deserializeBinaryFromReader);
      msg.setInlineTemplate(value);
      break;
    case 55:
      var value = new proto.api.BlockTemplate;
      reader.readMessage(value,proto.api.BlockTemplate.deserializeBinaryFromReader);
      msg.setBlockTemplate(value);
      break;
    case 56:
      var value = new proto.api.VideoTemplate;
      reader.readMessage(value,proto.api.VideoTemplate.deserializeBinaryFromReader);
      msg.setVideoTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSenderId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSeenMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Timestamp.serializeBinaryToWriter);
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReceiverId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getImageId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.api.Link.serializeBinaryToWriter
    );
  }
  f = message.getPromise();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.api.Promise.serializeBinaryToWriter
    );
  }
  f = message.getPictureId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSystemMessage();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.api.SystemMessage.serializeBinaryToWriter
    );
  }
  f = message.getTemplateType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getVisible();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.api.Image.serializeBinaryToWriter
    );
  }
  f = message.getDeleteTime();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessageContext();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.api.MessageContext.serializeBinaryToWriter
    );
  }
  f = message.getReactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.api.ReactionSummary.serializeBinaryToWriter
    );
  }
  f = message.getArticleTemplate();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.api.ArticleTemplate.serializeBinaryToWriter
    );
  }
  f = message.getGenericTemplate();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.api.GenericTemplate.serializeBinaryToWriter
    );
  }
  f = message.getLocationTemplate();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.api.LocationTemplate.serializeBinaryToWriter
    );
  }
  f = message.getIconTemplate();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.api.IconTemplate.serializeBinaryToWriter
    );
  }
  f = message.getInlineTemplate();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.api.InlineTemplate.serializeBinaryToWriter
    );
  }
  f = message.getBlockTemplate();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.api.BlockTemplate.serializeBinaryToWriter
    );
  }
  f = message.getVideoTemplate();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.api.VideoTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Message.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.api.Message.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string channel_id = 3;
 * @return {string}
 */
proto.api.Message.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sender_id = 4;
 * @return {number}
 */
proto.api.Message.prototype.getSenderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.api.Message.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Message.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * map<int64, google.protobuf.Timestamp> seen = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.google.protobuf.Timestamp>}
 */
proto.api.Message.prototype.getSeenMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.google.protobuf.Timestamp>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.google.protobuf.Timestamp));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearSeenMap = function() {
  this.getSeenMap().clear();
  return this;
};


/**
 * optional string data = 8;
 * @return {string}
 */
proto.api.Message.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 receiver_id = 9;
 * @return {number}
 */
proto.api.Message.prototype.getReceiverId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 image_id = 10;
 * @return {number}
 */
proto.api.Message.prototype.getImageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setImageId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string image_url = 11;
 * @return {string}
 */
proto.api.Message.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Link link = 12;
 * @return {?proto.api.Link}
 */
proto.api.Message.prototype.getLink = function() {
  return /** @type{?proto.api.Link} */ (
    jspb.Message.getWrapperField(this, proto.api.Link, 12));
};


/**
 * @param {?proto.api.Link|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setLink = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasLink = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Promise promise = 13;
 * @return {?proto.api.Promise}
 */
proto.api.Message.prototype.getPromise = function() {
  return /** @type{?proto.api.Promise} */ (
    jspb.Message.getWrapperField(this, proto.api.Promise, 13));
};


/**
 * @param {?proto.api.Promise|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setPromise = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearPromise = function() {
  return this.setPromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasPromise = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string picture_id = 14;
 * @return {string}
 */
proto.api.Message.prototype.getPictureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setPictureId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional SystemMessage system_message = 15;
 * @return {?proto.api.SystemMessage}
 */
proto.api.Message.prototype.getSystemMessage = function() {
  return /** @type{?proto.api.SystemMessage} */ (
    jspb.Message.getWrapperField(this, proto.api.SystemMessage, 15));
};


/**
 * @param {?proto.api.SystemMessage|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setSystemMessage = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearSystemMessage = function() {
  return this.setSystemMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasSystemMessage = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TemplateType template_type = 16;
 * @return {!proto.api.TemplateType}
 */
proto.api.Message.prototype.getTemplateType = function() {
  return /** @type {!proto.api.TemplateType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.api.TemplateType} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setTemplateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional Visible visible = 17;
 * @return {!proto.api.Visible}
 */
proto.api.Message.prototype.getVisible = function() {
  return /** @type {!proto.api.Visible} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.api.Visible} value
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.setVisible = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional User sender = 18;
 * @return {?proto.api.User}
 */
proto.api.Message.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 18));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasSender = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional User receiver = 19;
 * @return {?proto.api.User}
 */
proto.api.Message.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 19));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated Image images = 20;
 * @return {!Array<!proto.api.Image>}
 */
proto.api.Message.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.api.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Image, 20));
};


/**
 * @param {!Array<!proto.api.Image>} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.api.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Image}
 */
proto.api.Message.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.api.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional google.protobuf.Timestamp delete_time = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Message.prototype.getDeleteTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setDeleteTime = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearDeleteTime = function() {
  return this.setDeleteTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasDeleteTime = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional MessageContext message_context = 22;
 * @return {?proto.api.MessageContext}
 */
proto.api.Message.prototype.getMessageContext = function() {
  return /** @type{?proto.api.MessageContext} */ (
    jspb.Message.getWrapperField(this, proto.api.MessageContext, 22));
};


/**
 * @param {?proto.api.MessageContext|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setMessageContext = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearMessageContext = function() {
  return this.setMessageContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasMessageContext = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated ReactionSummary reactions = 23;
 * @return {!Array<!proto.api.ReactionSummary>}
 */
proto.api.Message.prototype.getReactionsList = function() {
  return /** @type{!Array<!proto.api.ReactionSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ReactionSummary, 23));
};


/**
 * @param {!Array<!proto.api.ReactionSummary>} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setReactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.api.ReactionSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ReactionSummary}
 */
proto.api.Message.prototype.addReactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.api.ReactionSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearReactionsList = function() {
  return this.setReactionsList([]);
};


/**
 * optional ArticleTemplate article_template = 50;
 * @return {?proto.api.ArticleTemplate}
 */
proto.api.Message.prototype.getArticleTemplate = function() {
  return /** @type{?proto.api.ArticleTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.ArticleTemplate, 50));
};


/**
 * @param {?proto.api.ArticleTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setArticleTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearArticleTemplate = function() {
  return this.setArticleTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasArticleTemplate = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional GenericTemplate generic_template = 51;
 * @return {?proto.api.GenericTemplate}
 */
proto.api.Message.prototype.getGenericTemplate = function() {
  return /** @type{?proto.api.GenericTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.GenericTemplate, 51));
};


/**
 * @param {?proto.api.GenericTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setGenericTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearGenericTemplate = function() {
  return this.setGenericTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasGenericTemplate = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional LocationTemplate location_template = 52;
 * @return {?proto.api.LocationTemplate}
 */
proto.api.Message.prototype.getLocationTemplate = function() {
  return /** @type{?proto.api.LocationTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.LocationTemplate, 52));
};


/**
 * @param {?proto.api.LocationTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setLocationTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearLocationTemplate = function() {
  return this.setLocationTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasLocationTemplate = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional IconTemplate icon_template = 53;
 * @return {?proto.api.IconTemplate}
 */
proto.api.Message.prototype.getIconTemplate = function() {
  return /** @type{?proto.api.IconTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.IconTemplate, 53));
};


/**
 * @param {?proto.api.IconTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setIconTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearIconTemplate = function() {
  return this.setIconTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasIconTemplate = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional InlineTemplate inline_template = 54;
 * @return {?proto.api.InlineTemplate}
 */
proto.api.Message.prototype.getInlineTemplate = function() {
  return /** @type{?proto.api.InlineTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.InlineTemplate, 54));
};


/**
 * @param {?proto.api.InlineTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setInlineTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearInlineTemplate = function() {
  return this.setInlineTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasInlineTemplate = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional BlockTemplate block_template = 55;
 * @return {?proto.api.BlockTemplate}
 */
proto.api.Message.prototype.getBlockTemplate = function() {
  return /** @type{?proto.api.BlockTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.BlockTemplate, 55));
};


/**
 * @param {?proto.api.BlockTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setBlockTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearBlockTemplate = function() {
  return this.setBlockTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasBlockTemplate = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional VideoTemplate video_template = 56;
 * @return {?proto.api.VideoTemplate}
 */
proto.api.Message.prototype.getVideoTemplate = function() {
  return /** @type{?proto.api.VideoTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.VideoTemplate, 56));
};


/**
 * @param {?proto.api.VideoTemplate|undefined} value
 * @return {!proto.api.Message} returns this
*/
proto.api.Message.prototype.setVideoTemplate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.api.Message.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Message} returns this
 */
proto.api.Message.prototype.clearVideoTemplate = function() {
  return this.setVideoTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Message.prototype.hasVideoTemplate = function() {
  return jspb.Message.getField(this, 56) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MessageContext.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MessageContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MessageContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MessageContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sender: (f = msg.getSender()) && proto.api.User.toObject(includeInstance, f),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MessageContext}
 */
proto.api.MessageContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MessageContext;
  return proto.api.MessageContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MessageContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MessageContext}
 */
proto.api.MessageContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 2:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MessageContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MessageContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MessageContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MessageContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 message_id = 1;
 * @return {number}
 */
proto.api.MessageContext.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.MessageContext} returns this
 */
proto.api.MessageContext.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional User sender = 2;
 * @return {?proto.api.User}
 */
proto.api.MessageContext.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.MessageContext} returns this
*/
proto.api.MessageContext.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.MessageContext} returns this
 */
proto.api.MessageContext.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MessageContext.prototype.hasSender = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.api.MessageContext.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageContext} returns this
 */
proto.api.MessageContext.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.api.MessageContext.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageContext} returns this
 */
proto.api.MessageContext.prototype.setImageUrl = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.MessageContext} returns this
 */
proto.api.MessageContext.prototype.clearImageUrl = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MessageContext.prototype.hasImageUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buttonText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    articleId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    hasPrice: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Link}
 */
proto.api.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Link;
  return proto.api.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Link}
 */
proto.api.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getButtonText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArticleId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getHasPrice();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string kind = 1;
 * @return {string}
 */
proto.api.Link.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_uri = 2;
 * @return {string}
 */
proto.api.Link.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.api.Link.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string button_text = 4;
 * @return {string}
 */
proto.api.Link.prototype.getButtonText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setButtonText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 article_id = 5;
 * @return {number}
 */
proto.api.Link.prototype.getArticleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setArticleId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double price = 6;
 * @return {number}
 */
proto.api.Link.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool has_price = 7;
 * @return {boolean}
 */
proto.api.Link.prototype.getHasPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Link} returns this
 */
proto.api.Link.prototype.setHasPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Promise.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Promise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Promise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Promise.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    notifyBeforeMinutes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    location: (f = msg.getLocation()) && proto.api.Promise.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Promise}
 */
proto.api.Promise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Promise;
  return proto.api.Promise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Promise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Promise}
 */
proto.api.Promise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotifyBeforeMinutes(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 7:
      var value = new proto.api.Promise.Location;
      reader.readMessage(value,proto.api.Promise.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Promise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Promise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Promise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Promise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNotifyBeforeMinutes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.Promise.Location.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Promise.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Promise.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Promise.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Promise.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Promise.Location}
 */
proto.api.Promise.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Promise.Location;
  return proto.api.Promise.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Promise.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Promise.Location}
 */
proto.api.Promise.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Promise.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Promise.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Promise.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Promise.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.Promise.Location.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Promise.Location} returns this
 */
proto.api.Promise.Location.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double latitude = 2;
 * @return {number}
 */
proto.api.Promise.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Promise.Location} returns this
 */
proto.api.Promise.Location.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double longitude = 3;
 * @return {number}
 */
proto.api.Promise.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Promise.Location} returns this
 */
proto.api.Promise.Location.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Promise.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Promise.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Promise} returns this
*/
proto.api.Promise.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Promise.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool deleted = 3;
 * @return {boolean}
 */
proto.api.Promise.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.api.Promise.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 notify_before_minutes = 5;
 * @return {number}
 */
proto.api.Promise.prototype.getNotifyBeforeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.setNotifyBeforeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string channel_id = 6;
 * @return {string}
 */
proto.api.Promise.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Location location = 7;
 * @return {?proto.api.Promise.Location}
 */
proto.api.Promise.prototype.getLocation = function() {
  return /** @type{?proto.api.Promise.Location} */ (
    jspb.Message.getWrapperField(this, proto.api.Promise.Location, 7));
};


/**
 * @param {?proto.api.Promise.Location|undefined} value
 * @return {!proto.api.Promise} returns this
*/
proto.api.Promise.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Promise} returns this
 */
proto.api.Promise.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Promise.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    width: jspb.Message.getFieldWithDefault(msg, 3, 0),
    height: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Image}
 */
proto.api.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Image;
  return proto.api.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Image}
 */
proto.api.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Image.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Image} returns this
 */
proto.api.Image.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.api.Image.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Image} returns this
 */
proto.api.Image.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 width = 3;
 * @return {number}
 */
proto.api.Image.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Image} returns this
 */
proto.api.Image.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 height = 4;
 * @return {number}
 */
proto.api.Image.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Image} returns this
 */
proto.api.Image.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Video.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Video.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Video} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Video.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: (f = msg.getDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    width: jspb.Message.getFieldWithDefault(msg, 3, 0),
    height: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isReady: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    playback: (f = msg.getPlayback()) && proto.api.Video.Playback.toObject(includeInstance, f),
    size: jspb.Message.getFieldWithDefault(msg, 7, 0),
    uploadedFileName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Video}
 */
proto.api.Video.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Video;
  return proto.api.Video.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Video} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Video}
 */
proto.api.Video.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReady(value);
      break;
    case 6:
      var value = new proto.api.Video.Playback;
      reader.readMessage(value,proto.api.Video.Playback.deserializeBinaryFromReader);
      msg.setPlayback(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadedFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Video.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Video.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Video} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Video.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsReady();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPlayback();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.Video.Playback.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUploadedFileName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Video.Playback.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Video.Playback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Video.Playback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Video.Playback.toObject = function(includeInstance, msg) {
  var f, obj = {
    dash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hls: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mp4: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Video.Playback}
 */
proto.api.Video.Playback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Video.Playback;
  return proto.api.Video.Playback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Video.Playback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Video.Playback}
 */
proto.api.Video.Playback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHls(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMp4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Video.Playback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Video.Playback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Video.Playback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Video.Playback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHls();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMp4();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dash = 1;
 * @return {string}
 */
proto.api.Video.Playback.prototype.getDash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Video.Playback} returns this
 */
proto.api.Video.Playback.prototype.setDash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hls = 2;
 * @return {string}
 */
proto.api.Video.Playback.prototype.getHls = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Video.Playback} returns this
 */
proto.api.Video.Playback.prototype.setHls = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mp4 = 3;
 * @return {string}
 */
proto.api.Video.Playback.prototype.getMp4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Video.Playback} returns this
 */
proto.api.Video.Playback.prototype.setMp4 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Video.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Duration duration = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.api.Video.prototype.getDuration = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.api.Video} returns this
*/
proto.api.Video.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Video.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 width = 3;
 * @return {number}
 */
proto.api.Video.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 height = 4;
 * @return {number}
 */
proto.api.Video.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_ready = 5;
 * @return {boolean}
 */
proto.api.Video.prototype.getIsReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.setIsReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional Playback playback = 6;
 * @return {?proto.api.Video.Playback}
 */
proto.api.Video.prototype.getPlayback = function() {
  return /** @type{?proto.api.Video.Playback} */ (
    jspb.Message.getWrapperField(this, proto.api.Video.Playback, 6));
};


/**
 * @param {?proto.api.Video.Playback|undefined} value
 * @return {!proto.api.Video} returns this
*/
proto.api.Video.prototype.setPlayback = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.clearPlayback = function() {
  return this.setPlayback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Video.prototype.hasPlayback = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 size = 7;
 * @return {number}
 */
proto.api.Video.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string uploaded_file_name = 8;
 * @return {string}
 */
proto.api.Video.prototype.getUploadedFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Video} returns this
 */
proto.api.Video.prototype.setUploadedFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.VideoTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.VideoTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.VideoTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VideoTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    video: (f = msg.getVideo()) && proto.api.Video.toObject(includeInstance, f),
    thumbnail: (f = msg.getThumbnail()) && proto.api.Image.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.VideoTemplate}
 */
proto.api.VideoTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.VideoTemplate;
  return proto.api.VideoTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.VideoTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.VideoTemplate}
 */
proto.api.VideoTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Video;
      reader.readMessage(value,proto.api.Video.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    case 2:
      var value = new proto.api.Image;
      reader.readMessage(value,proto.api.Image.deserializeBinaryFromReader);
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.VideoTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.VideoTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.VideoTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.VideoTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Video.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional Video video = 1;
 * @return {?proto.api.Video}
 */
proto.api.VideoTemplate.prototype.getVideo = function() {
  return /** @type{?proto.api.Video} */ (
    jspb.Message.getWrapperField(this, proto.api.Video, 1));
};


/**
 * @param {?proto.api.Video|undefined} value
 * @return {!proto.api.VideoTemplate} returns this
*/
proto.api.VideoTemplate.prototype.setVideo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.VideoTemplate} returns this
 */
proto.api.VideoTemplate.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.VideoTemplate.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Image thumbnail = 2;
 * @return {?proto.api.Image}
 */
proto.api.VideoTemplate.prototype.getThumbnail = function() {
  return /** @type{?proto.api.Image} */ (
    jspb.Message.getWrapperField(this, proto.api.Image, 2));
};


/**
 * @param {?proto.api.Image|undefined} value
 * @return {!proto.api.VideoTemplate} returns this
*/
proto.api.VideoTemplate.prototype.setThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.VideoTemplate} returns this
 */
proto.api.VideoTemplate.prototype.clearThumbnail = function() {
  return this.setThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.VideoTemplate.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Channel.repeatedFields_ = [5,28,38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Channel.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Channel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Channel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deprecated: jspb.Message.getFieldWithDefault(msg, 4, ""),
    memberIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isReadOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isMute: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    unreadCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    lastSeenId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lastMessageTime: (f = msg.getLastMessageTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastMessage: (f = msg.getLastMessage()) && proto.api.Message.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && proto.api.User.toObject(includeInstance, f),
    article: (f = msg.getArticle()) && proto.api.Article.toObject(includeInstance, f),
    promise: (f = msg.getPromise()) && proto.api.Promise.toObject(includeInstance, f),
    notice: (f = msg.getNotice()) && proto.api.Notice.toObject(includeInstance, f),
    isPromisable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    isReviewable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    enabledFeature: (f = msg.getEnabledFeature()) && proto.api.Channel.EnabledFeature.toObject(includeInstance, f),
    me: (f = msg.getMe()) && proto.api.User.toObject(includeInstance, f),
    bizAccount: (f = msg.getBizAccount()) && proto.api.BizAccount.toObject(includeInstance, f),
    targetUri: jspb.Message.getFieldWithDefault(msg, 26, ""),
    type: jspb.Message.getFieldWithDefault(msg, 27, 0),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.api.User.toObject, includeInstance),
    owner: (f = msg.getOwner()) && proto.api.User.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 30, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 31, ""),
    webView: (f = msg.getWebView()) && proto.api.WebView.toObject(includeInstance, f),
    memberLimit: jspb.Message.getFieldWithDefault(msg, 33, 0),
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    input: (f = msg.getInput()) && proto.api.Channel.Input.toObject(includeInstance, f),
    bubbleGroup: (f = msg.getBubbleGroup()) && proto.api.BubbleGroup.toObject(includeInstance, f),
    introduction: (f = msg.getIntroduction()) && proto.api.Introduction.toObject(includeInstance, f),
    toolboxButtonsList: jspb.Message.toObjectList(msg.getToolboxButtonsList(),
    proto.api.ToolboxButton.toObject, includeInstance),
    isTemporary: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    favoriteTime: (f = msg.getFavoriteTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unreadMentionsCount: jspb.Message.getFieldWithDefault(msg, 41, 0),
    memberCount: jspb.Message.getFieldWithDefault(msg, 42, 0),
    unreadReplyCount: jspb.Message.getFieldWithDefault(msg, 43, 0),
    commonFilterId: jspb.Message.getFieldWithDefault(msg, 44, 0),
    serviceFilterId: jspb.Message.getFieldWithDefault(msg, 45, 0),
    coverImageText: jspb.Message.getFieldWithDefault(msg, 49, ""),
    coverImageUrl: jspb.Message.getFieldWithDefault(msg, 50, ""),
    resourceIconUrl: jspb.Message.getFieldWithDefault(msg, 51, ""),
    lastMessageId: jspb.Message.getFieldWithDefault(msg, 52, 0),
    badge: jspb.Message.getFieldWithDefault(msg, 53, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Channel}
 */
proto.api.Channel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Channel;
  return proto.api.Channel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Channel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Channel}
 */
proto.api.Channel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecated(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMemberIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReadOnly(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMute(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnreadCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeenId(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastMessageTime(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 14:
      var value = new proto.api.Message;
      reader.readMessage(value,proto.api.Message.deserializeBinaryFromReader);
      msg.setLastMessage(value);
      break;
    case 16:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 17:
      var value = new proto.api.Article;
      reader.readMessage(value,proto.api.Article.deserializeBinaryFromReader);
      msg.setArticle(value);
      break;
    case 18:
      var value = new proto.api.Promise;
      reader.readMessage(value,proto.api.Promise.deserializeBinaryFromReader);
      msg.setPromise(value);
      break;
    case 19:
      var value = new proto.api.Notice;
      reader.readMessage(value,proto.api.Notice.deserializeBinaryFromReader);
      msg.setNotice(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPromisable(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReviewable(value);
      break;
    case 22:
      var value = new proto.api.Channel.EnabledFeature;
      reader.readMessage(value,proto.api.Channel.EnabledFeature.deserializeBinaryFromReader);
      msg.setEnabledFeature(value);
      break;
    case 23:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setMe(value);
      break;
    case 25:
      var value = new proto.api.BizAccount;
      reader.readMessage(value,proto.api.BizAccount.deserializeBinaryFromReader);
      msg.setBizAccount(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 27:
      var value = /** @type {!proto.api.Channel.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 28:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 29:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 32:
      var value = new proto.api.WebView;
      reader.readMessage(value,proto.api.WebView.deserializeBinaryFromReader);
      msg.setWebView(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMemberLimit(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    case 35:
      var value = new proto.api.Channel.Input;
      reader.readMessage(value,proto.api.Channel.Input.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    case 36:
      var value = new proto.api.BubbleGroup;
      reader.readMessage(value,proto.api.BubbleGroup.deserializeBinaryFromReader);
      msg.setBubbleGroup(value);
      break;
    case 37:
      var value = new proto.api.Introduction;
      reader.readMessage(value,proto.api.Introduction.deserializeBinaryFromReader);
      msg.setIntroduction(value);
      break;
    case 38:
      var value = new proto.api.ToolboxButton;
      reader.readMessage(value,proto.api.ToolboxButton.deserializeBinaryFromReader);
      msg.addToolboxButtons(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTemporary(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFavoriteTime(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnreadMentionsCount(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMemberCount(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnreadReplyCount(value);
      break;
    case 44:
      var value = /** @type {!proto.api.FilterID} */ (reader.readEnum());
      msg.setCommonFilterId(value);
      break;
    case 45:
      var value = /** @type {!proto.api.FilterID} */ (reader.readEnum());
      msg.setServiceFilterId(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImageText(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImageUrl(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceIconUrl(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastMessageId(value);
      break;
    case 53:
      var value = /** @type {!proto.api.Badge} */ (reader.readEnum());
      msg.setBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Channel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Channel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Channel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeprecated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMemberIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsReadOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsMute();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUnreadCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLastSeenId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getLastMessageTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastMessage();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.api.Message.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getArticle();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.api.Article.serializeBinaryToWriter
    );
  }
  f = message.getPromise();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.api.Promise.serializeBinaryToWriter
    );
  }
  f = message.getNotice();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.api.Notice.serializeBinaryToWriter
    );
  }
  f = message.getIsPromisable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getIsReviewable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getEnabledFeature();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.api.Channel.EnabledFeature.serializeBinaryToWriter
    );
  }
  f = message.getMe();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getBizAccount();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.api.BizAccount.serializeBinaryToWriter
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getWebView();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.api.WebView.serializeBinaryToWriter
    );
  }
  f = message.getMemberLimit();
  if (f !== 0) {
    writer.writeInt64(
      33,
      f
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.api.Channel.Input.serializeBinaryToWriter
    );
  }
  f = message.getBubbleGroup();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.api.BubbleGroup.serializeBinaryToWriter
    );
  }
  f = message.getIntroduction();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.api.Introduction.serializeBinaryToWriter
    );
  }
  f = message.getToolboxButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.api.ToolboxButton.serializeBinaryToWriter
    );
  }
  f = message.getIsTemporary();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getFavoriteTime();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnreadMentionsCount();
  if (f !== 0) {
    writer.writeInt32(
      41,
      f
    );
  }
  f = message.getMemberCount();
  if (f !== 0) {
    writer.writeInt32(
      42,
      f
    );
  }
  f = message.getUnreadReplyCount();
  if (f !== 0) {
    writer.writeInt32(
      43,
      f
    );
  }
  f = /** @type {!proto.api.FilterID} */ (jspb.Message.getField(message, 44));
  if (f != null) {
    writer.writeEnum(
      44,
      f
    );
  }
  f = /** @type {!proto.api.FilterID} */ (jspb.Message.getField(message, 45));
  if (f != null) {
    writer.writeEnum(
      45,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 49));
  if (f != null) {
    writer.writeString(
      49,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 50));
  if (f != null) {
    writer.writeString(
      50,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 51));
  if (f != null) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getLastMessageId();
  if (f !== 0) {
    writer.writeInt64(
      52,
      f
    );
  }
  f = message.getBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      53,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.Channel.Type = {
  TYPE_UNSPECIFIED: 0,
  DIRECT: 1,
  GROUP: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Channel.EnabledFeature.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Channel.EnabledFeature.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Channel.EnabledFeature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Channel.EnabledFeature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.EnabledFeature.toObject = function(includeInstance, msg) {
  var f, obj = {
    gift: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    promise: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    report: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    block: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    notification: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    manner: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    abuser: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    favoritePhrases: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    location: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    sticker: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    safeNumber: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    voip: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    sendMoney: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    editAnnouncement: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    transferFeaturesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    contentDownloadable: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    searchMessage: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Channel.EnabledFeature}
 */
proto.api.Channel.EnabledFeature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Channel.EnabledFeature;
  return proto.api.Channel.EnabledFeature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Channel.EnabledFeature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Channel.EnabledFeature}
 */
proto.api.Channel.EnabledFeature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGift(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromise(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReport(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlock(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotification(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManner(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAbuser(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavoritePhrases(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocation(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSticker(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSafeNumber(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVoip(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendMoney(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditAnnouncement(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addTransferFeatures(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContentDownloadable(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSearchMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Channel.EnabledFeature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Channel.EnabledFeature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Channel.EnabledFeature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.EnabledFeature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGift();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPromise();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReport();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBlock();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNotification();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getManner();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAbuser();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFavoritePhrases();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLocation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSticker();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSafeNumber();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getVoip();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getSendMoney();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getEditAnnouncement();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getTransferFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getContentDownloadable();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getSearchMessage();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional bool gift = 1;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getGift = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setGift = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool promise = 2;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getPromise = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setPromise = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool report = 3;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getReport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setReport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool block = 4;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getBlock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setBlock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool notification = 5;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setNotification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool manner = 6;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getManner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setManner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool abuser = 7;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getAbuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setAbuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool favorite_phrases = 8;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getFavoritePhrases = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setFavoritePhrases = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool location = 9;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getLocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setLocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool sticker = 10;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getSticker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setSticker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool safe_number = 11;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getSafeNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setSafeNumber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool voip = 12;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getVoip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setVoip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool send_money = 13;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getSendMoney = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setSendMoney = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool edit_announcement = 14;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getEditAnnouncement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setEditAnnouncement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated string transfer_features = 15;
 * @return {!Array<string>}
 */
proto.api.Channel.EnabledFeature.prototype.getTransferFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setTransferFeaturesList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.addTransferFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.clearTransferFeaturesList = function() {
  return this.setTransferFeaturesList([]);
};


/**
 * optional bool content_downloadable = 16;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getContentDownloadable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setContentDownloadable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool search_message = 17;
 * @return {boolean}
 */
proto.api.Channel.EnabledFeature.prototype.getSearchMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.EnabledFeature} returns this
 */
proto.api.Channel.EnabledFeature.prototype.setSearchMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.Channel.Input.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.Channel.Input.TypeCase = {
  TYPE_NOT_SET: 0,
  TEXT: 1,
  BUTTON: 2
};

/**
 * @return {proto.api.Channel.Input.TypeCase}
 */
proto.api.Channel.Input.prototype.getTypeCase = function() {
  return /** @type {proto.api.Channel.Input.TypeCase} */(jspb.Message.computeOneofCase(this, proto.api.Channel.Input.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Channel.Input.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Channel.Input.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Channel.Input} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.Input.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: (f = msg.getText()) && proto.api.Channel.Input.Text.toObject(includeInstance, f),
    button: (f = msg.getButton()) && proto.api.Button.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Channel.Input}
 */
proto.api.Channel.Input.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Channel.Input;
  return proto.api.Channel.Input.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Channel.Input} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Channel.Input}
 */
proto.api.Channel.Input.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Channel.Input.Text;
      reader.readMessage(value,proto.api.Channel.Input.Text.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    case 2:
      var value = new proto.api.Button;
      reader.readMessage(value,proto.api.Button.deserializeBinaryFromReader);
      msg.setButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Channel.Input.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Channel.Input.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Channel.Input} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.Input.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Channel.Input.Text.serializeBinaryToWriter
    );
  }
  f = message.getButton();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.Button.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Channel.Input.Text.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Channel.Input.Text.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Channel.Input.Text} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.Input.Text.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    placeholder: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Channel.Input.Text}
 */
proto.api.Channel.Input.Text.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Channel.Input.Text;
  return proto.api.Channel.Input.Text.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Channel.Input.Text} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Channel.Input.Text}
 */
proto.api.Channel.Input.Text.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Channel.Input.Text.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Channel.Input.Text.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Channel.Input.Text} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Channel.Input.Text.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPlaceholder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_disabled = 1;
 * @return {boolean}
 */
proto.api.Channel.Input.Text.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel.Input.Text} returns this
 */
proto.api.Channel.Input.Text.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string placeholder = 2;
 * @return {string}
 */
proto.api.Channel.Input.Text.prototype.getPlaceholder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel.Input.Text} returns this
 */
proto.api.Channel.Input.Text.prototype.setPlaceholder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Text text = 1;
 * @return {?proto.api.Channel.Input.Text}
 */
proto.api.Channel.Input.prototype.getText = function() {
  return /** @type{?proto.api.Channel.Input.Text} */ (
    jspb.Message.getWrapperField(this, proto.api.Channel.Input.Text, 1));
};


/**
 * @param {?proto.api.Channel.Input.Text|undefined} value
 * @return {!proto.api.Channel.Input} returns this
*/
proto.api.Channel.Input.prototype.setText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.Channel.Input.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel.Input} returns this
 */
proto.api.Channel.Input.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.Input.prototype.hasText = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Button button = 2;
 * @return {?proto.api.Button}
 */
proto.api.Channel.Input.prototype.getButton = function() {
  return /** @type{?proto.api.Button} */ (
    jspb.Message.getWrapperField(this, proto.api.Button, 2));
};


/**
 * @param {?proto.api.Button|undefined} value
 * @return {!proto.api.Channel.Input} returns this
*/
proto.api.Channel.Input.prototype.setButton = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.Channel.Input.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel.Input} returns this
 */
proto.api.Channel.Input.prototype.clearButton = function() {
  return this.setButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.Input.prototype.hasButton = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Channel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_type = 2;
 * @return {string}
 */
proto.api.Channel.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 resource_id = 3;
 * @return {number}
 */
proto.api.Channel.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string DEPRECATED = 4;
 * @return {string}
 */
proto.api.Channel.prototype.getDeprecated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setDeprecated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated int64 member_ids = 5;
 * @return {!Array<number>}
 */
proto.api.Channel.prototype.getMemberIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setMemberIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.addMemberIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearMemberIdsList = function() {
  return this.setMemberIdsList([]);
};


/**
 * optional bool is_favorite = 6;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_read_only = 7;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_disabled = 8;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_mute = 9;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 unread_count = 10;
 * @return {number}
 */
proto.api.Channel.prototype.getUnreadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setUnreadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 last_seen_id = 11;
 * @return {number}
 */
proto.api.Channel.prototype.getLastSeenId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setLastSeenId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp last_message_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Channel.prototype.getLastMessageTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setLastMessageTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearLastMessageTime = function() {
  return this.setLastMessageTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasLastMessageTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Channel.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Message last_message = 14;
 * @return {?proto.api.Message}
 */
proto.api.Channel.prototype.getLastMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, proto.api.Message, 14));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setLastMessage = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearLastMessage = function() {
  return this.setLastMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasLastMessage = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional User receiver = 16;
 * @return {?proto.api.User}
 */
proto.api.Channel.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 16));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Article article = 17;
 * @return {?proto.api.Article}
 */
proto.api.Channel.prototype.getArticle = function() {
  return /** @type{?proto.api.Article} */ (
    jspb.Message.getWrapperField(this, proto.api.Article, 17));
};


/**
 * @param {?proto.api.Article|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setArticle = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearArticle = function() {
  return this.setArticle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasArticle = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Promise promise = 18;
 * @return {?proto.api.Promise}
 */
proto.api.Channel.prototype.getPromise = function() {
  return /** @type{?proto.api.Promise} */ (
    jspb.Message.getWrapperField(this, proto.api.Promise, 18));
};


/**
 * @param {?proto.api.Promise|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setPromise = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearPromise = function() {
  return this.setPromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasPromise = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Notice notice = 19;
 * @return {?proto.api.Notice}
 */
proto.api.Channel.prototype.getNotice = function() {
  return /** @type{?proto.api.Notice} */ (
    jspb.Message.getWrapperField(this, proto.api.Notice, 19));
};


/**
 * @param {?proto.api.Notice|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setNotice = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearNotice = function() {
  return this.setNotice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasNotice = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool is_promisable = 20;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsPromisable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsPromisable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool is_reviewable = 21;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsReviewable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsReviewable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional EnabledFeature enabled_feature = 22;
 * @return {?proto.api.Channel.EnabledFeature}
 */
proto.api.Channel.prototype.getEnabledFeature = function() {
  return /** @type{?proto.api.Channel.EnabledFeature} */ (
    jspb.Message.getWrapperField(this, proto.api.Channel.EnabledFeature, 22));
};


/**
 * @param {?proto.api.Channel.EnabledFeature|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setEnabledFeature = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearEnabledFeature = function() {
  return this.setEnabledFeature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasEnabledFeature = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional User me = 23;
 * @return {?proto.api.User}
 */
proto.api.Channel.prototype.getMe = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 23));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setMe = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearMe = function() {
  return this.setMe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasMe = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional BizAccount biz_account = 25;
 * @return {?proto.api.BizAccount}
 */
proto.api.Channel.prototype.getBizAccount = function() {
  return /** @type{?proto.api.BizAccount} */ (
    jspb.Message.getWrapperField(this, proto.api.BizAccount, 25));
};


/**
 * @param {?proto.api.BizAccount|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setBizAccount = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearBizAccount = function() {
  return this.setBizAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasBizAccount = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string target_uri = 26;
 * @return {string}
 */
proto.api.Channel.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional Type type = 27;
 * @return {!proto.api.Channel.Type}
 */
proto.api.Channel.prototype.getType = function() {
  return /** @type {!proto.api.Channel.Type} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.api.Channel.Type} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * repeated User members = 28;
 * @return {!Array<!proto.api.User>}
 */
proto.api.Channel.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.User, 28));
};


/**
 * @param {!Array<!proto.api.User>} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.User}
 */
proto.api.Channel.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * optional User owner = 29;
 * @return {?proto.api.User}
 */
proto.api.Channel.prototype.getOwner = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 29));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string title = 30;
 * @return {string}
 */
proto.api.Channel.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string thumbnail = 31;
 * @return {string}
 */
proto.api.Channel.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional WebView web_view = 32;
 * @return {?proto.api.WebView}
 */
proto.api.Channel.prototype.getWebView = function() {
  return /** @type{?proto.api.WebView} */ (
    jspb.Message.getWrapperField(this, proto.api.WebView, 32));
};


/**
 * @param {?proto.api.WebView|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setWebView = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearWebView = function() {
  return this.setWebView(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasWebView = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional int64 member_limit = 33;
 * @return {number}
 */
proto.api.Channel.prototype.getMemberLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setMemberLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional bool closed = 34;
 * @return {boolean}
 */
proto.api.Channel.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional Input input = 35;
 * @return {?proto.api.Channel.Input}
 */
proto.api.Channel.prototype.getInput = function() {
  return /** @type{?proto.api.Channel.Input} */ (
    jspb.Message.getWrapperField(this, proto.api.Channel.Input, 35));
};


/**
 * @param {?proto.api.Channel.Input|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasInput = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional BubbleGroup bubble_group = 36;
 * @return {?proto.api.BubbleGroup}
 */
proto.api.Channel.prototype.getBubbleGroup = function() {
  return /** @type{?proto.api.BubbleGroup} */ (
    jspb.Message.getWrapperField(this, proto.api.BubbleGroup, 36));
};


/**
 * @param {?proto.api.BubbleGroup|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setBubbleGroup = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearBubbleGroup = function() {
  return this.setBubbleGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasBubbleGroup = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional Introduction introduction = 37;
 * @return {?proto.api.Introduction}
 */
proto.api.Channel.prototype.getIntroduction = function() {
  return /** @type{?proto.api.Introduction} */ (
    jspb.Message.getWrapperField(this, proto.api.Introduction, 37));
};


/**
 * @param {?proto.api.Introduction|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setIntroduction = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearIntroduction = function() {
  return this.setIntroduction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasIntroduction = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * repeated ToolboxButton toolbox_buttons = 38;
 * @return {!Array<!proto.api.ToolboxButton>}
 */
proto.api.Channel.prototype.getToolboxButtonsList = function() {
  return /** @type{!Array<!proto.api.ToolboxButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ToolboxButton, 38));
};


/**
 * @param {!Array<!proto.api.ToolboxButton>} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setToolboxButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.api.ToolboxButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ToolboxButton}
 */
proto.api.Channel.prototype.addToolboxButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.api.ToolboxButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearToolboxButtonsList = function() {
  return this.setToolboxButtonsList([]);
};


/**
 * optional bool is_temporary = 39;
 * @return {boolean}
 */
proto.api.Channel.prototype.getIsTemporary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setIsTemporary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional google.protobuf.Timestamp favorite_time = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Channel.prototype.getFavoriteTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Channel} returns this
*/
proto.api.Channel.prototype.setFavoriteTime = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearFavoriteTime = function() {
  return this.setFavoriteTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasFavoriteTime = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional int32 unread_mentions_count = 41;
 * @return {number}
 */
proto.api.Channel.prototype.getUnreadMentionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setUnreadMentionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional int32 member_count = 42;
 * @return {number}
 */
proto.api.Channel.prototype.getMemberCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setMemberCount = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional int32 unread_reply_count = 43;
 * @return {number}
 */
proto.api.Channel.prototype.getUnreadReplyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setUnreadReplyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};


/**
 * optional FilterID common_filter_id = 44;
 * @return {!proto.api.FilterID}
 */
proto.api.Channel.prototype.getCommonFilterId = function() {
  return /** @type {!proto.api.FilterID} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {!proto.api.FilterID} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setCommonFilterId = function(value) {
  return jspb.Message.setField(this, 44, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearCommonFilterId = function() {
  return jspb.Message.setField(this, 44, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasCommonFilterId = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional FilterID service_filter_id = 45;
 * @return {!proto.api.FilterID}
 */
proto.api.Channel.prototype.getServiceFilterId = function() {
  return /** @type {!proto.api.FilterID} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {!proto.api.FilterID} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setServiceFilterId = function(value) {
  return jspb.Message.setField(this, 45, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearServiceFilterId = function() {
  return jspb.Message.setField(this, 45, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasServiceFilterId = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional string cover_image_text = 49;
 * @return {string}
 */
proto.api.Channel.prototype.getCoverImageText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setCoverImageText = function(value) {
  return jspb.Message.setField(this, 49, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearCoverImageText = function() {
  return jspb.Message.setField(this, 49, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasCoverImageText = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional string cover_image_url = 50;
 * @return {string}
 */
proto.api.Channel.prototype.getCoverImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setCoverImageUrl = function(value) {
  return jspb.Message.setField(this, 50, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearCoverImageUrl = function() {
  return jspb.Message.setField(this, 50, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasCoverImageUrl = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional string resource_icon_url = 51;
 * @return {string}
 */
proto.api.Channel.prototype.getResourceIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setResourceIconUrl = function(value) {
  return jspb.Message.setField(this, 51, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.clearResourceIconUrl = function() {
  return jspb.Message.setField(this, 51, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Channel.prototype.hasResourceIconUrl = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional int64 last_message_id = 52;
 * @return {number}
 */
proto.api.Channel.prototype.getLastMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setLastMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 52, value);
};


/**
 * optional Badge badge = 53;
 * @return {!proto.api.Badge}
 */
proto.api.Channel.prototype.getBadge = function() {
  return /** @type {!proto.api.Badge} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {!proto.api.Badge} value
 * @return {!proto.api.Channel} returns this
 */
proto.api.Channel.prototype.setBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 53, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Article.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Article.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Article} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Article.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    smb: (f = msg.getSmb()) && proto.api.Smb.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    hasPrice: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    reviewId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    reservedUserId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    freeShareEventEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Article}
 */
proto.api.Article.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Article;
  return proto.api.Article.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Article} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Article}
 */
proto.api.Article.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstImageUrl(value);
      break;
    case 6:
      var value = new proto.api.Smb;
      reader.readMessage(value,proto.api.Smb.deserializeBinaryFromReader);
      msg.setSmb(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPrice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReservedUserId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFreeShareEventEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Article.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Article.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Article} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Article.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSmb();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.Smb.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getHasPrice();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getReviewId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getReservedUserId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getFreeShareEventEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Article.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.api.Article.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.api.Article.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.api.Article.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string first_image_url = 5;
 * @return {string}
 */
proto.api.Article.prototype.getFirstImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setFirstImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Smb smb = 6;
 * @return {?proto.api.Smb}
 */
proto.api.Article.prototype.getSmb = function() {
  return /** @type{?proto.api.Smb} */ (
    jspb.Message.getWrapperField(this, proto.api.Smb, 6));
};


/**
 * @param {?proto.api.Smb|undefined} value
 * @return {!proto.api.Article} returns this
*/
proto.api.Article.prototype.setSmb = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.clearSmb = function() {
  return this.setSmb(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Article.prototype.hasSmb = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.api.Article.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double price = 8;
 * @return {number}
 */
proto.api.Article.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool has_price = 9;
 * @return {boolean}
 */
proto.api.Article.prototype.getHasPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setHasPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 review_id = 10;
 * @return {number}
 */
proto.api.Article.prototype.getReviewId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setReviewId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 reserved_user_id = 11;
 * @return {number}
 */
proto.api.Article.prototype.getReservedUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setReservedUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool free_share_event_enabled = 12;
 * @return {boolean}
 */
proto.api.Article.prototype.getFreeShareEventEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Article} returns this
 */
proto.api.Article.prototype.setFreeShareEventEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Smb.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Smb.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Smb} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Smb.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reviewedByMe: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    categoryText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    smbReviewsCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    likesCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    firstImageUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Smb}
 */
proto.api.Smb.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Smb;
  return proto.api.Smb.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Smb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Smb}
 */
proto.api.Smb.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReviewedByMe(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryText(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSmbReviewsCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLikesCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Smb.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Smb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Smb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Smb.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReviewedByMe();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCategoryText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSmbReviewsCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLikesCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFirstImageUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.Smb.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.Smb.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.api.Smb.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool reviewed_by_me = 4;
 * @return {boolean}
 */
proto.api.Smb.prototype.getReviewedByMe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setReviewedByMe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string category_text = 5;
 * @return {string}
 */
proto.api.Smb.prototype.getCategoryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setCategoryText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 smb_reviews_count = 6;
 * @return {number}
 */
proto.api.Smb.prototype.getSmbReviewsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setSmbReviewsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 likes_count = 7;
 * @return {number}
 */
proto.api.Smb.prototype.getLikesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setLikesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string first_image_url = 8;
 * @return {string}
 */
proto.api.Smb.prototype.getFirstImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Smb} returns this
 */
proto.api.Smb.prototype.setFirstImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.User.repeatedFields_ = [11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.User.oneofGroups_ = [[101,102]];

/**
 * @enum {number}
 */
proto.api.User.ScoreCase = {
  SCORE_NOT_SET: 0,
  MANNER_TEMPERATURE: 101,
  KARROT_SCORE: 102
};

/**
 * @return {proto.api.User.ScoreCase}
 */
proto.api.User.prototype.getScoreCase = function() {
  return /** @type {proto.api.User.ScoreCase} */(jspb.Message.computeOneofCase(this, proto.api.User.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.User.prototype.toObject = function(opt_includeInstance) {
  return proto.api.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nickname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayRegionName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileImage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    messageResponseTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDoNotDisturbOn: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    doNotDisturbStartTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    doNotDisturbEndTime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    badgeImage: jspb.Message.getFieldWithDefault(msg, 10, ""),
    userFlaggedByMeList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 13, ""),
    type: jspb.Message.getFieldWithDefault(msg, 14, 0),
    displayRegionCheckinsCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    doNotDisturbeTimeZone: jspb.Message.getFieldWithDefault(msg, 16, ""),
    region: (f = msg.getRegion()) && proto.api.Region.toObject(includeInstance, f),
    isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    displayLocationName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    isBizFollower: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    isSameCondoMember: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    subnickname: jspb.Message.getFieldWithDefault(msg, 22, ""),
    role: jspb.Message.getFieldWithDefault(msg, 23, 0),
    mannerTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 101, 0.0),
    karrotScore: (f = msg.getKarrotScore()) && proto.api.User.KarrotScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.User}
 */
proto.api.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.User;
  return proto.api.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.User}
 */
proto.api.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayRegionName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageResponseTime(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDoNotDisturbOn(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoNotDisturbStartTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoNotDisturbEndTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeImage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserFlaggedByMe(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 14:
      var value = /** @type {!proto.api.User.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplayRegionCheckinsCount(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoNotDisturbeTimeZone(value);
      break;
    case 17:
      var value = new proto.api.Region;
      reader.readMessage(value,proto.api.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVerified(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayLocationName(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBizFollower(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSameCondoMember(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubnickname(value);
      break;
    case 23:
      var value = /** @type {!proto.api.User.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMannerTemperature(value);
      break;
    case 102:
      var value = new proto.api.User.KarrotScore;
      reader.readMessage(value,proto.api.User.KarrotScore.deserializeBinaryFromReader);
      msg.setKarrotScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayRegionName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMessageResponseTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDoNotDisturbOn();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDoNotDisturbStartTime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDoNotDisturbEndTime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBadgeImage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUserFlaggedByMeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getDisplayRegionCheckinsCount();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getDoNotDisturbeTimeZone();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.api.Region.serializeBinaryToWriter
    );
  }
  f = message.getIsVerified();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDisplayLocationName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getIsBizFollower();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getIsSameCondoMember();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getSubnickname();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 101));
  if (f != null) {
    writer.writeFloat(
      101,
      f
    );
  }
  f = message.getKarrotScore();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      proto.api.User.KarrotScore.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.User.Type = {
  TYPE_UNSPECIFIED: 0,
  NORMAL: 1,
  BIZ_ACCOUNT: 2,
  BIZ_USER: 3
};

/**
 * @enum {number}
 */
proto.api.User.Role = {
  ROLE_UNSPECIFIED: 0,
  ROLE_SUPERHOST: 2,
  ROLE_NORMAL: 1,
  ROLE_MANAGER: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.User.KarrotScore.prototype.toObject = function(opt_includeInstance) {
  return proto.api.User.KarrotScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.User.KarrotScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.User.KarrotScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentScore: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.User.KarrotScore}
 */
proto.api.User.KarrotScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.User.KarrotScore;
  return proto.api.User.KarrotScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.User.KarrotScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.User.KarrotScore}
 */
proto.api.User.KarrotScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.User.KarrotScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.User.KarrotScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.User.KarrotScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.User.KarrotScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentScore();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 current_score = 1;
 * @return {number}
 */
proto.api.User.KarrotScore.prototype.getCurrentScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.User.KarrotScore} returns this
 */
proto.api.User.KarrotScore.prototype.setCurrentScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string nickname = 2;
 * @return {string}
 */
proto.api.User.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_region_name = 3;
 * @return {string}
 */
proto.api.User.prototype.getDisplayRegionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setDisplayRegionName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_image = 4;
 * @return {string}
 */
proto.api.User.prototype.getProfileImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setProfileImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float temperature = 5;
 * @return {number}
 */
proto.api.User.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string message_response_time = 6;
 * @return {string}
 */
proto.api.User.prototype.getMessageResponseTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setMessageResponseTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_do_not_disturb_on = 7;
 * @return {boolean}
 */
proto.api.User.prototype.getIsDoNotDisturbOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setIsDoNotDisturbOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string do_not_disturb_start_time = 8;
 * @return {string}
 */
proto.api.User.prototype.getDoNotDisturbStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setDoNotDisturbStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string do_not_disturb_end_time = 9;
 * @return {string}
 */
proto.api.User.prototype.getDoNotDisturbEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setDoNotDisturbEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string badge_image = 10;
 * @return {string}
 */
proto.api.User.prototype.getBadgeImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setBadgeImage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string user_flagged_by_me = 11;
 * @return {!Array<string>}
 */
proto.api.User.prototype.getUserFlaggedByMeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setUserFlaggedByMeList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.addUserFlaggedByMe = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.clearUserFlaggedByMeList = function() {
  return this.setUserFlaggedByMeList([]);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.api.User.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string target_uri = 13;
 * @return {string}
 */
proto.api.User.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Type type = 14;
 * @return {!proto.api.User.Type}
 */
proto.api.User.prototype.getType = function() {
  return /** @type {!proto.api.User.Type} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.api.User.Type} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int32 display_region_checkins_count = 15;
 * @return {number}
 */
proto.api.User.prototype.getDisplayRegionCheckinsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setDisplayRegionCheckinsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string do_not_disturbe_time_zone = 16;
 * @return {string}
 */
proto.api.User.prototype.getDoNotDisturbeTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setDoNotDisturbeTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional Region region = 17;
 * @return {?proto.api.Region}
 */
proto.api.User.prototype.getRegion = function() {
  return /** @type{?proto.api.Region} */ (
    jspb.Message.getWrapperField(this, proto.api.Region, 17));
};


/**
 * @param {?proto.api.Region|undefined} value
 * @return {!proto.api.User} returns this
*/
proto.api.User.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.User.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool is_verified = 18;
 * @return {boolean}
 */
proto.api.User.prototype.getIsVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setIsVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string display_location_name = 19;
 * @return {string}
 */
proto.api.User.prototype.getDisplayLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setDisplayLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool is_biz_follower = 20;
 * @return {boolean}
 */
proto.api.User.prototype.getIsBizFollower = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setIsBizFollower = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool is_same_condo_member = 21;
 * @return {boolean}
 */
proto.api.User.prototype.getIsSameCondoMember = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setIsSameCondoMember = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string subnickname = 22;
 * @return {string}
 */
proto.api.User.prototype.getSubnickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setSubnickname = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional Role role = 23;
 * @return {!proto.api.User.Role}
 */
proto.api.User.prototype.getRole = function() {
  return /** @type {!proto.api.User.Role} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.api.User.Role} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional float manner_temperature = 101;
 * @return {number}
 */
proto.api.User.prototype.getMannerTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 101, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.setMannerTemperature = function(value) {
  return jspb.Message.setOneofField(this, 101, proto.api.User.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.clearMannerTemperature = function() {
  return jspb.Message.setOneofField(this, 101, proto.api.User.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.User.prototype.hasMannerTemperature = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional KarrotScore karrot_score = 102;
 * @return {?proto.api.User.KarrotScore}
 */
proto.api.User.prototype.getKarrotScore = function() {
  return /** @type{?proto.api.User.KarrotScore} */ (
    jspb.Message.getWrapperField(this, proto.api.User.KarrotScore, 102));
};


/**
 * @param {?proto.api.User.KarrotScore|undefined} value
 * @return {!proto.api.User} returns this
*/
proto.api.User.prototype.setKarrotScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 102, proto.api.User.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.User} returns this
 */
proto.api.User.prototype.clearKarrotScore = function() {
  return this.setKarrotScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.User.prototype.hasKarrotScore = function() {
  return jspb.Message.getField(this, 102) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Region.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Region.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Region} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Region.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name3: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name4: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fullname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    depth: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Region}
 */
proto.api.Region.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Region;
  return proto.api.Region.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Region} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Region}
 */
proto.api.Region.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName3(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName4(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Region.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Region.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Region} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Region.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName3();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName4();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.api.Region.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name1 = 2;
 * @return {string}
 */
proto.api.Region.prototype.getName1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setName1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name2 = 3;
 * @return {string}
 */
proto.api.Region.prototype.getName2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setName2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name3 = 4;
 * @return {string}
 */
proto.api.Region.prototype.getName3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setName3 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name4 = 5;
 * @return {string}
 */
proto.api.Region.prototype.getName4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setName4 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.api.Region.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fullname = 7;
 * @return {string}
 */
proto.api.Region.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 depth = 8;
 * @return {number}
 */
proto.api.Region.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Region} returns this
 */
proto.api.Region.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Notice.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Notice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Notice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Notice.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Notice}
 */
proto.api.Notice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Notice;
  return proto.api.Notice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Notice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Notice}
 */
proto.api.Notice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Notice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Notice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Notice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Notice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.api.Notice.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Notice} returns this
 */
proto.api.Notice.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.api.Notice.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Notice} returns this
 */
proto.api.Notice.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UnreadCount.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UnreadCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UnreadCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UnreadCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notification: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userMessage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bizMessageMap: (f = msg.getBizMessageMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UnreadCount}
 */
proto.api.UnreadCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UnreadCount;
  return proto.api.UnreadCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UnreadCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UnreadCount}
 */
proto.api.UnreadCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotification(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserMessage(value);
      break;
    case 5:
      var value = msg.getBizMessageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UnreadCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UnreadCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UnreadCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UnreadCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNotification();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUserMessage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBizMessageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.api.UnreadCount.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UnreadCount} returns this
 */
proto.api.UnreadCount.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 message = 2;
 * @return {number}
 */
proto.api.UnreadCount.prototype.getMessage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UnreadCount} returns this
 */
proto.api.UnreadCount.prototype.setMessage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 notification = 3;
 * @return {number}
 */
proto.api.UnreadCount.prototype.getNotification = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UnreadCount} returns this
 */
proto.api.UnreadCount.prototype.setNotification = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 user_message = 4;
 * @return {number}
 */
proto.api.UnreadCount.prototype.getUserMessage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UnreadCount} returns this
 */
proto.api.UnreadCount.prototype.setUserMessage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<int64, int32> biz_message = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.api.UnreadCount.prototype.getBizMessageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.UnreadCount} returns this
 */
proto.api.UnreadCount.prototype.clearBizMessageMap = function() {
  this.getBizMessageMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SystemMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SystemMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SystemMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SystemMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    button: (f = msg.getButton()) && proto.api.Button.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SystemMessage}
 */
proto.api.SystemMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SystemMessage;
  return proto.api.SystemMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SystemMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SystemMessage}
 */
proto.api.SystemMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.SystemMessage.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = new proto.api.Button;
      reader.readMessage(value,proto.api.Button.deserializeBinaryFromReader);
      msg.setButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SystemMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SystemMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SystemMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SystemMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getButton();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.Button.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.SystemMessage.Type = {
  TYPE_UNSPECIFIED: 0,
  TIP: 1,
  ALERT: 2,
  WARNING: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.api.SystemMessage.Type}
 */
proto.api.SystemMessage.prototype.getType = function() {
  return /** @type {!proto.api.SystemMessage.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.SystemMessage.Type} value
 * @return {!proto.api.SystemMessage} returns this
 */
proto.api.SystemMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.api.SystemMessage.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SystemMessage} returns this
 */
proto.api.SystemMessage.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Button button = 3;
 * @return {?proto.api.Button}
 */
proto.api.SystemMessage.prototype.getButton = function() {
  return /** @type{?proto.api.Button} */ (
    jspb.Message.getWrapperField(this, proto.api.Button, 3));
};


/**
 * @param {?proto.api.Button|undefined} value
 * @return {!proto.api.SystemMessage} returns this
*/
proto.api.SystemMessage.prototype.setButton = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SystemMessage} returns this
 */
proto.api.SystemMessage.prototype.clearButton = function() {
  return this.setButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SystemMessage.prototype.hasButton = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GenericTemplate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GenericTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GenericTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GenericTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GenericTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buttonsList: jspb.Message.toObjectList(msg.getButtonsList(),
    proto.api.Button.toObject, includeInstance),
    imageAspectRatio: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startThumbnailUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endThumbnailUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GenericTemplate}
 */
proto.api.GenericTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GenericTemplate;
  return proto.api.GenericTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GenericTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GenericTemplate}
 */
proto.api.GenericTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = new proto.api.Button;
      reader.readMessage(value,proto.api.Button.deserializeBinaryFromReader);
      msg.addButtons(value);
      break;
    case 5:
      var value = /** @type {!proto.api.ImageAspectRatio} */ (reader.readEnum());
      msg.setImageAspectRatio(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartThumbnailUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndThumbnailUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GenericTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GenericTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GenericTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GenericTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.Button.serializeBinaryToWriter
    );
  }
  f = message.getImageAspectRatio();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStartThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.api.GenericTemplate.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.api.GenericTemplate.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.api.GenericTemplate.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Button buttons = 4;
 * @return {!Array<!proto.api.Button>}
 */
proto.api.GenericTemplate.prototype.getButtonsList = function() {
  return /** @type{!Array<!proto.api.Button>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Button, 4));
};


/**
 * @param {!Array<!proto.api.Button>} value
 * @return {!proto.api.GenericTemplate} returns this
*/
proto.api.GenericTemplate.prototype.setButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.Button=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Button}
 */
proto.api.GenericTemplate.prototype.addButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.Button, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.clearButtonsList = function() {
  return this.setButtonsList([]);
};


/**
 * optional ImageAspectRatio image_aspect_ratio = 5;
 * @return {!proto.api.ImageAspectRatio}
 */
proto.api.GenericTemplate.prototype.getImageAspectRatio = function() {
  return /** @type {!proto.api.ImageAspectRatio} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.api.ImageAspectRatio} value
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.setImageAspectRatio = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string start_thumbnail_url = 6;
 * @return {string}
 */
proto.api.GenericTemplate.prototype.getStartThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.setStartThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string end_thumbnail_url = 7;
 * @return {string}
 */
proto.api.GenericTemplate.prototype.getEndThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GenericTemplate} returns this
 */
proto.api.GenericTemplate.prototype.setEndThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ArticleTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ArticleTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ArticleTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ArticleTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    hasPrice: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ArticleTemplate}
 */
proto.api.ArticleTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ArticleTemplate;
  return proto.api.ArticleTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ArticleTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ArticleTemplate}
 */
proto.api.ArticleTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ArticleTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ArticleTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ArticleTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ArticleTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHasPrice();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string target_uri = 1;
 * @return {string}
 */
proto.api.ArticleTemplate.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ArticleTemplate} returns this
 */
proto.api.ArticleTemplate.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.api.ArticleTemplate.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ArticleTemplate} returns this
 */
proto.api.ArticleTemplate.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.api.ArticleTemplate.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.ArticleTemplate} returns this
 */
proto.api.ArticleTemplate.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool has_price = 4;
 * @return {boolean}
 */
proto.api.ArticleTemplate.prototype.getHasPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ArticleTemplate} returns this
 */
proto.api.ArticleTemplate.prototype.setHasPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.api.ArticleTemplate.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ArticleTemplate} returns this
 */
proto.api.ArticleTemplate.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string message_type = 6;
 * @return {string}
 */
proto.api.ArticleTemplate.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ArticleTemplate} returns this
 */
proto.api.ArticleTemplate.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LocationTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LocationTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LocationTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LocationTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFieldWithDefault(msg, 1, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pictureId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LocationTemplate}
 */
proto.api.LocationTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LocationTemplate;
  return proto.api.LocationTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LocationTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LocationTemplate}
 */
proto.api.LocationTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LocationTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LocationTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LocationTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LocationTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPictureId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string latitude = 1;
 * @return {string}
 */
proto.api.LocationTemplate.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LocationTemplate} returns this
 */
proto.api.LocationTemplate.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string longitude = 2;
 * @return {string}
 */
proto.api.LocationTemplate.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LocationTemplate} returns this
 */
proto.api.LocationTemplate.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.api.LocationTemplate.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LocationTemplate} returns this
 */
proto.api.LocationTemplate.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 image_id = 4;
 * @return {number}
 */
proto.api.LocationTemplate.prototype.getImageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.LocationTemplate} returns this
 */
proto.api.LocationTemplate.prototype.setImageId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string picture_id = 5;
 * @return {string}
 */
proto.api.LocationTemplate.prototype.getPictureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LocationTemplate} returns this
 */
proto.api.LocationTemplate.prototype.setPictureId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.IconTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.IconTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.IconTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IconTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.IconTemplate}
 */
proto.api.IconTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.IconTemplate;
  return proto.api.IconTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.IconTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.IconTemplate}
 */
proto.api.IconTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.IconTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.IconTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.IconTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.IconTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.api.IconTemplate.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IconTemplate} returns this
 */
proto.api.IconTemplate.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.api.IconTemplate.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IconTemplate} returns this
 */
proto.api.IconTemplate.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string target_uri = 3;
 * @return {string}
 */
proto.api.IconTemplate.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.IconTemplate} returns this
 */
proto.api.IconTemplate.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.InlineTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.InlineTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.InlineTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.InlineTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    richText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.InlineTemplate}
 */
proto.api.InlineTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.InlineTemplate;
  return proto.api.InlineTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.InlineTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.InlineTemplate}
 */
proto.api.InlineTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.InlineTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.InlineTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.InlineTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.InlineTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRichText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string rich_text = 1;
 * @return {string}
 */
proto.api.InlineTemplate.prototype.getRichText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.InlineTemplate} returns this
 */
proto.api.InlineTemplate.prototype.setRichText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.Button.oneofGroups_ = [[100,101,102,103]];

/**
 * @enum {number}
 */
proto.api.Button.ActionCase = {
  ACTION_NOT_SET: 0,
  TARGET_URI: 100,
  TEXT_SHORTCUT: 101,
  POSTBACK_PAYLOAD: 102,
  POSTBACK_PAYLOAD_ONLY_FOR_INPUT: 103
};

/**
 * @return {proto.api.Button.ActionCase}
 */
proto.api.Button.prototype.getActionCase = function() {
  return /** @type {proto.api.Button.ActionCase} */(jspb.Message.computeOneofCase(this, proto.api.Button.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Button.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Button.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Button} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Button.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 100, ""),
    textShortcut: (f = msg.getTextShortcut()) && proto.api.TextShortcut.toObject(includeInstance, f),
    postbackPayload: jspb.Message.getFieldWithDefault(msg, 102, ""),
    postbackPayloadOnlyForInput: (f = msg.getPostbackPayloadOnlyForInput()) && proto.api.ButtonPostbackPayload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Button}
 */
proto.api.Button.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Button;
  return proto.api.Button.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Button} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Button}
 */
proto.api.Button.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.Button.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 101:
      var value = new proto.api.TextShortcut;
      reader.readMessage(value,proto.api.TextShortcut.deserializeBinaryFromReader);
      msg.setTextShortcut(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostbackPayload(value);
      break;
    case 103:
      var value = new proto.api.ButtonPostbackPayload;
      reader.readMessage(value,proto.api.ButtonPostbackPayload.deserializeBinaryFromReader);
      msg.setPostbackPayloadOnlyForInput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Button.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Button.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Button} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Button.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 100));
  if (f != null) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getTextShortcut();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.api.TextShortcut.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 102));
  if (f != null) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getPostbackPayloadOnlyForInput();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.api.ButtonPostbackPayload.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.Button.Type = {
  TYPE_UNSPECIFIED: 0,
  SECONDARY: 1,
  PRIMARY: 2
};

/**
 * optional Type type = 1;
 * @return {!proto.api.Button.Type}
 */
proto.api.Button.prototype.getType = function() {
  return /** @type {!proto.api.Button.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.Button.Type} value
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.api.Button.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.api.Button.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string target_uri = 100;
 * @return {string}
 */
proto.api.Button.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.setTargetUri = function(value) {
  return jspb.Message.setOneofField(this, 100, proto.api.Button.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.clearTargetUri = function() {
  return jspb.Message.setOneofField(this, 100, proto.api.Button.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Button.prototype.hasTargetUri = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional TextShortcut text_shortcut = 101;
 * @return {?proto.api.TextShortcut}
 */
proto.api.Button.prototype.getTextShortcut = function() {
  return /** @type{?proto.api.TextShortcut} */ (
    jspb.Message.getWrapperField(this, proto.api.TextShortcut, 101));
};


/**
 * @param {?proto.api.TextShortcut|undefined} value
 * @return {!proto.api.Button} returns this
*/
proto.api.Button.prototype.setTextShortcut = function(value) {
  return jspb.Message.setOneofWrapperField(this, 101, proto.api.Button.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.clearTextShortcut = function() {
  return this.setTextShortcut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Button.prototype.hasTextShortcut = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional string postback_payload = 102;
 * @return {string}
 */
proto.api.Button.prototype.getPostbackPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.setPostbackPayload = function(value) {
  return jspb.Message.setOneofField(this, 102, proto.api.Button.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.clearPostbackPayload = function() {
  return jspb.Message.setOneofField(this, 102, proto.api.Button.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Button.prototype.hasPostbackPayload = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional ButtonPostbackPayload postback_payload_only_for_input = 103;
 * @return {?proto.api.ButtonPostbackPayload}
 */
proto.api.Button.prototype.getPostbackPayloadOnlyForInput = function() {
  return /** @type{?proto.api.ButtonPostbackPayload} */ (
    jspb.Message.getWrapperField(this, proto.api.ButtonPostbackPayload, 103));
};


/**
 * @param {?proto.api.ButtonPostbackPayload|undefined} value
 * @return {!proto.api.Button} returns this
*/
proto.api.Button.prototype.setPostbackPayloadOnlyForInput = function(value) {
  return jspb.Message.setOneofWrapperField(this, 103, proto.api.Button.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Button} returns this
 */
proto.api.Button.prototype.clearPostbackPayloadOnlyForInput = function() {
  return this.setPostbackPayloadOnlyForInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Button.prototype.hasPostbackPayloadOnlyForInput = function() {
  return jspb.Message.getField(this, 103) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ButtonPostbackPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ButtonPostbackPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ButtonPostbackPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    promisePayload: (f = msg.getPromisePayload()) && proto.api.ButtonPostbackPayload.HoianPromise.toObject(includeInstance, f),
    webLogoutPayload: (f = msg.getWebLogoutPayload()) && proto.api.ButtonPostbackPayload.WebLogout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ButtonPostbackPayload}
 */
proto.api.ButtonPostbackPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ButtonPostbackPayload;
  return proto.api.ButtonPostbackPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ButtonPostbackPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ButtonPostbackPayload}
 */
proto.api.ButtonPostbackPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.ButtonPostbackPayload.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.api.ButtonPostbackPayload.HoianPromise;
      reader.readMessage(value,proto.api.ButtonPostbackPayload.HoianPromise.deserializeBinaryFromReader);
      msg.setPromisePayload(value);
      break;
    case 3:
      var value = new proto.api.ButtonPostbackPayload.WebLogout;
      reader.readMessage(value,proto.api.ButtonPostbackPayload.WebLogout.deserializeBinaryFromReader);
      msg.setWebLogoutPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ButtonPostbackPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ButtonPostbackPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ButtonPostbackPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPromisePayload();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.ButtonPostbackPayload.HoianPromise.serializeBinaryToWriter
    );
  }
  f = message.getWebLogoutPayload();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ButtonPostbackPayload.WebLogout.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.ButtonPostbackPayload.Type = {
  TYPE_UNSPECIFIED: 0,
  PROMISE: 1,
  WEB_LOGOUT: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ButtonPostbackPayload.HoianPromise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ButtonPostbackPayload.HoianPromise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackPayload.HoianPromise.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    promiseChangeUnixTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise}
 */
proto.api.ButtonPostbackPayload.HoianPromise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ButtonPostbackPayload.HoianPromise;
  return proto.api.ButtonPostbackPayload.HoianPromise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ButtonPostbackPayload.HoianPromise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise}
 */
proto.api.ButtonPostbackPayload.HoianPromise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPromiseChangeUnixTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ButtonPostbackPayload.HoianPromise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ButtonPostbackPayload.HoianPromise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackPayload.HoianPromise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise} returns this
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise} returns this
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise} returns this
*/
proto.api.ButtonPostbackPayload.HoianPromise.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise} returns this
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 promise_change_unix_timestamp = 4;
 * @return {number}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.getPromiseChangeUnixTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise} returns this
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.setPromiseChangeUnixTimestamp = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ButtonPostbackPayload.HoianPromise} returns this
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.clearPromiseChangeUnixTimestamp = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ButtonPostbackPayload.HoianPromise.prototype.hasPromiseChangeUnixTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ButtonPostbackPayload.WebLogout.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ButtonPostbackPayload.WebLogout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ButtonPostbackPayload.WebLogout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackPayload.WebLogout.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ButtonPostbackPayload.WebLogout}
 */
proto.api.ButtonPostbackPayload.WebLogout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ButtonPostbackPayload.WebLogout;
  return proto.api.ButtonPostbackPayload.WebLogout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ButtonPostbackPayload.WebLogout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ButtonPostbackPayload.WebLogout}
 */
proto.api.ButtonPostbackPayload.WebLogout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ButtonPostbackPayload.WebLogout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ButtonPostbackPayload.WebLogout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ButtonPostbackPayload.WebLogout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackPayload.WebLogout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.api.ButtonPostbackPayload.WebLogout.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ButtonPostbackPayload.WebLogout} returns this
 */
proto.api.ButtonPostbackPayload.WebLogout.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Type type = 1;
 * @return {!proto.api.ButtonPostbackPayload.Type}
 */
proto.api.ButtonPostbackPayload.prototype.getType = function() {
  return /** @type {!proto.api.ButtonPostbackPayload.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.ButtonPostbackPayload.Type} value
 * @return {!proto.api.ButtonPostbackPayload} returns this
 */
proto.api.ButtonPostbackPayload.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional HoianPromise promise_payload = 2;
 * @return {?proto.api.ButtonPostbackPayload.HoianPromise}
 */
proto.api.ButtonPostbackPayload.prototype.getPromisePayload = function() {
  return /** @type{?proto.api.ButtonPostbackPayload.HoianPromise} */ (
    jspb.Message.getWrapperField(this, proto.api.ButtonPostbackPayload.HoianPromise, 2));
};


/**
 * @param {?proto.api.ButtonPostbackPayload.HoianPromise|undefined} value
 * @return {!proto.api.ButtonPostbackPayload} returns this
*/
proto.api.ButtonPostbackPayload.prototype.setPromisePayload = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ButtonPostbackPayload} returns this
 */
proto.api.ButtonPostbackPayload.prototype.clearPromisePayload = function() {
  return this.setPromisePayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ButtonPostbackPayload.prototype.hasPromisePayload = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WebLogout web_logout_payload = 3;
 * @return {?proto.api.ButtonPostbackPayload.WebLogout}
 */
proto.api.ButtonPostbackPayload.prototype.getWebLogoutPayload = function() {
  return /** @type{?proto.api.ButtonPostbackPayload.WebLogout} */ (
    jspb.Message.getWrapperField(this, proto.api.ButtonPostbackPayload.WebLogout, 3));
};


/**
 * @param {?proto.api.ButtonPostbackPayload.WebLogout|undefined} value
 * @return {!proto.api.ButtonPostbackPayload} returns this
*/
proto.api.ButtonPostbackPayload.prototype.setWebLogoutPayload = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ButtonPostbackPayload} returns this
 */
proto.api.ButtonPostbackPayload.prototype.clearWebLogoutPayload = function() {
  return this.setWebLogoutPayload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ButtonPostbackPayload.prototype.hasWebLogoutPayload = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.ButtonPostbackAction.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.ButtonPostbackAction.ActionCase = {
  ACTION_NOT_SET: 0,
  OPEN_URL: 1,
  SNACKBAR: 2
};

/**
 * @return {proto.api.ButtonPostbackAction.ActionCase}
 */
proto.api.ButtonPostbackAction.prototype.getActionCase = function() {
  return /** @type {proto.api.ButtonPostbackAction.ActionCase} */(jspb.Message.computeOneofCase(this, proto.api.ButtonPostbackAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ButtonPostbackAction.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ButtonPostbackAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ButtonPostbackAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    openUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    snackbar: (f = msg.getSnackbar()) && proto.api.Snackbar.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ButtonPostbackAction}
 */
proto.api.ButtonPostbackAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ButtonPostbackAction;
  return proto.api.ButtonPostbackAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ButtonPostbackAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ButtonPostbackAction}
 */
proto.api.ButtonPostbackAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenUrl(value);
      break;
    case 2:
      var value = new proto.api.Snackbar;
      reader.readMessage(value,proto.api.Snackbar.deserializeBinaryFromReader);
      msg.setSnackbar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ButtonPostbackAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ButtonPostbackAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ButtonPostbackAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ButtonPostbackAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSnackbar();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.Snackbar.serializeBinaryToWriter
    );
  }
};


/**
 * optional string open_url = 1;
 * @return {string}
 */
proto.api.ButtonPostbackAction.prototype.getOpenUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ButtonPostbackAction} returns this
 */
proto.api.ButtonPostbackAction.prototype.setOpenUrl = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.ButtonPostbackAction.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ButtonPostbackAction} returns this
 */
proto.api.ButtonPostbackAction.prototype.clearOpenUrl = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.ButtonPostbackAction.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ButtonPostbackAction.prototype.hasOpenUrl = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Snackbar snackbar = 2;
 * @return {?proto.api.Snackbar}
 */
proto.api.ButtonPostbackAction.prototype.getSnackbar = function() {
  return /** @type{?proto.api.Snackbar} */ (
    jspb.Message.getWrapperField(this, proto.api.Snackbar, 2));
};


/**
 * @param {?proto.api.Snackbar|undefined} value
 * @return {!proto.api.ButtonPostbackAction} returns this
*/
proto.api.ButtonPostbackAction.prototype.setSnackbar = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.ButtonPostbackAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ButtonPostbackAction} returns this
 */
proto.api.ButtonPostbackAction.prototype.clearSnackbar = function() {
  return this.setSnackbar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ButtonPostbackAction.prototype.hasSnackbar = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Snackbar.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Snackbar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Snackbar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Snackbar.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    button: (f = msg.getButton()) && proto.api.Button.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Snackbar}
 */
proto.api.Snackbar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Snackbar;
  return proto.api.Snackbar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Snackbar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Snackbar}
 */
proto.api.Snackbar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.api.Button;
      reader.readMessage(value,proto.api.Button.deserializeBinaryFromReader);
      msg.setButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Snackbar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Snackbar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Snackbar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Snackbar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getButton();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.Button.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.api.Snackbar.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Snackbar} returns this
 */
proto.api.Snackbar.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Button button = 2;
 * @return {?proto.api.Button}
 */
proto.api.Snackbar.prototype.getButton = function() {
  return /** @type{?proto.api.Button} */ (
    jspb.Message.getWrapperField(this, proto.api.Button, 2));
};


/**
 * @param {?proto.api.Button|undefined} value
 * @return {!proto.api.Snackbar} returns this
*/
proto.api.Snackbar.prototype.setButton = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Snackbar} returns this
 */
proto.api.Snackbar.prototype.clearButton = function() {
  return this.setButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Snackbar.prototype.hasButton = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BizAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BizAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BizAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BizAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    individual: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reviewsCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    followersCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    regionName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    msgCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    reviewedByMe: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    type: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BizAccount}
 */
proto.api.BizAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BizAccount;
  return proto.api.BizAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BizAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BizAccount}
 */
proto.api.BizAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndividual(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewsCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFollowersCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMsgCount(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReviewedByMe(value);
      break;
    case 11:
      var value = /** @type {!proto.api.BizAccount.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BizAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BizAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BizAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BizAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndividual();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReviewsCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFollowersCount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getRegionName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMsgCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getReviewedByMe();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.BizAccount.Type = {
  TYPE_UNSPECIFIED: 0,
  DEFAULT: 1,
  INDIVIDUAL: 2,
  WIDGET: 3,
  MANDATORY: 4,
  OFFICIAL: 5,
  BRAND: 6
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.BizAccount.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.BizAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool individual = 3;
 * @return {boolean}
 */
proto.api.BizAccount.prototype.getIndividual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setIndividual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.api.BizAccount.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string target_uri = 5;
 * @return {string}
 */
proto.api.BizAccount.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 reviews_count = 6;
 * @return {number}
 */
proto.api.BizAccount.prototype.getReviewsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setReviewsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 followers_count = 7;
 * @return {number}
 */
proto.api.BizAccount.prototype.getFollowersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setFollowersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string region_name = 8;
 * @return {string}
 */
proto.api.BizAccount.prototype.getRegionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setRegionName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 msg_count = 9;
 * @return {number}
 */
proto.api.BizAccount.prototype.getMsgCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setMsgCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool reviewed_by_me = 10;
 * @return {boolean}
 */
proto.api.BizAccount.prototype.getReviewedByMe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setReviewedByMe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional Type type = 11;
 * @return {!proto.api.BizAccount.Type}
 */
proto.api.BizAccount.prototype.getType = function() {
  return /** @type {!proto.api.BizAccount.Type} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.api.BizAccount.Type} value
 * @return {!proto.api.BizAccount} returns this
 */
proto.api.BizAccount.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BizAccountUser.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BizAccountUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BizAccountUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BizAccountUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    role: jspb.Message.getFieldWithDefault(msg, 4, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    bizAccount: (f = msg.getBizAccount()) && proto.api.BizAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BizAccountUser}
 */
proto.api.BizAccountUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BizAccountUser;
  return proto.api.BizAccountUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BizAccountUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BizAccountUser}
 */
proto.api.BizAccountUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 10:
      var value = new proto.api.BizAccount;
      reader.readMessage(value,proto.api.BizAccount.deserializeBinaryFromReader);
      msg.setBizAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BizAccountUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BizAccountUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BizAccountUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BizAccountUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBizAccount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.BizAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.BizAccountUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BizAccountUser} returns this
 */
proto.api.BizAccountUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.api.BizAccountUser.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BizAccountUser} returns this
 */
proto.api.BizAccountUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.api.BizAccountUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BizAccountUser} returns this
 */
proto.api.BizAccountUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string role = 4;
 * @return {string}
 */
proto.api.BizAccountUser.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BizAccountUser} returns this
 */
proto.api.BizAccountUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.api.BizAccountUser.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.BizAccountUser} returns this
 */
proto.api.BizAccountUser.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional BizAccount biz_account = 10;
 * @return {?proto.api.BizAccount}
 */
proto.api.BizAccountUser.prototype.getBizAccount = function() {
  return /** @type{?proto.api.BizAccount} */ (
    jspb.Message.getWrapperField(this, proto.api.BizAccount, 10));
};


/**
 * @param {?proto.api.BizAccount|undefined} value
 * @return {!proto.api.BizAccountUser} returns this
*/
proto.api.BizAccountUser.prototype.setBizAccount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.BizAccountUser} returns this
 */
proto.api.BizAccountUser.prototype.clearBizAccount = function() {
  return this.setBizAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.BizAccountUser.prototype.hasBizAccount = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.WebView.prototype.toObject = function(opt_includeInstance) {
  return proto.api.WebView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.WebView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.WebView.toObject = function(includeInstance, msg) {
  var f, obj = {
    appUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pathUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    remoteUri: jspb.Message.getFieldWithDefault(msg, 3, ""),
    height: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.WebView}
 */
proto.api.WebView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.WebView;
  return proto.api.WebView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.WebView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.WebView}
 */
proto.api.WebView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPathUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteUri(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.WebView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.WebView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.WebView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.WebView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPathUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemoteUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string app_uri = 1;
 * @return {string}
 */
proto.api.WebView.prototype.getAppUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.WebView} returns this
 */
proto.api.WebView.prototype.setAppUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path_uri = 2;
 * @return {string}
 */
proto.api.WebView.prototype.getPathUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.WebView} returns this
 */
proto.api.WebView.prototype.setPathUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string remote_uri = 3;
 * @return {string}
 */
proto.api.WebView.prototype.getRemoteUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.WebView} returns this
 */
proto.api.WebView.prototype.setRemoteUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 height = 4;
 * @return {number}
 */
proto.api.WebView.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.WebView} returns this
 */
proto.api.WebView.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TextShortcut.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TextShortcut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TextShortcut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextShortcut.toObject = function(includeInstance, msg) {
  var f, obj = {
    bypass: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TextShortcut}
 */
proto.api.TextShortcut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TextShortcut;
  return proto.api.TextShortcut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TextShortcut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TextShortcut}
 */
proto.api.TextShortcut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBypass(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TextShortcut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TextShortcut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TextShortcut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextShortcut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBypass();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bypass = 1;
 * @return {string}
 */
proto.api.TextShortcut.prototype.getBypass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TextShortcut} returns this
 */
proto.api.TextShortcut.prototype.setBypass = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.api.TextShortcut.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TextShortcut} returns this
 */
proto.api.TextShortcut.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.BubbleGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BubbleGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BubbleGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BubbleGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BubbleGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    buttonsList: jspb.Message.toObjectList(msg.getButtonsList(),
    proto.api.Button.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BubbleGroup}
 */
proto.api.BubbleGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BubbleGroup;
  return proto.api.BubbleGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BubbleGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BubbleGroup}
 */
proto.api.BubbleGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.Button;
      reader.readMessage(value,proto.api.Button.deserializeBinaryFromReader);
      msg.addButtons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BubbleGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BubbleGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BubbleGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BubbleGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.Button.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.BubbleGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.BubbleGroup} returns this
 */
proto.api.BubbleGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Button buttons = 2;
 * @return {!Array<!proto.api.Button>}
 */
proto.api.BubbleGroup.prototype.getButtonsList = function() {
  return /** @type{!Array<!proto.api.Button>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Button, 2));
};


/**
 * @param {!Array<!proto.api.Button>} value
 * @return {!proto.api.BubbleGroup} returns this
*/
proto.api.BubbleGroup.prototype.setButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.Button=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Button}
 */
proto.api.BubbleGroup.prototype.addButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.Button, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.BubbleGroup} returns this
 */
proto.api.BubbleGroup.prototype.clearButtonsList = function() {
  return this.setButtonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.Introduction.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Introduction.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Introduction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Introduction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Introduction.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageButton: (f = msg.getImageButton()) && proto.api.ImageButton.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.api.Introduction.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Introduction}
 */
proto.api.Introduction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Introduction;
  return proto.api.Introduction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Introduction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Introduction}
 */
proto.api.Introduction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ImageButton;
      reader.readMessage(value,proto.api.ImageButton.deserializeBinaryFromReader);
      msg.setImageButton(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.api.Introduction.Row;
      reader.readMessage(value,proto.api.Introduction.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Introduction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Introduction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Introduction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Introduction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageButton();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ImageButton.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.Introduction.Row.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Introduction.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Introduction.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Introduction.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Introduction.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    iconUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    richText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Introduction.Row}
 */
proto.api.Introduction.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Introduction.Row;
  return proto.api.Introduction.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Introduction.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Introduction.Row}
 */
proto.api.Introduction.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Introduction.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Introduction.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Introduction.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Introduction.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIconUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRichText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string icon_url = 1;
 * @return {string}
 */
proto.api.Introduction.Row.prototype.getIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Introduction.Row} returns this
 */
proto.api.Introduction.Row.prototype.setIconUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rich_text = 2;
 * @return {string}
 */
proto.api.Introduction.Row.prototype.getRichText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Introduction.Row} returns this
 */
proto.api.Introduction.Row.prototype.setRichText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ImageButton image_button = 1;
 * @return {?proto.api.ImageButton}
 */
proto.api.Introduction.prototype.getImageButton = function() {
  return /** @type{?proto.api.ImageButton} */ (
    jspb.Message.getWrapperField(this, proto.api.ImageButton, 1));
};


/**
 * @param {?proto.api.ImageButton|undefined} value
 * @return {!proto.api.Introduction} returns this
*/
proto.api.Introduction.prototype.setImageButton = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Introduction} returns this
 */
proto.api.Introduction.prototype.clearImageButton = function() {
  return this.setImageButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Introduction.prototype.hasImageButton = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.api.Introduction.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Introduction} returns this
 */
proto.api.Introduction.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Row rows = 3;
 * @return {!Array<!proto.api.Introduction.Row>}
 */
proto.api.Introduction.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.api.Introduction.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Introduction.Row, 3));
};


/**
 * @param {!Array<!proto.api.Introduction.Row>} value
 * @return {!proto.api.Introduction} returns this
*/
proto.api.Introduction.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.Introduction.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Introduction.Row}
 */
proto.api.Introduction.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.Introduction.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.Introduction} returns this
 */
proto.api.Introduction.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ImageButton.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ImageButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ImageButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ImageButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ImageButton}
 */
proto.api.ImageButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ImageButton;
  return proto.api.ImageButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ImageButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ImageButton}
 */
proto.api.ImageButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ImageButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ImageButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ImageButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ImageButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string image_url = 1;
 * @return {string}
 */
proto.api.ImageButton.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ImageButton} returns this
 */
proto.api.ImageButton.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_uri = 2;
 * @return {string}
 */
proto.api.ImageButton.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ImageButton} returns this
 */
proto.api.ImageButton.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.ToolboxButton.oneofGroups_ = [[10,11]];

/**
 * @enum {number}
 */
proto.api.ToolboxButton.ActionCase = {
  ACTION_NOT_SET: 0,
  TARGET_URI: 10,
  NATIVE_ACTION: 11
};

/**
 * @return {proto.api.ToolboxButton.ActionCase}
 */
proto.api.ToolboxButton.prototype.getActionCase = function() {
  return /** @type {proto.api.ToolboxButton.ActionCase} */(jspb.Message.computeOneofCase(this, proto.api.ToolboxButton.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ToolboxButton.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ToolboxButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ToolboxButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ToolboxButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    iconUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 10, ""),
    nativeAction: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ToolboxButton}
 */
proto.api.ToolboxButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ToolboxButton;
  return proto.api.ToolboxButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ToolboxButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ToolboxButton}
 */
proto.api.ToolboxButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 11:
      var value = /** @type {!proto.api.NativeAction} */ (reader.readEnum());
      msg.setNativeAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ToolboxButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ToolboxButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ToolboxButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ToolboxButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIconUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {!proto.api.NativeAction} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string icon_url = 1;
 * @return {string}
 */
proto.api.ToolboxButton.prototype.getIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.setIconUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.api.ToolboxButton.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_name = 3;
 * @return {string}
 */
proto.api.ToolboxButton.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string target_uri = 10;
 * @return {string}
 */
proto.api.ToolboxButton.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.setTargetUri = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.api.ToolboxButton.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.clearTargetUri = function() {
  return jspb.Message.setOneofField(this, 10, proto.api.ToolboxButton.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ToolboxButton.prototype.hasTargetUri = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NativeAction native_action = 11;
 * @return {!proto.api.NativeAction}
 */
proto.api.ToolboxButton.prototype.getNativeAction = function() {
  return /** @type {!proto.api.NativeAction} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.api.NativeAction} value
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.setNativeAction = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.api.ToolboxButton.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ToolboxButton} returns this
 */
proto.api.ToolboxButton.prototype.clearNativeAction = function() {
  return jspb.Message.setOneofField(this, 11, proto.api.ToolboxButton.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ToolboxButton.prototype.hasNativeAction = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.ChannelListHeader.oneofGroups_ = [[10,11,12]];

/**
 * @enum {number}
 */
proto.api.ChannelListHeader.TemplateCase = {
  TEMPLATE_NOT_SET: 0,
  NOTICE: 10,
  NOTICE_WITH_LINK: 11,
  BASIC_BANNER: 12
};

/**
 * @return {proto.api.ChannelListHeader.TemplateCase}
 */
proto.api.ChannelListHeader.prototype.getTemplateCase = function() {
  return /** @type {proto.api.ChannelListHeader.TemplateCase} */(jspb.Message.computeOneofCase(this, proto.api.ChannelListHeader.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ChannelListHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ChannelListHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ChannelListHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notice: (f = msg.getNotice()) && proto.api.ChannelListHeader.Notice.toObject(includeInstance, f),
    noticeWithLink: (f = msg.getNoticeWithLink()) && proto.api.ChannelListHeader.NoticeWithLink.toObject(includeInstance, f),
    basicBanner: (f = msg.getBasicBanner()) && proto.api.ChannelListHeader.BasicBanner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ChannelListHeader}
 */
proto.api.ChannelListHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ChannelListHeader;
  return proto.api.ChannelListHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ChannelListHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ChannelListHeader}
 */
proto.api.ChannelListHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = new proto.api.ChannelListHeader.Notice;
      reader.readMessage(value,proto.api.ChannelListHeader.Notice.deserializeBinaryFromReader);
      msg.setNotice(value);
      break;
    case 11:
      var value = new proto.api.ChannelListHeader.NoticeWithLink;
      reader.readMessage(value,proto.api.ChannelListHeader.NoticeWithLink.deserializeBinaryFromReader);
      msg.setNoticeWithLink(value);
      break;
    case 12:
      var value = new proto.api.ChannelListHeader.BasicBanner;
      reader.readMessage(value,proto.api.ChannelListHeader.BasicBanner.deserializeBinaryFromReader);
      msg.setBasicBanner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ChannelListHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ChannelListHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ChannelListHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.ChannelListHeader.Notice.serializeBinaryToWriter
    );
  }
  f = message.getNoticeWithLink();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.ChannelListHeader.NoticeWithLink.serializeBinaryToWriter
    );
  }
  f = message.getBasicBanner();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.api.ChannelListHeader.BasicBanner.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ChannelListHeader.Notice.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ChannelListHeader.Notice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ChannelListHeader.Notice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.Notice.toObject = function(includeInstance, msg) {
  var f, obj = {
    richText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ChannelListHeader.Notice}
 */
proto.api.ChannelListHeader.Notice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ChannelListHeader.Notice;
  return proto.api.ChannelListHeader.Notice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ChannelListHeader.Notice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ChannelListHeader.Notice}
 */
proto.api.ChannelListHeader.Notice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ChannelListHeader.Notice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ChannelListHeader.Notice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ChannelListHeader.Notice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.Notice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRichText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string rich_text = 1;
 * @return {string}
 */
proto.api.ChannelListHeader.Notice.prototype.getRichText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.Notice} returns this
 */
proto.api.ChannelListHeader.Notice.prototype.setRichText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ChannelListHeader.NoticeWithLink.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ChannelListHeader.NoticeWithLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ChannelListHeader.NoticeWithLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.NoticeWithLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    richText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ChannelListHeader.NoticeWithLink}
 */
proto.api.ChannelListHeader.NoticeWithLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ChannelListHeader.NoticeWithLink;
  return proto.api.ChannelListHeader.NoticeWithLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ChannelListHeader.NoticeWithLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ChannelListHeader.NoticeWithLink}
 */
proto.api.ChannelListHeader.NoticeWithLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ChannelListHeader.NoticeWithLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ChannelListHeader.NoticeWithLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ChannelListHeader.NoticeWithLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.NoticeWithLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRichText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string rich_text = 1;
 * @return {string}
 */
proto.api.ChannelListHeader.NoticeWithLink.prototype.getRichText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.NoticeWithLink} returns this
 */
proto.api.ChannelListHeader.NoticeWithLink.prototype.setRichText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_uri = 2;
 * @return {string}
 */
proto.api.ChannelListHeader.NoticeWithLink.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.NoticeWithLink} returns this
 */
proto.api.ChannelListHeader.NoticeWithLink.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ChannelListHeader.BasicBanner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ChannelListHeader.BasicBanner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.BasicBanner.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    button: (f = msg.getButton()) && proto.api.ChannelListHeader.BasicBanner.Button.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ChannelListHeader.BasicBanner}
 */
proto.api.ChannelListHeader.BasicBanner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ChannelListHeader.BasicBanner;
  return proto.api.ChannelListHeader.BasicBanner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ChannelListHeader.BasicBanner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ChannelListHeader.BasicBanner}
 */
proto.api.ChannelListHeader.BasicBanner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.ChannelListHeader.BasicBanner.Color} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = new proto.api.ChannelListHeader.BasicBanner.Button;
      reader.readMessage(value,proto.api.ChannelListHeader.BasicBanner.Button.deserializeBinaryFromReader);
      msg.setButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ChannelListHeader.BasicBanner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ChannelListHeader.BasicBanner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.BasicBanner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getButton();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.ChannelListHeader.BasicBanner.Button.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.ChannelListHeader.BasicBanner.Color = {
  COLOR_UNSPECIFIED: 0,
  COLOR_GRAY: 0,
  COLOR_YELLOW: 1,
  COLOR_GREEN: 2,
  COLOR_CARROT: 3,
  COLOR_BLUE: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ChannelListHeader.BasicBanner.Button.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ChannelListHeader.BasicBanner.Button} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.BasicBanner.Button.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ChannelListHeader.BasicBanner.Button}
 */
proto.api.ChannelListHeader.BasicBanner.Button.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ChannelListHeader.BasicBanner.Button;
  return proto.api.ChannelListHeader.BasicBanner.Button.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ChannelListHeader.BasicBanner.Button} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ChannelListHeader.BasicBanner.Button}
 */
proto.api.ChannelListHeader.BasicBanner.Button.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.ChannelListHeader.BasicBanner.Button.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ChannelListHeader.BasicBanner.Button.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ChannelListHeader.BasicBanner.Button} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChannelListHeader.BasicBanner.Button.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.ChannelListHeader.BasicBanner.Button.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_TEXT: 0,
  TYPE_BASIC: 1
};

/**
 * optional Type type = 1;
 * @return {!proto.api.ChannelListHeader.BasicBanner.Button.Type}
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.getType = function() {
  return /** @type {!proto.api.ChannelListHeader.BasicBanner.Button.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.ChannelListHeader.BasicBanner.Button.Type} value
 * @return {!proto.api.ChannelListHeader.BasicBanner.Button} returns this
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.BasicBanner.Button} returns this
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string target_uri = 3;
 * @return {string}
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.BasicBanner.Button} returns this
 */
proto.api.ChannelListHeader.BasicBanner.Button.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Color color = 1;
 * @return {!proto.api.ChannelListHeader.BasicBanner.Color}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.getColor = function() {
  return /** @type {!proto.api.ChannelListHeader.BasicBanner.Color} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.ChannelListHeader.BasicBanner.Color} value
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
 */
proto.api.ChannelListHeader.BasicBanner.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
 */
proto.api.ChannelListHeader.BasicBanner.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
 */
proto.api.ChannelListHeader.BasicBanner.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
 */
proto.api.ChannelListHeader.BasicBanner.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
 */
proto.api.ChannelListHeader.BasicBanner.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Button button = 5;
 * @return {?proto.api.ChannelListHeader.BasicBanner.Button}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.getButton = function() {
  return /** @type{?proto.api.ChannelListHeader.BasicBanner.Button} */ (
    jspb.Message.getWrapperField(this, proto.api.ChannelListHeader.BasicBanner.Button, 5));
};


/**
 * @param {?proto.api.ChannelListHeader.BasicBanner.Button|undefined} value
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
*/
proto.api.ChannelListHeader.BasicBanner.prototype.setButton = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChannelListHeader.BasicBanner} returns this
 */
proto.api.ChannelListHeader.BasicBanner.prototype.clearButton = function() {
  return this.setButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChannelListHeader.BasicBanner.prototype.hasButton = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.ChannelListHeader.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ChannelListHeader} returns this
 */
proto.api.ChannelListHeader.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Notice notice = 10;
 * @return {?proto.api.ChannelListHeader.Notice}
 */
proto.api.ChannelListHeader.prototype.getNotice = function() {
  return /** @type{?proto.api.ChannelListHeader.Notice} */ (
    jspb.Message.getWrapperField(this, proto.api.ChannelListHeader.Notice, 10));
};


/**
 * @param {?proto.api.ChannelListHeader.Notice|undefined} value
 * @return {!proto.api.ChannelListHeader} returns this
*/
proto.api.ChannelListHeader.prototype.setNotice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.api.ChannelListHeader.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChannelListHeader} returns this
 */
proto.api.ChannelListHeader.prototype.clearNotice = function() {
  return this.setNotice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChannelListHeader.prototype.hasNotice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NoticeWithLink notice_with_link = 11;
 * @return {?proto.api.ChannelListHeader.NoticeWithLink}
 */
proto.api.ChannelListHeader.prototype.getNoticeWithLink = function() {
  return /** @type{?proto.api.ChannelListHeader.NoticeWithLink} */ (
    jspb.Message.getWrapperField(this, proto.api.ChannelListHeader.NoticeWithLink, 11));
};


/**
 * @param {?proto.api.ChannelListHeader.NoticeWithLink|undefined} value
 * @return {!proto.api.ChannelListHeader} returns this
*/
proto.api.ChannelListHeader.prototype.setNoticeWithLink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.api.ChannelListHeader.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChannelListHeader} returns this
 */
proto.api.ChannelListHeader.prototype.clearNoticeWithLink = function() {
  return this.setNoticeWithLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChannelListHeader.prototype.hasNoticeWithLink = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional BasicBanner basic_banner = 12;
 * @return {?proto.api.ChannelListHeader.BasicBanner}
 */
proto.api.ChannelListHeader.prototype.getBasicBanner = function() {
  return /** @type{?proto.api.ChannelListHeader.BasicBanner} */ (
    jspb.Message.getWrapperField(this, proto.api.ChannelListHeader.BasicBanner, 12));
};


/**
 * @param {?proto.api.ChannelListHeader.BasicBanner|undefined} value
 * @return {!proto.api.ChannelListHeader} returns this
*/
proto.api.ChannelListHeader.prototype.setBasicBanner = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.api.ChannelListHeader.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChannelListHeader} returns this
 */
proto.api.ChannelListHeader.prototype.clearBasicBanner = function() {
  return this.setBasicBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChannelListHeader.prototype.hasBasicBanner = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.PersistentMenuItem.oneofGroups_ = [[10,11]];

/**
 * @enum {number}
 */
proto.api.PersistentMenuItem.ActionCase = {
  ACTION_NOT_SET: 0,
  POSTBACK: 10,
  OPEN_URL: 11
};

/**
 * @return {proto.api.PersistentMenuItem.ActionCase}
 */
proto.api.PersistentMenuItem.prototype.getActionCase = function() {
  return /** @type {proto.api.PersistentMenuItem.ActionCase} */(jspb.Message.computeOneofCase(this, proto.api.PersistentMenuItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PersistentMenuItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PersistentMenuItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PersistentMenuItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenuItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    postback: (f = msg.getPostback()) && proto.api.PersistentMenuItem.PostbackAction.toObject(includeInstance, f),
    openUrl: (f = msg.getOpenUrl()) && proto.api.PersistentMenuItem.OpenURLAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PersistentMenuItem}
 */
proto.api.PersistentMenuItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PersistentMenuItem;
  return proto.api.PersistentMenuItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PersistentMenuItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PersistentMenuItem}
 */
proto.api.PersistentMenuItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 10:
      var value = new proto.api.PersistentMenuItem.PostbackAction;
      reader.readMessage(value,proto.api.PersistentMenuItem.PostbackAction.deserializeBinaryFromReader);
      msg.setPostback(value);
      break;
    case 11:
      var value = new proto.api.PersistentMenuItem.OpenURLAction;
      reader.readMessage(value,proto.api.PersistentMenuItem.OpenURLAction.deserializeBinaryFromReader);
      msg.setOpenUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PersistentMenuItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PersistentMenuItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PersistentMenuItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenuItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPostback();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.PersistentMenuItem.PostbackAction.serializeBinaryToWriter
    );
  }
  f = message.getOpenUrl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.PersistentMenuItem.OpenURLAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PersistentMenuItem.PostbackAction.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PersistentMenuItem.PostbackAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PersistentMenuItem.PostbackAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenuItem.PostbackAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PersistentMenuItem.PostbackAction}
 */
proto.api.PersistentMenuItem.PostbackAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PersistentMenuItem.PostbackAction;
  return proto.api.PersistentMenuItem.PostbackAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PersistentMenuItem.PostbackAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PersistentMenuItem.PostbackAction}
 */
proto.api.PersistentMenuItem.PostbackAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PersistentMenuItem.PostbackAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PersistentMenuItem.PostbackAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PersistentMenuItem.PostbackAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenuItem.PostbackAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.api.PersistentMenuItem.PostbackAction.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PersistentMenuItem.PostbackAction} returns this
 */
proto.api.PersistentMenuItem.PostbackAction.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PersistentMenuItem.OpenURLAction.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PersistentMenuItem.OpenURLAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PersistentMenuItem.OpenURLAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenuItem.OpenURLAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PersistentMenuItem.OpenURLAction}
 */
proto.api.PersistentMenuItem.OpenURLAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PersistentMenuItem.OpenURLAction;
  return proto.api.PersistentMenuItem.OpenURLAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PersistentMenuItem.OpenURLAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PersistentMenuItem.OpenURLAction}
 */
proto.api.PersistentMenuItem.OpenURLAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PersistentMenuItem.OpenURLAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PersistentMenuItem.OpenURLAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PersistentMenuItem.OpenURLAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenuItem.OpenURLAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.api.PersistentMenuItem.OpenURLAction.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PersistentMenuItem.OpenURLAction} returns this
 */
proto.api.PersistentMenuItem.OpenURLAction.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.api.PersistentMenuItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.PersistentMenuItem} returns this
 */
proto.api.PersistentMenuItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PostbackAction postback = 10;
 * @return {?proto.api.PersistentMenuItem.PostbackAction}
 */
proto.api.PersistentMenuItem.prototype.getPostback = function() {
  return /** @type{?proto.api.PersistentMenuItem.PostbackAction} */ (
    jspb.Message.getWrapperField(this, proto.api.PersistentMenuItem.PostbackAction, 10));
};


/**
 * @param {?proto.api.PersistentMenuItem.PostbackAction|undefined} value
 * @return {!proto.api.PersistentMenuItem} returns this
*/
proto.api.PersistentMenuItem.prototype.setPostback = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.api.PersistentMenuItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.PersistentMenuItem} returns this
 */
proto.api.PersistentMenuItem.prototype.clearPostback = function() {
  return this.setPostback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.PersistentMenuItem.prototype.hasPostback = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OpenURLAction open_url = 11;
 * @return {?proto.api.PersistentMenuItem.OpenURLAction}
 */
proto.api.PersistentMenuItem.prototype.getOpenUrl = function() {
  return /** @type{?proto.api.PersistentMenuItem.OpenURLAction} */ (
    jspb.Message.getWrapperField(this, proto.api.PersistentMenuItem.OpenURLAction, 11));
};


/**
 * @param {?proto.api.PersistentMenuItem.OpenURLAction|undefined} value
 * @return {!proto.api.PersistentMenuItem} returns this
*/
proto.api.PersistentMenuItem.prototype.setOpenUrl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.api.PersistentMenuItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.PersistentMenuItem} returns this
 */
proto.api.PersistentMenuItem.prototype.clearOpenUrl = function() {
  return this.setOpenUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.PersistentMenuItem.prototype.hasOpenUrl = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.PersistentMenu.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.PersistentMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.api.PersistentMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.PersistentMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.api.PersistentMenuItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.PersistentMenu}
 */
proto.api.PersistentMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.PersistentMenu;
  return proto.api.PersistentMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.PersistentMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.PersistentMenu}
 */
proto.api.PersistentMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.PersistentMenuItem;
      reader.readMessage(value,proto.api.PersistentMenuItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.PersistentMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.PersistentMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.PersistentMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.PersistentMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.PersistentMenuItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PersistentMenuItem items = 1;
 * @return {!Array<!proto.api.PersistentMenuItem>}
 */
proto.api.PersistentMenu.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.api.PersistentMenuItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.PersistentMenuItem, 1));
};


/**
 * @param {!Array<!proto.api.PersistentMenuItem>} value
 * @return {!proto.api.PersistentMenu} returns this
*/
proto.api.PersistentMenu.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.PersistentMenuItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.PersistentMenuItem}
 */
proto.api.PersistentMenu.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.PersistentMenuItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.PersistentMenu} returns this
 */
proto.api.PersistentMenu.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AutoReplyMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AutoReplyMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AutoReplyMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReplyMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    itemCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    canCreateItem: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AutoReplyMetadata}
 */
proto.api.AutoReplyMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AutoReplyMetadata;
  return proto.api.AutoReplyMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AutoReplyMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AutoReplyMetadata}
 */
proto.api.AutoReplyMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemCount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanCreateItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AutoReplyMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AutoReplyMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AutoReplyMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReplyMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getItemCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCanCreateItem();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.api.AutoReplyMetadata.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.AutoReplyMetadata} returns this
 */
proto.api.AutoReplyMetadata.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 item_count = 2;
 * @return {number}
 */
proto.api.AutoReplyMetadata.prototype.getItemCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.AutoReplyMetadata} returns this
 */
proto.api.AutoReplyMetadata.prototype.setItemCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool can_create_item = 3;
 * @return {boolean}
 */
proto.api.AutoReplyMetadata.prototype.getCanCreateItem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.AutoReplyMetadata} returns this
 */
proto.api.AutoReplyMetadata.prototype.setCanCreateItem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AutoReplyQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AutoReplyQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AutoReplyQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReplyQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AutoReplyQuestion}
 */
proto.api.AutoReplyQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AutoReplyQuestion;
  return proto.api.AutoReplyQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AutoReplyQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AutoReplyQuestion}
 */
proto.api.AutoReplyQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AutoReplyQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AutoReplyQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AutoReplyQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReplyQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.api.AutoReplyQuestion.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AutoReplyQuestion} returns this
 */
proto.api.AutoReplyQuestion.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.AutoReplyAnswer.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AutoReplyAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AutoReplyAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AutoReplyAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReplyAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.api.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AutoReplyAnswer}
 */
proto.api.AutoReplyAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AutoReplyAnswer;
  return proto.api.AutoReplyAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AutoReplyAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AutoReplyAnswer}
 */
proto.api.AutoReplyAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = new proto.api.Image;
      reader.readMessage(value,proto.api.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AutoReplyAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AutoReplyAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AutoReplyAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReplyAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.api.AutoReplyAnswer.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AutoReplyAnswer} returns this
 */
proto.api.AutoReplyAnswer.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Image images = 2;
 * @return {!Array<!proto.api.Image>}
 */
proto.api.AutoReplyAnswer.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.api.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Image, 2));
};


/**
 * @param {!Array<!proto.api.Image>} value
 * @return {!proto.api.AutoReplyAnswer} returns this
*/
proto.api.AutoReplyAnswer.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Image}
 */
proto.api.AutoReplyAnswer.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.AutoReplyAnswer} returns this
 */
proto.api.AutoReplyAnswer.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AutoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AutoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AutoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    question: (f = msg.getQuestion()) && proto.api.AutoReplyQuestion.toObject(includeInstance, f),
    answer: (f = msg.getAnswer()) && proto.api.AutoReplyAnswer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AutoReply}
 */
proto.api.AutoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AutoReply;
  return proto.api.AutoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AutoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AutoReply}
 */
proto.api.AutoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.AutoReplyQuestion;
      reader.readMessage(value,proto.api.AutoReplyQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 3:
      var value = new proto.api.AutoReplyAnswer;
      reader.readMessage(value,proto.api.AutoReplyAnswer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AutoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AutoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AutoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AutoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.AutoReplyQuestion.serializeBinaryToWriter
    );
  }
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.AutoReplyAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.AutoReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AutoReply} returns this
 */
proto.api.AutoReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AutoReplyQuestion question = 2;
 * @return {?proto.api.AutoReplyQuestion}
 */
proto.api.AutoReply.prototype.getQuestion = function() {
  return /** @type{?proto.api.AutoReplyQuestion} */ (
    jspb.Message.getWrapperField(this, proto.api.AutoReplyQuestion, 2));
};


/**
 * @param {?proto.api.AutoReplyQuestion|undefined} value
 * @return {!proto.api.AutoReply} returns this
*/
proto.api.AutoReply.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AutoReply} returns this
 */
proto.api.AutoReply.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AutoReply.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AutoReplyAnswer answer = 3;
 * @return {?proto.api.AutoReplyAnswer}
 */
proto.api.AutoReply.prototype.getAnswer = function() {
  return /** @type{?proto.api.AutoReplyAnswer} */ (
    jspb.Message.getWrapperField(this, proto.api.AutoReplyAnswer, 3));
};


/**
 * @param {?proto.api.AutoReplyAnswer|undefined} value
 * @return {!proto.api.AutoReply} returns this
*/
proto.api.AutoReply.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AutoReply} returns this
 */
proto.api.AutoReply.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AutoReply.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Announcement.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Announcement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Announcement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Announcement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createBy: (f = msg.getCreateBy()) && proto.api.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Announcement}
 */
proto.api.Announcement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Announcement;
  return proto.api.Announcement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Announcement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Announcement}
 */
proto.api.Announcement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 5:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setCreateBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Announcement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Announcement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Announcement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Announcement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Announcement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Announcement} returns this
 */
proto.api.Announcement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.api.Announcement.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Announcement} returns this
 */
proto.api.Announcement.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.api.Announcement.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Announcement} returns this
 */
proto.api.Announcement.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.Announcement.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.Announcement} returns this
*/
proto.api.Announcement.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Announcement} returns this
 */
proto.api.Announcement.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Announcement.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User create_by = 5;
 * @return {?proto.api.User}
 */
proto.api.Announcement.prototype.getCreateBy = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 5));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Announcement} returns this
*/
proto.api.Announcement.prototype.setCreateBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Announcement} returns this
 */
proto.api.Announcement.prototype.clearCreateBy = function() {
  return this.setCreateBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Announcement.prototype.hasCreateBy = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SectionBlock.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SectionBlock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SectionBlock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SectionBlock.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: (f = msg.getText()) && proto.api.TextNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SectionBlock}
 */
proto.api.SectionBlock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SectionBlock;
  return proto.api.SectionBlock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SectionBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SectionBlock}
 */
proto.api.SectionBlock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.TextNode;
      reader.readMessage(value,proto.api.TextNode.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SectionBlock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SectionBlock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SectionBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SectionBlock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.TextNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TextNode text = 1;
 * @return {?proto.api.TextNode}
 */
proto.api.SectionBlock.prototype.getText = function() {
  return /** @type{?proto.api.TextNode} */ (
    jspb.Message.getWrapperField(this, proto.api.TextNode, 1));
};


/**
 * @param {?proto.api.TextNode|undefined} value
 * @return {!proto.api.SectionBlock} returns this
*/
proto.api.SectionBlock.prototype.setText = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SectionBlock} returns this
 */
proto.api.SectionBlock.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SectionBlock.prototype.hasText = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.TextNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.TextNode.ContentCase = {
  CONTENT_NOT_SET: 0,
  MARKDOWN: 1,
  PLAIN_TEXT: 2
};

/**
 * @return {proto.api.TextNode.ContentCase}
 */
proto.api.TextNode.prototype.getContentCase = function() {
  return /** @type {proto.api.TextNode.ContentCase} */(jspb.Message.computeOneofCase(this, proto.api.TextNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TextNode.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TextNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TextNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    markdown: (f = msg.getMarkdown()) && proto.api.TextNode.Markdown.toObject(includeInstance, f),
    plainText: (f = msg.getPlainText()) && proto.api.TextNode.PlainText.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TextNode}
 */
proto.api.TextNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TextNode;
  return proto.api.TextNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TextNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TextNode}
 */
proto.api.TextNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.TextNode.Markdown;
      reader.readMessage(value,proto.api.TextNode.Markdown.deserializeBinaryFromReader);
      msg.setMarkdown(value);
      break;
    case 2:
      var value = new proto.api.TextNode.PlainText;
      reader.readMessage(value,proto.api.TextNode.PlainText.deserializeBinaryFromReader);
      msg.setPlainText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TextNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TextNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TextNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarkdown();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.TextNode.Markdown.serializeBinaryToWriter
    );
  }
  f = message.getPlainText();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.TextNode.PlainText.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TextNode.Markdown.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TextNode.Markdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TextNode.Markdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextNode.Markdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TextNode.Markdown}
 */
proto.api.TextNode.Markdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TextNode.Markdown;
  return proto.api.TextNode.Markdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TextNode.Markdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TextNode.Markdown}
 */
proto.api.TextNode.Markdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TextNode.Markdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TextNode.Markdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TextNode.Markdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextNode.Markdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.api.TextNode.Markdown.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TextNode.Markdown} returns this
 */
proto.api.TextNode.Markdown.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TextNode.PlainText.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TextNode.PlainText.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TextNode.PlainText} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextNode.PlainText.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TextNode.PlainText}
 */
proto.api.TextNode.PlainText.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TextNode.PlainText;
  return proto.api.TextNode.PlainText.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TextNode.PlainText} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TextNode.PlainText}
 */
proto.api.TextNode.PlainText.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TextNode.PlainText.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TextNode.PlainText.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TextNode.PlainText} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TextNode.PlainText.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.api.TextNode.PlainText.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TextNode.PlainText} returns this
 */
proto.api.TextNode.PlainText.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Markdown markdown = 1;
 * @return {?proto.api.TextNode.Markdown}
 */
proto.api.TextNode.prototype.getMarkdown = function() {
  return /** @type{?proto.api.TextNode.Markdown} */ (
    jspb.Message.getWrapperField(this, proto.api.TextNode.Markdown, 1));
};


/**
 * @param {?proto.api.TextNode.Markdown|undefined} value
 * @return {!proto.api.TextNode} returns this
*/
proto.api.TextNode.prototype.setMarkdown = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.TextNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.TextNode} returns this
 */
proto.api.TextNode.prototype.clearMarkdown = function() {
  return this.setMarkdown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TextNode.prototype.hasMarkdown = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlainText plain_text = 2;
 * @return {?proto.api.TextNode.PlainText}
 */
proto.api.TextNode.prototype.getPlainText = function() {
  return /** @type{?proto.api.TextNode.PlainText} */ (
    jspb.Message.getWrapperField(this, proto.api.TextNode.PlainText, 2));
};


/**
 * @param {?proto.api.TextNode.PlainText|undefined} value
 * @return {!proto.api.TextNode} returns this
*/
proto.api.TextNode.prototype.setPlainText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.TextNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.TextNode} returns this
 */
proto.api.TextNode.prototype.clearPlainText = function() {
  return this.setPlainText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TextNode.prototype.hasPlainText = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.BlockNode.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.api.BlockNode.BlockCase = {
  BLOCK_NOT_SET: 0,
  SECTION: 1
};

/**
 * @return {proto.api.BlockNode.BlockCase}
 */
proto.api.BlockNode.prototype.getBlockCase = function() {
  return /** @type {proto.api.BlockNode.BlockCase} */(jspb.Message.computeOneofCase(this, proto.api.BlockNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BlockNode.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BlockNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BlockNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BlockNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    section: (f = msg.getSection()) && proto.api.SectionBlock.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BlockNode}
 */
proto.api.BlockNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BlockNode;
  return proto.api.BlockNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BlockNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BlockNode}
 */
proto.api.BlockNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.SectionBlock;
      reader.readMessage(value,proto.api.SectionBlock.deserializeBinaryFromReader);
      msg.setSection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BlockNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BlockNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BlockNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BlockNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.SectionBlock.serializeBinaryToWriter
    );
  }
};


/**
 * optional SectionBlock section = 1;
 * @return {?proto.api.SectionBlock}
 */
proto.api.BlockNode.prototype.getSection = function() {
  return /** @type{?proto.api.SectionBlock} */ (
    jspb.Message.getWrapperField(this, proto.api.SectionBlock, 1));
};


/**
 * @param {?proto.api.SectionBlock|undefined} value
 * @return {!proto.api.BlockNode} returns this
*/
proto.api.BlockNode.prototype.setSection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.BlockNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.BlockNode} returns this
 */
proto.api.BlockNode.prototype.clearSection = function() {
  return this.setSection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.BlockNode.prototype.hasSection = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.BlockTemplate.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BlockTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BlockTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BlockTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BlockTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    blocksList: jspb.Message.toObjectList(msg.getBlocksList(),
    proto.api.BlockNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BlockTemplate}
 */
proto.api.BlockTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BlockTemplate;
  return proto.api.BlockTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BlockTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BlockTemplate}
 */
proto.api.BlockTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.BlockNode;
      reader.readMessage(value,proto.api.BlockNode.deserializeBinaryFromReader);
      msg.addBlocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BlockTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BlockTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BlockTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BlockTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.BlockNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlockNode blocks = 1;
 * @return {!Array<!proto.api.BlockNode>}
 */
proto.api.BlockTemplate.prototype.getBlocksList = function() {
  return /** @type{!Array<!proto.api.BlockNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.BlockNode, 1));
};


/**
 * @param {!Array<!proto.api.BlockNode>} value
 * @return {!proto.api.BlockTemplate} returns this
*/
proto.api.BlockTemplate.prototype.setBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.BlockNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.BlockNode}
 */
proto.api.BlockTemplate.prototype.addBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.BlockNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.BlockTemplate} returns this
 */
proto.api.BlockTemplate.prototype.clearBlocksList = function() {
  return this.setBlocksList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.MoreMenu.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.api.MoreMenu.ValueCase = {
  VALUE_NOT_SET: 0,
  TARGET_URI: 1
};

/**
 * @return {proto.api.MoreMenu.ValueCase}
 */
proto.api.MoreMenu.prototype.getValueCase = function() {
  return /** @type {proto.api.MoreMenu.ValueCase} */(jspb.Message.computeOneofCase(this, proto.api.MoreMenu.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MoreMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MoreMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MoreMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MoreMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MoreMenu}
 */
proto.api.MoreMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MoreMenu;
  return proto.api.MoreMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MoreMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MoreMenu}
 */
proto.api.MoreMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MoreMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MoreMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MoreMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MoreMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string target_uri = 1;
 * @return {string}
 */
proto.api.MoreMenu.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MoreMenu} returns this
 */
proto.api.MoreMenu.prototype.setTargetUri = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.MoreMenu.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.MoreMenu} returns this
 */
proto.api.MoreMenu.prototype.clearTargetUri = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.MoreMenu.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MoreMenu.prototype.hasTargetUri = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.TopBar.oneofGroups_ = [[101,102,103,104]];

/**
 * @enum {number}
 */
proto.api.TopBar.BadgeCase = {
  BADGE_NOT_SET: 0,
  MANNER_TEMPERATURE: 101,
  KARROT_SCORE: 102,
  BIZ_FOLLOWER: 103,
  VERIFIED: 104
};

/**
 * @return {proto.api.TopBar.BadgeCase}
 */
proto.api.TopBar.prototype.getBadgeCase = function() {
  return /** @type {proto.api.TopBar.BadgeCase} */(jspb.Message.computeOneofCase(this, proto.api.TopBar.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TopBar.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TopBar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TopBar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TopBar.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    titleTargetUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitleTargetUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mannerTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 101, 0.0),
    karrotScore: jspb.Message.getFieldWithDefault(msg, 102, 0),
    bizFollower: jspb.Message.getBooleanFieldWithDefault(msg, 103, false),
    verified: jspb.Message.getBooleanFieldWithDefault(msg, 104, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TopBar}
 */
proto.api.TopBar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TopBar;
  return proto.api.TopBar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TopBar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TopBar}
 */
proto.api.TopBar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleTargetUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitleTargetUri(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMannerTemperature(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKarrotScore(value);
      break;
    case 103:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBizFollower(value);
      break;
    case 104:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TopBar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TopBar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TopBar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TopBar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitleTargetUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 101));
  if (f != null) {
    writer.writeFloat(
      101,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 102));
  if (f != null) {
    writer.writeInt32(
      102,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 103));
  if (f != null) {
    writer.writeBool(
      103,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 104));
  if (f != null) {
    writer.writeBool(
      104,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.api.TopBar.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title_target_uri = 2;
 * @return {string}
 */
proto.api.TopBar.prototype.getTitleTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setTitleTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subtitle = 3;
 * @return {string}
 */
proto.api.TopBar.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subtitle_target_uri = 4;
 * @return {string}
 */
proto.api.TopBar.prototype.getSubtitleTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setSubtitleTargetUri = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.clearSubtitleTargetUri = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TopBar.prototype.hasSubtitleTargetUri = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float manner_temperature = 101;
 * @return {number}
 */
proto.api.TopBar.prototype.getMannerTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 101, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setMannerTemperature = function(value) {
  return jspb.Message.setOneofField(this, 101, proto.api.TopBar.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.clearMannerTemperature = function() {
  return jspb.Message.setOneofField(this, 101, proto.api.TopBar.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TopBar.prototype.hasMannerTemperature = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional int32 karrot_score = 102;
 * @return {number}
 */
proto.api.TopBar.prototype.getKarrotScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setKarrotScore = function(value) {
  return jspb.Message.setOneofField(this, 102, proto.api.TopBar.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.clearKarrotScore = function() {
  return jspb.Message.setOneofField(this, 102, proto.api.TopBar.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TopBar.prototype.hasKarrotScore = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional bool biz_follower = 103;
 * @return {boolean}
 */
proto.api.TopBar.prototype.getBizFollower = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 103, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setBizFollower = function(value) {
  return jspb.Message.setOneofField(this, 103, proto.api.TopBar.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.clearBizFollower = function() {
  return jspb.Message.setOneofField(this, 103, proto.api.TopBar.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TopBar.prototype.hasBizFollower = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional bool verified = 104;
 * @return {boolean}
 */
proto.api.TopBar.prototype.getVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 104, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.setVerified = function(value) {
  return jspb.Message.setOneofField(this, 104, proto.api.TopBar.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.TopBar} returns this
 */
proto.api.TopBar.prototype.clearVerified = function() {
  return jspb.Message.setOneofField(this, 104, proto.api.TopBar.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.TopBar.prototype.hasVerified = function() {
  return jspb.Message.getField(this, 104) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.ReactionTarget.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.api.ReactionTarget.ValueCase = {
  VALUE_NOT_SET: 0,
  MESSAGE_ID: 1
};

/**
 * @return {proto.api.ReactionTarget.ValueCase}
 */
proto.api.ReactionTarget.prototype.getValueCase = function() {
  return /** @type {proto.api.ReactionTarget.ValueCase} */(jspb.Message.computeOneofCase(this, proto.api.ReactionTarget.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ReactionTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ReactionTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ReactionTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReactionTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageId: (f = msg.getMessageId()) && proto.api.ReactionTarget.MessageID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ReactionTarget}
 */
proto.api.ReactionTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ReactionTarget;
  return proto.api.ReactionTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ReactionTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ReactionTarget}
 */
proto.api.ReactionTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ReactionTarget.MessageID;
      reader.readMessage(value,proto.api.ReactionTarget.MessageID.deserializeBinaryFromReader);
      msg.setMessageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ReactionTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ReactionTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ReactionTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReactionTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.ReactionTarget.MessageID.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ReactionTarget.MessageID.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ReactionTarget.MessageID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ReactionTarget.MessageID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReactionTarget.MessageID.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ReactionTarget.MessageID}
 */
proto.api.ReactionTarget.MessageID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ReactionTarget.MessageID;
  return proto.api.ReactionTarget.MessageID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ReactionTarget.MessageID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ReactionTarget.MessageID}
 */
proto.api.ReactionTarget.MessageID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ReactionTarget.MessageID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ReactionTarget.MessageID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ReactionTarget.MessageID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReactionTarget.MessageID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.ReactionTarget.MessageID.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReactionTarget.MessageID} returns this
 */
proto.api.ReactionTarget.MessageID.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.ReactionTarget.MessageID.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReactionTarget.MessageID} returns this
 */
proto.api.ReactionTarget.MessageID.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional MessageID message_id = 1;
 * @return {?proto.api.ReactionTarget.MessageID}
 */
proto.api.ReactionTarget.prototype.getMessageId = function() {
  return /** @type{?proto.api.ReactionTarget.MessageID} */ (
    jspb.Message.getWrapperField(this, proto.api.ReactionTarget.MessageID, 1));
};


/**
 * @param {?proto.api.ReactionTarget.MessageID|undefined} value
 * @return {!proto.api.ReactionTarget} returns this
*/
proto.api.ReactionTarget.prototype.setMessageId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.ReactionTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReactionTarget} returns this
 */
proto.api.ReactionTarget.prototype.clearMessageId = function() {
  return this.setMessageId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReactionTarget.prototype.hasMessageId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Emoji.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Emoji.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Emoji} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Emoji.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iconUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Emoji}
 */
proto.api.Emoji.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Emoji;
  return proto.api.Emoji.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Emoji} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Emoji}
 */
proto.api.Emoji.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Emoji.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Emoji.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Emoji} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Emoji.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIconUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Emoji.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Emoji} returns this
 */
proto.api.Emoji.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon_url = 2;
 * @return {string}
 */
proto.api.Emoji.prototype.getIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Emoji} returns this
 */
proto.api.Emoji.prototype.setIconUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ReactionSummary.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ReactionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ReactionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ReactionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReactionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    emoji: (f = msg.getEmoji()) && proto.api.Emoji.toObject(includeInstance, f),
    previewUsersList: jspb.Message.toObjectList(msg.getPreviewUsersList(),
    proto.api.User.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hasReacted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ReactionSummary}
 */
proto.api.ReactionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ReactionSummary;
  return proto.api.ReactionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ReactionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ReactionSummary}
 */
proto.api.ReactionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Emoji;
      reader.readMessage(value,proto.api.Emoji.deserializeBinaryFromReader);
      msg.setEmoji(value);
      break;
    case 2:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.addPreviewUsers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasReacted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ReactionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ReactionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ReactionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReactionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmoji();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Emoji.serializeBinaryToWriter
    );
  }
  f = message.getPreviewUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getHasReacted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional Emoji emoji = 1;
 * @return {?proto.api.Emoji}
 */
proto.api.ReactionSummary.prototype.getEmoji = function() {
  return /** @type{?proto.api.Emoji} */ (
    jspb.Message.getWrapperField(this, proto.api.Emoji, 1));
};


/**
 * @param {?proto.api.Emoji|undefined} value
 * @return {!proto.api.ReactionSummary} returns this
*/
proto.api.ReactionSummary.prototype.setEmoji = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReactionSummary} returns this
 */
proto.api.ReactionSummary.prototype.clearEmoji = function() {
  return this.setEmoji(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReactionSummary.prototype.hasEmoji = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User preview_users = 2;
 * @return {!Array<!proto.api.User>}
 */
proto.api.ReactionSummary.prototype.getPreviewUsersList = function() {
  return /** @type{!Array<!proto.api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.User, 2));
};


/**
 * @param {!Array<!proto.api.User>} value
 * @return {!proto.api.ReactionSummary} returns this
*/
proto.api.ReactionSummary.prototype.setPreviewUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.User}
 */
proto.api.ReactionSummary.prototype.addPreviewUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ReactionSummary} returns this
 */
proto.api.ReactionSummary.prototype.clearPreviewUsersList = function() {
  return this.setPreviewUsersList([]);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.api.ReactionSummary.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReactionSummary} returns this
 */
proto.api.ReactionSummary.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool has_reacted = 4;
 * @return {boolean}
 */
proto.api.ReactionSummary.prototype.getHasReacted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ReactionSummary} returns this
 */
proto.api.ReactionSummary.prototype.setHasReacted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Reaction.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Reaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Reaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Reaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    emoji: (f = msg.getEmoji()) && proto.api.Emoji.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.api.User.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && proto.api.ReactionTarget.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Reaction}
 */
proto.api.Reaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Reaction;
  return proto.api.Reaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Reaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Reaction}
 */
proto.api.Reaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Emoji;
      reader.readMessage(value,proto.api.Emoji.deserializeBinaryFromReader);
      msg.setEmoji(value);
      break;
    case 2:
      var value = new proto.api.User;
      reader.readMessage(value,proto.api.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto.api.ReactionTarget;
      reader.readMessage(value,proto.api.ReactionTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Reaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Reaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Reaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Reaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmoji();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Emoji.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.User.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ReactionTarget.serializeBinaryToWriter
    );
  }
};


/**
 * optional Emoji emoji = 1;
 * @return {?proto.api.Emoji}
 */
proto.api.Reaction.prototype.getEmoji = function() {
  return /** @type{?proto.api.Emoji} */ (
    jspb.Message.getWrapperField(this, proto.api.Emoji, 1));
};


/**
 * @param {?proto.api.Emoji|undefined} value
 * @return {!proto.api.Reaction} returns this
*/
proto.api.Reaction.prototype.setEmoji = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Reaction} returns this
 */
proto.api.Reaction.prototype.clearEmoji = function() {
  return this.setEmoji(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Reaction.prototype.hasEmoji = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.api.User}
 */
proto.api.Reaction.prototype.getUser = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, proto.api.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.Reaction} returns this
*/
proto.api.Reaction.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Reaction} returns this
 */
proto.api.Reaction.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Reaction.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReactionTarget target = 3;
 * @return {?proto.api.ReactionTarget}
 */
proto.api.Reaction.prototype.getTarget = function() {
  return /** @type{?proto.api.ReactionTarget} */ (
    jspb.Message.getWrapperField(this, proto.api.ReactionTarget, 3));
};


/**
 * @param {?proto.api.ReactionTarget|undefined} value
 * @return {!proto.api.Reaction} returns this
*/
proto.api.Reaction.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Reaction} returns this
 */
proto.api.Reaction.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Reaction.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Filter}
 */
proto.api.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Filter;
  return proto.api.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Filter}
 */
proto.api.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.FilterID} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional FilterID ID = 1;
 * @return {!proto.api.FilterID}
 */
proto.api.Filter.prototype.getId = function() {
  return /** @type {!proto.api.FilterID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.FilterID} value
 * @return {!proto.api.Filter} returns this
 */
proto.api.Filter.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Filter} returns this
 */
proto.api.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchResultChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchResultChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchResultChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchResultChannel}
 */
proto.api.SearchResultChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchResultChannel;
  return proto.api.SearchResultChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchResultChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchResultChannel}
 */
proto.api.SearchResultChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchResultChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchResultChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchResultChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.SearchResultChannel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultChannel} returns this
 */
proto.api.SearchResultChannel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.SearchResultChannel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultChannel} returns this
 */
proto.api.SearchResultChannel.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.SearchResultChannel} returns this
 */
proto.api.SearchResultChannel.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultChannel.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchResultUser.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchResultUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchResultUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nickname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    badgeImageUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchResultUser}
 */
proto.api.SearchResultUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchResultUser;
  return proto.api.SearchResultUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchResultUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchResultUser}
 */
proto.api.SearchResultUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.User.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchResultUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchResultUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchResultUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.SearchResultUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SearchResultUser} returns this
 */
proto.api.SearchResultUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional User.Type type = 2;
 * @return {!proto.api.User.Type}
 */
proto.api.SearchResultUser.prototype.getType = function() {
  return /** @type {!proto.api.User.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.User.Type} value
 * @return {!proto.api.SearchResultUser} returns this
 */
proto.api.SearchResultUser.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string nickname = 3;
 * @return {string}
 */
proto.api.SearchResultUser.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultUser} returns this
 */
proto.api.SearchResultUser.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_image_url = 4;
 * @return {string}
 */
proto.api.SearchResultUser.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultUser} returns this
 */
proto.api.SearchResultUser.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string badge_image_url = 5;
 * @return {string}
 */
proto.api.SearchResultUser.prototype.getBadgeImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultUser} returns this
 */
proto.api.SearchResultUser.prototype.setBadgeImageUrl = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.SearchResultUser} returns this
 */
proto.api.SearchResultUser.prototype.clearBadgeImageUrl = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultUser.prototype.hasBadgeImageUrl = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchResultReactionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchResultReactionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchResultReactionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultReactionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    emoji: (f = msg.getEmoji()) && proto.api.Emoji.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasReacted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchResultReactionSummary}
 */
proto.api.SearchResultReactionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchResultReactionSummary;
  return proto.api.SearchResultReactionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchResultReactionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchResultReactionSummary}
 */
proto.api.SearchResultReactionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Emoji;
      reader.readMessage(value,proto.api.Emoji.deserializeBinaryFromReader);
      msg.setEmoji(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasReacted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchResultReactionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchResultReactionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchResultReactionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultReactionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmoji();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Emoji.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHasReacted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Emoji emoji = 1;
 * @return {?proto.api.Emoji}
 */
proto.api.SearchResultReactionSummary.prototype.getEmoji = function() {
  return /** @type{?proto.api.Emoji} */ (
    jspb.Message.getWrapperField(this, proto.api.Emoji, 1));
};


/**
 * @param {?proto.api.Emoji|undefined} value
 * @return {!proto.api.SearchResultReactionSummary} returns this
*/
proto.api.SearchResultReactionSummary.prototype.setEmoji = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SearchResultReactionSummary} returns this
 */
proto.api.SearchResultReactionSummary.prototype.clearEmoji = function() {
  return this.setEmoji(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultReactionSummary.prototype.hasEmoji = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.api.SearchResultReactionSummary.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SearchResultReactionSummary} returns this
 */
proto.api.SearchResultReactionSummary.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_reacted = 3;
 * @return {boolean}
 */
proto.api.SearchResultReactionSummary.prototype.getHasReacted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SearchResultReactionSummary} returns this
 */
proto.api.SearchResultReactionSummary.prototype.setHasReacted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.SearchResultMessage.repeatedFields_ = [2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchResultMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchResultMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchResultMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tokensList: jspb.Message.toObjectList(msg.getTokensList(),
    proto.api.SearchResultMessage.Token.toObject, includeInstance),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sender: (f = msg.getSender()) && proto.api.SearchResultUser.toObject(includeInstance, f),
    reactionsList: jspb.Message.toObjectList(msg.getReactionsList(),
    proto.api.SearchResultReactionSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchResultMessage}
 */
proto.api.SearchResultMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchResultMessage;
  return proto.api.SearchResultMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchResultMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchResultMessage}
 */
proto.api.SearchResultMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.SearchResultMessage.Token;
      reader.readMessage(value,proto.api.SearchResultMessage.Token.deserializeBinaryFromReader);
      msg.addTokens(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 4:
      var value = new proto.api.SearchResultUser;
      reader.readMessage(value,proto.api.SearchResultUser.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 5:
      var value = new proto.api.SearchResultReactionSummary;
      reader.readMessage(value,proto.api.SearchResultReactionSummary.deserializeBinaryFromReader);
      msg.addReactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchResultMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchResultMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchResultMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTokensList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.SearchResultMessage.Token.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.SearchResultUser.serializeBinaryToWriter
    );
  }
  f = message.getReactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.SearchResultReactionSummary.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.SearchResultMessage.Token.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.SearchResultMessage.Token.TypeCase = {
  TYPE_NOT_SET: 0,
  PLAIN_TEXT: 1,
  HIGHLIGHTED_TEXT: 2
};

/**
 * @return {proto.api.SearchResultMessage.Token.TypeCase}
 */
proto.api.SearchResultMessage.Token.prototype.getTypeCase = function() {
  return /** @type {proto.api.SearchResultMessage.Token.TypeCase} */(jspb.Message.computeOneofCase(this, proto.api.SearchResultMessage.Token.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchResultMessage.Token.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchResultMessage.Token.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchResultMessage.Token} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultMessage.Token.toObject = function(includeInstance, msg) {
  var f, obj = {
    plainText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    highlightedText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchResultMessage.Token}
 */
proto.api.SearchResultMessage.Token.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchResultMessage.Token;
  return proto.api.SearchResultMessage.Token.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchResultMessage.Token} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchResultMessage.Token}
 */
proto.api.SearchResultMessage.Token.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlainText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHighlightedText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchResultMessage.Token.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchResultMessage.Token.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchResultMessage.Token} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchResultMessage.Token.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string plain_text = 1;
 * @return {string}
 */
proto.api.SearchResultMessage.Token.prototype.getPlainText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultMessage.Token} returns this
 */
proto.api.SearchResultMessage.Token.prototype.setPlainText = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.SearchResultMessage.Token.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.SearchResultMessage.Token} returns this
 */
proto.api.SearchResultMessage.Token.prototype.clearPlainText = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.SearchResultMessage.Token.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultMessage.Token.prototype.hasPlainText = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string highlighted_text = 2;
 * @return {string}
 */
proto.api.SearchResultMessage.Token.prototype.getHighlightedText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchResultMessage.Token} returns this
 */
proto.api.SearchResultMessage.Token.prototype.setHighlightedText = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.api.SearchResultMessage.Token.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.SearchResultMessage.Token} returns this
 */
proto.api.SearchResultMessage.Token.prototype.clearHighlightedText = function() {
  return jspb.Message.setOneofField(this, 2, proto.api.SearchResultMessage.Token.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultMessage.Token.prototype.hasHighlightedText = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.SearchResultMessage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SearchResultMessage} returns this
 */
proto.api.SearchResultMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Token tokens = 2;
 * @return {!Array<!proto.api.SearchResultMessage.Token>}
 */
proto.api.SearchResultMessage.prototype.getTokensList = function() {
  return /** @type{!Array<!proto.api.SearchResultMessage.Token>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.SearchResultMessage.Token, 2));
};


/**
 * @param {!Array<!proto.api.SearchResultMessage.Token>} value
 * @return {!proto.api.SearchResultMessage} returns this
*/
proto.api.SearchResultMessage.prototype.setTokensList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.SearchResultMessage.Token=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.SearchResultMessage.Token}
 */
proto.api.SearchResultMessage.prototype.addTokens = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.SearchResultMessage.Token, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.SearchResultMessage} returns this
 */
proto.api.SearchResultMessage.prototype.clearTokensList = function() {
  return this.setTokensList([]);
};


/**
 * optional google.protobuf.Timestamp create_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.SearchResultMessage.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.SearchResultMessage} returns this
*/
proto.api.SearchResultMessage.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SearchResultMessage} returns this
 */
proto.api.SearchResultMessage.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultMessage.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SearchResultUser sender = 4;
 * @return {?proto.api.SearchResultUser}
 */
proto.api.SearchResultMessage.prototype.getSender = function() {
  return /** @type{?proto.api.SearchResultUser} */ (
    jspb.Message.getWrapperField(this, proto.api.SearchResultUser, 4));
};


/**
 * @param {?proto.api.SearchResultUser|undefined} value
 * @return {!proto.api.SearchResultMessage} returns this
*/
proto.api.SearchResultMessage.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SearchResultMessage} returns this
 */
proto.api.SearchResultMessage.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchResultMessage.prototype.hasSender = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated SearchResultReactionSummary reactions = 5;
 * @return {!Array<!proto.api.SearchResultReactionSummary>}
 */
proto.api.SearchResultMessage.prototype.getReactionsList = function() {
  return /** @type{!Array<!proto.api.SearchResultReactionSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.SearchResultReactionSummary, 5));
};


/**
 * @param {!Array<!proto.api.SearchResultReactionSummary>} value
 * @return {!proto.api.SearchResultMessage} returns this
*/
proto.api.SearchResultMessage.prototype.setReactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.SearchResultReactionSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.SearchResultReactionSummary}
 */
proto.api.SearchResultMessage.prototype.addReactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.SearchResultReactionSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.SearchResultMessage} returns this
 */
proto.api.SearchResultMessage.prototype.clearReactionsList = function() {
  return this.setReactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchMessageHit.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchMessageHit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchMessageHit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchMessageHit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channel: (f = msg.getChannel()) && proto.api.SearchResultChannel.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && proto.api.SearchResultMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchMessageHit}
 */
proto.api.SearchMessageHit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchMessageHit;
  return proto.api.SearchMessageHit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchMessageHit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchMessageHit}
 */
proto.api.SearchMessageHit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.SearchResultChannel;
      reader.readMessage(value,proto.api.SearchResultChannel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 3:
      var value = new proto.api.SearchResultMessage;
      reader.readMessage(value,proto.api.SearchResultMessage.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchMessageHit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchMessageHit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchMessageHit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchMessageHit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.SearchResultChannel.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.SearchResultMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.SearchMessageHit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchMessageHit} returns this
 */
proto.api.SearchMessageHit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SearchResultChannel channel = 2;
 * @return {?proto.api.SearchResultChannel}
 */
proto.api.SearchMessageHit.prototype.getChannel = function() {
  return /** @type{?proto.api.SearchResultChannel} */ (
    jspb.Message.getWrapperField(this, proto.api.SearchResultChannel, 2));
};


/**
 * @param {?proto.api.SearchResultChannel|undefined} value
 * @return {!proto.api.SearchMessageHit} returns this
*/
proto.api.SearchMessageHit.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SearchMessageHit} returns this
 */
proto.api.SearchMessageHit.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchMessageHit.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SearchResultMessage message = 3;
 * @return {?proto.api.SearchResultMessage}
 */
proto.api.SearchMessageHit.prototype.getMessage = function() {
  return /** @type{?proto.api.SearchResultMessage} */ (
    jspb.Message.getWrapperField(this, proto.api.SearchResultMessage, 3));
};


/**
 * @param {?proto.api.SearchResultMessage|undefined} value
 * @return {!proto.api.SearchMessageHit} returns this
*/
proto.api.SearchMessageHit.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SearchMessageHit} returns this
 */
proto.api.SearchMessageHit.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchMessageHit.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.SearchPagingKey.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.api.SearchPagingKey.ValueCase = {
  VALUE_NOT_SET: 0,
  MESSAGE_ID: 1
};

/**
 * @return {proto.api.SearchPagingKey.ValueCase}
 */
proto.api.SearchPagingKey.prototype.getValueCase = function() {
  return /** @type {proto.api.SearchPagingKey.ValueCase} */(jspb.Message.computeOneofCase(this, proto.api.SearchPagingKey.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchPagingKey.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchPagingKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchPagingKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchPagingKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchPagingKey}
 */
proto.api.SearchPagingKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchPagingKey;
  return proto.api.SearchPagingKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchPagingKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchPagingKey}
 */
proto.api.SearchPagingKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchPagingKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchPagingKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchPagingKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchPagingKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_id = 1;
 * @return {number}
 */
proto.api.SearchPagingKey.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SearchPagingKey} returns this
 */
proto.api.SearchPagingKey.prototype.setMessageId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.SearchPagingKey.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.SearchPagingKey} returns this
 */
proto.api.SearchPagingKey.prototype.clearMessageId = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.SearchPagingKey.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchPagingKey.prototype.hasMessageId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.api.TemplateType = {
  TEMPLATE_TYPE_UNSPECIFIED: 0,
  TEMPLATE_TYPE_ARTICLE: 1,
  TEMPLATE_TYPE_GENERIC: 2,
  TEMPLATE_TYPE_LOCATION: 3,
  TEMPLATE_TYPE_ICON: 4,
  TEMPLATE_TYPE_INLINE: 5,
  TEMPLATE_TYPE_BLOCK: 6,
  TEMPLATE_TYPE_VIDEO: 7
};

/**
 * @enum {number}
 */
proto.api.ImageAspectRatio = {
  IMAGE_ASPECT_RATIO_UNSPECIFIED: 0,
  IMAGE_ASPECT_RATIO_HORIZONTAL: 1,
  IMAGE_ASPECT_RATIO_SQUARE: 2,
  IMAGE_ASPECT_RATIO_HORIZONTAL_WIDE: 3
};

/**
 * @enum {number}
 */
proto.api.Visible = {
  VISIBLE_UNSPECIFIED: 0,
  VISIBLE_SENDER: 1,
  VISIBLE_RECEIVER: 2,
  VISIBLE_ALL: 3
};

/**
 * @enum {number}
 */
proto.api.NativeAction = {
  TYPE_UNSPECIFIED: 0,
  PICTURE: 1,
  CAMERA: 2,
  SHARE_LOCATION: 3,
  PROMISE: 4,
  FAVORITE_PHRASES: 5,
  SAFE_NUMBER: 6,
  VOIP: 7,
  SEND_MONEY: 8
};

/**
 * @enum {number}
 */
proto.api.FilterID = {
  FILTER_ID_UNSPECIFIED: 0,
  FILTER_ID_ALL: 0,
  FILTER_ID_FLEAMARKET: 1,
  FILTER_ID_COMMUNITY: 2,
  FILTER_ID_JOBS: 3,
  FILTER_ID_CARS: 4,
  FILTER_ID_REALTY: 5,
  FILTER_ID_BUY: 20,
  FILTER_ID_SELL: 21,
  FILTER_ID_UNREAD_COUNT: 40
};

/**
 * @enum {number}
 */
proto.api.Badge = {
  BADGE_UNSPECIFIED: 0,
  BADGE_PROMISED: 1,
  BADGE_RESERVED: 2,
  BADGE_ESCROW: 3
};

/**
 * @enum {number}
 */
proto.api.SearchSortOption = {
  SEARCH_SORT_UNSPECIFIED: 0,
  SEARCH_SORT_MESSAGE_ID_DESCENDING: 1,
  SEARCH_SORT_MESSAGE_ID_ASCENDING: 2
};

/**
 * @enum {number}
 */
proto.api.SearchAnalyzerType = {
  SEARCH_ANALYZER_TYPE_UNSPECIFIED: 0,
  SEARCH_ANALYZER_TYPE_NGRAM: 1,
  SEARCH_ANALYZER_TYPE_KARROT: 2
};

goog.object.extend(exports, proto.api);
