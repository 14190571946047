import { IS_PROD } from 'constants/config'

const logLevel = {
  log: !IS_PROD ? console.log.bind(window.console) : function(){},
  info: !IS_PROD ? console.info.bind(window.console) : function(){},
  warn: !IS_PROD ? console.warn.bind(window.console) : function(){},
  error: !IS_PROD ? console.error.bind(window.console) : function(){},
}

export { logLevel }
