import React, { useState, useCallback, useEffect } from 'react'

import ChatReplyList from './ChatReplyList'
import { ChatReplyPopup } from './chatReplyStyle'
import ChatReplySubmit from './ChatReplySubmit'
import { replySchema } from 'types/PersistentTypes'
import ChatReplyIntro from './ChatReplyIntro'
import { AUTO_REPLY_INTRO, getLocalStorage } from 'utils/localStorage'
import ChatReplyUpdate from './ChatReplyUpdate'
import { getReplyMeta } from 'utils/protobuf/query'
import { socketStore } from 'utils/socket/socketStore'
import Modal from 'components/common/Modal'
import ReplyClosePopup from './ReplyClosePopup'

interface ChatReplyMissionProps {
  setShowReply: React.Dispatch<React.SetStateAction<boolean>>
}
const ChatReplyMission = ({ setShowReply }: ChatReplyMissionProps) => {
  const [replyRouter, setReplyRouter] = useState<replySchema>(getLocalStorage(AUTO_REPLY_INTRO) ? 'list' : 'intro')
  const handleRouter = (schema: replySchema) => {
    setReplyRouter(schema)
  }
  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const modalClose = () => setModalObj((origin) => ({ ...origin, isShow: false }))
  const closeAutoReply = useCallback(() => {
    if (replyRouter === 'submit' || replyRouter === 'update') {
      setModalObj({
        isShow: true,
        content: <ReplyClosePopup modalClose={modalClose} setShowReply={setShowReply} />,
      })
    } else {
      setShowReply(false)
    }
  }, [setShowReply, replyRouter])
  useEffect(() => {
    window.addEventListener('click', closeAutoReply)
    return () => window.removeEventListener('click', closeAutoReply)
  }, [closeAutoReply])

  const socket = socketStore.chatSocket
  const [selectedReply, setSelectedReply] = useState('')
  useEffect(() => {
    if (socket?.readyState === 1) {
      const message = getReplyMeta().serializeBinary()
      socket.send(message)
    }
  }, [socket])
  return (
    <ChatReplyPopup onClick={(e) => e.stopPropagation()}>
      {replyRouter === 'list' ? (
        <ChatReplyList handleRouter={handleRouter} setSelectedReply={setSelectedReply} />
      ) : replyRouter === 'submit' ? (
        <ChatReplySubmit handleRouter={handleRouter} modalClose={modalClose} setModalObj={setModalObj} />
      ) : replyRouter === 'update' ? (
        <ChatReplyUpdate
          handleRouter={handleRouter}
          selectedReply={selectedReply}
          modalClose={modalClose}
          setModalObj={setModalObj}
        />
      ) : replyRouter === 'intro' ? (
        <ChatReplyIntro handleRouter={handleRouter} />
      ) : null}
      <Modal isVisible={modalObj.isShow} content={modalObj.content} onClose={modalClose} />
    </ChatReplyPopup>
  )
}

export default ChatReplyMission
