import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { ChannelTypes } from 'types/ChatTypes'
import { StoreState } from 'store/ducks'
import { ChannelPreviewStyle, GroupProfileImg } from './ChannelListStyle'
import ChannelMessage from './ChannelMessage'
import {
  PromiseCheck,
  ReserveCheck,
  genChannelPrefix,
  ProfileParamConverter,
  ArticleParamConverter,
} from 'utils/snippet'
import { muteStore } from 'utils/cacheStore'
interface ChannelListPreviewProps {
  chatItem: ChannelTypes
  linkPath: string
}
const ChannelListPreview = ({ chatItem, linkPath }: ChannelListPreviewProps) => {
  const lastMessage = chatItem?.lastMessage?.content || ''
  const lastChatTime = (chatItem?.lastMessageTime?.seconds || 0) * 1000
  const [profileData, setProfileData] = useState({
    image: '',
    nickname: '',
    displayRegion: '',
  })
  useEffect(() => {
    setProfileData(profileParser(chatItem))
  }, [chatItem])
  const selectChannelId = useSelector((state: StoreState) => state.chatStore.selectChannelId)
  const lastMessageId = chatItem.lastMessage?.id || 0
  const unreadCount = chatItem.unreadCount
  const destroyed = chatItem.receiver?.status === 'destroyed' || chatItem.isDisabled
  const channelUrl = chatItem.id
    ? `/${linkPath}/${genChannelPrefix(chatItem.id.length * 7 || 0)}${window.btoa(chatItem.id)}`
    : ''
  const isMute = muteStore.dataList[chatItem.id] ? true : false
  useSelector((state: StoreState) => state.utilStore.muteChange)
  
  const articleImage = chatItem.article?.firstImageUrl
  if (chatItem.type === 2) {
    // 그룹채팅
    const channelTitle = chatItem.title || ''
    const firstImage = ProfileParamConverter(chatItem?.membersList[0]?.profileImage)
    const secondImage = ProfileParamConverter(chatItem?.membersList[1]?.profileImage)
    return (
      <ChannelPreviewStyle
        to={channelUrl}
        className={`${selectChannelId === chatItem.id ? 'selected' : ''} ${destroyed ? 'destroyed' : ''} ${chatItem.isFavorite ? 'favorite' : ''}`}
      >
        <GroupProfileImg>
          <img className="profile-image first-image" src={firstImage} alt="profile-first" />
          <img className="profile-image second-image" src={secondImage} alt="profile-second" />
        </GroupProfileImg>
        <ChannelMessage
          lastMessageId={lastMessageId}
          nickname={channelTitle}
          memberCount={chatItem.membersList?.length}
          lastMessage={lastMessage}
          lastChatTime={lastChatTime}
          isMute={isMute}
          isFavorite={chatItem.isFavorite}
        />
        {unreadCount > 0 ? <div className="unread-count-wrapper">{unreadCount}</div> : null}
      </ChannelPreviewStyle>
    )
  } else {
    // 단일 채팅
    const channelTitle =
      destroyed && profileData.nickname.indexOf('탈퇴') === -1
        ? `${profileData.nickname}(탈퇴)`
        : profileData.nickname || ''
    const isPromise = PromiseCheck(chatItem)
    const isReserve = ReserveCheck(chatItem, isPromise)
    const block = (chatItem.receiver?.userFlaggedByMeList || []).length > 0
    const isVerified = Boolean(chatItem.receiver?.isVerified)
    return (
      <ChannelPreviewStyle
        to={channelUrl}
        className={`${selectChannelId === chatItem.id ? 'selected' : ''} ${destroyed || block ? 'destroyed' : ''} ${
          chatItem.isFavorite ? 'favorite' : ''
        }`}
      >
        <div className="profile-wrapper">
          <img className="profile-image" src={profileData.image || ''} alt="profile" />
        </div>
        <ChannelMessage
          lastMessageId={lastMessageId}
          nickname={channelTitle}
          lastMessage={lastMessage}
          displayRegion={profileData.displayRegion}
          lastChatTime={lastChatTime}
          isPromise={isPromise}
          isReserve={isReserve}
          isMute={isMute}
          isFavorite={chatItem.isFavorite}
          isVerified={isVerified}
        />
        {unreadCount > 0 ? <div className="unread-count-wrapper">{unreadCount}</div> : null}
        {articleImage ? (
          <img src={ArticleParamConverter(articleImage)} className="preview-image" alt={chatItem.article?.title} />
        ) : null}
      </ChannelPreviewStyle>
    )
  }
}

function profileParser(chatItem: ChannelTypes): { image: string; nickname: string; displayRegion: string } {
  const { receiver, article, resourceType } = chatItem
  // 탈퇴한 사용자는 resourceType은 남아 있지만, article 이 없음
  if (article && receiver?.type === 2) {
    if (resourceType === 'SMBA') {
      return {
        image: ProfileParamConverter(article?.smb?.firstImageUrl || ''),
        nickname: article?.smb?.name || '',
        displayRegion: article?.smb?.categoryText || '',
      }
    } else {
      // FLEA(벼룩장터), BUSI(동네홍보), QNAA(우리동네질문), STOR(동네생활), POPA(풍성게시글), BIZA(비즈프로필), RLTY(부동산), MEET(동네모임)
      // 리소스 타입은 다음을 참조 https://github.com/daangn/rocket-chat/blob/main/internal/domain/resource.go
      return {
        image: ProfileParamConverter(article?.firstImageUrl || ''),
        nickname: article?.title || '',
        displayRegion: '',
      }
    }
  } else {
    return {
      image: ProfileParamConverter(receiver?.profileImage || ''),
      nickname: receiver?.nickname || '',
      displayRegion: receiver?.displayRegionName || '',
    }
  }
}

export default ChannelListPreview
