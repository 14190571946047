import { AuthAPI } from 'utils/httpAPI'
import { store } from 'store'
import {
  getRefToken,
  jwtDecoder,
  getDecodeAccessToken,
  saveAccessToken,
  saveRefreshToken,
  deleteToken,
} from 'utils/auth/tokenUtils'
import { getSupportRegion } from 'utils/snippet'
import { checkExpireAction } from 'utils/auth/sessionManager'

let tokenUpdateTimer: NodeJS.Timeout
function tokenUpdateInterval() {
  if (tokenUpdateTimer) {
    clearInterval(tokenUpdateTimer)
  }
  tokenUpdateTimer = setInterval(() => {
    const refToken = getRefToken()
    const refDecodeToken = jwtDecoder(refToken)
    const accDecodeToken = getDecodeAccessToken()
    const currentSec = Math.floor(new Date().getTime() / 1000)
    if (accDecodeToken && accDecodeToken.exp > currentSec + 60) {
      return
    } else if (refToken && refDecodeToken && refDecodeToken.exp > currentSec) {
      // token refresh
      wrapperTokenRefresh(refToken)
    } else {
      // 두 토큰이 둘다 없을 때 == 당근웹채팅은 껐지만 브라우저는 끄지 않고 1주일 지난 상황
      // login page redirect
      deleteToken()
      // window.location.reload()
    }
  }, 1000 * 60)
}

let tokenController = true
async function wrapperTokenRefresh(encodeRefToken: string) {
  if (tokenController) {
    tokenController = false
    if (checkExpireAction()) {
      deleteToken()
      window.location.href = '/login'
    }
    const allowRegion = getSupportRegion()
    AuthAPI.tokenRefresh(encodeRefToken, allowRegion)
      .then(({ data }) => {
        const token = data.access_token
        const refToken = data.refresh_token
        if (token && refToken) {
          saveAccessToken(token)
          saveRefreshToken(refToken)
          store.dispatch({ type: 'user/setAccessToken', payload: token })
        } else {
          deleteToken()
          if (window.location.pathname === '/login') return
          else {
            window.location.href = '/login'
          }
        }
        tokenController = true
      })
      .catch(() => {
        tokenController = true
      })
  }
}

export { tokenUpdateInterval, wrapperTokenRefresh }
