export const AddFile = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="3" />
    <path
      d="M6 9C7.10457 9 8 8.10457 8 7C8 5.89543 7.10457 5 6 5C4.89543 5 4 5.89543 4 7C4 8.10457 4.89543 9 6 9Z"
      fill="white"
    />
    <path d="M3 16L6.5 12L10 16" fill="white" />
    <path d="M7 16L12 10L17 16" fill="white" />
  </svg>
)

export const MacroIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2C0.895431 0 0 0.895431 0 2V18ZM5 5.1C4.50294 5.1 4.1 5.50294 4.1 6C4.1 6.49706 4.50294 6.9 5 6.9H15C15.4971 6.9 15.9 6.49706 15.9 6C15.9 5.50294 15.4971 5.1 15 5.1H5ZM4.1 10C4.1 9.50294 4.50294 9.1 5 9.1H15C15.4971 9.1 15.9 9.50294 15.9 10C15.9 10.4971 15.4971 10.9 15 10.9H5C4.50294 10.9 4.1 10.4971 4.1 10ZM5 13.1C4.50294 13.1 4.1 13.5029 4.1 14C4.1 14.4971 4.50294 14.9 5 14.9H15C15.4971 14.9 15.9 14.4971 15.9 14C15.9 13.5029 15.4971 13.1 15 13.1H5Z"
    />
  </svg>
)

export const StickerIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM7.55556 8.2222C7.55556 9.08131 6.85912 9.77776 6.00001 9.77776C5.1409 9.77776 4.44445 9.08131 4.44445 8.2222C4.44445 7.36309 5.1409 6.66665 6.00001 6.66665C6.85912 6.66665 7.55556 7.36309 7.55556 8.2222ZM14.0002 9.77776C14.8593 9.77776 15.5558 9.08131 15.5558 8.2222C15.5558 7.36309 14.8593 6.66665 14.0002 6.66665C13.1411 6.66665 12.4447 7.36309 12.4447 8.2222C12.4447 9.08131 13.1411 9.77776 14.0002 9.77776ZM6.29774 11.9236C6.13284 11.5137 5.66687 11.3151 5.25697 11.48C4.84706 11.6449 4.64845 12.1109 4.81336 12.5208C5.28799 13.7006 6.18929 14.5207 7.14118 15.0388C8.08738 15.5537 9.13514 15.8 9.99999 15.8C11.8597 15.8 14.2492 14.8376 15.186 12.5223C15.3518 12.1127 15.1541 11.6463 14.7445 11.4806C14.3349 11.3149 13.8686 11.5126 13.7028 11.9221C13.0841 13.4512 11.4292 14.2 9.99999 14.2C9.40929 14.2 8.62371 14.024 7.90603 13.6334C7.19403 13.2459 6.60088 12.6771 6.29774 11.9236Z"
    />
  </svg>
)

export const UnreadIconUnSelect = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4687 6.37459C13.6756 6.11573 13.6334 5.73818 13.3746 5.5313C13.1157 5.32442 12.7382 5.36655 12.5313 5.62541L7.72681 11.637L5.39354 9.60959C5.14341 9.39225 4.76444 9.41883 4.5471 9.66896C4.32975 9.91909 4.35633 10.2981 4.60646 10.5154L7.41166 12.9529C7.53501 13.0601 7.69673 13.1123 7.85947 13.0975C8.02221 13.0828 8.17188 13.0022 8.2739 12.8746L13.4687 6.37459Z"
      fill="#ADB1BA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM16.8 9C16.8 13.3078 13.3078 16.8 9 16.8C4.69218 16.8 1.2 13.3078 1.2 9C1.2 4.69218 4.69218 1.2 9 1.2C13.3078 1.2 16.8 4.69218 16.8 9Z"
      fill="#ADB1BA"
    />
  </svg>
)

export const UnreadIconSelect = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.4687 6.37457C13.6756 6.11572 13.6334 5.73816 13.3746 5.53128C13.1157 5.3244 12.7382 5.36654 12.5313 5.6254L7.72681 11.637L5.39354 9.60958C5.14341 9.39223 4.76444 9.41881 4.5471 9.66894C4.32975 9.91908 4.35633 10.298 4.60646 10.5154L7.41166 12.9529C7.53501 13.0601 7.69673 13.1123 7.85947 13.0975C8.02221 13.0827 8.17188 13.0022 8.2739 12.8746L13.4687 6.37457Z"
      fill="#FF7E36"
    />
  </svg>
)

export const UpArrowIcon = () => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 6L6 1L1 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const DownArrowIcon = ({ fill }: { fill?: string }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L6 6L11 1"
      stroke={fill || 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const EmptyChannelTalkIcon = () => (
  <svg width="96" height="81" viewBox="0 0 96 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.0004 0C15.0185 0 0 13.0729 0 29.6567C0 40.358 6.27606 49.642 15.5279 54.8364L13.8397 64.5305C13.7353 65.1299 13.928 65.7446 14.3535 66.1751L14.3573 66.179L14.3724 66.1939C14.3853 66.2066 14.4061 66.2267 14.4326 66.2506C14.4869 66.2995 14.568 66.3668 14.6744 66.435C14.9082 66.5849 15.1569 66.6709 15.3962 66.7073C15.7666 66.7637 16.0661 66.6901 16.1358 66.673L16.1413 66.6716C16.3174 66.6287 16.5003 66.558 16.6232 66.51C16.9302 66.3901 17.5014 66.1524 18.5787 65.6955C20.7218 64.7866 24.9636 62.9696 33.3799 59.3641C51.1931 59.1817 66.0008 46.1763 66.0008 29.7093C66.0008 13.1297 50.987 0 33.0004 0Z"
      fill="#DCDEE3"
    />
    <path
      d="M72.2312 29.4385C72.2312 48.2002 56.7085 62.679 37.8858 64.8408C44.0168 70.067 52.3818 73.2792 61.479 73.3633C70.2216 76.9749 74.6257 78.7941 76.8498 79.7036C77.9674 80.1606 78.5583 80.3977 78.8749 80.517C79.0036 80.5654 79.1863 80.6333 79.3599 80.6741L79.3652 80.6754C79.4339 80.6917 79.7238 80.7604 80.0821 80.7078C80.313 80.6739 80.5564 80.5935 80.7883 80.4501C80.8943 80.3846 80.9756 80.3195 81.0307 80.2717C81.0459 80.2585 81.0593 80.2464 81.0704 80.2362C81.0789 80.2284 81.0861 80.2217 81.0918 80.2163L81.1071 80.2017L81.111 80.1978C81.5557 79.764 81.7577 79.1325 81.6467 78.5179L79.9012 68.8524C89.4699 63.674 96 54.3943 96 43.6557C96 29.1206 84.0984 17.353 68.7254 14.6059C70.9682 19.0808 72.2312 24.0881 72.2312 29.4385Z"
      fill="#DCDEE3"
    />
  </svg>
)

export const InfoIcon = ({ size = 16 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 4.5C8.27614 4.5 8.5 4.72386 8.5 5V8.2C8.5 8.47614 8.27614 8.7 8 8.7C7.72386 8.7 7.5 8.47614 7.5 8.2V5C7.5 4.72386 7.72386 4.5 8 4.5Z" />
    <path d="M8.8002 10.8C8.8002 11.2418 8.44202 11.6 8.0002 11.6C7.55837 11.6 7.2002 11.2418 7.2002 10.8C7.2002 10.3582 7.55837 10 8.0002 10C8.44202 10 8.8002 10.3582 8.8002 10.8Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
    />
  </svg>
)

export const MoreButton = () => (
  <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0002 3.19998C2.7152 3.19998 3.3002 2.61498 3.3002 1.89998C3.3002 1.18498 2.7152 0.599976 2.0002 0.599976C1.2852 0.599976 0.700195 1.18498 0.700195 1.89998C0.700195 2.61498 1.2852 3.19998 2.0002 3.19998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0002 6.70001C1.2852 6.70001 0.700195 7.28501 0.700195 8.00001C0.700195 8.71501 1.2852 9.30001 2.0002 9.30001C2.7152 9.30001 3.3002 8.71501 3.3002 8.00001C3.3002 7.28501 2.7152 6.70001 2.0002 6.70001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0002 12.8C1.2852 12.8 0.700195 13.385 0.700195 14.1C0.700195 14.815 1.2852 15.4 2.0002 15.4C2.7152 15.4 3.3002 14.815 3.3002 14.1C3.3002 13.385 2.7152 12.8 2.0002 12.8Z"
      fill="currentColor"
    />
  </svg>
)

export const DownArrow = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.1998 12.0684L1.56588 7.43448C1.25346 7.12207 0.746929 7.12207 0.434509 7.43448C0.122091 7.7469 0.122091 8.25344 0.43451 8.56586L6.41894 14.5503C6.56475 14.7042 6.77107 14.8002 6.9998 14.8002C7.06635 14.8002 7.13099 14.7921 7.1928 14.7768C7.32951 14.743 7.45904 14.6727 7.56588 14.5659L13.5659 8.56586C13.8783 8.25344 13.8783 7.7469 13.5659 7.43448C13.2535 7.12207 12.7469 7.12207 12.4345 7.43449L7.7998 12.0692L7.79981 1.0002C7.79981 0.558368 7.44163 0.200195 6.9998 0.200195C6.55798 0.200195 6.1998 0.558368 6.1998 1.0002L6.1998 12.0684Z"
      fill="#212124"
    />
  </svg>
)

export const QuestionIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="8.5" stroke="#868B94" />
    <path
      d="M8.25586 11.0977C8.26367 10.6328 8.31641 10.2656 8.41406 9.99609C8.51172 9.72656 8.71094 9.42773 9.01172 9.09961L9.7793 8.30859C10.1074 7.9375 10.2715 7.53906 10.2715 7.11328C10.2715 6.70312 10.1641 6.38281 9.94922 6.15234C9.73438 5.91797 9.42188 5.80078 9.01172 5.80078C8.61328 5.80078 8.29297 5.90625 8.05078 6.11719C7.80859 6.32812 7.6875 6.61133 7.6875 6.9668H6.60352C6.61133 6.33398 6.83594 5.82422 7.27734 5.4375C7.72266 5.04688 8.30078 4.85156 9.01172 4.85156C9.75 4.85156 10.3242 5.05078 10.7344 5.44922C11.1484 5.84375 11.3555 6.38672 11.3555 7.07812C11.3555 7.76172 11.0391 8.43555 10.4062 9.09961L9.76758 9.73242C9.48242 10.0488 9.33984 10.5039 9.33984 11.0977H8.25586ZM8.20898 12.9551C8.20898 12.7793 8.26172 12.6328 8.36719 12.5156C8.47656 12.3945 8.63672 12.334 8.84766 12.334C9.05859 12.334 9.21875 12.3945 9.32812 12.5156C9.4375 12.6328 9.49219 12.7793 9.49219 12.9551C9.49219 13.1309 9.4375 13.2773 9.32812 13.3945C9.21875 13.5078 9.05859 13.5645 8.84766 13.5645C8.63672 13.5645 8.47656 13.5078 8.36719 13.3945C8.26172 13.2773 8.20898 13.1309 8.20898 12.9551Z"
      fill="#868B94"
    />
  </svg>
)

export const CalendarIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99995 0.699951C4.44178 0.699951 4.79995 1.05812 4.79995 1.49995V2.49994H9.19995V1.49995C9.19995 1.05812 9.55812 0.699951 9.99995 0.699951C10.4418 0.699951 10.8 1.05812 10.8 1.49995V2.49994H11.5C12.1213 2.49994 12.625 3.00362 12.625 3.62494V10.7499C12.625 11.7855 11.7855 12.6249 10.75 12.6249H3.25C2.21447 12.6249 1.375 11.7855 1.375 10.7499V3.62494C1.375 3.00362 1.87868 2.49994 2.5 2.49994H3.19995V1.49995C3.19995 1.05812 3.55812 0.699951 3.99995 0.699951ZM11.5 6.39993H2.5V5.34993H11.5V6.39993Z"
      fill="#212124"
    />
  </svg>
)

export const AlarmPreventIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.00002 4.9C2.00002 2.18599 4.25203 0 7.00002 0C8.11875 0 9.15437 0.361839 9.98906 0.973283C10.1068 1.05953 10.181 1.19292 10.1921 1.33845C10.2033 1.48397 10.1503 1.6271 10.047 1.73028L2.85348 8.92031C2.71046 9.06326 2.49542 9.10599 2.30862 9.02858C2.12181 8.95118 2.00002 8.76888 2.00002 8.56667V4.9Z"
      fill="#212529"
    />
    <path
      d="M11.6382 3.06887L13.3536 1.35355C13.5488 1.15829 13.5488 0.841709 13.3536 0.646447C13.1583 0.451184 12.8417 0.451184 12.6464 0.646447L0.646447 12.6464C0.451184 12.8417 0.451184 13.1583 0.646447 13.3536C0.841709 13.5488 1.15829 13.5488 1.35355 13.3536L2.70711 12H13C13.2762 12 13.5 11.7761 13.5 11.5V10.7667C13.5 10.6662 13.4697 10.568 13.4131 10.485L12 8.41243V4.9C12 4.25332 11.8715 3.63497 11.6382 3.06887Z"
      fill="#212529"
    />
    <path
      d="M5.88233 12.5C5.68499 12.5 5.5061 12.6161 5.42571 12.7963C5.34531 12.9765 5.37844 13.1872 5.51028 13.334C5.8754 13.7407 6.40714 14 6.99795 14C7.58876 14 8.1205 13.7407 8.48562 13.334C8.61746 13.1872 8.65059 12.9765 8.57019 12.7963C8.4898 12.6161 8.31091 12.5 8.11357 12.5H5.88233Z"
      fill="#212529"
    />
  </svg>
)

export const QRScanner = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99999 2H4.8C3.2536 2 2 3.2536 2 4.8V6.99999L3.6 6.99999V4.8C3.6 4.13726 4.13726 3.6 4.8 3.6H6.99999V2ZM20 6.99999V4.8C20 4.13726 19.4627 3.6 18.8 3.6H17V2H18.8C20.3464 2 21.6 3.2536 21.6 4.8V6.99999L20 6.99999ZM17 20H18.8C19.4627 20 20 19.4627 20 18.8V17H21.6V18.8C21.6 20.3464 20.3464 21.6 18.8 21.6H17V20ZM3.6 17V18.8C3.6 19.4627 4.13726 20 4.8 20H6.99999V21.6H4.8C3.2536 21.6 2 20.3464 2 18.8V17H3.6Z"
      fill="#4D5159"
    />
    <rect x="2" y="11.2" width="20" height="1.6" fill="#4D5159" />
  </svg>
)

export const EllipsisIcon = () => (
  <svg width="36" height="36" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M192 512c0 35.2 28.8 64 64 64s64-28.8 64-64-28.8-64-64-64-64 28.8-64 64z m255.99999999 0c0 35.2 28.8 64 64.00000001 64.00000001s64-28.8 64.00000001-64.00000001-28.8-64-64.00000001-64.00000001-64 28.8-64.00000001 64.00000001z m256.00000001 0c0 35.2 28.8 64 64 64s64-28.8 64-64-28.8-64-64-64-64 28.8-64 64z"
      fill="currentColor"
    />
  </svg>
)

export const SlideArrowIcon = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M335.083,271.083
      L228.416,377.749c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251c-8.341-8.341-8.341-21.824,0-30.165
      L289.835,256l-91.584-91.584c-8.341-8.341-8.341-21.824,0-30.165s21.824-8.341,30.165,0l106.667,106.667
      C343.424,249.259,343.424,262.741,335.083,271.083z"
    />
  </svg>
)

export const OfficialCertIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49875 2.96157C6.99096 2.08939 7.92611 1.5 9 1.5C10.0739 1.5 11.009 2.08936 11.5012 2.96143C12.466 2.69285 13.544 2.93744 14.3032 3.69666C15.0626 4.45603 15.307 5.53399 15.0384 6.49875C15.9106 6.99096 16.5 7.92611 16.5 9C16.5 10.0739 15.9106 11.009 15.0386 11.5012C15.3072 12.466 15.0626 13.544 14.3033 14.3032C13.544 15.0626 12.466 15.307 11.5012 15.0384C11.009 15.9106 10.0739 16.5 9 16.5C7.92614 16.5 6.991 15.9106 6.49878 15.0386C5.53405 15.3072 4.45602 15.0626 3.6968 14.3033C2.93743 13.544 2.69296 12.466 2.96157 11.5013C2.08939 11.009 1.5 10.0739 1.5 9C1.5 7.92614 2.08936 6.991 2.96143 6.49878C2.69285 5.53404 2.93744 4.45602 3.69666 3.6968C4.45603 2.93743 5.53399 2.69296 6.49875 2.96157ZM11.7341 6.49602C11.4472 6.28914 11.0469 6.354 10.84 6.6409L8.41363 10.0057L7.11178 8.59068C6.87229 8.33037 6.46714 8.31349 6.20683 8.55297C5.94653 8.79245 5.92964 9.19761 6.16913 9.45791L8.00255 11.4508C8.13284 11.5924 8.31988 11.6679 8.51196 11.6565C8.70405 11.645 8.88081 11.5478 8.99335 11.3917L11.879 7.39009C12.0859 7.10319 12.021 6.70291 11.7341 6.49602Z"
      fill="#00B493"
    />
  </svg>
)

export const CloseIcon = ({ size = 18 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96967 3.96967C4.26256 3.67678 4.73744 3.67678 5.03033 3.96967L9 7.93934L12.9697 3.96967C13.2626 3.67678 13.7374 3.67678 14.0303 3.96967C14.3232 4.26256 14.3232 4.73744 14.0303 5.03033L10.0607 9L14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303C13.7374 14.3232 13.2626 14.3232 12.9697 14.0303L9 10.0607L5.03033 14.0303C4.73744 14.3232 4.26256 14.3232 3.96967 14.0303C3.67678 13.7374 3.67678 13.2626 3.96967 12.9697L7.93934 9L3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967Z"
    />
  </svg>
)

export const WarningIcon = ({ size = 18 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99968 4.73486C8.30024 4.73486 8.54389 4.97851 8.54389 5.27908V8.3403C8.54389 8.64086 8.30024 8.88452 7.99968 8.88452C7.69911 8.88452 7.45546 8.64086 7.45546 8.3403V5.27908C7.45546 4.97851 7.69911 4.73486 7.99968 4.73486Z"
      fill="currentColor"
    />
    <path
      d="M8.67981 10.7212C8.67981 11.0969 8.37525 11.4015 7.99954 11.4015C7.62384 11.4015 7.31927 11.0969 7.31927 10.7212C7.31927 10.3455 7.62384 10.041 7.99954 10.041C8.37525 10.041 8.67981 10.3455 8.67981 10.7212Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99968 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99968 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016ZM7.99968 2.42193C4.9189 2.42193 2.42144 4.91939 2.42144 8.00016C2.42144 11.0809 4.9189 13.5784 7.99968 13.5784C11.0804 13.5784 13.5779 11.0809 13.5779 8.00016C13.5779 4.91939 11.0804 2.42193 7.99968 2.42193Z"
      fill="currentColor"
    />
  </svg>
)

export const ReplyMissionIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8163 22.4801C18.063 22.6329 18.3732 22.6401 18.6267 22.4989C18.8803 22.3577 19.0375 22.0902 19.0375 21.8V18.4773C21.2039 16.7699 22.6 14.2342 22.6 11.3806C22.6 6.1252 17.91 2 12.3 2C6.69002 2 2 6.1252 2 11.3806C2 16.6361 6.69002 20.7613 12.3 20.7613C13.0774 20.7613 13.8357 20.6828 14.5655 20.5337C14.9332 20.7346 15.5983 21.1285 16.2651 21.5315C16.6712 21.777 17.0591 22.0142 17.3457 22.1902C17.489 22.2782 17.6068 22.3507 17.6887 22.4013L17.8163 22.4801ZM8.8 9C8.35817 9 8 9.35817 8 9.8C8 10.2418 8.35817 10.6 8.8 10.6H16.675C17.1168 10.6 17.475 10.2418 17.475 9.8C17.475 9.35817 17.1168 9 16.675 9H8.8ZM8.8 12.5C8.35817 12.5 8 12.8582 8 13.3C8 13.7418 8.35817 14.1 8.8 14.1H16.675C17.1168 14.1 17.475 13.7418 17.475 13.3C17.475 12.8582 17.1168 12.5 16.675 12.5H8.8Z"
    />
  </svg>
)

export const AddPlusRegular = ({ size = 18 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99996 1.5C9.35343 1.5 9.63997 1.78654 9.63997 2.14001V8.36003H15.86C16.2135 8.36003 16.5 8.64657 16.5 9.00004C16.5 9.35351 16.2135 9.64005 15.86 9.64005H9.63997V15.86C9.63997 16.2135 9.35343 16.5 8.99996 16.5C8.6465 16.5 8.35996 16.2135 8.35996 15.86V9.64005H2.14C1.78654 9.64005 1.5 9.35351 1.5 9.00004C1.5 8.64657 1.78654 8.36003 2.14 8.36003H8.35996V2.14001C8.35996 1.78654 8.6465 1.5 8.99996 1.5Z"
    />
  </svg>
)

export const BackArrowRegular = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5661 19.4341C12.8785 19.7465 12.8785 20.2531 12.5661 20.5655C12.2537 20.8779 11.7472 20.8779 11.4348 20.5655L3.43475 12.5655C3.12234 12.2531 3.12234 11.7465 3.43475 11.4341L11.4348 3.43412C11.7472 3.1217 12.2537 3.1217 12.5661 3.43412C12.8785 3.74654 12.8785 4.25307 12.5661 4.56549L5.93181 11.1998L20 11.1998C20.4419 11.1998 20.8 11.558 20.8 11.9998C20.8 12.4416 20.4419 12.7998 20 12.7998L5.93181 12.7998L12.5661 19.4341Z" />
  </svg>
)

export const EmoticonRegular = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.8 10.4002C9.8 11.1734 9.1732 11.8002 8.4 11.8002C7.6268 11.8002 7 11.1734 7 10.4002C7 9.62698 7.6268 9.00018 8.4 9.00018C9.1732 9.00018 9.8 9.62698 9.8 10.4002Z" />
    <path d="M15.6002 11.8002C16.3734 11.8002 17.0002 11.1734 17.0002 10.4002C17.0002 9.62698 16.3734 9.00018 15.6002 9.00018C14.827 9.00018 14.2002 9.62698 14.2002 10.4002C14.2002 11.1734 14.827 11.8002 15.6002 11.8002Z" />
    <path d="M8.74211 13.7016C8.57721 13.2917 8.11124 13.0931 7.70134 13.258C7.29144 13.4229 7.09282 13.8888 7.25773 14.2987C7.69347 15.3819 8.52005 16.1326 9.38875 16.6053C10.2518 17.075 11.2078 17.3002 11.9999 17.3002C13.6952 17.3002 15.8824 16.4233 16.7415 14.3002C16.9072 13.8907 16.7096 13.4243 16.3 13.2586C15.8904 13.0928 15.424 13.2905 15.2583 13.7001C14.7174 15.037 13.2647 15.7002 11.9999 15.7002C11.482 15.7002 10.7881 15.5453 10.1536 15.2C9.52479 14.8578 9.00636 14.3584 8.74211 13.7016Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 2.2002C6.5878 2.2002 2.2002 6.5878 2.2002 12.0002C2.2002 17.4126 6.5878 21.8002 12.0002 21.8002C17.4126 21.8002 21.8002 17.4126 21.8002 12.0002C21.8002 6.5878 17.4126 2.2002 12.0002 2.2002ZM3.8002 12.0002C3.8002 7.47146 7.47146 3.8002 12.0002 3.8002C16.5289 3.8002 20.2002 7.47146 20.2002 12.0002C20.2002 16.5289 16.5289 20.2002 12.0002 20.2002C7.47146 20.2002 3.8002 16.5289 3.8002 12.0002Z"
    />
  </svg>
)

export const MissionRegular = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5002 3.8002C17.3836 3.8002 21.2002 7.35837 21.2002 11.5808C21.2002 14.004 19.9581 16.1909 17.9685 17.6315C17.7607 17.782 17.6377 18.023 17.6377 18.2795V20.5714C17.5256 20.5033 17.41 20.4332 17.2929 20.3624C16.501 19.8837 15.5577 19.3229 15.2067 19.1631C15.0461 19.09 14.8659 19.072 14.694 19.1119C13.9942 19.2746 13.2591 19.3615 12.5002 19.3615C7.61677 19.3615 3.80019 15.8033 3.80019 11.5808C3.80019 7.35837 7.61677 3.8002 12.5002 3.8002ZM18.0165 22.6803C18.2632 22.8331 18.5734 22.8403 18.8269 22.6991C19.0805 22.5579 19.2377 22.2904 19.2377 22.0002V18.6775C21.4041 16.9701 22.8002 14.4344 22.8002 11.5808C22.8002 6.3254 18.1102 2.2002 12.5002 2.2002C6.89021 2.2002 2.2002 6.3254 2.2002 11.5808C2.2002 16.8363 6.89021 20.9615 12.5002 20.9615C13.2776 20.9615 14.0359 20.883 14.7657 20.7338C15.1334 20.9348 15.7985 21.3286 16.4653 21.7317C16.8714 21.9772 17.2593 22.2144 17.5459 22.3904C17.6892 22.4784 17.807 22.5509 17.8889 22.6015L18.0165 22.6803Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.82522 10.0002C7.82522 9.55837 8.18339 9.2002 8.62522 9.2002H16.5002C16.942 9.2002 17.3002 9.55837 17.3002 10.0002C17.3002 10.442 16.942 10.8002 16.5002 10.8002H8.62522C8.18339 10.8002 7.82522 10.442 7.82522 10.0002Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.82522 13.5002C7.82522 13.0584 8.18339 12.7002 8.62522 12.7002H16.5002C16.942 12.7002 17.3002 13.0584 17.3002 13.5002C17.3002 13.942 16.942 14.3002 16.5002 14.3002H8.62522C8.18339 14.3002 7.82522 13.942 7.82522 13.5002Z"
    />
  </svg>
)

export const TrashRegular = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16732 4.33301C4.53551 4.33301 4.83398 4.63148 4.83398 4.99967V14.9997C4.83398 16.0122 5.6548 16.833 6.66732 16.833H13.334C14.3465 16.833 15.1673 16.0122 15.1673 14.9997V4.99967C15.1673 4.63148 15.4658 4.33301 15.834 4.33301C16.2022 4.33301 16.5007 4.63148 16.5007 4.99967V14.9997C16.5007 16.7486 15.0829 18.1663 13.334 18.1663H6.66732C4.91842 18.1663 3.50065 16.7486 3.50065 14.9997V4.99967C3.50065 4.63148 3.79913 4.33301 4.16732 4.33301Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08398 8.49967C7.45217 8.49967 7.75065 8.79815 7.75065 9.16634V13.333C7.75065 13.7012 7.45217 13.9997 7.08398 13.9997C6.71579 13.9997 6.41732 13.7012 6.41732 13.333V9.16634C6.41732 8.79815 6.71579 8.49967 7.08398 8.49967Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0007 8.49967C10.3688 8.49967 10.6673 8.79815 10.6673 9.16634V13.333C10.6673 13.7012 10.3688 13.9997 10.0007 13.9997C9.63246 13.9997 9.33398 13.7012 9.33398 13.333V9.16634C9.33398 8.79815 9.63246 8.49967 10.0007 8.49967Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9173 8.49967C13.2855 8.49967 13.584 8.79815 13.584 9.16634V13.333C13.584 13.7012 13.2855 13.9997 12.9173 13.9997C12.5491 13.9997 12.2507 13.7012 12.2507 13.333V9.16634C12.2507 8.79815 12.5491 8.49967 12.9173 8.49967Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83398 4.99967C1.83398 4.63148 2.13246 4.33301 2.50065 4.33301H17.5007C17.8688 4.33301 18.1673 4.63148 18.1673 4.99967C18.1673 5.36786 17.8688 5.66634 17.5007 5.66634H2.50065C2.13246 5.66634 1.83398 5.36786 1.83398 4.99967Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8682 2.28886C6.95894 2.01663 7.2137 1.83301 7.50066 1.83301H12.5007C12.7876 1.83301 13.0424 2.01663 13.1331 2.28886L13.9664 4.78886C14.0342 4.99215 14.0001 5.21564 13.8748 5.38948C13.7495 5.56333 13.5483 5.66634 13.334 5.66634H6.66732C6.45303 5.66634 6.2518 5.56333 6.12649 5.38948C6.00119 5.21564 5.9671 4.99215 6.03487 4.78886L6.8682 2.28886ZM7.98116 3.16634L7.59227 4.33301H12.409L12.0201 3.16634H7.98116Z"
    />
  </svg>
)

export const CameraRegular = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2 6L7.74 3.43C7.89 3.19 8.23 3 8.49 3H15.51C15.78 3 16.11 3.19 16.26 3.43L17.8 6H20C21.1 6 22 6.89 22 8V18C22 19.11 21.11 20 20 20H4C2.9 20 2 19.11 2 18V8C2 6.9 2.89 6 4 6H6.2ZM11.0002 5.70001C10.5584 5.70001 10.2002 6.05818 10.2002 6.50001C10.2002 6.94184 10.5584 7.30001 11.0002 7.30001H13.0002C13.442 7.30001 13.8002 6.94184 13.8002 6.50001C13.8002 6.05818 13.442 5.70001 13.0002 5.70001H11.0002ZM9.3002 13C9.3002 11.5088 10.509 10.3 12.0002 10.3C13.4914 10.3 14.7002 11.5088 14.7002 13C14.7002 14.4912 13.4914 15.7 12.0002 15.7C10.509 15.7 9.3002 14.4912 9.3002 13ZM12.0002 8.70001C9.62537 8.70001 7.7002 10.6252 7.7002 13C7.7002 15.3748 9.62537 17.3 12.0002 17.3C14.375 17.3 16.3002 15.3748 16.3002 13C16.3002 10.6252 14.375 8.70001 12.0002 8.70001Z"
    />
  </svg>
)

export const CloseCircle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25835 7.09038C6.40751 6.94123 6.64935 6.94123 6.7985 7.09038L9.58398 9.87586L12.3695 7.09038C12.5186 6.94123 12.7605 6.94123 12.9096 7.09038C13.0588 7.23954 13.0588 7.48138 12.9096 7.63054L10.1241 10.416L12.9096 13.2015C13.0588 13.3507 13.0588 13.5925 12.9096 13.7416C12.7605 13.8908 12.5186 13.8908 12.3695 13.7416L9.58398 10.9562L6.7985 13.7416C6.64935 13.8908 6.40751 13.8908 6.25835 13.7416C6.10919 13.5925 6.10919 13.3507 6.25835 13.2015L9.04383 10.416L6.25835 7.63054C6.10919 7.48138 6.10919 7.23954 6.25835 7.09038Z"
      fill="white"
    />
  </svg>
)
