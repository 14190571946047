import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { tryAtoB } from 'utils/snippet'
import { DAANGN_BUSINESS_URL, FAQ_URL } from 'constants/resource'
import { StoreState } from 'store/ducks'
import { socketStore } from 'utils/socket/socketStore'
import { getChannelItem, getMessagesQuery } from 'utils/protobuf/query'
import { gtagEmit } from 'utils/eventUtils'

import ChatMessageList from './ChatMessageList'
import ChatNormalRoom from './ChatNormalRoom'
import ChatFakeSend from 'components/chatMain/chatSend/ChatFakeSend'

import { ChatInnerLayout } from 'components/common/layoutStyle'
import { DisableSendForm } from './chatSend/ChatSendStyle'
import { EmptyChannelTalkIcon, WarningIcon } from 'utils/SVGIcons'
import { useBizAccountCheck } from 'hooks/useBizAccountCheck'
import { EmptyBizAccount } from 'components/empty/emptyStyle'

const BRAND_PROFILE_TYPE = 6

const ChatMain = () => {
  const dispatch = useDispatch()
  const params: { channelId?: string; bizId?: string } = useParams()
  const channelId = tryAtoB(params.channelId?.slice(1) || '')
  const socket = socketStore.chatSocket
  const targetRoom = useSelector((state: StoreState) => state.chatStore.selectChannelobj)
  const channelBlock = useSelector((state: StoreState) => state.chatStore.channelBlock)
  const isBizRequestPending = useSelector((state: StoreState) => state.chatStore.isBizRequestPending)

  const requestChannelItem = useCallback(() => {
    if (channelId) {
      if (channelId !== targetRoom?.id && socket?.readyState === 1) {
        const message = getChannelItem(channelId).serializeBinary()
        socket.send(message)
      }
    } else {
      dispatch({
        type: 'chat/resetSelectedChannel',
      })
    }
  }, [channelId, socket, targetRoom?.id, dispatch])
  useEffect(() => {
    if (params.bizId) {
      if (!isBizRequestPending) {
        requestChannelItem()
      }
    } else {
      requestChannelItem()
    }
  }, [isBizRequestPending, params, requestChannelItem])

  useEffect(() => {
    if (targetRoom) {
      gtagEmit('event', 'access_channel', {
        channelId: targetRoom.id,
        channelType: targetRoom.type,
        articleType: targetRoom.resourceType,
      })
      if (socket?.readyState === 1) {
        const message = getMessagesQuery({
          channelId: `${targetRoom.id}`,
          messageId: Number(targetRoom?.lastMessage?.id || 0),
        }).serializeBinary()
        socket.send(message)
      }
    }
  }, [targetRoom, socket])

  const customInput = targetRoom?.input
  const customText = customInput?.text
  const customButton = customInput?.button
  const { bizToken, bizAccount } = useBizAccountCheck()
  const targetBizAccount = bizAccount.length > 0 ? bizAccount.filter((item) => item.id === targetRoom?.me?.id) : []
  const brandAccount = targetBizAccount.length > 0 && targetBizAccount[0]?.type === BRAND_PROFILE_TYPE
  if ((targetRoom?.receiver?.userFlaggedByMeList || []).length > 0 || channelBlock) {
    return (
      <ChatInnerLayout>
        <ChatMessageList />
        <ChatFakeSend placeholder="차단한 사용자와는 대화할 수 없어요" />
        <div className="block-state" />
      </ChatInnerLayout>
    )
  } else if (targetRoom?.isDisabled) {
    return (
      <ChatInnerLayout>
        <ChatMessageList />
        <ChatFakeSend placeholder="더이상 이 사용자와 대화할 수 없어요" />
        <div className="block-state" />
      </ChatInnerLayout>
    )
  } else if (targetRoom?.isReadOnly) {
    return (
      <ChatInnerLayout>
        <ChatMessageList />
        <DisableSendForm>
          <a href={FAQ_URL} target="_blank" rel="noreferrer">
            <button className="qna-button">자주 묻는 질문 보기</button>
          </a>
        </DisableSendForm>
      </ChatInnerLayout>
    )
  } else if (customText?.isDisabled) {
    return (
      <ChatInnerLayout>
        <ChatMessageList />
        <ChatFakeSend placeholder={customText.placeholder} />
        <div className="block-only-send" />
      </ChatInnerLayout>
    )
  } else if (customButton) {
    return (
      <ChatInnerLayout>
        <ChatMessageList />
        <DisableSendForm>
          <a href={FAQ_URL} target="_blank" rel="noreferrer">
            <button className="qna-button">{customButton.text}</button>
          </a>
        </DisableSendForm>
      </ChatInnerLayout>
    )
  } else if (brandAccount) {
    return (
      <ChatInnerLayout>
        <ChatMessageList />
        <ChatFakeSend placeholder="브랜드 비즈프로필은 사용자와 대화할 수 없어요" />
      </ChatInnerLayout>
    )
  } else if (targetRoom) {
    return (
      <ChatInnerLayout>
        <ChatNormalRoom channelId={channelId} />
      </ChatInnerLayout>
    )
  } else if (bizToken && bizAccount.length < 1) {
    return (
      <EmptyBizAccount>
        <WarningIcon size={60} />
        <div className="empty-biz-textarea">
          <div className="empty-biz-title">비즈프로필이 없어요.</div>
          <div className="empty-biz-subtitle">채팅에서 사용할 비즈프로필을 먼저 생성해 주세요.</div>
          <a className="empty-biz-button" href={DAANGN_BUSINESS_URL} rel="noreferrer">
            비즈프로필 만들기
          </a>
        </div>
      </EmptyBizAccount>
    )
  } else {
    return (
      <ChatInnerLayout>
        <div className="empty-box">
          <EmptyChannelTalkIcon />
          <div className="empty-description">채팅할 상대를 선택해주세요.</div>
        </div>
      </ChatInnerLayout>
    )
  }
}

export default ChatMain
