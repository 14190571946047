import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ChatForm, ChannelTextArea, ChatSendButton } from './ChatSendStyle'
import ChatSticker from './ChatSticker'
import ChatMacro from './ChatMacro'
import { getLocalStorage } from 'utils/localStorage'
import { AddFile, MacroIcon, ReplyMissionIcon } from 'utils/SVGIcons'
import { gtagEmit } from 'utils/eventUtils'
import { openToast } from 'utils/localDispatcher'
import ChatReplyMission from './chatReply/ChatReplyMission'
import { ChannelTypes } from 'types/ChatTypes'
import ChatReplyUserview from './chatReply/ChatReplyUserview'
import { gtagInputBoxWrapper } from 'utils/eventWrapper'
import { lastInsertBox } from 'utils/cacheStore'

interface ChatSendProps {
  handleSendMessage: Function
  handleInputFile: (event: React.ChangeEvent<HTMLInputElement>) => void
  targetRoom: ChannelTypes | null
}
const ChatSend = ({ handleSendMessage, handleInputFile, targetRoom }: ChatSendProps) => {
  const [message, setMessage] = useState('')
  const inputRef = useRef<HTMLTextAreaElement>(null!)
  const handleMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
    lastInsertBox.set(`${targetRoom?.id}`, e.target.value)
  }
  const submitWrapper = (e: React.SyntheticEvent) => {
    e.preventDefault()
    handleSubmit()
  }
  const handleSubmit = () => {
    if (message === '') {
      return false
    } else if (message.length >= 1000) {
      openToast('1000자를 초과했어요')
      return false
    }
    handleSendMessage({ message: message, type: 'TEXT', setMessage, sendInput: inputRef.current })
    inputRef.current.focus()
    lastInsertBox.remove(`${targetRoom?.id}`)
  }
  const handleSendKeyboard = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && e.keyCode !== 229) {
      e.preventDefault()
      handleSubmit()
    } else if (e.altKey && e.key.match(/[1-9]/g)) {
      e.preventDefault()
      const macroList = getLocalStorage('macro')
      setMessage(macroList[e.key])
      gtagEmit('event', 'shortcut', {})
    }
  }

  const [showSticker, setShowSticker] = useState(false)
  const handleStickerKeyboard = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setShowSticker(false)
      setShowMacro(false)
    }
  }
  const location = useLocation()
  const [showMacro, setShowMacro] = useState(false)
  const macroToggle = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setShowMacro((origin) => !origin)
    gtagInputBoxWrapper('favorite_phrase')
  }
  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus()
    }
  }, [location.pathname])

  const [showReply, setShowReply] = useState(false)
  const replyToggle = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    gtagInputBoxWrapper('persistent_menu')
    setShowReply((origin) => !origin)
  }
  const currentPath = location.pathname
  useEffect(() => {
    const originMessage = lastInsertBox.dataList?.[`${targetRoom?.id}`] || ''
    setMessage(originMessage)
  }, [currentPath, targetRoom])

  const [showUserReply, setShowUserReply] = useState(false)
  const userReplyToggle = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    gtagInputBoxWrapper('persistent_menu')
    setShowUserReply((origin) => !origin)
  }
  const showReplyMission = targetRoom && targetRoom?.bizAccount?.id === targetRoom?.me?.id
  const showReplyQuestion = targetRoom && targetRoom?.bizAccount?.id && targetRoom.bizAccount.id !== targetRoom?.me?.id
  return (
    <>
      <ChatForm onSubmit={submitWrapper} onKeyDown={handleStickerKeyboard}>
        <ChannelTextArea
          ref={inputRef}
          value={message}
          onChange={handleMessage}
          onKeyDown={handleSendKeyboard}
          placeholder="메시지를 입력해주세요"
        />
        <div className="chatform-option-area">
          <div className="chatform-submenu">
            <label className="option-wrapper">
              <span className="option-tooltip">사진</span>
              <AddFile />
              <input type="file" onChange={handleInputFile} multiple accept="image/png, image/jpeg, image/gif" />
            </label>
            <label className={`option-wrapper ${showMacro ? 'selected' : ''}`} onClick={macroToggle}>
              <span className="option-tooltip">자주 쓰는 문구</span>
              <MacroIcon />
            </label>
            {showReplyMission ? (
              <label className={`option-wrapper ${showReply ? 'selected' : ''}`} onClick={replyToggle}>
                <span className="option-tooltip">자동응답</span>
                <ReplyMissionIcon />
              </label>
            ) : showReplyQuestion ? (
              <label className={`option-wrapper ${showUserReply ? 'selected' : ''}`} onClick={userReplyToggle}>
                <span className="option-tooltip">자동응답</span>
                <ReplyMissionIcon />
              </label>
            ) : null}

            <label className="option-wrapper">
              <span className="option-tooltip">이모티콘</span>
              <ChatSticker
                handleSendMessage={handleSendMessage}
                showSticker={showSticker}
                setShowSticker={setShowSticker}
              />
            </label>
          </div>
          {(message || '').length > 0 ? (
            <ChatSendButton>전송</ChatSendButton>
          ) : (
            <ChatSendButton className="disable" aria-disabled="true">
              전송
            </ChatSendButton>
          )}
        </div>
        <span className="text-length">{`${(message || '').length}/1000`}</span>
      </ChatForm>
      {showMacro ? <ChatMacro setShowMacro={setShowMacro} /> : null}
      {showReply ? <ChatReplyMission setShowReply={setShowReply} /> : null}
      {showUserReply ? <ChatReplyUserview setShowUserReply={setShowUserReply} /> : null}
    </>
  )
}

export default ChatSend
