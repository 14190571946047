import { BadWordsWrapper } from './ChatSendStyle'
import { binarySend } from 'utils/socket/socketDispatcher'
import { useCallback, useEffect } from 'react'
interface BadWordsPopupProps {
  closeModal: Function
  binary: Uint8Array
  setMessage: Function | undefined
  sendInput: HTMLTextAreaElement | undefined
}
const BadWordsPopup = ({ closeModal, binary, setMessage, sendInput }: BadWordsPopupProps) => {
  const handleClose = useCallback(() => {
    closeModal()
    if (sendInput) sendInput.focus()
  }, [closeModal, sendInput])
  const handleSend = () => {
    binarySend(binary)
    setMessage && setMessage('')
    closeModal()
  }
  const handleKeydown = useCallback(
    ({ key }:KeyboardEvent) => {
      if (key === 'Enter') {
        handleClose()
      }
    },
    [handleClose],
  )
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [handleKeydown])
  return (
    <BadWordsWrapper>
      <div className="content-text">
        앗, 메시지에 상대방이 오해할만한 단어가 포함되어 있어요! 이웃에게 보내기 전 한 번 더 확인해보세요.
      </div>
      <button type="button" className="modal-full-button edit-button" onClick={handleClose}>
        메시지 수정하기
      </button>
      <button type="button" className="modal-full-button common-bg-hover" onClick={handleSend}>
        그냥 보낼게요
      </button>
    </BadWordsWrapper>
  )
}

export default BadWordsPopup
