import axios from 'axios'
import { v4 } from 'uuid'
import {
  BIG_PICTURE_URL,
  AUTH_HEADER_KEY,
  KARROTAUTH_HTTP,
  XPROPERTY_URL,
  BUSINESS_AUTH_URL,
  BIZ_PLATFORM_CENTER,
} from 'constants/config'
import { getAccToken } from 'utils/auth/tokenUtils'
import { getImageInfo, imageCompress } from 'utils/imageParser'
import { openToast } from 'utils/localDispatcher'
import { store } from 'store'
import { getSupportRegion } from './snippet'

const bizPlatformCenterInstance = axios.create({
  withCredentials: true,
  baseURL: BIZ_PLATFORM_CENTER,
})

interface imageUploadReqestProps {
  fileItem: File
  userData: {
    id: number
    auth_token: string
  }
  channelId: string
}
export const imageUploadReqest = async ({ fileItem, userData, channelId }: imageUploadReqestProps) => {
  const fileData = await imageCompress(fileItem)
  const { width, height } = await getImageInfo(fileData)
  const bizToken = store.getState().authStore.bizToken
  if (bizToken) {
    const requestBody = new FormData()
    requestBody.append('body', fileData)
    requestBody.append('region', getSupportRegion())
    requestBody.append('service', 'rocketchat')
    requestBody.append('type', 'chat')
    requestBody.append('width', `${width}`)
    requestBody.append('height', `${height}`)
    requestBody.append('contentType', fileItem.type)
    requestBody.append('filepath', `rocketchat/${channelId}/${v4()}.${fileItem.type.replace('image/', '')}`)
    try {
      const { data } = await bizPlatformCenterInstance.post(`/api/v1/images`, requestBody)
      return {
        ...data.data,
        width,
        height,
      }
    } catch (_) {
      openToast('파일 전송에 실패했어요')
    }
  } else {
    const accessToken = getAccToken()
    const requestBody = {
      user_id: Number(userData.id),
      service: 'rocketchat',
      type: 'chat',
      width: width,
      height: height,
      filepath: `rocketchat/${channelId}/${v4()}.${fileItem.type.replace('image/', '')}`,
      content_type: fileItem.type,
    }
    const requestOption = {
      headers: {
        [AUTH_HEADER_KEY]: accessToken,
        'Content-Type': 'application/json',
      },
    }
    try {
      const { data } = await axios.post(
        `${BIG_PICTURE_URL.replace(':region', 'kr')}/api/v2/request_upload_url`,
        requestBody,
        requestOption,
      )
      if (data) {
        await axios.put(data.data.image.upload_url, fileData, {
          headers: {
            'Content-Type': fileItem.type,
            'X-Amz-Meta-Id': data.data.image.id,
          },
        })
        return data.data.image
      }
    } catch (_) {
      openToast('파일 전송에 실패했어요')
    }
  }
}

export const AuthAPI = {
  tokenRefresh: async (refToken: string, region: string) => {
    return axios.post(`${KARROTAUTH_HTTP.replace(':region', region)}/v2/auth/refreshtokens`, {
      refresh_token: refToken,
    })
  },
  getUserInfo: async (accessToken: string, region: string) => {
    return axios.get(`${KARROTAUTH_HTTP.replace(':region', region)}/v2/auth/u`, {
      headers: {
        'x-user-token': accessToken,
      },
    })
  },
}
export const XPropertyAPI = {
  badWords: async () => {
    return axios.get(`${XPROPERTY_URL}/v1/countries/KR/properties/chat_bad_words_regex`)
  },
}

const bizPlatformAuthInstance = axios.create({
  withCredentials: true,
  baseURL: BUSINESS_AUTH_URL,
})
export const businessAuthAPI = {
  getBizToken: () => {
    return bizPlatformAuthInstance.get('/api/v2/auths/tokens')
  },
  getProfiles: () => {
    return bizPlatformAuthInstance.get('/api/v1/profiles')
  },
}

export const getStickers = () => {
  return axios.get(`/sticker.json`)
}
