interface socketStoreProps {
  chatSocket: WebSocket | null;
  qrSocket: (WebSocket | null)[];
}
const socketStore: socketStoreProps = {
  chatSocket: null,
  qrSocket: [],
};

export { socketStore };
