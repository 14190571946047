import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { StoreState } from 'store/ducks'
import { openLoader } from 'utils/localDispatcher'
import { BizProfileParamConverter, getUrlParams, ProfileParamConverter } from 'utils/snippet'
import { gtagEmit } from 'utils/eventUtils'

const ChannelListSidebar = () => {
  const bizAccount = useSelector((state: StoreState) => state.authStore.bizAccount)
  const userAccount = useSelector((state: StoreState) => state.authStore.user)
  const userProfile = useSelector((state: StoreState) => state.authStore.profileImage)
  const selectUser = useSelector((state: StoreState) => state.authStore.selectUser)
  const unreadObj = useSelector((state: StoreState) => state.chatStore.unreadObj)
  const selectChannelobj = useSelector((state: StoreState) => state.chatStore.selectChannelobj)

  const dispatch = useDispatch()
  const pathList = window.location.pathname.split('/')
  const accountType = useMemo(() => pathList[1], [pathList])
  const bizAccountId = useMemo(() => Number(pathList[2]), [pathList])
  useEffect(() => {
    const { bizAccountId, accountType } = getUrlParams()
    if (accountType === 'business') {
      if (bizAccount.length > 0) {
        const bizName = bizAccount.find((item) => item.id === bizAccountId)?.name
        dispatch({
          type: 'user/setSelectUser',
          payload: {
            id: bizAccountId,
            type: 'business',
            nickname: bizName,
          },
        })
        gtagEmit('event', 'biz_usage', {
          biz_id: bizAccountId,
        })
      }
    } else if (accountType === '' || accountType === 'room') {
      if (userAccount.id && selectUser !== userAccount.id) {
        dispatch({
          type: 'user/setSelectUser',
          payload: {
            id: userAccount.id,
            type: 'normal',
            nickname: userAccount.nick,
          },
        })
      }
    }
  }, [accountType, bizAccountId, bizAccount, dispatch, userAccount, selectUser])

  const handleClick = (userId: number) => {
    if (selectUser !== userId) {
      openLoader()
    }
  }

  useEffect(() => {
    const totalUnread = Object.values(unreadObj).reduce((acc, cur) => acc + cur, 0)
    const { bizAccountId, accountType } = getUrlParams()
    const bizName = bizAccount.find((item) => item.id === bizAccountId)?.name
    const totalNotation = totalUnread > 0 ? `(${totalUnread})` : ''
    if (Object.keys(unreadObj).length < 1) return
    if (accountType === '') {
      document.title = `${totalNotation}${userAccount.nick} | 당근채팅`
    } else if (accountType === 'room') {
      if (
        selectChannelobj?.resourceType === 'STOR' ||
        selectChannelobj?.resourceType === 'MEET' ||
        selectChannelobj?.resourceType === 'CBUY'
      ) {
        document.title = `${totalNotation}${selectChannelobj?.title} - ${userAccount.nick} | 당근채팅`
      } else {
        if (selectChannelobj) {
          if(selectChannelobj.type === 2){
            document.title = `${totalNotation}${selectChannelobj.title} - ${userAccount.nick} | 당근채팅`
          } else {
            document.title = `${totalNotation}${selectChannelobj?.receiver?.nickname} - ${userAccount.nick} | 당근채팅`
          }
        }
      }
    } else {
      if (selectChannelobj) {
        document.title = `${totalNotation}${selectChannelobj?.receiver?.nickname || ''} - ${bizName} | 당근채팅`
      } else {
        if (bizAccountId) {
          document.title = `${totalNotation}${bizName} | 당근채팅`
        }
      }
    }
  }, [unreadObj, selectChannelobj, bizAccount, userAccount])
  return (
    <nav className="sidebar">
      {userAccount.id ? (
        <Link className="anchor" to={'/'}>
          <img
            className={`${selectUser === userAccount.id ? 'selected' : ''} profile-image`}
            src={ProfileParamConverter(userProfile)}
            alt={userAccount.nick}
            onClick={() => handleClick(userAccount.id)}
          />
          {selectUser === userAccount.id ? null : <div className="tooltip">{userAccount.nick}</div>}
          {unreadObj[-1] > 0 ? <div className="unread-count" /> : null}
        </Link>
      ) : null}
      {bizAccount.map((bizItem) => (
        <Link className="anchor" to={`/business/${bizItem.id}`} key={bizItem.id}>
          <img
            className={`${bizItem.id === selectUser ? 'selected' : ''} profile-image`}
            src={BizProfileParamConverter(bizItem.imageUrl)}
            alt={bizItem.name}
            onClick={() => handleClick(bizItem.id)}
          />
          {bizItem.id === selectUser ? null : <div className="tooltip">{bizItem.name}</div>}
          {unreadObj[bizItem.id] > 0 ? <div className="unread-count" /> : null}
        </Link>
      ))}
    </nav>
  )
}

export default ChannelListSidebar
