import React, { useEffect, useCallback, ReactNode } from 'react'
import styled from '@emotion/styled'

//#region - Styled Components
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  display: flex;
  align-items: center;

  background-color: rgba(33, 33, 36, 0.6);
  justify-content: center;
  animation: fadeIn 0.3s;
`
const ModalDialog = styled.div`
  position: relative;

  display: flex;
  margin: 0 20px;
  max-width: 1000px;
  border-radius: 10px;

  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--seed-semantic-color-paper-default);

  text-align: left;

  will-change: opacity;
  animation: fadeIn 0.5s;
  &.full-size {
    overflow: visible;
  }
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 32px;
`

const ModalBody = styled.div`
  &.full-size {
    position: relative;
  }
`
const ModalFooter = styled.div``
//#endregion

interface ModalProps {
  className?: string
  isVisible?: boolean
  title?: ReactNode
  content?: ReactNode
  footer?: ReactNode
  onClose: Function
}
function Modal({ className, isVisible = false, title, content, footer, onClose }: ModalProps) {
  const keydownHelper = useCallback(
    ({ key }:KeyboardEvent) => {
      if (key === 'Escape') onClose()
    },
    [onClose],
  )
  useEffect(() => {
    document.addEventListener('keydown', keydownHelper)
    return () => document.removeEventListener('keydown', keydownHelper)
  }, [keydownHelper])

  useEffect(() => {
    // whale 브라우저에서 document.body 가 없는 케이스가 존재.
    if(document.body){
      document.body.style.overflow = !isVisible ? 'auto' : 'hidden'
    }
  }, [isVisible])
  const handleModalWrap = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onClose()
  }
  return !isVisible ? null : (
    <ModalWrapper onClick={handleModalWrap}>
      <ModalDialog className={className} onClick={(e) => e.stopPropagation()}>
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalBody className={className}>{content}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalDialog>
    </ModalWrapper>
  )
}

export default Modal
