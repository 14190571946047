import styled from '@emotion/styled'

//#region Chat Message
const ChatMessageMain = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  overflow: hidden;
`
const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;
  background-color: var(--seed-scale-color-gray-00);
  .day-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    color: var(--seed-scale-color-gray-700);
    .date-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      border: 1px solid var(--seed-scale-color-gray-200);
      box-sizing: border-box;
      border-radius: 14px;
      padding: 0 12px;
      font-size: 12px;
    }
  }
  .left + .right,
  .right + .left {
    margin-top: 20px;
  }
  .for-containment {
    contain: content;
  }
  .to-bottom-button {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    right: 24px;
    bottom: 8px;
    height: 44px;
    width: 44px;
    background-color: var(--seed-semantic-color-paper-default);
    opacity: 0.97;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.18);
  }
`

//#endregion

export { ChatMessageMain, ScrollWrapper }
