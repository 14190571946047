import { useEffect, useState } from 'react'
import { FooterBannerWrapper } from './footerStyle'
import { CloseIcon } from 'utils/SVGIcons'
import { requestPushPermission } from 'utils/browserPush'
import { NOTIFICATION_BANNER, getLocalStorage, setLocalStorage } from 'utils/localStorage'
import { ONE_DAY } from 'constants/dateUnit'
import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'
import { gtagWrapper } from 'utils/eventWrapper'

interface bannerStateProps {
  state: 'first' | 'second' | 'third' | 'none'
  expire: number
  closeCount: number
}

const FooterBanner = () => {
  const [notiObjCheck, setNotiObjCheck] = useState(false)
  useEffect(() => {
    try {
      if (Notification.permission === 'granted') {
      }
      setNotiObjCheck(true)
    } catch (e) {
      setNotiObjCheck(false)
    }
  }, [])
  const permissionChange = useSelector((state: StoreState) => state.utilStore.permissionChange)
  const [bannerState, setBannerState] = useState<bannerStateProps | undefined>(getLocalStorage(NOTIFICATION_BANNER))
  useEffect(() => {
    setBannerState(getLocalStorage(NOTIFICATION_BANNER))
  }, [permissionChange])

  useEffect(() => {
    if (bannerState) {
      if (bannerState.state === 'first') {
        gtagWrapper('event', 'show_pc_chat_notibanner', { where: '2' })
      }
      if (bannerState.state === 'second') {
        gtagWrapper('event', 'show_pc_chat_notibanner', { where: '1' })
      }
      if (bannerState.state === 'third') {
        gtagWrapper('event', 'show_pc_chat_notibanner', { where: '2' })
      }
    } else {
      gtagWrapper('event', 'show_pc_chat_notibanner', { where: 'none' })
    }
  }, [bannerState])
  if (notiObjCheck && Notification.permission !== 'granted' && Notification.permission !== 'denied') {
    if (typeof bannerState === 'undefined' || (bannerState.state === 'first' && bannerState.expire < Date.now())) {
      const handleFirstClose = () => {
        const closeCount = (bannerState?.closeCount || 0) + 1
        setBannerState({ state: 'second', expire: 0, closeCount: closeCount })
        setLocalStorage({ key: NOTIFICATION_BANNER, value: { state: 'second', expire: 0, closeCount: closeCount } })
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: '1_close' })
      }
      const firstRequest = () => {
        requestPushPermission()
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: '1_yes' })
      }
      return (
        <FooterBannerWrapper>
          <div>
            PC알림을 허용하고, 알림창에서 새 메시지를 빠르게 확인해 보세요.
            <span className="link-strong" onClick={firstRequest}>
              알림 받기
            </span>
          </div>
          <span className="close-wrapper" onClick={handleFirstClose}>
            <CloseIcon />
          </span>
        </FooterBannerWrapper>
      )
    } else if (bannerState.state === 'second') {
      const handleSecondClose = (closeType: string) => {
        const closeCount = bannerState?.closeCount || -1
        setBannerState({ state: 'none', expire: 0, closeCount: closeCount })
        setLocalStorage({ key: NOTIFICATION_BANNER, value: { state: 'none', expire: 0 } })
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: closeType })
      }
      const checkInThreeDays = () => {
        const closeCount = (bannerState?.closeCount || 0) + 1
        setBannerState({ state: 'first', expire: Date.now() + ONE_DAY * 3, closeCount: closeCount })
        setLocalStorage({ key: NOTIFICATION_BANNER, value: { state: 'first', expire: Date.now() + ONE_DAY * 3 } })
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: '2_later' })
      }
      const secondRequest = () => {
        requestPushPermission()
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: '2_yes' })
      }
      return (
        <FooterBannerWrapper>
          <div>
            <span>PC채팅을 이용 중이라면, 알림창을 사용하는 것을 추천해요! </span>
            <span className="link-strong" onClick={secondRequest}>
              알림 받기
            </span>
            <span> • </span>
            <span className="link-strong" onClick={checkInThreeDays}>
              나중에
            </span>
            <span> • </span>
            <span className="link-strong" onClick={() => handleSecondClose('2_no')}>
              이 컴퓨터에서 알림 끄기
            </span>
          </div>
          <span className="close-wrapper" onClick={() => handleSecondClose('2_close')}>
            <CloseIcon />
          </span>
        </FooterBannerWrapper>
      )
    } else {
      return null
    }
  } else {
    // 메시지를 받았을 때 특정 조건에 따라 셀렉터가 띄우기
    if (bannerState?.state === 'third' && bannerState.expire < new Date().getTime()) {
      const handleThirdClose = (closeType: string) => {
        const closeCount = bannerState?.closeCount || -1
        setBannerState({ state: 'none', expire: 0, closeCount: closeCount })
        setLocalStorage({ key: NOTIFICATION_BANNER, value: { state: 'none', expire: 0 } })
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: closeType })
      }
      const handleGoToFaq = () => {
        gtagWrapper('event', 'click_pc_chat_notibanner', { btn_action: '3_no' })
      }
      return (
        <FooterBannerWrapper>
          <div>
            <span>혹시 알림창을 잘 확인하셨나요? </span>
            <span className="link-strong" onClick={() => handleThirdClose('3_yes')}>
              네, 확인했어요
            </span>
            <span> • </span>
            <a
              href="https://www.daangn.com/wv/faqs/3304"
              target="_blank"
              className="link-strong"
              rel="noreferrer"
              onClick={handleGoToFaq}
            >
              아뇨, 못봤어요
            </a>
          </div>
          <span className="close-wrapper" onClick={() => handleThirdClose('3_close')}>
            <CloseIcon />
          </span>
        </FooterBannerWrapper>
      )
    }
    return null
  }
}

export default FooterBanner
