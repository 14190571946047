// source: api/event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var api_model_pb = require('../api/model_pb.js');
goog.object.extend(proto, api_model_pb);
goog.exportSymbol('proto.api.DispatchEvent', null, global);
goog.exportSymbol('proto.api.DispatchEvent.EventCase', null, global);
goog.exportSymbol('proto.api.JoinMemberEvent', null, global);
goog.exportSymbol('proto.api.JoinVoiceChannelEvent', null, global);
goog.exportSymbol('proto.api.LeaveMemberEvent', null, global);
goog.exportSymbol('proto.api.LeaveMemberEvent.By', null, global);
goog.exportSymbol('proto.api.MessageStyleNotification', null, global);
goog.exportSymbol('proto.api.NewMessageEvent', null, global);
goog.exportSymbol('proto.api.NewMessageEvent.Notification', null, global);
goog.exportSymbol('proto.api.NewMessageEvent.Notification.NotificationStyleCase', null, global);
goog.exportSymbol('proto.api.NewMessageEvent.Notification.Type', null, global);
goog.exportSymbol('proto.api.ReadMessageEvent', null, global);
goog.exportSymbol('proto.api.RenewChannelEvent', null, global);
goog.exportSymbol('proto.api.ReplyEvent', null, global);
goog.exportSymbol('proto.api.ReplyEvent.Notification', null, global);
goog.exportSymbol('proto.api.ReplyEvent.Notification.NotificationStyleCase', null, global);
goog.exportSymbol('proto.api.TerminateVoiceChannelEvent', null, global);
goog.exportSymbol('proto.api.TerminateVoiceChannelReason', null, global);
goog.exportSymbol('proto.api.UpdateBubbleGroupEvent', null, global);
goog.exportSymbol('proto.api.UpdateMessageEvent', null, global);
goog.exportSymbol('proto.api.UserMentionEvent', null, global);
goog.exportSymbol('proto.api.UserMentionEvent.Notification', null, global);
goog.exportSymbol('proto.api.UserMentionEvent.Notification.NotificationStyleCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DispatchEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.DispatchEvent.oneofGroups_);
};
goog.inherits(proto.api.DispatchEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DispatchEvent.displayName = 'proto.api.DispatchEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MessageStyleNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MessageStyleNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MessageStyleNotification.displayName = 'proto.api.MessageStyleNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.NewMessageEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.NewMessageEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.NewMessageEvent.displayName = 'proto.api.NewMessageEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.NewMessageEvent.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.NewMessageEvent.Notification.oneofGroups_);
};
goog.inherits(proto.api.NewMessageEvent.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.NewMessageEvent.Notification.displayName = 'proto.api.NewMessageEvent.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ReadMessageEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ReadMessageEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ReadMessageEvent.displayName = 'proto.api.ReadMessageEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.JoinMemberEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.JoinMemberEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.JoinMemberEvent.displayName = 'proto.api.JoinMemberEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LeaveMemberEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.LeaveMemberEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LeaveMemberEvent.displayName = 'proto.api.LeaveMemberEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.RenewChannelEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.RenewChannelEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.RenewChannelEvent.displayName = 'proto.api.RenewChannelEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateBubbleGroupEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateBubbleGroupEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateBubbleGroupEvent.displayName = 'proto.api.UpdateBubbleGroupEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateMessageEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateMessageEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateMessageEvent.displayName = 'proto.api.UpdateMessageEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.JoinVoiceChannelEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.JoinVoiceChannelEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.JoinVoiceChannelEvent.displayName = 'proto.api.JoinVoiceChannelEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TerminateVoiceChannelEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.TerminateVoiceChannelEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TerminateVoiceChannelEvent.displayName = 'proto.api.TerminateVoiceChannelEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UserMentionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UserMentionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UserMentionEvent.displayName = 'proto.api.UserMentionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UserMentionEvent.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.UserMentionEvent.Notification.oneofGroups_);
};
goog.inherits(proto.api.UserMentionEvent.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UserMentionEvent.Notification.displayName = 'proto.api.UserMentionEvent.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ReplyEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ReplyEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ReplyEvent.displayName = 'proto.api.ReplyEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ReplyEvent.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.ReplyEvent.Notification.oneofGroups_);
};
goog.inherits(proto.api.ReplyEvent.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ReplyEvent.Notification.displayName = 'proto.api.ReplyEvent.Notification';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.DispatchEvent.oneofGroups_ = [[30,31,32,33,34,35,36,37,38,39,40]];

/**
 * @enum {number}
 */
proto.api.DispatchEvent.EventCase = {
  EVENT_NOT_SET: 0,
  NEW_MESSAGE: 30,
  READ_MESSAGE: 31,
  JOIN_MEMBER: 32,
  LEAVE_MEMBER: 33,
  RENEW_CHANNEL: 34,
  UPDATE_BUBBLE_GROUP: 35,
  UPDATE_MESSAGE: 36,
  JOIN_VOICE_CHANNEL: 37,
  TERMINATE_VOICE_CHANNEL: 38,
  USER_MENTION: 39,
  REPLY: 40
};

/**
 * @return {proto.api.DispatchEvent.EventCase}
 */
proto.api.DispatchEvent.prototype.getEventCase = function() {
  return /** @type {proto.api.DispatchEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.api.DispatchEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DispatchEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DispatchEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DispatchEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DispatchEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && api_model_pb.User.toObject(includeInstance, f),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newMessage: (f = msg.getNewMessage()) && proto.api.NewMessageEvent.toObject(includeInstance, f),
    readMessage: (f = msg.getReadMessage()) && proto.api.ReadMessageEvent.toObject(includeInstance, f),
    joinMember: (f = msg.getJoinMember()) && proto.api.JoinMemberEvent.toObject(includeInstance, f),
    leaveMember: (f = msg.getLeaveMember()) && proto.api.LeaveMemberEvent.toObject(includeInstance, f),
    renewChannel: (f = msg.getRenewChannel()) && proto.api.RenewChannelEvent.toObject(includeInstance, f),
    updateBubbleGroup: (f = msg.getUpdateBubbleGroup()) && proto.api.UpdateBubbleGroupEvent.toObject(includeInstance, f),
    updateMessage: (f = msg.getUpdateMessage()) && proto.api.UpdateMessageEvent.toObject(includeInstance, f),
    joinVoiceChannel: (f = msg.getJoinVoiceChannel()) && proto.api.JoinVoiceChannelEvent.toObject(includeInstance, f),
    terminateVoiceChannel: (f = msg.getTerminateVoiceChannel()) && proto.api.TerminateVoiceChannelEvent.toObject(includeInstance, f),
    userMention: (f = msg.getUserMention()) && proto.api.UserMentionEvent.toObject(includeInstance, f),
    reply: (f = msg.getReply()) && proto.api.ReplyEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DispatchEvent}
 */
proto.api.DispatchEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DispatchEvent;
  return proto.api.DispatchEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DispatchEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DispatchEvent}
 */
proto.api.DispatchEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 30:
      var value = new proto.api.NewMessageEvent;
      reader.readMessage(value,proto.api.NewMessageEvent.deserializeBinaryFromReader);
      msg.setNewMessage(value);
      break;
    case 31:
      var value = new proto.api.ReadMessageEvent;
      reader.readMessage(value,proto.api.ReadMessageEvent.deserializeBinaryFromReader);
      msg.setReadMessage(value);
      break;
    case 32:
      var value = new proto.api.JoinMemberEvent;
      reader.readMessage(value,proto.api.JoinMemberEvent.deserializeBinaryFromReader);
      msg.setJoinMember(value);
      break;
    case 33:
      var value = new proto.api.LeaveMemberEvent;
      reader.readMessage(value,proto.api.LeaveMemberEvent.deserializeBinaryFromReader);
      msg.setLeaveMember(value);
      break;
    case 34:
      var value = new proto.api.RenewChannelEvent;
      reader.readMessage(value,proto.api.RenewChannelEvent.deserializeBinaryFromReader);
      msg.setRenewChannel(value);
      break;
    case 35:
      var value = new proto.api.UpdateBubbleGroupEvent;
      reader.readMessage(value,proto.api.UpdateBubbleGroupEvent.deserializeBinaryFromReader);
      msg.setUpdateBubbleGroup(value);
      break;
    case 36:
      var value = new proto.api.UpdateMessageEvent;
      reader.readMessage(value,proto.api.UpdateMessageEvent.deserializeBinaryFromReader);
      msg.setUpdateMessage(value);
      break;
    case 37:
      var value = new proto.api.JoinVoiceChannelEvent;
      reader.readMessage(value,proto.api.JoinVoiceChannelEvent.deserializeBinaryFromReader);
      msg.setJoinVoiceChannel(value);
      break;
    case 38:
      var value = new proto.api.TerminateVoiceChannelEvent;
      reader.readMessage(value,proto.api.TerminateVoiceChannelEvent.deserializeBinaryFromReader);
      msg.setTerminateVoiceChannel(value);
      break;
    case 39:
      var value = new proto.api.UserMentionEvent;
      reader.readMessage(value,proto.api.UserMentionEvent.deserializeBinaryFromReader);
      msg.setUserMention(value);
      break;
    case 40:
      var value = new proto.api.ReplyEvent;
      reader.readMessage(value,proto.api.ReplyEvent.deserializeBinaryFromReader);
      msg.setReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DispatchEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DispatchEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DispatchEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DispatchEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewMessage();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.api.NewMessageEvent.serializeBinaryToWriter
    );
  }
  f = message.getReadMessage();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.api.ReadMessageEvent.serializeBinaryToWriter
    );
  }
  f = message.getJoinMember();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.api.JoinMemberEvent.serializeBinaryToWriter
    );
  }
  f = message.getLeaveMember();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.api.LeaveMemberEvent.serializeBinaryToWriter
    );
  }
  f = message.getRenewChannel();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.api.RenewChannelEvent.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBubbleGroup();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.api.UpdateBubbleGroupEvent.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMessage();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.api.UpdateMessageEvent.serializeBinaryToWriter
    );
  }
  f = message.getJoinVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.api.JoinVoiceChannelEvent.serializeBinaryToWriter
    );
  }
  f = message.getTerminateVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.api.TerminateVoiceChannelEvent.serializeBinaryToWriter
    );
  }
  f = message.getUserMention();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.api.UserMentionEvent.serializeBinaryToWriter
    );
  }
  f = message.getReply();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.api.ReplyEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.api.User}
 */
proto.api.DispatchEvent.prototype.getUser = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 1));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.api.DispatchEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NewMessageEvent new_message = 30;
 * @return {?proto.api.NewMessageEvent}
 */
proto.api.DispatchEvent.prototype.getNewMessage = function() {
  return /** @type{?proto.api.NewMessageEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.NewMessageEvent, 30));
};


/**
 * @param {?proto.api.NewMessageEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setNewMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearNewMessage = function() {
  return this.setNewMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasNewMessage = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional ReadMessageEvent read_message = 31;
 * @return {?proto.api.ReadMessageEvent}
 */
proto.api.DispatchEvent.prototype.getReadMessage = function() {
  return /** @type{?proto.api.ReadMessageEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.ReadMessageEvent, 31));
};


/**
 * @param {?proto.api.ReadMessageEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setReadMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearReadMessage = function() {
  return this.setReadMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasReadMessage = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional JoinMemberEvent join_member = 32;
 * @return {?proto.api.JoinMemberEvent}
 */
proto.api.DispatchEvent.prototype.getJoinMember = function() {
  return /** @type{?proto.api.JoinMemberEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.JoinMemberEvent, 32));
};


/**
 * @param {?proto.api.JoinMemberEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setJoinMember = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearJoinMember = function() {
  return this.setJoinMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasJoinMember = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional LeaveMemberEvent leave_member = 33;
 * @return {?proto.api.LeaveMemberEvent}
 */
proto.api.DispatchEvent.prototype.getLeaveMember = function() {
  return /** @type{?proto.api.LeaveMemberEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.LeaveMemberEvent, 33));
};


/**
 * @param {?proto.api.LeaveMemberEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setLeaveMember = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearLeaveMember = function() {
  return this.setLeaveMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasLeaveMember = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional RenewChannelEvent renew_channel = 34;
 * @return {?proto.api.RenewChannelEvent}
 */
proto.api.DispatchEvent.prototype.getRenewChannel = function() {
  return /** @type{?proto.api.RenewChannelEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.RenewChannelEvent, 34));
};


/**
 * @param {?proto.api.RenewChannelEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setRenewChannel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearRenewChannel = function() {
  return this.setRenewChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasRenewChannel = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional UpdateBubbleGroupEvent update_bubble_group = 35;
 * @return {?proto.api.UpdateBubbleGroupEvent}
 */
proto.api.DispatchEvent.prototype.getUpdateBubbleGroup = function() {
  return /** @type{?proto.api.UpdateBubbleGroupEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateBubbleGroupEvent, 35));
};


/**
 * @param {?proto.api.UpdateBubbleGroupEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setUpdateBubbleGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearUpdateBubbleGroup = function() {
  return this.setUpdateBubbleGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasUpdateBubbleGroup = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional UpdateMessageEvent update_message = 36;
 * @return {?proto.api.UpdateMessageEvent}
 */
proto.api.DispatchEvent.prototype.getUpdateMessage = function() {
  return /** @type{?proto.api.UpdateMessageEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateMessageEvent, 36));
};


/**
 * @param {?proto.api.UpdateMessageEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setUpdateMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearUpdateMessage = function() {
  return this.setUpdateMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasUpdateMessage = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional JoinVoiceChannelEvent join_voice_channel = 37;
 * @return {?proto.api.JoinVoiceChannelEvent}
 */
proto.api.DispatchEvent.prototype.getJoinVoiceChannel = function() {
  return /** @type{?proto.api.JoinVoiceChannelEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.JoinVoiceChannelEvent, 37));
};


/**
 * @param {?proto.api.JoinVoiceChannelEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setJoinVoiceChannel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearJoinVoiceChannel = function() {
  return this.setJoinVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasJoinVoiceChannel = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional TerminateVoiceChannelEvent terminate_voice_channel = 38;
 * @return {?proto.api.TerminateVoiceChannelEvent}
 */
proto.api.DispatchEvent.prototype.getTerminateVoiceChannel = function() {
  return /** @type{?proto.api.TerminateVoiceChannelEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.TerminateVoiceChannelEvent, 38));
};


/**
 * @param {?proto.api.TerminateVoiceChannelEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setTerminateVoiceChannel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearTerminateVoiceChannel = function() {
  return this.setTerminateVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasTerminateVoiceChannel = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional UserMentionEvent user_mention = 39;
 * @return {?proto.api.UserMentionEvent}
 */
proto.api.DispatchEvent.prototype.getUserMention = function() {
  return /** @type{?proto.api.UserMentionEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.UserMentionEvent, 39));
};


/**
 * @param {?proto.api.UserMentionEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setUserMention = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearUserMention = function() {
  return this.setUserMention(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasUserMention = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional ReplyEvent reply = 40;
 * @return {?proto.api.ReplyEvent}
 */
proto.api.DispatchEvent.prototype.getReply = function() {
  return /** @type{?proto.api.ReplyEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.ReplyEvent, 40));
};


/**
 * @param {?proto.api.ReplyEvent|undefined} value
 * @return {!proto.api.DispatchEvent} returns this
*/
proto.api.DispatchEvent.prototype.setReply = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.api.DispatchEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DispatchEvent} returns this
 */
proto.api.DispatchEvent.prototype.clearReply = function() {
  return this.setReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DispatchEvent.prototype.hasReply = function() {
  return jspb.Message.getField(this, 40) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MessageStyleNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MessageStyleNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MessageStyleNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MessageStyleNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    landingDeeplinkUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isSilence: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isMute: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isReadOnly: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MessageStyleNotification}
 */
proto.api.MessageStyleNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MessageStyleNotification;
  return proto.api.MessageStyleNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MessageStyleNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MessageStyleNotification}
 */
proto.api.MessageStyleNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandingDeeplinkUrl(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSilence(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMute(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReadOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MessageStyleNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MessageStyleNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MessageStyleNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MessageStyleNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLandingDeeplinkUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsSilence();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsMute();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsReadOnly();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.api.MessageStyleNotification.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subtitle = 2;
 * @return {string}
 */
proto.api.MessageStyleNotification.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setSubtitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.clearSubtitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MessageStyleNotification.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string body = 3;
 * @return {string}
 */
proto.api.MessageStyleNotification.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_image_url = 4;
 * @return {string}
 */
proto.api.MessageStyleNotification.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_image_url = 5;
 * @return {string}
 */
proto.api.MessageStyleNotification.prototype.getThumbnailImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setThumbnailImageUrl = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.clearThumbnailImageUrl = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MessageStyleNotification.prototype.hasThumbnailImageUrl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string landing_deeplink_url = 6;
 * @return {string}
 */
proto.api.MessageStyleNotification.prototype.getLandingDeeplinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setLandingDeeplinkUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_silence = 7;
 * @return {boolean}
 */
proto.api.MessageStyleNotification.prototype.getIsSilence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setIsSilence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_mute = 8;
 * @return {boolean}
 */
proto.api.MessageStyleNotification.prototype.getIsMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setIsMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_read_only = 9;
 * @return {boolean}
 */
proto.api.MessageStyleNotification.prototype.getIsReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.MessageStyleNotification} returns this
 */
proto.api.MessageStyleNotification.prototype.setIsReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.NewMessageEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.NewMessageEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.NewMessageEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NewMessageEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    senderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    senderNickname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    senderProfile: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    silence: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    mute: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    targetUri: jspb.Message.getFieldWithDefault(msg, 8, ""),
    resourceTitle: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unreadCount: (f = msg.getUnreadCount()) && api_model_pb.UnreadCount.toObject(includeInstance, f),
    notificationSound: jspb.Message.getFieldWithDefault(msg, 13, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    sender: (f = msg.getSender()) && api_model_pb.User.toObject(includeInstance, f),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    notification: (f = msg.getNotification()) && proto.api.NewMessageEvent.Notification.toObject(includeInstance, f),
    readonly: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    receiver: (f = msg.getReceiver()) && api_model_pb.User.toObject(includeInstance, f),
    sessionId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    collapseId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 22, ""),
    templateType: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.NewMessageEvent}
 */
proto.api.NewMessageEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.NewMessageEvent;
  return proto.api.NewMessageEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.NewMessageEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.NewMessageEvent}
 */
proto.api.NewMessageEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderNickname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderProfile(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSilence(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceTitle(value);
      break;
    case 10:
      var value = new api_model_pb.UnreadCount;
      reader.readMessage(value,api_model_pb.UnreadCount.deserializeBinaryFromReader);
      msg.setUnreadCount(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotificationSound(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 15:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 17:
      var value = new proto.api.NewMessageEvent.Notification;
      reader.readMessage(value,proto.api.NewMessageEvent.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 19:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollapseId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    case 23:
      var value = /** @type {!proto.api.TemplateType} */ (reader.readEnum());
      msg.setTemplateType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.NewMessageEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.NewMessageEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.NewMessageEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NewMessageEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSenderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSenderNickname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSenderProfile();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSilence();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMute();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getResourceTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnreadCount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      api_model_pb.UnreadCount.serializeBinaryToWriter
    );
  }
  f = message.getNotificationSound();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.api.NewMessageEvent.Notification.serializeBinaryToWriter
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCollapseId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTemplateType();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.NewMessageEvent.Notification.oneofGroups_ = [[8]];

/**
 * @enum {number}
 */
proto.api.NewMessageEvent.Notification.NotificationStyleCase = {
  NOTIFICATION_STYLE_NOT_SET: 0,
  MESSAGE_STYLE_NOTIFICATION: 8
};

/**
 * @return {proto.api.NewMessageEvent.Notification.NotificationStyleCase}
 */
proto.api.NewMessageEvent.Notification.prototype.getNotificationStyleCase = function() {
  return /** @type {proto.api.NewMessageEvent.Notification.NotificationStyleCase} */(jspb.Message.computeOneofCase(this, proto.api.NewMessageEvent.Notification.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.NewMessageEvent.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.api.NewMessageEvent.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.NewMessageEvent.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NewMessageEvent.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    largeImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isCircleLargeIcon: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    translatedPushTitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    messageStyleNotification: (f = msg.getMessageStyleNotification()) && proto.api.MessageStyleNotification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.NewMessageEvent.Notification}
 */
proto.api.NewMessageEvent.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.NewMessageEvent.Notification;
  return proto.api.NewMessageEvent.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.NewMessageEvent.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.NewMessageEvent.Notification}
 */
proto.api.NewMessageEvent.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLargeImageUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCircleLargeIcon(value);
      break;
    case 6:
      var value = /** @type {!proto.api.NewMessageEvent.Notification.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslatedPushTitle(value);
      break;
    case 8:
      var value = new proto.api.MessageStyleNotification;
      reader.readMessage(value,proto.api.MessageStyleNotification.deserializeBinaryFromReader);
      msg.setMessageStyleNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.NewMessageEvent.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.NewMessageEvent.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.NewMessageEvent.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NewMessageEvent.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLargeImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsCircleLargeIcon();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTranslatedPushTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMessageStyleNotification();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.MessageStyleNotification.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.NewMessageEvent.Notification.Type = {
  TYPE_UNSPECIFIED: 0,
  NORMAL: 1,
  BIZ_CHAT: 2
};

/**
 * optional string summary = 1;
 * @return {string}
 */
proto.api.NewMessageEvent.Notification.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nickname = 2;
 * @return {string}
 */
proto.api.NewMessageEvent.Notification.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.api.NewMessageEvent.Notification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string large_image_url = 4;
 * @return {string}
 */
proto.api.NewMessageEvent.Notification.prototype.getLargeImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setLargeImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_circle_large_icon = 5;
 * @return {boolean}
 */
proto.api.NewMessageEvent.Notification.prototype.getIsCircleLargeIcon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setIsCircleLargeIcon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional Type type = 6;
 * @return {!proto.api.NewMessageEvent.Notification.Type}
 */
proto.api.NewMessageEvent.Notification.prototype.getType = function() {
  return /** @type {!proto.api.NewMessageEvent.Notification.Type} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.api.NewMessageEvent.Notification.Type} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string translated_push_title = 7;
 * @return {string}
 */
proto.api.NewMessageEvent.Notification.prototype.getTranslatedPushTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.setTranslatedPushTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional MessageStyleNotification message_style_notification = 8;
 * @return {?proto.api.MessageStyleNotification}
 */
proto.api.NewMessageEvent.Notification.prototype.getMessageStyleNotification = function() {
  return /** @type{?proto.api.MessageStyleNotification} */ (
    jspb.Message.getWrapperField(this, proto.api.MessageStyleNotification, 8));
};


/**
 * @param {?proto.api.MessageStyleNotification|undefined} value
 * @return {!proto.api.NewMessageEvent.Notification} returns this
*/
proto.api.NewMessageEvent.Notification.prototype.setMessageStyleNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.api.NewMessageEvent.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.NewMessageEvent.Notification} returns this
 */
proto.api.NewMessageEvent.Notification.prototype.clearMessageStyleNotification = function() {
  return this.setMessageStyleNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NewMessageEvent.Notification.prototype.hasMessageStyleNotification = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 sender_id = 2;
 * @return {number}
 */
proto.api.NewMessageEvent.prototype.getSenderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sender_nickname = 3;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getSenderNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setSenderNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sender_profile = 4;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getSenderProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setSenderProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool silence = 6;
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.getSilence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setSilence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool mute = 7;
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string target_uri = 8;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string resource_title = 9;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getResourceTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setResourceTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional UnreadCount unread_count = 10;
 * @return {?proto.api.UnreadCount}
 */
proto.api.NewMessageEvent.prototype.getUnreadCount = function() {
  return /** @type{?proto.api.UnreadCount} */ (
    jspb.Message.getWrapperField(this, api_model_pb.UnreadCount, 10));
};


/**
 * @param {?proto.api.UnreadCount|undefined} value
 * @return {!proto.api.NewMessageEvent} returns this
*/
proto.api.NewMessageEvent.prototype.setUnreadCount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.clearUnreadCount = function() {
  return this.setUnreadCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.hasUnreadCount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string notification_sound = 13;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getNotificationSound = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setNotificationSound = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 message_id = 14;
 * @return {number}
 */
proto.api.NewMessageEvent.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional User sender = 15;
 * @return {?proto.api.User}
 */
proto.api.NewMessageEvent.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 15));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.NewMessageEvent} returns this
*/
proto.api.NewMessageEvent.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.hasSender = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 biz_account_id = 16;
 * @return {number}
 */
proto.api.NewMessageEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional Notification notification = 17;
 * @return {?proto.api.NewMessageEvent.Notification}
 */
proto.api.NewMessageEvent.prototype.getNotification = function() {
  return /** @type{?proto.api.NewMessageEvent.Notification} */ (
    jspb.Message.getWrapperField(this, proto.api.NewMessageEvent.Notification, 17));
};


/**
 * @param {?proto.api.NewMessageEvent.Notification|undefined} value
 * @return {!proto.api.NewMessageEvent} returns this
*/
proto.api.NewMessageEvent.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool readonly = 18;
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setReadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional User receiver = 19;
 * @return {?proto.api.User}
 */
proto.api.NewMessageEvent.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 19));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.NewMessageEvent} returns this
*/
proto.api.NewMessageEvent.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NewMessageEvent.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string session_id = 20;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string collapse_id = 21;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getCollapseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setCollapseId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string message_type = 22;
 * @return {string}
 */
proto.api.NewMessageEvent.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional TemplateType template_type = 23;
 * @return {!proto.api.TemplateType}
 */
proto.api.NewMessageEvent.prototype.getTemplateType = function() {
  return /** @type {!proto.api.TemplateType} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.api.TemplateType} value
 * @return {!proto.api.NewMessageEvent} returns this
 */
proto.api.NewMessageEvent.prototype.setTemplateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ReadMessageEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ReadMessageEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ReadMessageEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReadMessageEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deprecated: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reader: (f = msg.getReader()) && api_model_pb.User.toObject(includeInstance, f),
    sessionId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ReadMessageEvent}
 */
proto.api.ReadMessageEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ReadMessageEvent;
  return proto.api.ReadMessageEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ReadMessageEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ReadMessageEvent}
 */
proto.api.ReadMessageEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeprecated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 6:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReader(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ReadMessageEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ReadMessageEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ReadMessageEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReadMessageEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeprecated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getReader();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.ReadMessageEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.ReadMessageEvent.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 DEPRECATED = 3;
 * @return {number}
 */
proto.api.ReadMessageEvent.prototype.getDeprecated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.setDeprecated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string target_uri = 4;
 * @return {string}
 */
proto.api.ReadMessageEvent.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 biz_account_id = 5;
 * @return {number}
 */
proto.api.ReadMessageEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional User reader = 6;
 * @return {?proto.api.User}
 */
proto.api.ReadMessageEvent.prototype.getReader = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 6));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.ReadMessageEvent} returns this
*/
proto.api.ReadMessageEvent.prototype.setReader = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.clearReader = function() {
  return this.setReader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReadMessageEvent.prototype.hasReader = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string session_id = 7;
 * @return {string}
 */
proto.api.ReadMessageEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReadMessageEvent} returns this
 */
proto.api.ReadMessageEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.JoinMemberEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.JoinMemberEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.JoinMemberEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.JoinMemberEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    member: (f = msg.getMember()) && api_model_pb.User.toObject(includeInstance, f),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.JoinMemberEvent}
 */
proto.api.JoinMemberEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.JoinMemberEvent;
  return proto.api.JoinMemberEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.JoinMemberEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.JoinMemberEvent}
 */
proto.api.JoinMemberEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.JoinMemberEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.JoinMemberEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.JoinMemberEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.JoinMemberEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.JoinMemberEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.JoinMemberEvent} returns this
 */
proto.api.JoinMemberEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User member = 2;
 * @return {?proto.api.User}
 */
proto.api.JoinMemberEvent.prototype.getMember = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.JoinMemberEvent} returns this
*/
proto.api.JoinMemberEvent.prototype.setMember = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.JoinMemberEvent} returns this
 */
proto.api.JoinMemberEvent.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.JoinMemberEvent.prototype.hasMember = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 biz_account_id = 3;
 * @return {number}
 */
proto.api.JoinMemberEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.JoinMemberEvent} returns this
 */
proto.api.JoinMemberEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string session_id = 4;
 * @return {string}
 */
proto.api.JoinMemberEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.JoinMemberEvent} returns this
 */
proto.api.JoinMemberEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LeaveMemberEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LeaveMemberEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LeaveMemberEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LeaveMemberEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    member: (f = msg.getMember()) && api_model_pb.User.toObject(includeInstance, f),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    by: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LeaveMemberEvent}
 */
proto.api.LeaveMemberEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LeaveMemberEvent;
  return proto.api.LeaveMemberEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LeaveMemberEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LeaveMemberEvent}
 */
proto.api.LeaveMemberEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 4:
      var value = /** @type {!proto.api.LeaveMemberEvent.By} */ (reader.readEnum());
      msg.setBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LeaveMemberEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LeaveMemberEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LeaveMemberEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LeaveMemberEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.LeaveMemberEvent.By = {
  SELF: 0,
  OWNER: 1,
  ADMIN: 2
};

/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.LeaveMemberEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LeaveMemberEvent} returns this
 */
proto.api.LeaveMemberEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User member = 2;
 * @return {?proto.api.User}
 */
proto.api.LeaveMemberEvent.prototype.getMember = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.LeaveMemberEvent} returns this
*/
proto.api.LeaveMemberEvent.prototype.setMember = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.LeaveMemberEvent} returns this
 */
proto.api.LeaveMemberEvent.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.LeaveMemberEvent.prototype.hasMember = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 biz_account_id = 3;
 * @return {number}
 */
proto.api.LeaveMemberEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.LeaveMemberEvent} returns this
 */
proto.api.LeaveMemberEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional By by = 4;
 * @return {!proto.api.LeaveMemberEvent.By}
 */
proto.api.LeaveMemberEvent.prototype.getBy = function() {
  return /** @type {!proto.api.LeaveMemberEvent.By} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.api.LeaveMemberEvent.By} value
 * @return {!proto.api.LeaveMemberEvent} returns this
 */
proto.api.LeaveMemberEvent.prototype.setBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string session_id = 5;
 * @return {string}
 */
proto.api.LeaveMemberEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LeaveMemberEvent} returns this
 */
proto.api.LeaveMemberEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.RenewChannelEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.RenewChannelEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.RenewChannelEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RenewChannelEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.RenewChannelEvent}
 */
proto.api.RenewChannelEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.RenewChannelEvent;
  return proto.api.RenewChannelEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.RenewChannelEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.RenewChannelEvent}
 */
proto.api.RenewChannelEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.RenewChannelEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.RenewChannelEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.RenewChannelEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RenewChannelEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.RenewChannelEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.RenewChannelEvent} returns this
 */
proto.api.RenewChannelEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 biz_account_id = 2;
 * @return {number}
 */
proto.api.RenewChannelEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.RenewChannelEvent} returns this
 */
proto.api.RenewChannelEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string session_id = 3;
 * @return {string}
 */
proto.api.RenewChannelEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.RenewChannelEvent} returns this
 */
proto.api.RenewChannelEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateBubbleGroupEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateBubbleGroupEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateBubbleGroupEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBubbleGroupEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bubbleGroup: (f = msg.getBubbleGroup()) && api_model_pb.BubbleGroup.toObject(includeInstance, f),
    sessionId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateBubbleGroupEvent}
 */
proto.api.UpdateBubbleGroupEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateBubbleGroupEvent;
  return proto.api.UpdateBubbleGroupEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateBubbleGroupEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateBubbleGroupEvent}
 */
proto.api.UpdateBubbleGroupEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 3:
      var value = new api_model_pb.BubbleGroup;
      reader.readMessage(value,api_model_pb.BubbleGroup.deserializeBinaryFromReader);
      msg.setBubbleGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateBubbleGroupEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateBubbleGroupEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateBubbleGroupEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBubbleGroupEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBubbleGroup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      api_model_pb.BubbleGroup.serializeBinaryToWriter
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.UpdateBubbleGroupEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateBubbleGroupEvent} returns this
 */
proto.api.UpdateBubbleGroupEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 biz_account_id = 2;
 * @return {number}
 */
proto.api.UpdateBubbleGroupEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdateBubbleGroupEvent} returns this
 */
proto.api.UpdateBubbleGroupEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BubbleGroup bubble_group = 3;
 * @return {?proto.api.BubbleGroup}
 */
proto.api.UpdateBubbleGroupEvent.prototype.getBubbleGroup = function() {
  return /** @type{?proto.api.BubbleGroup} */ (
    jspb.Message.getWrapperField(this, api_model_pb.BubbleGroup, 3));
};


/**
 * @param {?proto.api.BubbleGroup|undefined} value
 * @return {!proto.api.UpdateBubbleGroupEvent} returns this
*/
proto.api.UpdateBubbleGroupEvent.prototype.setBubbleGroup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateBubbleGroupEvent} returns this
 */
proto.api.UpdateBubbleGroupEvent.prototype.clearBubbleGroup = function() {
  return this.setBubbleGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateBubbleGroupEvent.prototype.hasBubbleGroup = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string session_id = 4;
 * @return {string}
 */
proto.api.UpdateBubbleGroupEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateBubbleGroupEvent} returns this
 */
proto.api.UpdateBubbleGroupEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateMessageEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateMessageEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateMessageEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateMessageEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: (f = msg.getMessage()) && api_model_pb.Message.toObject(includeInstance, f),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messageId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateMessageEvent}
 */
proto.api.UpdateMessageEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateMessageEvent;
  return proto.api.UpdateMessageEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateMessageEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateMessageEvent}
 */
proto.api.UpdateMessageEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateMessageEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateMessageEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateMessageEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateMessageEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.UpdateMessageEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateMessageEvent} returns this
 */
proto.api.UpdateMessageEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Message message = 2;
 * @return {?proto.api.Message}
 */
proto.api.UpdateMessageEvent.prototype.getMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Message, 2));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.UpdateMessageEvent} returns this
*/
proto.api.UpdateMessageEvent.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateMessageEvent} returns this
 */
proto.api.UpdateMessageEvent.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateMessageEvent.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 biz_account_id = 3;
 * @return {number}
 */
proto.api.UpdateMessageEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdateMessageEvent} returns this
 */
proto.api.UpdateMessageEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.UpdateMessageEvent} returns this
 */
proto.api.UpdateMessageEvent.prototype.clearBizAccountId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateMessageEvent.prototype.hasBizAccountId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 message_id = 4;
 * @return {number}
 */
proto.api.UpdateMessageEvent.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdateMessageEvent} returns this
 */
proto.api.UpdateMessageEvent.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string session_id = 100;
 * @return {string}
 */
proto.api.UpdateMessageEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateMessageEvent} returns this
 */
proto.api.UpdateMessageEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.JoinVoiceChannelEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.JoinVoiceChannelEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.JoinVoiceChannelEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.JoinVoiceChannelEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && api_model_pb.User.toObject(includeInstance, f),
    sessionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.JoinVoiceChannelEvent}
 */
proto.api.JoinVoiceChannelEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.JoinVoiceChannelEvent;
  return proto.api.JoinVoiceChannelEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.JoinVoiceChannelEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.JoinVoiceChannelEvent}
 */
proto.api.JoinVoiceChannelEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.JoinVoiceChannelEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.JoinVoiceChannelEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.JoinVoiceChannelEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.JoinVoiceChannelEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.JoinVoiceChannelEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.JoinVoiceChannelEvent} returns this
 */
proto.api.JoinVoiceChannelEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.api.User}
 */
proto.api.JoinVoiceChannelEvent.prototype.getUser = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.JoinVoiceChannelEvent} returns this
*/
proto.api.JoinVoiceChannelEvent.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.JoinVoiceChannelEvent} returns this
 */
proto.api.JoinVoiceChannelEvent.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.JoinVoiceChannelEvent.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string session_id = 3;
 * @return {string}
 */
proto.api.JoinVoiceChannelEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.JoinVoiceChannelEvent} returns this
 */
proto.api.JoinVoiceChannelEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TerminateVoiceChannelEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TerminateVoiceChannelEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TerminateVoiceChannelEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TerminateVoiceChannelEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TerminateVoiceChannelEvent}
 */
proto.api.TerminateVoiceChannelEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TerminateVoiceChannelEvent;
  return proto.api.TerminateVoiceChannelEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TerminateVoiceChannelEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TerminateVoiceChannelEvent}
 */
proto.api.TerminateVoiceChannelEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.TerminateVoiceChannelReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TerminateVoiceChannelEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TerminateVoiceChannelEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TerminateVoiceChannelEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TerminateVoiceChannelEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.TerminateVoiceChannelEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TerminateVoiceChannelEvent} returns this
 */
proto.api.TerminateVoiceChannelEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TerminateVoiceChannelReason reason = 2;
 * @return {!proto.api.TerminateVoiceChannelReason}
 */
proto.api.TerminateVoiceChannelEvent.prototype.getReason = function() {
  return /** @type {!proto.api.TerminateVoiceChannelReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.TerminateVoiceChannelReason} value
 * @return {!proto.api.TerminateVoiceChannelEvent} returns this
 */
proto.api.TerminateVoiceChannelEvent.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string session_id = 3;
 * @return {string}
 */
proto.api.TerminateVoiceChannelEvent.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TerminateVoiceChannelEvent} returns this
 */
proto.api.TerminateVoiceChannelEvent.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UserMentionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UserMentionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UserMentionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserMentionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sender: (f = msg.getSender()) && api_model_pb.User.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && api_model_pb.User.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.api.UserMentionEvent.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UserMentionEvent}
 */
proto.api.UserMentionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UserMentionEvent;
  return proto.api.UserMentionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UserMentionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UserMentionEvent}
 */
proto.api.UserMentionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 5:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 6:
      var value = new proto.api.UserMentionEvent.Notification;
      reader.readMessage(value,proto.api.UserMentionEvent.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UserMentionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UserMentionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UserMentionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserMentionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.UserMentionEvent.Notification.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.UserMentionEvent.Notification.oneofGroups_ = [[9]];

/**
 * @enum {number}
 */
proto.api.UserMentionEvent.Notification.NotificationStyleCase = {
  NOTIFICATION_STYLE_NOT_SET: 0,
  MESSAGE_STYLE_NOTIFICATION: 9
};

/**
 * @return {proto.api.UserMentionEvent.Notification.NotificationStyleCase}
 */
proto.api.UserMentionEvent.Notification.prototype.getNotificationStyleCase = function() {
  return /** @type {proto.api.UserMentionEvent.Notification.NotificationStyleCase} */(jspb.Message.computeOneofCase(this, proto.api.UserMentionEvent.Notification.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UserMentionEvent.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UserMentionEvent.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UserMentionEvent.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserMentionEvent.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    largeImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isCircleLargeIcon: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    targetUri: jspb.Message.getFieldWithDefault(msg, 6, ""),
    mute: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    silence: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    messageStyleNotification: (f = msg.getMessageStyleNotification()) && proto.api.MessageStyleNotification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UserMentionEvent.Notification}
 */
proto.api.UserMentionEvent.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UserMentionEvent.Notification;
  return proto.api.UserMentionEvent.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UserMentionEvent.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UserMentionEvent.Notification}
 */
proto.api.UserMentionEvent.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLargeImageUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCircleLargeIcon(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSilence(value);
      break;
    case 9:
      var value = new proto.api.MessageStyleNotification;
      reader.readMessage(value,proto.api.MessageStyleNotification.deserializeBinaryFromReader);
      msg.setMessageStyleNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UserMentionEvent.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UserMentionEvent.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UserMentionEvent.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserMentionEvent.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLargeImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsCircleLargeIcon();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMute();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSilence();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMessageStyleNotification();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.api.MessageStyleNotification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string summary = 1;
 * @return {string}
 */
proto.api.UserMentionEvent.Notification.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nickname = 2;
 * @return {string}
 */
proto.api.UserMentionEvent.Notification.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.api.UserMentionEvent.Notification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string large_image_url = 4;
 * @return {string}
 */
proto.api.UserMentionEvent.Notification.prototype.getLargeImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setLargeImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_circle_large_icon = 5;
 * @return {boolean}
 */
proto.api.UserMentionEvent.Notification.prototype.getIsCircleLargeIcon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setIsCircleLargeIcon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string target_uri = 6;
 * @return {string}
 */
proto.api.UserMentionEvent.Notification.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool mute = 7;
 * @return {boolean}
 */
proto.api.UserMentionEvent.Notification.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool silence = 8;
 * @return {boolean}
 */
proto.api.UserMentionEvent.Notification.prototype.getSilence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.setSilence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional MessageStyleNotification message_style_notification = 9;
 * @return {?proto.api.MessageStyleNotification}
 */
proto.api.UserMentionEvent.Notification.prototype.getMessageStyleNotification = function() {
  return /** @type{?proto.api.MessageStyleNotification} */ (
    jspb.Message.getWrapperField(this, proto.api.MessageStyleNotification, 9));
};


/**
 * @param {?proto.api.MessageStyleNotification|undefined} value
 * @return {!proto.api.UserMentionEvent.Notification} returns this
*/
proto.api.UserMentionEvent.Notification.prototype.setMessageStyleNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.api.UserMentionEvent.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UserMentionEvent.Notification} returns this
 */
proto.api.UserMentionEvent.Notification.prototype.clearMessageStyleNotification = function() {
  return this.setMessageStyleNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UserMentionEvent.Notification.prototype.hasMessageStyleNotification = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.UserMentionEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent} returns this
 */
proto.api.UserMentionEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.UserMentionEvent.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UserMentionEvent} returns this
 */
proto.api.UserMentionEvent.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.api.UserMentionEvent.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserMentionEvent} returns this
 */
proto.api.UserMentionEvent.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional User sender = 4;
 * @return {?proto.api.User}
 */
proto.api.UserMentionEvent.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 4));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.UserMentionEvent} returns this
*/
proto.api.UserMentionEvent.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UserMentionEvent} returns this
 */
proto.api.UserMentionEvent.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UserMentionEvent.prototype.hasSender = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User receiver = 5;
 * @return {?proto.api.User}
 */
proto.api.UserMentionEvent.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 5));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.UserMentionEvent} returns this
*/
proto.api.UserMentionEvent.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UserMentionEvent} returns this
 */
proto.api.UserMentionEvent.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UserMentionEvent.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Notification notification = 6;
 * @return {?proto.api.UserMentionEvent.Notification}
 */
proto.api.UserMentionEvent.prototype.getNotification = function() {
  return /** @type{?proto.api.UserMentionEvent.Notification} */ (
    jspb.Message.getWrapperField(this, proto.api.UserMentionEvent.Notification, 6));
};


/**
 * @param {?proto.api.UserMentionEvent.Notification|undefined} value
 * @return {!proto.api.UserMentionEvent} returns this
*/
proto.api.UserMentionEvent.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UserMentionEvent} returns this
 */
proto.api.UserMentionEvent.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UserMentionEvent.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ReplyEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ReplyEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ReplyEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReplyEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sender: (f = msg.getSender()) && api_model_pb.User.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && api_model_pb.User.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.api.ReplyEvent.Notification.toObject(includeInstance, f),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ReplyEvent}
 */
proto.api.ReplyEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ReplyEvent;
  return proto.api.ReplyEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ReplyEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ReplyEvent}
 */
proto.api.ReplyEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 5:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 6:
      var value = new proto.api.ReplyEvent.Notification;
      reader.readMessage(value,proto.api.ReplyEvent.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ReplyEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ReplyEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ReplyEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReplyEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.ReplyEvent.Notification.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.ReplyEvent.Notification.oneofGroups_ = [[6]];

/**
 * @enum {number}
 */
proto.api.ReplyEvent.Notification.NotificationStyleCase = {
  NOTIFICATION_STYLE_NOT_SET: 0,
  MESSAGE_STYLE_NOTIFICATION: 6
};

/**
 * @return {proto.api.ReplyEvent.Notification.NotificationStyleCase}
 */
proto.api.ReplyEvent.Notification.prototype.getNotificationStyleCase = function() {
  return /** @type {proto.api.ReplyEvent.Notification.NotificationStyleCase} */(jspb.Message.computeOneofCase(this, proto.api.ReplyEvent.Notification.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ReplyEvent.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ReplyEvent.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ReplyEvent.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReplyEvent.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    nickname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mute: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    readonly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    summary: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messageStyleNotification: (f = msg.getMessageStyleNotification()) && proto.api.MessageStyleNotification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ReplyEvent.Notification}
 */
proto.api.ReplyEvent.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ReplyEvent.Notification;
  return proto.api.ReplyEvent.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ReplyEvent.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ReplyEvent.Notification}
 */
proto.api.ReplyEvent.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 6:
      var value = new proto.api.MessageStyleNotification;
      reader.readMessage(value,proto.api.MessageStyleNotification.deserializeBinaryFromReader);
      msg.setMessageStyleNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ReplyEvent.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ReplyEvent.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ReplyEvent.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ReplyEvent.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMute();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessageStyleNotification();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.MessageStyleNotification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string nickname = 1;
 * @return {string}
 */
proto.api.ReplyEvent.Notification.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReplyEvent.Notification} returns this
 */
proto.api.ReplyEvent.Notification.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profile_image_url = 2;
 * @return {string}
 */
proto.api.ReplyEvent.Notification.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReplyEvent.Notification} returns this
 */
proto.api.ReplyEvent.Notification.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool mute = 3;
 * @return {boolean}
 */
proto.api.ReplyEvent.Notification.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ReplyEvent.Notification} returns this
 */
proto.api.ReplyEvent.Notification.prototype.setMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool readonly = 4;
 * @return {boolean}
 */
proto.api.ReplyEvent.Notification.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.ReplyEvent.Notification} returns this
 */
proto.api.ReplyEvent.Notification.prototype.setReadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string summary = 5;
 * @return {string}
 */
proto.api.ReplyEvent.Notification.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReplyEvent.Notification} returns this
 */
proto.api.ReplyEvent.Notification.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional MessageStyleNotification message_style_notification = 6;
 * @return {?proto.api.MessageStyleNotification}
 */
proto.api.ReplyEvent.Notification.prototype.getMessageStyleNotification = function() {
  return /** @type{?proto.api.MessageStyleNotification} */ (
    jspb.Message.getWrapperField(this, proto.api.MessageStyleNotification, 6));
};


/**
 * @param {?proto.api.MessageStyleNotification|undefined} value
 * @return {!proto.api.ReplyEvent.Notification} returns this
*/
proto.api.ReplyEvent.Notification.prototype.setMessageStyleNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.api.ReplyEvent.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReplyEvent.Notification} returns this
 */
proto.api.ReplyEvent.Notification.prototype.clearMessageStyleNotification = function() {
  return this.setMessageStyleNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReplyEvent.Notification.prototype.hasMessageStyleNotification = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.ReplyEvent.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.ReplyEvent.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.api.ReplyEvent.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional User sender = 4;
 * @return {?proto.api.User}
 */
proto.api.ReplyEvent.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 4));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.ReplyEvent} returns this
*/
proto.api.ReplyEvent.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReplyEvent.prototype.hasSender = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User receiver = 5;
 * @return {?proto.api.User}
 */
proto.api.ReplyEvent.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 5));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.ReplyEvent} returns this
*/
proto.api.ReplyEvent.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReplyEvent.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Notification notification = 6;
 * @return {?proto.api.ReplyEvent.Notification}
 */
proto.api.ReplyEvent.prototype.getNotification = function() {
  return /** @type{?proto.api.ReplyEvent.Notification} */ (
    jspb.Message.getWrapperField(this, proto.api.ReplyEvent.Notification, 6));
};


/**
 * @param {?proto.api.ReplyEvent.Notification|undefined} value
 * @return {!proto.api.ReplyEvent} returns this
*/
proto.api.ReplyEvent.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReplyEvent.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 biz_account_id = 7;
 * @return {number}
 */
proto.api.ReplyEvent.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.setBizAccountId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ReplyEvent} returns this
 */
proto.api.ReplyEvent.prototype.clearBizAccountId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ReplyEvent.prototype.hasBizAccountId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.api.TerminateVoiceChannelReason = {
  TERMINATE_VOICE_CHANNEL_REASON_TYPE_UNSPECIFIED: 0,
  TERMINATE_VOICE_CHANNEL_REASON_BY_USER: 1,
  TERMINATE_VOICE_CHANNEL_REASON_STATUS_CALLING: 2,
  TERMINATE_VOICE_CHANNEL_REASON_TIMEOUT: 3,
  TERMINATE_VOICE_CHANNEL_REASON_PERMISSION_REQUIRED: 4
};

goog.object.extend(exports, proto.api);
