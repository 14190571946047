import { OptionModalStyle } from './ChatOptionStyle'
import { ButtonInModal } from 'components/common/commonStyle'
import { ChannelTypes } from 'types/ChatTypes'

interface BlockUserProps {
  closeModal: Function
  selectChannelobj: ChannelTypes
  blockChannel: Function
}
const BlockUser = ({ closeModal, selectChannelobj, blockChannel }: BlockUserProps) => {
  const handleClose = () => closeModal()
  return (
    <OptionModalStyle>
      <div className="content">{`차단하면 ${selectChannelobj.receiver?.nickname}님의 게시글은 보이지 않고, 나에게 댓글과 채팅도 보낼 수 없어요. 차단하시겠어요?`}</div>
      <div className="button-wrapper">
        <ButtonInModal className="common-bg-hover" onClick={handleClose}>취소</ButtonInModal>
        <ButtonInModal className="confirm" onClick={() => blockChannel(true)}>
          네, 차단할래요
        </ButtonInModal>
      </div>
    </OptionModalStyle>
  )
}

export default BlockUser
