import React, { memo } from 'react'
import dayjs from 'dayjs'

import { ChannelTypes, MessageTypes, ModalTypes } from 'types/ChatTypes'
import { AnnoMessage, SystMessage, MessageWrapper, MessageProfile, MessageSubInfo } from './ChatMessageStyle'
import { checkAppScheme, ProfileParamConverter } from 'utils/snippet'
import TemplateMessage from './TemplateMessage'
import ChatContent from './ChatContent'
import MessageUnread from './MessageUnread'
import MessageHover from './MessageHover'
import { openToast } from 'utils/localDispatcher'

const systTypeToString = {
  1: '안내',
  2: '알림',
  3: '경고',
}
const systTypeToClass = {
  1: 'info',
  2: 'alert',
  3: 'warning',
}

const allowToolbox = (messageItem: MessageTypes, msTargetDate: number) => {
  const type = messageItem.type
  if (type === 'STIC' || type === 'IMAG' || type === 'GALL' || (type === 'TEMP' && messageItem.templateType === 3)) {
    if (messageItem.deleteTime || msTargetDate + 1000 * 60 * 5 < new Date().getTime()) {
      return false
    } else {
      return true
    }
  } else if (type === 'TEXT') {
    return messageItem.deleteTime ? false : true
  }
}

interface ChatMessageItemProps {
  messageItem: MessageTypes
  selectChannelobj: ChannelTypes
  isSequence: boolean
  isDate: boolean
  isLast: boolean
  setModalObj: React.Dispatch<React.SetStateAction<ModalTypes>>
}
const ChatMessageItem = ({
  messageItem,
  selectChannelobj,
  isSequence,
  isDate,
  isLast,
  setModalObj,
}: ChatMessageItemProps) => {
  if (messageItem.type === 'ANNO') {
    return (
      <div id={`for-scroll-${messageItem.id}`}>
        <AnnoMessage>{messageItem.content}</AnnoMessage>
      </div>
    )
  } else if (messageItem.type === 'SYST') {
    const targetMessage = messageItem?.systemMessage
    const link = targetMessage?.button?.uri || ''
    const appCheck = checkAppScheme(link)
    return (
      <div id={`for-scroll-${messageItem.id}`}>
        <SystMessage>
          <div className={`syst-box ${systTypeToClass[targetMessage?.type || 1]}`}>
            <span className="syst-title">{systTypeToString[targetMessage?.type || 1]}</span>
            <span>{targetMessage?.text}</span>
            {targetMessage?.button ? (
              appCheck ? (
                <span
                  className="syst-button"
                  onClick={() => {
                    openToast('앱에서 확인해 주세요')
                  }}
                >
                  {targetMessage.button?.text}
                </span>
              ) : (
                <a href={targetMessage.button?.uri} target="_blank" rel="noreferrer" className="syst-button">
                  {targetMessage.button?.text}
                </a>
              )
            ) : null}
          </div>
        </SystMessage>
      </div>
    )
  } else if (messageItem.type === 'TEMP' && messageItem.templateType === 5) {
    return (
      <AnnoMessage>
        <TemplateMessage messageItem={messageItem} />
      </AnnoMessage>
    )
  } else {
    const senderChat = selectChannelobj?.me?.id !== messageItem.senderId
    const msTargetDate = (messageItem.createTime?.seconds || 0) * 1000
    const date = dayjs(msTargetDate).format('A h:mm')
    const sequenceClass = isSequence ? 'sequnce-message' : ''
    const templateClass = messageItem.type === 'TEMP' ? 'template-message' : ''
    const isMessageMenu = allowToolbox(messageItem, msTargetDate)
    return (
      <div id={`for-scroll-${messageItem.id}`} className={`for-containment ${senderChat ? 'left' : 'right'}`}>
        {senderChat ? (
          <MessageWrapper senderChat={senderChat} className={`${sequenceClass} ${templateClass}`}>
            <MessageProfile className={sequenceClass}>
              <img
                className="profile-image"
                src={ProfileParamConverter(messageItem.sender?.profileImage || '')}
                alt={messageItem.sender?.nickname || 'profile'}
              />
            </MessageProfile>
            <div className="message-wrapper">
              {selectChannelobj.type === 2 && !isSequence ? (
                <div className="nickname">{messageItem?.sender?.nickname}</div>
              ) : null}
              <ChatContent messageItem={messageItem} setModalObj={setModalObj} />
              {isMessageMenu ? <MessageHover messageItem={messageItem} /> : null}
            </div>
            <MessageSubInfo>
              {isLast ? <MessageUnread senderChat={senderChat} messageItem={messageItem} /> : null}
              {isDate ? <div className="message-date">{date}</div> : null}
            </MessageSubInfo>
          </MessageWrapper>
        ) : (
          <MessageWrapper senderChat={senderChat} className={`for-containment ${sequenceClass} ${templateClass}`}>
            <MessageSubInfo className="sender">
              {isLast ? <MessageUnread senderChat={senderChat} messageItem={messageItem} /> : null}
              {isDate ? <div className="message-date">{date}</div> : null}
            </MessageSubInfo>
            <div className="message-wrapper">
              <ChatContent messageItem={messageItem} setModalObj={setModalObj} />
              {isMessageMenu ? <MessageHover messageItem={messageItem} /> : null}
            </div>
          </MessageWrapper>
        )}
      </div>
    )
  }
}

function messageEqual(prevProps: ChatMessageItemProps, nextProps: ChatMessageItemProps) {
  return (
    prevProps.messageItem.id === nextProps.messageItem.id &&
    prevProps.messageItem.seenMap === nextProps.messageItem.seenMap &&
    prevProps.isDate === nextProps.isDate &&
    prevProps.isLast === nextProps.isLast
  )
}

export default memo(ChatMessageItem, messageEqual)
