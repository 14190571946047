import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { closeModal } from 'utils/localDispatcher'
import { StoreState } from 'store/ducks'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  display: flex;
  align-items: center;

  background-color: rgba(33, 33, 36, 0.6);
  justify-content: center;
  animation: fadeIn 0.3s;
`
const ModalDialog = styled.div`
  position: relative;

  display: flex;
  margin: 0 20px;
  max-width: 1000px;
  border-radius: 10px;

  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;

  text-align: left;

  will-change: opacity;
  animation: fadeIn 0.5s;
`

const ReduxModal = () => {
  const modalObj = useSelector((state: StoreState) => state.utilStore.modal)
  const reduxModalKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') closeModal()
  }
  useEffect(() => {
    document.addEventListener('keydown', reduxModalKeydown)
    return () => document.removeEventListener('keydown', reduxModalKeydown)
  }, [])
  const handleModalWrap = (e: React.SyntheticEvent) => {
    e.preventDefault()
    closeModal()
  }
  return modalObj.isShow ? (
    <ModalWrapper onClick={handleModalWrap}>
      <ModalDialog onClick={(e) => e.stopPropagation()}>{modalObj.content}</ModalDialog>
    </ModalWrapper>
  ) : null
}

export default ReduxModal
