// source: api/api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var validate_validate_pb = require('../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var api_model_pb = require('../api/model_pb.js');
goog.object.extend(proto, api_model_pb);
var api_event_pb = require('../api/event_pb.js');
goog.object.extend(proto, api_event_pb);
goog.exportSymbol('proto.api.BanUserRequest', null, global);
goog.exportSymbol('proto.api.BanUserResponse', null, global);
goog.exportSymbol('proto.api.CancelVoiceChannelRequest', null, global);
goog.exportSymbol('proto.api.CancelVoiceChannelResponse', null, global);
goog.exportSymbol('proto.api.CloseChannelListHeaderRequest', null, global);
goog.exportSymbol('proto.api.CloseChannelListHeaderResponse', null, global);
goog.exportSymbol('proto.api.CreateAnnouncementRequest', null, global);
goog.exportSymbol('proto.api.CreateAnnouncementResponse', null, global);
goog.exportSymbol('proto.api.CreateAutoReplyRequest', null, global);
goog.exportSymbol('proto.api.CreateAutoReplyResponse', null, global);
goog.exportSymbol('proto.api.CreateChannelRequest', null, global);
goog.exportSymbol('proto.api.CreateChannelResponse', null, global);
goog.exportSymbol('proto.api.CreatePromiseRequest', null, global);
goog.exportSymbol('proto.api.CreatePromiseResponse', null, global);
goog.exportSymbol('proto.api.DeleteAnnouncementRequest', null, global);
goog.exportSymbol('proto.api.DeleteAnnouncementResponse', null, global);
goog.exportSymbol('proto.api.DeleteAutoReplyRequest', null, global);
goog.exportSymbol('proto.api.DeleteAutoReplyResponse', null, global);
goog.exportSymbol('proto.api.DeleteMessageRequest', null, global);
goog.exportSymbol('proto.api.DeleteMessageRequest.DeleteByRole', null, global);
goog.exportSymbol('proto.api.DeleteMessageResponse', null, global);
goog.exportSymbol('proto.api.DeletePromiseRequest', null, global);
goog.exportSymbol('proto.api.DeletePromiseResponse', null, global);
goog.exportSymbol('proto.api.GetAutoReplyMetadataRequest', null, global);
goog.exportSymbol('proto.api.GetAutoReplyMetadataResponse', null, global);
goog.exportSymbol('proto.api.GetAutoReplyRequest', null, global);
goog.exportSymbol('proto.api.GetAutoReplyResponse', null, global);
goog.exportSymbol('proto.api.GetBizAccountRequest', null, global);
goog.exportSymbol('proto.api.GetBizAccountResponse', null, global);
goog.exportSymbol('proto.api.GetBizAccountsRequest', null, global);
goog.exportSymbol('proto.api.GetBizAccountsResponse', null, global);
goog.exportSymbol('proto.api.GetChannelListHeaderRequest', null, global);
goog.exportSymbol('proto.api.GetChannelListHeaderResponse', null, global);
goog.exportSymbol('proto.api.GetChannelRequest', null, global);
goog.exportSymbol('proto.api.GetChannelResponse', null, global);
goog.exportSymbol('proto.api.GetChannelsRequest', null, global);
goog.exportSymbol('proto.api.GetChannelsRequest.PagingKey', null, global);
goog.exportSymbol('proto.api.GetChannelsRequest.PagingKey.Kind', null, global);
goog.exportSymbol('proto.api.GetChannelsResponse', null, global);
goog.exportSymbol('proto.api.GetFiltersRequest', null, global);
goog.exportSymbol('proto.api.GetFiltersResponse', null, global);
goog.exportSymbol('proto.api.GetMemberRequest', null, global);
goog.exportSymbol('proto.api.GetMemberResponse', null, global);
goog.exportSymbol('proto.api.GetMembersRequest', null, global);
goog.exportSymbol('proto.api.GetMembersResponse', null, global);
goog.exportSymbol('proto.api.GetMessagesRequest', null, global);
goog.exportSymbol('proto.api.GetMessagesResponse', null, global);
goog.exportSymbol('proto.api.GetRtcTokenRequest', null, global);
goog.exportSymbol('proto.api.GetRtcTokenResponse', null, global);
goog.exportSymbol('proto.api.GetSafeNumberRequest', null, global);
goog.exportSymbol('proto.api.GetSafeNumberResponse', null, global);
goog.exportSymbol('proto.api.GetSellerChannelsRequest', null, global);
goog.exportSymbol('proto.api.GetSellerChannelsResponse', null, global);
goog.exportSymbol('proto.api.GetStickersRequest', null, global);
goog.exportSymbol('proto.api.GetStickersResponse', null, global);
goog.exportSymbol('proto.api.GetStickersResponse.Sticker', null, global);
goog.exportSymbol('proto.api.GetUnreadCountRequest', null, global);
goog.exportSymbol('proto.api.GetUnreadCountResponse', null, global);
goog.exportSymbol('proto.api.LeaveChannelRequest', null, global);
goog.exportSymbol('proto.api.LeaveChannelResponse', null, global);
goog.exportSymbol('proto.api.ListAutoRepliesRequest', null, global);
goog.exportSymbol('proto.api.ListAutoRepliesResponse', null, global);
goog.exportSymbol('proto.api.ListReactionRequest', null, global);
goog.exportSymbol('proto.api.ListReactionResponse', null, global);
goog.exportSymbol('proto.api.MarkAsReadRequest', null, global);
goog.exportSymbol('proto.api.MarkAsReadResponse', null, global);
goog.exportSymbol('proto.api.Range', null, global);
goog.exportSymbol('proto.api.RejectVoiceChannelRequest', null, global);
goog.exportSymbol('proto.api.RejectVoiceChannelResponse', null, global);
goog.exportSymbol('proto.api.Request', null, global);
goog.exportSymbol('proto.api.Response', null, global);
goog.exportSymbol('proto.api.SearchMembersRequest', null, global);
goog.exportSymbol('proto.api.SearchMembersResponse', null, global);
goog.exportSymbol('proto.api.SendButtonPostbackRequest', null, global);
goog.exportSymbol('proto.api.SendButtonPostbackResponse', null, global);
goog.exportSymbol('proto.api.SendMessageRequest', null, global);
goog.exportSymbol('proto.api.SendMessageResponse', null, global);
goog.exportSymbol('proto.api.SendPersistentMenuPostbackRequest', null, global);
goog.exportSymbol('proto.api.SendPersistentMenuPostbackResponse', null, global);
goog.exportSymbol('proto.api.SetReactionRequest', null, global);
goog.exportSymbol('proto.api.SetReactionResponse', null, global);
goog.exportSymbol('proto.api.Status', null, global);
goog.exportSymbol('proto.api.StatusAction', null, global);
goog.exportSymbol('proto.api.UpdateAutoReplyMetadataRequest', null, global);
goog.exportSymbol('proto.api.UpdateAutoReplyMetadataResponse', null, global);
goog.exportSymbol('proto.api.UpdateAutoReplyRequest', null, global);
goog.exportSymbol('proto.api.UpdateAutoReplyResponse', null, global);
goog.exportSymbol('proto.api.UpdateBlockStatusRequest', null, global);
goog.exportSymbol('proto.api.UpdateBlockStatusResponse', null, global);
goog.exportSymbol('proto.api.UpdatePromiseRequest', null, global);
goog.exportSymbol('proto.api.UpdatePromiseResponse', null, global);
goog.exportSymbol('proto.api.UpdateUserChannelRequest', null, global);
goog.exportSymbol('proto.api.UpdateUserChannelRequest.OptionCase', null, global);
goog.exportSymbol('proto.api.UpdateUserChannelResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Request.displayName = 'proto.api.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.StatusAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.StatusAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.StatusAction.displayName = 'proto.api.StatusAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Response.displayName = 'proto.api.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateChannelRequest.displayName = 'proto.api.CreateChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateChannelResponse.displayName = 'proto.api.CreateChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SendMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SendMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SendMessageRequest.displayName = 'proto.api.SendMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SendMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SendMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SendMessageResponse.displayName = 'proto.api.SendMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelsRequest.displayName = 'proto.api.GetChannelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelsRequest.PagingKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChannelsRequest.PagingKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelsRequest.PagingKey.displayName = 'proto.api.GetChannelsRequest.PagingKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelsResponse.displayName = 'proto.api.GetChannelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSellerChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetSellerChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSellerChannelsRequest.displayName = 'proto.api.GetSellerChannelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSellerChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetSellerChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetSellerChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSellerChannelsResponse.displayName = 'proto.api.GetSellerChannelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMessagesRequest.displayName = 'proto.api.GetMessagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMessagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetMessagesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetMessagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMessagesResponse.displayName = 'proto.api.GetMessagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MarkAsReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MarkAsReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MarkAsReadRequest.displayName = 'proto.api.MarkAsReadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MarkAsReadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MarkAsReadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MarkAsReadResponse.displayName = 'proto.api.MarkAsReadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LeaveChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.LeaveChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LeaveChannelRequest.displayName = 'proto.api.LeaveChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.LeaveChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.LeaveChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.LeaveChannelResponse.displayName = 'proto.api.LeaveChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateUserChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.UpdateUserChannelRequest.oneofGroups_);
};
goog.inherits(proto.api.UpdateUserChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateUserChannelRequest.displayName = 'proto.api.UpdateUserChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateUserChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateUserChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateUserChannelResponse.displayName = 'proto.api.UpdateUserChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelRequest.displayName = 'proto.api.GetChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelResponse.displayName = 'proto.api.GetChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetUnreadCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetUnreadCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetUnreadCountRequest.displayName = 'proto.api.GetUnreadCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetUnreadCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetUnreadCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetUnreadCountResponse.displayName = 'proto.api.GetUnreadCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreatePromiseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreatePromiseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreatePromiseRequest.displayName = 'proto.api.CreatePromiseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreatePromiseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreatePromiseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreatePromiseResponse.displayName = 'proto.api.CreatePromiseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdatePromiseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdatePromiseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdatePromiseRequest.displayName = 'proto.api.UpdatePromiseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdatePromiseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdatePromiseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdatePromiseResponse.displayName = 'proto.api.UpdatePromiseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeletePromiseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeletePromiseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeletePromiseRequest.displayName = 'proto.api.DeletePromiseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeletePromiseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeletePromiseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeletePromiseResponse.displayName = 'proto.api.DeletePromiseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetBizAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetBizAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetBizAccountsRequest.displayName = 'proto.api.GetBizAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetBizAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetBizAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetBizAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetBizAccountsResponse.displayName = 'proto.api.GetBizAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetBizAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetBizAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetBizAccountRequest.displayName = 'proto.api.GetBizAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetBizAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetBizAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetBizAccountResponse.displayName = 'proto.api.GetBizAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetStickersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetStickersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetStickersRequest.displayName = 'proto.api.GetStickersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetStickersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetStickersResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetStickersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetStickersResponse.displayName = 'proto.api.GetStickersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetStickersResponse.Sticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetStickersResponse.Sticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetStickersResponse.Sticker.displayName = 'proto.api.GetStickersResponse.Sticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSafeNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetSafeNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSafeNumberRequest.displayName = 'proto.api.GetSafeNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetSafeNumberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetSafeNumberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetSafeNumberResponse.displayName = 'proto.api.GetSafeNumberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateBlockStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateBlockStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateBlockStatusRequest.displayName = 'proto.api.UpdateBlockStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateBlockStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateBlockStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateBlockStatusResponse.displayName = 'proto.api.UpdateBlockStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BanUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BanUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BanUserRequest.displayName = 'proto.api.BanUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.BanUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.BanUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.BanUserResponse.displayName = 'proto.api.BanUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetRtcTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetRtcTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetRtcTokenRequest.displayName = 'proto.api.GetRtcTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetRtcTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetRtcTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetRtcTokenResponse.displayName = 'proto.api.GetRtcTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CancelVoiceChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CancelVoiceChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CancelVoiceChannelRequest.displayName = 'proto.api.CancelVoiceChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CancelVoiceChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CancelVoiceChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CancelVoiceChannelResponse.displayName = 'proto.api.CancelVoiceChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.RejectVoiceChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.RejectVoiceChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.RejectVoiceChannelRequest.displayName = 'proto.api.RejectVoiceChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.RejectVoiceChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.RejectVoiceChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.RejectVoiceChannelResponse.displayName = 'proto.api.RejectVoiceChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelListHeaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChannelListHeaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelListHeaderRequest.displayName = 'proto.api.GetChannelListHeaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChannelListHeaderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChannelListHeaderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChannelListHeaderResponse.displayName = 'proto.api.GetChannelListHeaderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CloseChannelListHeaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CloseChannelListHeaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CloseChannelListHeaderRequest.displayName = 'proto.api.CloseChannelListHeaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CloseChannelListHeaderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CloseChannelListHeaderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CloseChannelListHeaderResponse.displayName = 'proto.api.CloseChannelListHeaderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteMessageRequest.displayName = 'proto.api.DeleteMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteMessageResponse.displayName = 'proto.api.DeleteMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SendPersistentMenuPostbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SendPersistentMenuPostbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SendPersistentMenuPostbackRequest.displayName = 'proto.api.SendPersistentMenuPostbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SendPersistentMenuPostbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SendPersistentMenuPostbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SendPersistentMenuPostbackResponse.displayName = 'proto.api.SendPersistentMenuPostbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAutoReplyMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAutoReplyMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAutoReplyMetadataRequest.displayName = 'proto.api.GetAutoReplyMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAutoReplyMetadataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAutoReplyMetadataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAutoReplyMetadataResponse.displayName = 'proto.api.GetAutoReplyMetadataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAutoReplyMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAutoReplyMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAutoReplyMetadataRequest.displayName = 'proto.api.UpdateAutoReplyMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAutoReplyMetadataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAutoReplyMetadataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAutoReplyMetadataResponse.displayName = 'proto.api.UpdateAutoReplyMetadataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAutoReplyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAutoReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAutoReplyRequest.displayName = 'proto.api.CreateAutoReplyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAutoReplyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAutoReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAutoReplyResponse.displayName = 'proto.api.CreateAutoReplyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListAutoRepliesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListAutoRepliesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListAutoRepliesRequest.displayName = 'proto.api.ListAutoRepliesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListAutoRepliesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListAutoRepliesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListAutoRepliesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListAutoRepliesResponse.displayName = 'proto.api.ListAutoRepliesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAutoReplyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAutoReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAutoReplyRequest.displayName = 'proto.api.GetAutoReplyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAutoReplyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAutoReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAutoReplyResponse.displayName = 'proto.api.GetAutoReplyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAutoReplyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAutoReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAutoReplyRequest.displayName = 'proto.api.UpdateAutoReplyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAutoReplyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAutoReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAutoReplyResponse.displayName = 'proto.api.UpdateAutoReplyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAutoReplyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAutoReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAutoReplyRequest.displayName = 'proto.api.DeleteAutoReplyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAutoReplyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAutoReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAutoReplyResponse.displayName = 'proto.api.DeleteAutoReplyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAnnouncementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAnnouncementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAnnouncementRequest.displayName = 'proto.api.CreateAnnouncementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAnnouncementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAnnouncementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAnnouncementResponse.displayName = 'proto.api.CreateAnnouncementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAnnouncementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAnnouncementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAnnouncementRequest.displayName = 'proto.api.DeleteAnnouncementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAnnouncementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAnnouncementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAnnouncementResponse.displayName = 'proto.api.DeleteAnnouncementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SetReactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SetReactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SetReactionRequest.displayName = 'proto.api.SetReactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SetReactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SetReactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SetReactionResponse.displayName = 'proto.api.SetReactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListReactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListReactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListReactionRequest.displayName = 'proto.api.ListReactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListReactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListReactionResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListReactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListReactionResponse.displayName = 'proto.api.ListReactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMembersRequest.displayName = 'proto.api.GetMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMembersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMembersResponse.displayName = 'proto.api.GetMembersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMemberRequest.displayName = 'proto.api.GetMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetMemberResponse.displayName = 'proto.api.GetMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SearchMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchMembersRequest.displayName = 'proto.api.SearchMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SearchMembersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.SearchMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.api.SearchMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SearchMembersResponse.displayName = 'proto.api.SearchMembersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SendButtonPostbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SendButtonPostbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SendButtonPostbackRequest.displayName = 'proto.api.SendButtonPostbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SendButtonPostbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SendButtonPostbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SendButtonPostbackResponse.displayName = 'proto.api.SendButtonPostbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetFiltersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetFiltersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetFiltersRequest.displayName = 'proto.api.GetFiltersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetFiltersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetFiltersResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetFiltersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetFiltersResponse.displayName = 'proto.api.GetFiltersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propsMap: (f = msg.getPropsMap()) ? f.toObject(includeInstance, undefined) : [],
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    echo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sendMessage: (f = msg.getSendMessage()) && proto.api.SendMessageRequest.toObject(includeInstance, f),
    getChannels: (f = msg.getGetChannels()) && proto.api.GetChannelsRequest.toObject(includeInstance, f),
    getMessages: (f = msg.getGetMessages()) && proto.api.GetMessagesRequest.toObject(includeInstance, f),
    createChannel: (f = msg.getCreateChannel()) && proto.api.CreateChannelRequest.toObject(includeInstance, f),
    markAsRead: (f = msg.getMarkAsRead()) && proto.api.MarkAsReadRequest.toObject(includeInstance, f),
    leaveChannel: (f = msg.getLeaveChannel()) && proto.api.LeaveChannelRequest.toObject(includeInstance, f),
    updateUserChannel: (f = msg.getUpdateUserChannel()) && proto.api.UpdateUserChannelRequest.toObject(includeInstance, f),
    getChannel: (f = msg.getGetChannel()) && proto.api.GetChannelRequest.toObject(includeInstance, f),
    getSellerChannels: (f = msg.getGetSellerChannels()) && proto.api.GetSellerChannelsRequest.toObject(includeInstance, f),
    getUnreadCount: (f = msg.getGetUnreadCount()) && proto.api.GetUnreadCountRequest.toObject(includeInstance, f),
    createPromise: (f = msg.getCreatePromise()) && proto.api.CreatePromiseRequest.toObject(includeInstance, f),
    updatePromise: (f = msg.getUpdatePromise()) && proto.api.UpdatePromiseRequest.toObject(includeInstance, f),
    deletePromise: (f = msg.getDeletePromise()) && proto.api.DeletePromiseRequest.toObject(includeInstance, f),
    getBizAccounts: (f = msg.getGetBizAccounts()) && proto.api.GetBizAccountsRequest.toObject(includeInstance, f),
    getBizAccount: (f = msg.getGetBizAccount()) && proto.api.GetBizAccountRequest.toObject(includeInstance, f),
    getStickers: (f = msg.getGetStickers()) && proto.api.GetStickersRequest.toObject(includeInstance, f),
    getSafeNumber: (f = msg.getGetSafeNumber()) && proto.api.GetSafeNumberRequest.toObject(includeInstance, f),
    updateBlockStatus: (f = msg.getUpdateBlockStatus()) && proto.api.UpdateBlockStatusRequest.toObject(includeInstance, f),
    banUser: (f = msg.getBanUser()) && proto.api.BanUserRequest.toObject(includeInstance, f),
    getRtcToken: (f = msg.getGetRtcToken()) && proto.api.GetRtcTokenRequest.toObject(includeInstance, f),
    cancelVoiceChannel: (f = msg.getCancelVoiceChannel()) && proto.api.CancelVoiceChannelRequest.toObject(includeInstance, f),
    rejectVoiceChannel: (f = msg.getRejectVoiceChannel()) && proto.api.RejectVoiceChannelRequest.toObject(includeInstance, f),
    getChannelListHeader: (f = msg.getGetChannelListHeader()) && proto.api.GetChannelListHeaderRequest.toObject(includeInstance, f),
    closeChannelListHeader: (f = msg.getCloseChannelListHeader()) && proto.api.CloseChannelListHeaderRequest.toObject(includeInstance, f),
    deleteMessage: (f = msg.getDeleteMessage()) && proto.api.DeleteMessageRequest.toObject(includeInstance, f),
    sendPersistentMenuPostback: (f = msg.getSendPersistentMenuPostback()) && proto.api.SendPersistentMenuPostbackRequest.toObject(includeInstance, f),
    getAutoReplyMetadata: (f = msg.getGetAutoReplyMetadata()) && proto.api.GetAutoReplyMetadataRequest.toObject(includeInstance, f),
    updateAutoReplyMetadata: (f = msg.getUpdateAutoReplyMetadata()) && proto.api.UpdateAutoReplyMetadataRequest.toObject(includeInstance, f),
    listAutoReplies: (f = msg.getListAutoReplies()) && proto.api.ListAutoRepliesRequest.toObject(includeInstance, f),
    createAutoReply: (f = msg.getCreateAutoReply()) && proto.api.CreateAutoReplyRequest.toObject(includeInstance, f),
    getAutoReply: (f = msg.getGetAutoReply()) && proto.api.GetAutoReplyRequest.toObject(includeInstance, f),
    updateAutoReply: (f = msg.getUpdateAutoReply()) && proto.api.UpdateAutoReplyRequest.toObject(includeInstance, f),
    deleteAutoReply: (f = msg.getDeleteAutoReply()) && proto.api.DeleteAutoReplyRequest.toObject(includeInstance, f),
    createAnnouncement: (f = msg.getCreateAnnouncement()) && proto.api.CreateAnnouncementRequest.toObject(includeInstance, f),
    deleteAnnouncement: (f = msg.getDeleteAnnouncement()) && proto.api.DeleteAnnouncementRequest.toObject(includeInstance, f),
    setReaction: (f = msg.getSetReaction()) && proto.api.SetReactionRequest.toObject(includeInstance, f),
    listReaction: (f = msg.getListReaction()) && proto.api.ListReactionRequest.toObject(includeInstance, f),
    getFilters: (f = msg.getGetFilters()) && proto.api.GetFiltersRequest.toObject(includeInstance, f),
    getMember: (f = msg.getGetMember()) && proto.api.GetMemberRequest.toObject(includeInstance, f),
    getMembers: (f = msg.getGetMembers()) && proto.api.GetMembersRequest.toObject(includeInstance, f),
    searchMembers: (f = msg.getSearchMembers()) && proto.api.SearchMembersRequest.toObject(includeInstance, f),
    sendButtonPostback: (f = msg.getSendButtonPostback()) && proto.api.SendButtonPostbackRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Request}
 */
proto.api.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Request;
  return proto.api.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Request}
 */
proto.api.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = msg.getPropsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEcho(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 100:
      var value = new proto.api.SendMessageRequest;
      reader.readMessage(value,proto.api.SendMessageRequest.deserializeBinaryFromReader);
      msg.setSendMessage(value);
      break;
    case 101:
      var value = new proto.api.GetChannelsRequest;
      reader.readMessage(value,proto.api.GetChannelsRequest.deserializeBinaryFromReader);
      msg.setGetChannels(value);
      break;
    case 102:
      var value = new proto.api.GetMessagesRequest;
      reader.readMessage(value,proto.api.GetMessagesRequest.deserializeBinaryFromReader);
      msg.setGetMessages(value);
      break;
    case 103:
      var value = new proto.api.CreateChannelRequest;
      reader.readMessage(value,proto.api.CreateChannelRequest.deserializeBinaryFromReader);
      msg.setCreateChannel(value);
      break;
    case 104:
      var value = new proto.api.MarkAsReadRequest;
      reader.readMessage(value,proto.api.MarkAsReadRequest.deserializeBinaryFromReader);
      msg.setMarkAsRead(value);
      break;
    case 105:
      var value = new proto.api.LeaveChannelRequest;
      reader.readMessage(value,proto.api.LeaveChannelRequest.deserializeBinaryFromReader);
      msg.setLeaveChannel(value);
      break;
    case 106:
      var value = new proto.api.UpdateUserChannelRequest;
      reader.readMessage(value,proto.api.UpdateUserChannelRequest.deserializeBinaryFromReader);
      msg.setUpdateUserChannel(value);
      break;
    case 107:
      var value = new proto.api.GetChannelRequest;
      reader.readMessage(value,proto.api.GetChannelRequest.deserializeBinaryFromReader);
      msg.setGetChannel(value);
      break;
    case 108:
      var value = new proto.api.GetSellerChannelsRequest;
      reader.readMessage(value,proto.api.GetSellerChannelsRequest.deserializeBinaryFromReader);
      msg.setGetSellerChannels(value);
      break;
    case 109:
      var value = new proto.api.GetUnreadCountRequest;
      reader.readMessage(value,proto.api.GetUnreadCountRequest.deserializeBinaryFromReader);
      msg.setGetUnreadCount(value);
      break;
    case 110:
      var value = new proto.api.CreatePromiseRequest;
      reader.readMessage(value,proto.api.CreatePromiseRequest.deserializeBinaryFromReader);
      msg.setCreatePromise(value);
      break;
    case 111:
      var value = new proto.api.UpdatePromiseRequest;
      reader.readMessage(value,proto.api.UpdatePromiseRequest.deserializeBinaryFromReader);
      msg.setUpdatePromise(value);
      break;
    case 112:
      var value = new proto.api.DeletePromiseRequest;
      reader.readMessage(value,proto.api.DeletePromiseRequest.deserializeBinaryFromReader);
      msg.setDeletePromise(value);
      break;
    case 113:
      var value = new proto.api.GetBizAccountsRequest;
      reader.readMessage(value,proto.api.GetBizAccountsRequest.deserializeBinaryFromReader);
      msg.setGetBizAccounts(value);
      break;
    case 114:
      var value = new proto.api.GetBizAccountRequest;
      reader.readMessage(value,proto.api.GetBizAccountRequest.deserializeBinaryFromReader);
      msg.setGetBizAccount(value);
      break;
    case 115:
      var value = new proto.api.GetStickersRequest;
      reader.readMessage(value,proto.api.GetStickersRequest.deserializeBinaryFromReader);
      msg.setGetStickers(value);
      break;
    case 116:
      var value = new proto.api.GetSafeNumberRequest;
      reader.readMessage(value,proto.api.GetSafeNumberRequest.deserializeBinaryFromReader);
      msg.setGetSafeNumber(value);
      break;
    case 117:
      var value = new proto.api.UpdateBlockStatusRequest;
      reader.readMessage(value,proto.api.UpdateBlockStatusRequest.deserializeBinaryFromReader);
      msg.setUpdateBlockStatus(value);
      break;
    case 118:
      var value = new proto.api.BanUserRequest;
      reader.readMessage(value,proto.api.BanUserRequest.deserializeBinaryFromReader);
      msg.setBanUser(value);
      break;
    case 119:
      var value = new proto.api.GetRtcTokenRequest;
      reader.readMessage(value,proto.api.GetRtcTokenRequest.deserializeBinaryFromReader);
      msg.setGetRtcToken(value);
      break;
    case 120:
      var value = new proto.api.CancelVoiceChannelRequest;
      reader.readMessage(value,proto.api.CancelVoiceChannelRequest.deserializeBinaryFromReader);
      msg.setCancelVoiceChannel(value);
      break;
    case 121:
      var value = new proto.api.RejectVoiceChannelRequest;
      reader.readMessage(value,proto.api.RejectVoiceChannelRequest.deserializeBinaryFromReader);
      msg.setRejectVoiceChannel(value);
      break;
    case 122:
      var value = new proto.api.GetChannelListHeaderRequest;
      reader.readMessage(value,proto.api.GetChannelListHeaderRequest.deserializeBinaryFromReader);
      msg.setGetChannelListHeader(value);
      break;
    case 123:
      var value = new proto.api.CloseChannelListHeaderRequest;
      reader.readMessage(value,proto.api.CloseChannelListHeaderRequest.deserializeBinaryFromReader);
      msg.setCloseChannelListHeader(value);
      break;
    case 124:
      var value = new proto.api.DeleteMessageRequest;
      reader.readMessage(value,proto.api.DeleteMessageRequest.deserializeBinaryFromReader);
      msg.setDeleteMessage(value);
      break;
    case 125:
      var value = new proto.api.SendPersistentMenuPostbackRequest;
      reader.readMessage(value,proto.api.SendPersistentMenuPostbackRequest.deserializeBinaryFromReader);
      msg.setSendPersistentMenuPostback(value);
      break;
    case 126:
      var value = new proto.api.GetAutoReplyMetadataRequest;
      reader.readMessage(value,proto.api.GetAutoReplyMetadataRequest.deserializeBinaryFromReader);
      msg.setGetAutoReplyMetadata(value);
      break;
    case 127:
      var value = new proto.api.UpdateAutoReplyMetadataRequest;
      reader.readMessage(value,proto.api.UpdateAutoReplyMetadataRequest.deserializeBinaryFromReader);
      msg.setUpdateAutoReplyMetadata(value);
      break;
    case 128:
      var value = new proto.api.ListAutoRepliesRequest;
      reader.readMessage(value,proto.api.ListAutoRepliesRequest.deserializeBinaryFromReader);
      msg.setListAutoReplies(value);
      break;
    case 129:
      var value = new proto.api.CreateAutoReplyRequest;
      reader.readMessage(value,proto.api.CreateAutoReplyRequest.deserializeBinaryFromReader);
      msg.setCreateAutoReply(value);
      break;
    case 130:
      var value = new proto.api.GetAutoReplyRequest;
      reader.readMessage(value,proto.api.GetAutoReplyRequest.deserializeBinaryFromReader);
      msg.setGetAutoReply(value);
      break;
    case 131:
      var value = new proto.api.UpdateAutoReplyRequest;
      reader.readMessage(value,proto.api.UpdateAutoReplyRequest.deserializeBinaryFromReader);
      msg.setUpdateAutoReply(value);
      break;
    case 132:
      var value = new proto.api.DeleteAutoReplyRequest;
      reader.readMessage(value,proto.api.DeleteAutoReplyRequest.deserializeBinaryFromReader);
      msg.setDeleteAutoReply(value);
      break;
    case 133:
      var value = new proto.api.CreateAnnouncementRequest;
      reader.readMessage(value,proto.api.CreateAnnouncementRequest.deserializeBinaryFromReader);
      msg.setCreateAnnouncement(value);
      break;
    case 134:
      var value = new proto.api.DeleteAnnouncementRequest;
      reader.readMessage(value,proto.api.DeleteAnnouncementRequest.deserializeBinaryFromReader);
      msg.setDeleteAnnouncement(value);
      break;
    case 135:
      var value = new proto.api.SetReactionRequest;
      reader.readMessage(value,proto.api.SetReactionRequest.deserializeBinaryFromReader);
      msg.setSetReaction(value);
      break;
    case 136:
      var value = new proto.api.ListReactionRequest;
      reader.readMessage(value,proto.api.ListReactionRequest.deserializeBinaryFromReader);
      msg.setListReaction(value);
      break;
    case 137:
      var value = new proto.api.GetFiltersRequest;
      reader.readMessage(value,proto.api.GetFiltersRequest.deserializeBinaryFromReader);
      msg.setGetFilters(value);
      break;
    case 141:
      var value = new proto.api.GetMemberRequest;
      reader.readMessage(value,proto.api.GetMemberRequest.deserializeBinaryFromReader);
      msg.setGetMember(value);
      break;
    case 142:
      var value = new proto.api.GetMembersRequest;
      reader.readMessage(value,proto.api.GetMembersRequest.deserializeBinaryFromReader);
      msg.setGetMembers(value);
      break;
    case 143:
      var value = new proto.api.SearchMembersRequest;
      reader.readMessage(value,proto.api.SearchMembersRequest.deserializeBinaryFromReader);
      msg.setSearchMembers(value);
      break;
    case 150:
      var value = new proto.api.SendButtonPostbackRequest;
      reader.readMessage(value,proto.api.SendButtonPostbackRequest.deserializeBinaryFromReader);
      msg.setSendButtonPostback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEcho();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSendMessage();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.api.SendMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetChannels();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.api.GetChannelsRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetMessages();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      proto.api.GetMessagesRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateChannel();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.api.CreateChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getMarkAsRead();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      proto.api.MarkAsReadRequest.serializeBinaryToWriter
    );
  }
  f = message.getLeaveChannel();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      proto.api.LeaveChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUserChannel();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      proto.api.UpdateUserChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetChannel();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      proto.api.GetChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetSellerChannels();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      proto.api.GetSellerChannelsRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetUnreadCount();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      proto.api.GetUnreadCountRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreatePromise();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      proto.api.CreatePromiseRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatePromise();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      proto.api.UpdatePromiseRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeletePromise();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      proto.api.DeletePromiseRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetBizAccounts();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      proto.api.GetBizAccountsRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetBizAccount();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      proto.api.GetBizAccountRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetStickers();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      proto.api.GetStickersRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetSafeNumber();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      proto.api.GetSafeNumberRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBlockStatus();
  if (f != null) {
    writer.writeMessage(
      117,
      f,
      proto.api.UpdateBlockStatusRequest.serializeBinaryToWriter
    );
  }
  f = message.getBanUser();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      proto.api.BanUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetRtcToken();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      proto.api.GetRtcTokenRequest.serializeBinaryToWriter
    );
  }
  f = message.getCancelVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      120,
      f,
      proto.api.CancelVoiceChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getRejectVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      proto.api.RejectVoiceChannelRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetChannelListHeader();
  if (f != null) {
    writer.writeMessage(
      122,
      f,
      proto.api.GetChannelListHeaderRequest.serializeBinaryToWriter
    );
  }
  f = message.getCloseChannelListHeader();
  if (f != null) {
    writer.writeMessage(
      123,
      f,
      proto.api.CloseChannelListHeaderRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleteMessage();
  if (f != null) {
    writer.writeMessage(
      124,
      f,
      proto.api.DeleteMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSendPersistentMenuPostback();
  if (f != null) {
    writer.writeMessage(
      125,
      f,
      proto.api.SendPersistentMenuPostbackRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetAutoReplyMetadata();
  if (f != null) {
    writer.writeMessage(
      126,
      f,
      proto.api.GetAutoReplyMetadataRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAutoReplyMetadata();
  if (f != null) {
    writer.writeMessage(
      127,
      f,
      proto.api.UpdateAutoReplyMetadataRequest.serializeBinaryToWriter
    );
  }
  f = message.getListAutoReplies();
  if (f != null) {
    writer.writeMessage(
      128,
      f,
      proto.api.ListAutoRepliesRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateAutoReply();
  if (f != null) {
    writer.writeMessage(
      129,
      f,
      proto.api.CreateAutoReplyRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetAutoReply();
  if (f != null) {
    writer.writeMessage(
      130,
      f,
      proto.api.GetAutoReplyRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAutoReply();
  if (f != null) {
    writer.writeMessage(
      131,
      f,
      proto.api.UpdateAutoReplyRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAutoReply();
  if (f != null) {
    writer.writeMessage(
      132,
      f,
      proto.api.DeleteAutoReplyRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateAnnouncement();
  if (f != null) {
    writer.writeMessage(
      133,
      f,
      proto.api.CreateAnnouncementRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAnnouncement();
  if (f != null) {
    writer.writeMessage(
      134,
      f,
      proto.api.DeleteAnnouncementRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetReaction();
  if (f != null) {
    writer.writeMessage(
      135,
      f,
      proto.api.SetReactionRequest.serializeBinaryToWriter
    );
  }
  f = message.getListReaction();
  if (f != null) {
    writer.writeMessage(
      136,
      f,
      proto.api.ListReactionRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetFilters();
  if (f != null) {
    writer.writeMessage(
      137,
      f,
      proto.api.GetFiltersRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetMember();
  if (f != null) {
    writer.writeMessage(
      141,
      f,
      proto.api.GetMemberRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetMembers();
  if (f != null) {
    writer.writeMessage(
      142,
      f,
      proto.api.GetMembersRequest.serializeBinaryToWriter
    );
  }
  f = message.getSearchMembers();
  if (f != null) {
    writer.writeMessage(
      143,
      f,
      proto.api.SearchMembersRequest.serializeBinaryToWriter
    );
  }
  f = message.getSendButtonPostback();
  if (f != null) {
    writer.writeMessage(
      150,
      f,
      proto.api.SendButtonPostbackRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.Request.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> props = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.Request.prototype.getPropsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearPropsMap = function() {
  this.getPropsMap().clear();
  return this;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.api.Request.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string echo = 4;
 * @return {string}
 */
proto.api.Request.prototype.getEcho = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.setEcho = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 biz_account_id = 5;
 * @return {number}
 */
proto.api.Request.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional SendMessageRequest send_message = 100;
 * @return {?proto.api.SendMessageRequest}
 */
proto.api.Request.prototype.getSendMessage = function() {
  return /** @type{?proto.api.SendMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.SendMessageRequest, 100));
};


/**
 * @param {?proto.api.SendMessageRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setSendMessage = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearSendMessage = function() {
  return this.setSendMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasSendMessage = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional GetChannelsRequest get_channels = 101;
 * @return {?proto.api.GetChannelsRequest}
 */
proto.api.Request.prototype.getGetChannels = function() {
  return /** @type{?proto.api.GetChannelsRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelsRequest, 101));
};


/**
 * @param {?proto.api.GetChannelsRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetChannels = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetChannels = function() {
  return this.setGetChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetChannels = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional GetMessagesRequest get_messages = 102;
 * @return {?proto.api.GetMessagesRequest}
 */
proto.api.Request.prototype.getGetMessages = function() {
  return /** @type{?proto.api.GetMessagesRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetMessagesRequest, 102));
};


/**
 * @param {?proto.api.GetMessagesRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetMessages = function(value) {
  return jspb.Message.setWrapperField(this, 102, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetMessages = function() {
  return this.setGetMessages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetMessages = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional CreateChannelRequest create_channel = 103;
 * @return {?proto.api.CreateChannelRequest}
 */
proto.api.Request.prototype.getCreateChannel = function() {
  return /** @type{?proto.api.CreateChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CreateChannelRequest, 103));
};


/**
 * @param {?proto.api.CreateChannelRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setCreateChannel = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearCreateChannel = function() {
  return this.setCreateChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasCreateChannel = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional MarkAsReadRequest mark_as_read = 104;
 * @return {?proto.api.MarkAsReadRequest}
 */
proto.api.Request.prototype.getMarkAsRead = function() {
  return /** @type{?proto.api.MarkAsReadRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.MarkAsReadRequest, 104));
};


/**
 * @param {?proto.api.MarkAsReadRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setMarkAsRead = function(value) {
  return jspb.Message.setWrapperField(this, 104, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearMarkAsRead = function() {
  return this.setMarkAsRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasMarkAsRead = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional LeaveChannelRequest leave_channel = 105;
 * @return {?proto.api.LeaveChannelRequest}
 */
proto.api.Request.prototype.getLeaveChannel = function() {
  return /** @type{?proto.api.LeaveChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.LeaveChannelRequest, 105));
};


/**
 * @param {?proto.api.LeaveChannelRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setLeaveChannel = function(value) {
  return jspb.Message.setWrapperField(this, 105, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearLeaveChannel = function() {
  return this.setLeaveChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasLeaveChannel = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional UpdateUserChannelRequest update_user_channel = 106;
 * @return {?proto.api.UpdateUserChannelRequest}
 */
proto.api.Request.prototype.getUpdateUserChannel = function() {
  return /** @type{?proto.api.UpdateUserChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateUserChannelRequest, 106));
};


/**
 * @param {?proto.api.UpdateUserChannelRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setUpdateUserChannel = function(value) {
  return jspb.Message.setWrapperField(this, 106, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearUpdateUserChannel = function() {
  return this.setUpdateUserChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasUpdateUserChannel = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional GetChannelRequest get_channel = 107;
 * @return {?proto.api.GetChannelRequest}
 */
proto.api.Request.prototype.getGetChannel = function() {
  return /** @type{?proto.api.GetChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelRequest, 107));
};


/**
 * @param {?proto.api.GetChannelRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetChannel = function(value) {
  return jspb.Message.setWrapperField(this, 107, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetChannel = function() {
  return this.setGetChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetChannel = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional GetSellerChannelsRequest get_seller_channels = 108;
 * @return {?proto.api.GetSellerChannelsRequest}
 */
proto.api.Request.prototype.getGetSellerChannels = function() {
  return /** @type{?proto.api.GetSellerChannelsRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetSellerChannelsRequest, 108));
};


/**
 * @param {?proto.api.GetSellerChannelsRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetSellerChannels = function(value) {
  return jspb.Message.setWrapperField(this, 108, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetSellerChannels = function() {
  return this.setGetSellerChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetSellerChannels = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional GetUnreadCountRequest get_unread_count = 109;
 * @return {?proto.api.GetUnreadCountRequest}
 */
proto.api.Request.prototype.getGetUnreadCount = function() {
  return /** @type{?proto.api.GetUnreadCountRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetUnreadCountRequest, 109));
};


/**
 * @param {?proto.api.GetUnreadCountRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetUnreadCount = function(value) {
  return jspb.Message.setWrapperField(this, 109, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetUnreadCount = function() {
  return this.setGetUnreadCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetUnreadCount = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional CreatePromiseRequest create_promise = 110;
 * @return {?proto.api.CreatePromiseRequest}
 */
proto.api.Request.prototype.getCreatePromise = function() {
  return /** @type{?proto.api.CreatePromiseRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CreatePromiseRequest, 110));
};


/**
 * @param {?proto.api.CreatePromiseRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setCreatePromise = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearCreatePromise = function() {
  return this.setCreatePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasCreatePromise = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional UpdatePromiseRequest update_promise = 111;
 * @return {?proto.api.UpdatePromiseRequest}
 */
proto.api.Request.prototype.getUpdatePromise = function() {
  return /** @type{?proto.api.UpdatePromiseRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdatePromiseRequest, 111));
};


/**
 * @param {?proto.api.UpdatePromiseRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setUpdatePromise = function(value) {
  return jspb.Message.setWrapperField(this, 111, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearUpdatePromise = function() {
  return this.setUpdatePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasUpdatePromise = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional DeletePromiseRequest delete_promise = 112;
 * @return {?proto.api.DeletePromiseRequest}
 */
proto.api.Request.prototype.getDeletePromise = function() {
  return /** @type{?proto.api.DeletePromiseRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.DeletePromiseRequest, 112));
};


/**
 * @param {?proto.api.DeletePromiseRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setDeletePromise = function(value) {
  return jspb.Message.setWrapperField(this, 112, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearDeletePromise = function() {
  return this.setDeletePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasDeletePromise = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional GetBizAccountsRequest get_biz_accounts = 113;
 * @return {?proto.api.GetBizAccountsRequest}
 */
proto.api.Request.prototype.getGetBizAccounts = function() {
  return /** @type{?proto.api.GetBizAccountsRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetBizAccountsRequest, 113));
};


/**
 * @param {?proto.api.GetBizAccountsRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetBizAccounts = function(value) {
  return jspb.Message.setWrapperField(this, 113, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetBizAccounts = function() {
  return this.setGetBizAccounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetBizAccounts = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional GetBizAccountRequest get_biz_account = 114;
 * @return {?proto.api.GetBizAccountRequest}
 */
proto.api.Request.prototype.getGetBizAccount = function() {
  return /** @type{?proto.api.GetBizAccountRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetBizAccountRequest, 114));
};


/**
 * @param {?proto.api.GetBizAccountRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetBizAccount = function(value) {
  return jspb.Message.setWrapperField(this, 114, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetBizAccount = function() {
  return this.setGetBizAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetBizAccount = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional GetStickersRequest get_stickers = 115;
 * @return {?proto.api.GetStickersRequest}
 */
proto.api.Request.prototype.getGetStickers = function() {
  return /** @type{?proto.api.GetStickersRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetStickersRequest, 115));
};


/**
 * @param {?proto.api.GetStickersRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetStickers = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetStickers = function() {
  return this.setGetStickers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetStickers = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional GetSafeNumberRequest get_safe_number = 116;
 * @return {?proto.api.GetSafeNumberRequest}
 */
proto.api.Request.prototype.getGetSafeNumber = function() {
  return /** @type{?proto.api.GetSafeNumberRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetSafeNumberRequest, 116));
};


/**
 * @param {?proto.api.GetSafeNumberRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetSafeNumber = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetSafeNumber = function() {
  return this.setGetSafeNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetSafeNumber = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional UpdateBlockStatusRequest update_block_status = 117;
 * @return {?proto.api.UpdateBlockStatusRequest}
 */
proto.api.Request.prototype.getUpdateBlockStatus = function() {
  return /** @type{?proto.api.UpdateBlockStatusRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateBlockStatusRequest, 117));
};


/**
 * @param {?proto.api.UpdateBlockStatusRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setUpdateBlockStatus = function(value) {
  return jspb.Message.setWrapperField(this, 117, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearUpdateBlockStatus = function() {
  return this.setUpdateBlockStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasUpdateBlockStatus = function() {
  return jspb.Message.getField(this, 117) != null;
};


/**
 * optional BanUserRequest ban_user = 118;
 * @return {?proto.api.BanUserRequest}
 */
proto.api.Request.prototype.getBanUser = function() {
  return /** @type{?proto.api.BanUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.BanUserRequest, 118));
};


/**
 * @param {?proto.api.BanUserRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setBanUser = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearBanUser = function() {
  return this.setBanUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasBanUser = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional GetRtcTokenRequest get_rtc_token = 119;
 * @return {?proto.api.GetRtcTokenRequest}
 */
proto.api.Request.prototype.getGetRtcToken = function() {
  return /** @type{?proto.api.GetRtcTokenRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetRtcTokenRequest, 119));
};


/**
 * @param {?proto.api.GetRtcTokenRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetRtcToken = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetRtcToken = function() {
  return this.setGetRtcToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetRtcToken = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional CancelVoiceChannelRequest cancel_voice_channel = 120;
 * @return {?proto.api.CancelVoiceChannelRequest}
 */
proto.api.Request.prototype.getCancelVoiceChannel = function() {
  return /** @type{?proto.api.CancelVoiceChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CancelVoiceChannelRequest, 120));
};


/**
 * @param {?proto.api.CancelVoiceChannelRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setCancelVoiceChannel = function(value) {
  return jspb.Message.setWrapperField(this, 120, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearCancelVoiceChannel = function() {
  return this.setCancelVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasCancelVoiceChannel = function() {
  return jspb.Message.getField(this, 120) != null;
};


/**
 * optional RejectVoiceChannelRequest reject_voice_channel = 121;
 * @return {?proto.api.RejectVoiceChannelRequest}
 */
proto.api.Request.prototype.getRejectVoiceChannel = function() {
  return /** @type{?proto.api.RejectVoiceChannelRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.RejectVoiceChannelRequest, 121));
};


/**
 * @param {?proto.api.RejectVoiceChannelRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setRejectVoiceChannel = function(value) {
  return jspb.Message.setWrapperField(this, 121, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearRejectVoiceChannel = function() {
  return this.setRejectVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasRejectVoiceChannel = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional GetChannelListHeaderRequest get_channel_list_header = 122;
 * @return {?proto.api.GetChannelListHeaderRequest}
 */
proto.api.Request.prototype.getGetChannelListHeader = function() {
  return /** @type{?proto.api.GetChannelListHeaderRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelListHeaderRequest, 122));
};


/**
 * @param {?proto.api.GetChannelListHeaderRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetChannelListHeader = function(value) {
  return jspb.Message.setWrapperField(this, 122, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetChannelListHeader = function() {
  return this.setGetChannelListHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetChannelListHeader = function() {
  return jspb.Message.getField(this, 122) != null;
};


/**
 * optional CloseChannelListHeaderRequest close_channel_list_header = 123;
 * @return {?proto.api.CloseChannelListHeaderRequest}
 */
proto.api.Request.prototype.getCloseChannelListHeader = function() {
  return /** @type{?proto.api.CloseChannelListHeaderRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CloseChannelListHeaderRequest, 123));
};


/**
 * @param {?proto.api.CloseChannelListHeaderRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setCloseChannelListHeader = function(value) {
  return jspb.Message.setWrapperField(this, 123, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearCloseChannelListHeader = function() {
  return this.setCloseChannelListHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasCloseChannelListHeader = function() {
  return jspb.Message.getField(this, 123) != null;
};


/**
 * optional DeleteMessageRequest delete_message = 124;
 * @return {?proto.api.DeleteMessageRequest}
 */
proto.api.Request.prototype.getDeleteMessage = function() {
  return /** @type{?proto.api.DeleteMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.DeleteMessageRequest, 124));
};


/**
 * @param {?proto.api.DeleteMessageRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setDeleteMessage = function(value) {
  return jspb.Message.setWrapperField(this, 124, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearDeleteMessage = function() {
  return this.setDeleteMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasDeleteMessage = function() {
  return jspb.Message.getField(this, 124) != null;
};


/**
 * optional SendPersistentMenuPostbackRequest send_persistent_menu_postback = 125;
 * @return {?proto.api.SendPersistentMenuPostbackRequest}
 */
proto.api.Request.prototype.getSendPersistentMenuPostback = function() {
  return /** @type{?proto.api.SendPersistentMenuPostbackRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.SendPersistentMenuPostbackRequest, 125));
};


/**
 * @param {?proto.api.SendPersistentMenuPostbackRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setSendPersistentMenuPostback = function(value) {
  return jspb.Message.setWrapperField(this, 125, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearSendPersistentMenuPostback = function() {
  return this.setSendPersistentMenuPostback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasSendPersistentMenuPostback = function() {
  return jspb.Message.getField(this, 125) != null;
};


/**
 * optional GetAutoReplyMetadataRequest get_auto_reply_metadata = 126;
 * @return {?proto.api.GetAutoReplyMetadataRequest}
 */
proto.api.Request.prototype.getGetAutoReplyMetadata = function() {
  return /** @type{?proto.api.GetAutoReplyMetadataRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetAutoReplyMetadataRequest, 126));
};


/**
 * @param {?proto.api.GetAutoReplyMetadataRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetAutoReplyMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 126, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetAutoReplyMetadata = function() {
  return this.setGetAutoReplyMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetAutoReplyMetadata = function() {
  return jspb.Message.getField(this, 126) != null;
};


/**
 * optional UpdateAutoReplyMetadataRequest update_auto_reply_metadata = 127;
 * @return {?proto.api.UpdateAutoReplyMetadataRequest}
 */
proto.api.Request.prototype.getUpdateAutoReplyMetadata = function() {
  return /** @type{?proto.api.UpdateAutoReplyMetadataRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateAutoReplyMetadataRequest, 127));
};


/**
 * @param {?proto.api.UpdateAutoReplyMetadataRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setUpdateAutoReplyMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 127, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearUpdateAutoReplyMetadata = function() {
  return this.setUpdateAutoReplyMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasUpdateAutoReplyMetadata = function() {
  return jspb.Message.getField(this, 127) != null;
};


/**
 * optional ListAutoRepliesRequest list_auto_replies = 128;
 * @return {?proto.api.ListAutoRepliesRequest}
 */
proto.api.Request.prototype.getListAutoReplies = function() {
  return /** @type{?proto.api.ListAutoRepliesRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.ListAutoRepliesRequest, 128));
};


/**
 * @param {?proto.api.ListAutoRepliesRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setListAutoReplies = function(value) {
  return jspb.Message.setWrapperField(this, 128, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearListAutoReplies = function() {
  return this.setListAutoReplies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasListAutoReplies = function() {
  return jspb.Message.getField(this, 128) != null;
};


/**
 * optional CreateAutoReplyRequest create_auto_reply = 129;
 * @return {?proto.api.CreateAutoReplyRequest}
 */
proto.api.Request.prototype.getCreateAutoReply = function() {
  return /** @type{?proto.api.CreateAutoReplyRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CreateAutoReplyRequest, 129));
};


/**
 * @param {?proto.api.CreateAutoReplyRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setCreateAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 129, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearCreateAutoReply = function() {
  return this.setCreateAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasCreateAutoReply = function() {
  return jspb.Message.getField(this, 129) != null;
};


/**
 * optional GetAutoReplyRequest get_auto_reply = 130;
 * @return {?proto.api.GetAutoReplyRequest}
 */
proto.api.Request.prototype.getGetAutoReply = function() {
  return /** @type{?proto.api.GetAutoReplyRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetAutoReplyRequest, 130));
};


/**
 * @param {?proto.api.GetAutoReplyRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 130, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetAutoReply = function() {
  return this.setGetAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetAutoReply = function() {
  return jspb.Message.getField(this, 130) != null;
};


/**
 * optional UpdateAutoReplyRequest update_auto_reply = 131;
 * @return {?proto.api.UpdateAutoReplyRequest}
 */
proto.api.Request.prototype.getUpdateAutoReply = function() {
  return /** @type{?proto.api.UpdateAutoReplyRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateAutoReplyRequest, 131));
};


/**
 * @param {?proto.api.UpdateAutoReplyRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setUpdateAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 131, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearUpdateAutoReply = function() {
  return this.setUpdateAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasUpdateAutoReply = function() {
  return jspb.Message.getField(this, 131) != null;
};


/**
 * optional DeleteAutoReplyRequest delete_auto_reply = 132;
 * @return {?proto.api.DeleteAutoReplyRequest}
 */
proto.api.Request.prototype.getDeleteAutoReply = function() {
  return /** @type{?proto.api.DeleteAutoReplyRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.DeleteAutoReplyRequest, 132));
};


/**
 * @param {?proto.api.DeleteAutoReplyRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setDeleteAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 132, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearDeleteAutoReply = function() {
  return this.setDeleteAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasDeleteAutoReply = function() {
  return jspb.Message.getField(this, 132) != null;
};


/**
 * optional CreateAnnouncementRequest create_announcement = 133;
 * @return {?proto.api.CreateAnnouncementRequest}
 */
proto.api.Request.prototype.getCreateAnnouncement = function() {
  return /** @type{?proto.api.CreateAnnouncementRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.CreateAnnouncementRequest, 133));
};


/**
 * @param {?proto.api.CreateAnnouncementRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setCreateAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 133, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearCreateAnnouncement = function() {
  return this.setCreateAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasCreateAnnouncement = function() {
  return jspb.Message.getField(this, 133) != null;
};


/**
 * optional DeleteAnnouncementRequest delete_announcement = 134;
 * @return {?proto.api.DeleteAnnouncementRequest}
 */
proto.api.Request.prototype.getDeleteAnnouncement = function() {
  return /** @type{?proto.api.DeleteAnnouncementRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.DeleteAnnouncementRequest, 134));
};


/**
 * @param {?proto.api.DeleteAnnouncementRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setDeleteAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 134, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearDeleteAnnouncement = function() {
  return this.setDeleteAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasDeleteAnnouncement = function() {
  return jspb.Message.getField(this, 134) != null;
};


/**
 * optional SetReactionRequest set_reaction = 135;
 * @return {?proto.api.SetReactionRequest}
 */
proto.api.Request.prototype.getSetReaction = function() {
  return /** @type{?proto.api.SetReactionRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.SetReactionRequest, 135));
};


/**
 * @param {?proto.api.SetReactionRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setSetReaction = function(value) {
  return jspb.Message.setWrapperField(this, 135, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearSetReaction = function() {
  return this.setSetReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasSetReaction = function() {
  return jspb.Message.getField(this, 135) != null;
};


/**
 * optional ListReactionRequest list_reaction = 136;
 * @return {?proto.api.ListReactionRequest}
 */
proto.api.Request.prototype.getListReaction = function() {
  return /** @type{?proto.api.ListReactionRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.ListReactionRequest, 136));
};


/**
 * @param {?proto.api.ListReactionRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setListReaction = function(value) {
  return jspb.Message.setWrapperField(this, 136, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearListReaction = function() {
  return this.setListReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasListReaction = function() {
  return jspb.Message.getField(this, 136) != null;
};


/**
 * optional GetFiltersRequest get_filters = 137;
 * @return {?proto.api.GetFiltersRequest}
 */
proto.api.Request.prototype.getGetFilters = function() {
  return /** @type{?proto.api.GetFiltersRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetFiltersRequest, 137));
};


/**
 * @param {?proto.api.GetFiltersRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetFilters = function(value) {
  return jspb.Message.setWrapperField(this, 137, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetFilters = function() {
  return this.setGetFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetFilters = function() {
  return jspb.Message.getField(this, 137) != null;
};


/**
 * optional GetMemberRequest get_member = 141;
 * @return {?proto.api.GetMemberRequest}
 */
proto.api.Request.prototype.getGetMember = function() {
  return /** @type{?proto.api.GetMemberRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetMemberRequest, 141));
};


/**
 * @param {?proto.api.GetMemberRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetMember = function(value) {
  return jspb.Message.setWrapperField(this, 141, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetMember = function() {
  return this.setGetMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetMember = function() {
  return jspb.Message.getField(this, 141) != null;
};


/**
 * optional GetMembersRequest get_members = 142;
 * @return {?proto.api.GetMembersRequest}
 */
proto.api.Request.prototype.getGetMembers = function() {
  return /** @type{?proto.api.GetMembersRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.GetMembersRequest, 142));
};


/**
 * @param {?proto.api.GetMembersRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setGetMembers = function(value) {
  return jspb.Message.setWrapperField(this, 142, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearGetMembers = function() {
  return this.setGetMembers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasGetMembers = function() {
  return jspb.Message.getField(this, 142) != null;
};


/**
 * optional SearchMembersRequest search_members = 143;
 * @return {?proto.api.SearchMembersRequest}
 */
proto.api.Request.prototype.getSearchMembers = function() {
  return /** @type{?proto.api.SearchMembersRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.SearchMembersRequest, 143));
};


/**
 * @param {?proto.api.SearchMembersRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setSearchMembers = function(value) {
  return jspb.Message.setWrapperField(this, 143, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearSearchMembers = function() {
  return this.setSearchMembers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasSearchMembers = function() {
  return jspb.Message.getField(this, 143) != null;
};


/**
 * optional SendButtonPostbackRequest send_button_postback = 150;
 * @return {?proto.api.SendButtonPostbackRequest}
 */
proto.api.Request.prototype.getSendButtonPostback = function() {
  return /** @type{?proto.api.SendButtonPostbackRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.SendButtonPostbackRequest, 150));
};


/**
 * @param {?proto.api.SendButtonPostbackRequest|undefined} value
 * @return {!proto.api.Request} returns this
*/
proto.api.Request.prototype.setSendButtonPostback = function(value) {
  return jspb.Message.setWrapperField(this, 150, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Request} returns this
 */
proto.api.Request.prototype.clearSendButtonPostback = function() {
  return this.setSendButtonPostback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Request.prototype.hasSendButtonPostback = function() {
  return jspb.Message.getField(this, 150) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.StatusAction.prototype.toObject = function(opt_includeInstance) {
  return proto.api.StatusAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.StatusAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.StatusAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.StatusAction}
 */
proto.api.StatusAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.StatusAction;
  return proto.api.StatusAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.StatusAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.StatusAction}
 */
proto.api.StatusAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.StatusAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.StatusAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.StatusAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.StatusAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.api.StatusAction.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.StatusAction} returns this
 */
proto.api.StatusAction.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_uri = 2;
 * @return {string}
 */
proto.api.StatusAction.prototype.getTargetUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.StatusAction} returns this
 */
proto.api.StatusAction.prototype.setTargetUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    echo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusAction: (f = msg.getStatusAction()) && proto.api.StatusAction.toObject(includeInstance, f),
    sendMessage: (f = msg.getSendMessage()) && proto.api.SendMessageResponse.toObject(includeInstance, f),
    getChannels: (f = msg.getGetChannels()) && proto.api.GetChannelsResponse.toObject(includeInstance, f),
    getMessages: (f = msg.getGetMessages()) && proto.api.GetMessagesResponse.toObject(includeInstance, f),
    createChannel: (f = msg.getCreateChannel()) && proto.api.CreateChannelResponse.toObject(includeInstance, f),
    markAsRead: (f = msg.getMarkAsRead()) && proto.api.MarkAsReadResponse.toObject(includeInstance, f),
    leaveChannel: (f = msg.getLeaveChannel()) && proto.api.LeaveChannelResponse.toObject(includeInstance, f),
    updateUserChannel: (f = msg.getUpdateUserChannel()) && proto.api.UpdateUserChannelResponse.toObject(includeInstance, f),
    getChannel: (f = msg.getGetChannel()) && proto.api.GetChannelResponse.toObject(includeInstance, f),
    getSellerChannels: (f = msg.getGetSellerChannels()) && proto.api.GetSellerChannelsResponse.toObject(includeInstance, f),
    getUnreadCount: (f = msg.getGetUnreadCount()) && proto.api.GetUnreadCountResponse.toObject(includeInstance, f),
    createPromise: (f = msg.getCreatePromise()) && proto.api.CreatePromiseResponse.toObject(includeInstance, f),
    updatePromise: (f = msg.getUpdatePromise()) && proto.api.UpdatePromiseResponse.toObject(includeInstance, f),
    deletePromise: (f = msg.getDeletePromise()) && proto.api.DeletePromiseResponse.toObject(includeInstance, f),
    getBizAccounts: (f = msg.getGetBizAccounts()) && proto.api.GetBizAccountsResponse.toObject(includeInstance, f),
    getBizAccount: (f = msg.getGetBizAccount()) && proto.api.GetBizAccountResponse.toObject(includeInstance, f),
    getStickers: (f = msg.getGetStickers()) && proto.api.GetStickersResponse.toObject(includeInstance, f),
    getSafeNumber: (f = msg.getGetSafeNumber()) && proto.api.GetSafeNumberResponse.toObject(includeInstance, f),
    updateBlockStatus: (f = msg.getUpdateBlockStatus()) && proto.api.UpdateBlockStatusResponse.toObject(includeInstance, f),
    banUser: (f = msg.getBanUser()) && proto.api.BanUserResponse.toObject(includeInstance, f),
    getRtcToken: (f = msg.getGetRtcToken()) && proto.api.GetRtcTokenResponse.toObject(includeInstance, f),
    cancelVoiceChannel: (f = msg.getCancelVoiceChannel()) && proto.api.CancelVoiceChannelResponse.toObject(includeInstance, f),
    rejectVoiceChannel: (f = msg.getRejectVoiceChannel()) && proto.api.RejectVoiceChannelResponse.toObject(includeInstance, f),
    getChannelListHeader: (f = msg.getGetChannelListHeader()) && proto.api.GetChannelListHeaderResponse.toObject(includeInstance, f),
    closeChannelListHeader: (f = msg.getCloseChannelListHeader()) && proto.api.CloseChannelListHeaderResponse.toObject(includeInstance, f),
    deleteMessage: (f = msg.getDeleteMessage()) && proto.api.DeleteMessageResponse.toObject(includeInstance, f),
    sendPersistentMenuPostback: (f = msg.getSendPersistentMenuPostback()) && proto.api.SendPersistentMenuPostbackResponse.toObject(includeInstance, f),
    getAutoReplyMetadata: (f = msg.getGetAutoReplyMetadata()) && proto.api.GetAutoReplyMetadataResponse.toObject(includeInstance, f),
    updateAutoReplyMetadata: (f = msg.getUpdateAutoReplyMetadata()) && proto.api.UpdateAutoReplyMetadataResponse.toObject(includeInstance, f),
    listAutoReplies: (f = msg.getListAutoReplies()) && proto.api.ListAutoRepliesResponse.toObject(includeInstance, f),
    createAutoReply: (f = msg.getCreateAutoReply()) && proto.api.CreateAutoReplyResponse.toObject(includeInstance, f),
    getAutoReply: (f = msg.getGetAutoReply()) && proto.api.GetAutoReplyResponse.toObject(includeInstance, f),
    updateAutoReply: (f = msg.getUpdateAutoReply()) && proto.api.UpdateAutoReplyResponse.toObject(includeInstance, f),
    deleteAutoReply: (f = msg.getDeleteAutoReply()) && proto.api.DeleteAutoReplyResponse.toObject(includeInstance, f),
    createAnnouncement: (f = msg.getCreateAnnouncement()) && proto.api.CreateAnnouncementResponse.toObject(includeInstance, f),
    deleteAnnouncement: (f = msg.getDeleteAnnouncement()) && proto.api.DeleteAnnouncementResponse.toObject(includeInstance, f),
    setReaction: (f = msg.getSetReaction()) && proto.api.SetReactionResponse.toObject(includeInstance, f),
    listReaction: (f = msg.getListReaction()) && proto.api.ListReactionResponse.toObject(includeInstance, f),
    getFilters: (f = msg.getGetFilters()) && proto.api.GetFiltersResponse.toObject(includeInstance, f),
    getMember: (f = msg.getGetMember()) && proto.api.GetMemberResponse.toObject(includeInstance, f),
    getMembers: (f = msg.getGetMembers()) && proto.api.GetMembersResponse.toObject(includeInstance, f),
    searchMembers: (f = msg.getSearchMembers()) && proto.api.SearchMembersResponse.toObject(includeInstance, f),
    sendButtonPostback: (f = msg.getSendButtonPostback()) && proto.api.SendButtonPostbackResponse.toObject(includeInstance, f),
    newMessage: (f = msg.getNewMessage()) && api_event_pb.NewMessageEvent.toObject(includeInstance, f),
    readMessage: (f = msg.getReadMessage()) && api_event_pb.ReadMessageEvent.toObject(includeInstance, f),
    joinMember: (f = msg.getJoinMember()) && api_event_pb.JoinMemberEvent.toObject(includeInstance, f),
    leaveMember: (f = msg.getLeaveMember()) && api_event_pb.LeaveMemberEvent.toObject(includeInstance, f),
    renewChannel: (f = msg.getRenewChannel()) && api_event_pb.RenewChannelEvent.toObject(includeInstance, f),
    updateBubbleGroup: (f = msg.getUpdateBubbleGroup()) && api_event_pb.UpdateBubbleGroupEvent.toObject(includeInstance, f),
    updateMessage: (f = msg.getUpdateMessage()) && api_event_pb.UpdateMessageEvent.toObject(includeInstance, f),
    joinVoiceChannel: (f = msg.getJoinVoiceChannel()) && api_event_pb.JoinVoiceChannelEvent.toObject(includeInstance, f),
    terminateVoiceChannel: (f = msg.getTerminateVoiceChannel()) && api_event_pb.TerminateVoiceChannelEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Response}
 */
proto.api.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Response;
  return proto.api.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Response}
 */
proto.api.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.api.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEcho(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 7:
      var value = new proto.api.StatusAction;
      reader.readMessage(value,proto.api.StatusAction.deserializeBinaryFromReader);
      msg.setStatusAction(value);
      break;
    case 100:
      var value = new proto.api.SendMessageResponse;
      reader.readMessage(value,proto.api.SendMessageResponse.deserializeBinaryFromReader);
      msg.setSendMessage(value);
      break;
    case 101:
      var value = new proto.api.GetChannelsResponse;
      reader.readMessage(value,proto.api.GetChannelsResponse.deserializeBinaryFromReader);
      msg.setGetChannels(value);
      break;
    case 102:
      var value = new proto.api.GetMessagesResponse;
      reader.readMessage(value,proto.api.GetMessagesResponse.deserializeBinaryFromReader);
      msg.setGetMessages(value);
      break;
    case 103:
      var value = new proto.api.CreateChannelResponse;
      reader.readMessage(value,proto.api.CreateChannelResponse.deserializeBinaryFromReader);
      msg.setCreateChannel(value);
      break;
    case 104:
      var value = new proto.api.MarkAsReadResponse;
      reader.readMessage(value,proto.api.MarkAsReadResponse.deserializeBinaryFromReader);
      msg.setMarkAsRead(value);
      break;
    case 105:
      var value = new proto.api.LeaveChannelResponse;
      reader.readMessage(value,proto.api.LeaveChannelResponse.deserializeBinaryFromReader);
      msg.setLeaveChannel(value);
      break;
    case 106:
      var value = new proto.api.UpdateUserChannelResponse;
      reader.readMessage(value,proto.api.UpdateUserChannelResponse.deserializeBinaryFromReader);
      msg.setUpdateUserChannel(value);
      break;
    case 107:
      var value = new proto.api.GetChannelResponse;
      reader.readMessage(value,proto.api.GetChannelResponse.deserializeBinaryFromReader);
      msg.setGetChannel(value);
      break;
    case 108:
      var value = new proto.api.GetSellerChannelsResponse;
      reader.readMessage(value,proto.api.GetSellerChannelsResponse.deserializeBinaryFromReader);
      msg.setGetSellerChannels(value);
      break;
    case 109:
      var value = new proto.api.GetUnreadCountResponse;
      reader.readMessage(value,proto.api.GetUnreadCountResponse.deserializeBinaryFromReader);
      msg.setGetUnreadCount(value);
      break;
    case 110:
      var value = new proto.api.CreatePromiseResponse;
      reader.readMessage(value,proto.api.CreatePromiseResponse.deserializeBinaryFromReader);
      msg.setCreatePromise(value);
      break;
    case 111:
      var value = new proto.api.UpdatePromiseResponse;
      reader.readMessage(value,proto.api.UpdatePromiseResponse.deserializeBinaryFromReader);
      msg.setUpdatePromise(value);
      break;
    case 112:
      var value = new proto.api.DeletePromiseResponse;
      reader.readMessage(value,proto.api.DeletePromiseResponse.deserializeBinaryFromReader);
      msg.setDeletePromise(value);
      break;
    case 113:
      var value = new proto.api.GetBizAccountsResponse;
      reader.readMessage(value,proto.api.GetBizAccountsResponse.deserializeBinaryFromReader);
      msg.setGetBizAccounts(value);
      break;
    case 114:
      var value = new proto.api.GetBizAccountResponse;
      reader.readMessage(value,proto.api.GetBizAccountResponse.deserializeBinaryFromReader);
      msg.setGetBizAccount(value);
      break;
    case 115:
      var value = new proto.api.GetStickersResponse;
      reader.readMessage(value,proto.api.GetStickersResponse.deserializeBinaryFromReader);
      msg.setGetStickers(value);
      break;
    case 116:
      var value = new proto.api.GetSafeNumberResponse;
      reader.readMessage(value,proto.api.GetSafeNumberResponse.deserializeBinaryFromReader);
      msg.setGetSafeNumber(value);
      break;
    case 117:
      var value = new proto.api.UpdateBlockStatusResponse;
      reader.readMessage(value,proto.api.UpdateBlockStatusResponse.deserializeBinaryFromReader);
      msg.setUpdateBlockStatus(value);
      break;
    case 118:
      var value = new proto.api.BanUserResponse;
      reader.readMessage(value,proto.api.BanUserResponse.deserializeBinaryFromReader);
      msg.setBanUser(value);
      break;
    case 119:
      var value = new proto.api.GetRtcTokenResponse;
      reader.readMessage(value,proto.api.GetRtcTokenResponse.deserializeBinaryFromReader);
      msg.setGetRtcToken(value);
      break;
    case 120:
      var value = new proto.api.CancelVoiceChannelResponse;
      reader.readMessage(value,proto.api.CancelVoiceChannelResponse.deserializeBinaryFromReader);
      msg.setCancelVoiceChannel(value);
      break;
    case 121:
      var value = new proto.api.RejectVoiceChannelResponse;
      reader.readMessage(value,proto.api.RejectVoiceChannelResponse.deserializeBinaryFromReader);
      msg.setRejectVoiceChannel(value);
      break;
    case 122:
      var value = new proto.api.GetChannelListHeaderResponse;
      reader.readMessage(value,proto.api.GetChannelListHeaderResponse.deserializeBinaryFromReader);
      msg.setGetChannelListHeader(value);
      break;
    case 123:
      var value = new proto.api.CloseChannelListHeaderResponse;
      reader.readMessage(value,proto.api.CloseChannelListHeaderResponse.deserializeBinaryFromReader);
      msg.setCloseChannelListHeader(value);
      break;
    case 124:
      var value = new proto.api.DeleteMessageResponse;
      reader.readMessage(value,proto.api.DeleteMessageResponse.deserializeBinaryFromReader);
      msg.setDeleteMessage(value);
      break;
    case 125:
      var value = new proto.api.SendPersistentMenuPostbackResponse;
      reader.readMessage(value,proto.api.SendPersistentMenuPostbackResponse.deserializeBinaryFromReader);
      msg.setSendPersistentMenuPostback(value);
      break;
    case 126:
      var value = new proto.api.GetAutoReplyMetadataResponse;
      reader.readMessage(value,proto.api.GetAutoReplyMetadataResponse.deserializeBinaryFromReader);
      msg.setGetAutoReplyMetadata(value);
      break;
    case 127:
      var value = new proto.api.UpdateAutoReplyMetadataResponse;
      reader.readMessage(value,proto.api.UpdateAutoReplyMetadataResponse.deserializeBinaryFromReader);
      msg.setUpdateAutoReplyMetadata(value);
      break;
    case 128:
      var value = new proto.api.ListAutoRepliesResponse;
      reader.readMessage(value,proto.api.ListAutoRepliesResponse.deserializeBinaryFromReader);
      msg.setListAutoReplies(value);
      break;
    case 129:
      var value = new proto.api.CreateAutoReplyResponse;
      reader.readMessage(value,proto.api.CreateAutoReplyResponse.deserializeBinaryFromReader);
      msg.setCreateAutoReply(value);
      break;
    case 130:
      var value = new proto.api.GetAutoReplyResponse;
      reader.readMessage(value,proto.api.GetAutoReplyResponse.deserializeBinaryFromReader);
      msg.setGetAutoReply(value);
      break;
    case 131:
      var value = new proto.api.UpdateAutoReplyResponse;
      reader.readMessage(value,proto.api.UpdateAutoReplyResponse.deserializeBinaryFromReader);
      msg.setUpdateAutoReply(value);
      break;
    case 132:
      var value = new proto.api.DeleteAutoReplyResponse;
      reader.readMessage(value,proto.api.DeleteAutoReplyResponse.deserializeBinaryFromReader);
      msg.setDeleteAutoReply(value);
      break;
    case 133:
      var value = new proto.api.CreateAnnouncementResponse;
      reader.readMessage(value,proto.api.CreateAnnouncementResponse.deserializeBinaryFromReader);
      msg.setCreateAnnouncement(value);
      break;
    case 134:
      var value = new proto.api.DeleteAnnouncementResponse;
      reader.readMessage(value,proto.api.DeleteAnnouncementResponse.deserializeBinaryFromReader);
      msg.setDeleteAnnouncement(value);
      break;
    case 135:
      var value = new proto.api.SetReactionResponse;
      reader.readMessage(value,proto.api.SetReactionResponse.deserializeBinaryFromReader);
      msg.setSetReaction(value);
      break;
    case 136:
      var value = new proto.api.ListReactionResponse;
      reader.readMessage(value,proto.api.ListReactionResponse.deserializeBinaryFromReader);
      msg.setListReaction(value);
      break;
    case 137:
      var value = new proto.api.GetFiltersResponse;
      reader.readMessage(value,proto.api.GetFiltersResponse.deserializeBinaryFromReader);
      msg.setGetFilters(value);
      break;
    case 141:
      var value = new proto.api.GetMemberResponse;
      reader.readMessage(value,proto.api.GetMemberResponse.deserializeBinaryFromReader);
      msg.setGetMember(value);
      break;
    case 142:
      var value = new proto.api.GetMembersResponse;
      reader.readMessage(value,proto.api.GetMembersResponse.deserializeBinaryFromReader);
      msg.setGetMembers(value);
      break;
    case 143:
      var value = new proto.api.SearchMembersResponse;
      reader.readMessage(value,proto.api.SearchMembersResponse.deserializeBinaryFromReader);
      msg.setSearchMembers(value);
      break;
    case 150:
      var value = new proto.api.SendButtonPostbackResponse;
      reader.readMessage(value,proto.api.SendButtonPostbackResponse.deserializeBinaryFromReader);
      msg.setSendButtonPostback(value);
      break;
    case 200:
      var value = new api_event_pb.NewMessageEvent;
      reader.readMessage(value,api_event_pb.NewMessageEvent.deserializeBinaryFromReader);
      msg.setNewMessage(value);
      break;
    case 201:
      var value = new api_event_pb.ReadMessageEvent;
      reader.readMessage(value,api_event_pb.ReadMessageEvent.deserializeBinaryFromReader);
      msg.setReadMessage(value);
      break;
    case 202:
      var value = new api_event_pb.JoinMemberEvent;
      reader.readMessage(value,api_event_pb.JoinMemberEvent.deserializeBinaryFromReader);
      msg.setJoinMember(value);
      break;
    case 203:
      var value = new api_event_pb.LeaveMemberEvent;
      reader.readMessage(value,api_event_pb.LeaveMemberEvent.deserializeBinaryFromReader);
      msg.setLeaveMember(value);
      break;
    case 204:
      var value = new api_event_pb.RenewChannelEvent;
      reader.readMessage(value,api_event_pb.RenewChannelEvent.deserializeBinaryFromReader);
      msg.setRenewChannel(value);
      break;
    case 205:
      var value = new api_event_pb.UpdateBubbleGroupEvent;
      reader.readMessage(value,api_event_pb.UpdateBubbleGroupEvent.deserializeBinaryFromReader);
      msg.setUpdateBubbleGroup(value);
      break;
    case 206:
      var value = new api_event_pb.UpdateMessageEvent;
      reader.readMessage(value,api_event_pb.UpdateMessageEvent.deserializeBinaryFromReader);
      msg.setUpdateMessage(value);
      break;
    case 211:
      var value = new api_event_pb.JoinVoiceChannelEvent;
      reader.readMessage(value,api_event_pb.JoinVoiceChannelEvent.deserializeBinaryFromReader);
      msg.setJoinVoiceChannel(value);
      break;
    case 212:
      var value = new api_event_pb.TerminateVoiceChannelEvent;
      reader.readMessage(value,api_event_pb.TerminateVoiceChannelEvent.deserializeBinaryFromReader);
      msg.setTerminateVoiceChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEcho();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusAction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.StatusAction.serializeBinaryToWriter
    );
  }
  f = message.getSendMessage();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.api.SendMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetChannels();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.api.GetChannelsResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetMessages();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      proto.api.GetMessagesResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateChannel();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.api.CreateChannelResponse.serializeBinaryToWriter
    );
  }
  f = message.getMarkAsRead();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      proto.api.MarkAsReadResponse.serializeBinaryToWriter
    );
  }
  f = message.getLeaveChannel();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      proto.api.LeaveChannelResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUserChannel();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      proto.api.UpdateUserChannelResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetChannel();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      proto.api.GetChannelResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetSellerChannels();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      proto.api.GetSellerChannelsResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetUnreadCount();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      proto.api.GetUnreadCountResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatePromise();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      proto.api.CreatePromiseResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatePromise();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      proto.api.UpdatePromiseResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeletePromise();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      proto.api.DeletePromiseResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetBizAccounts();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      proto.api.GetBizAccountsResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetBizAccount();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      proto.api.GetBizAccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetStickers();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      proto.api.GetStickersResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetSafeNumber();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      proto.api.GetSafeNumberResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBlockStatus();
  if (f != null) {
    writer.writeMessage(
      117,
      f,
      proto.api.UpdateBlockStatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getBanUser();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      proto.api.BanUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetRtcToken();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      proto.api.GetRtcTokenResponse.serializeBinaryToWriter
    );
  }
  f = message.getCancelVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      120,
      f,
      proto.api.CancelVoiceChannelResponse.serializeBinaryToWriter
    );
  }
  f = message.getRejectVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      proto.api.RejectVoiceChannelResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetChannelListHeader();
  if (f != null) {
    writer.writeMessage(
      122,
      f,
      proto.api.GetChannelListHeaderResponse.serializeBinaryToWriter
    );
  }
  f = message.getCloseChannelListHeader();
  if (f != null) {
    writer.writeMessage(
      123,
      f,
      proto.api.CloseChannelListHeaderResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteMessage();
  if (f != null) {
    writer.writeMessage(
      124,
      f,
      proto.api.DeleteMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSendPersistentMenuPostback();
  if (f != null) {
    writer.writeMessage(
      125,
      f,
      proto.api.SendPersistentMenuPostbackResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetAutoReplyMetadata();
  if (f != null) {
    writer.writeMessage(
      126,
      f,
      proto.api.GetAutoReplyMetadataResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAutoReplyMetadata();
  if (f != null) {
    writer.writeMessage(
      127,
      f,
      proto.api.UpdateAutoReplyMetadataResponse.serializeBinaryToWriter
    );
  }
  f = message.getListAutoReplies();
  if (f != null) {
    writer.writeMessage(
      128,
      f,
      proto.api.ListAutoRepliesResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateAutoReply();
  if (f != null) {
    writer.writeMessage(
      129,
      f,
      proto.api.CreateAutoReplyResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetAutoReply();
  if (f != null) {
    writer.writeMessage(
      130,
      f,
      proto.api.GetAutoReplyResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAutoReply();
  if (f != null) {
    writer.writeMessage(
      131,
      f,
      proto.api.UpdateAutoReplyResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAutoReply();
  if (f != null) {
    writer.writeMessage(
      132,
      f,
      proto.api.DeleteAutoReplyResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateAnnouncement();
  if (f != null) {
    writer.writeMessage(
      133,
      f,
      proto.api.CreateAnnouncementResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAnnouncement();
  if (f != null) {
    writer.writeMessage(
      134,
      f,
      proto.api.DeleteAnnouncementResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetReaction();
  if (f != null) {
    writer.writeMessage(
      135,
      f,
      proto.api.SetReactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getListReaction();
  if (f != null) {
    writer.writeMessage(
      136,
      f,
      proto.api.ListReactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetFilters();
  if (f != null) {
    writer.writeMessage(
      137,
      f,
      proto.api.GetFiltersResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetMember();
  if (f != null) {
    writer.writeMessage(
      141,
      f,
      proto.api.GetMemberResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetMembers();
  if (f != null) {
    writer.writeMessage(
      142,
      f,
      proto.api.GetMembersResponse.serializeBinaryToWriter
    );
  }
  f = message.getSearchMembers();
  if (f != null) {
    writer.writeMessage(
      143,
      f,
      proto.api.SearchMembersResponse.serializeBinaryToWriter
    );
  }
  f = message.getSendButtonPostback();
  if (f != null) {
    writer.writeMessage(
      150,
      f,
      proto.api.SendButtonPostbackResponse.serializeBinaryToWriter
    );
  }
  f = message.getNewMessage();
  if (f != null) {
    writer.writeMessage(
      200,
      f,
      api_event_pb.NewMessageEvent.serializeBinaryToWriter
    );
  }
  f = message.getReadMessage();
  if (f != null) {
    writer.writeMessage(
      201,
      f,
      api_event_pb.ReadMessageEvent.serializeBinaryToWriter
    );
  }
  f = message.getJoinMember();
  if (f != null) {
    writer.writeMessage(
      202,
      f,
      api_event_pb.JoinMemberEvent.serializeBinaryToWriter
    );
  }
  f = message.getLeaveMember();
  if (f != null) {
    writer.writeMessage(
      203,
      f,
      api_event_pb.LeaveMemberEvent.serializeBinaryToWriter
    );
  }
  f = message.getRenewChannel();
  if (f != null) {
    writer.writeMessage(
      204,
      f,
      api_event_pb.RenewChannelEvent.serializeBinaryToWriter
    );
  }
  f = message.getUpdateBubbleGroup();
  if (f != null) {
    writer.writeMessage(
      205,
      f,
      api_event_pb.UpdateBubbleGroupEvent.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMessage();
  if (f != null) {
    writer.writeMessage(
      206,
      f,
      api_event_pb.UpdateMessageEvent.serializeBinaryToWriter
    );
  }
  f = message.getJoinVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      211,
      f,
      api_event_pb.JoinVoiceChannelEvent.serializeBinaryToWriter
    );
  }
  f = message.getTerminateVoiceChannel();
  if (f != null) {
    writer.writeMessage(
      212,
      f,
      api_event_pb.TerminateVoiceChannelEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.Response.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.api.Status}
 */
proto.api.Response.prototype.getStatus = function() {
  return /** @type {!proto.api.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.Status} value
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.api.Response.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string echo = 4;
 * @return {string}
 */
proto.api.Response.prototype.getEcho = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.setEcho = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 biz_account_id = 5;
 * @return {number}
 */
proto.api.Response.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status_message = 6;
 * @return {string}
 */
proto.api.Response.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional StatusAction status_action = 7;
 * @return {?proto.api.StatusAction}
 */
proto.api.Response.prototype.getStatusAction = function() {
  return /** @type{?proto.api.StatusAction} */ (
    jspb.Message.getWrapperField(this, proto.api.StatusAction, 7));
};


/**
 * @param {?proto.api.StatusAction|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setStatusAction = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearStatusAction = function() {
  return this.setStatusAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasStatusAction = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SendMessageResponse send_message = 100;
 * @return {?proto.api.SendMessageResponse}
 */
proto.api.Response.prototype.getSendMessage = function() {
  return /** @type{?proto.api.SendMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.SendMessageResponse, 100));
};


/**
 * @param {?proto.api.SendMessageResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setSendMessage = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearSendMessage = function() {
  return this.setSendMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasSendMessage = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional GetChannelsResponse get_channels = 101;
 * @return {?proto.api.GetChannelsResponse}
 */
proto.api.Response.prototype.getGetChannels = function() {
  return /** @type{?proto.api.GetChannelsResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelsResponse, 101));
};


/**
 * @param {?proto.api.GetChannelsResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetChannels = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetChannels = function() {
  return this.setGetChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetChannels = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional GetMessagesResponse get_messages = 102;
 * @return {?proto.api.GetMessagesResponse}
 */
proto.api.Response.prototype.getGetMessages = function() {
  return /** @type{?proto.api.GetMessagesResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetMessagesResponse, 102));
};


/**
 * @param {?proto.api.GetMessagesResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetMessages = function(value) {
  return jspb.Message.setWrapperField(this, 102, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetMessages = function() {
  return this.setGetMessages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetMessages = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional CreateChannelResponse create_channel = 103;
 * @return {?proto.api.CreateChannelResponse}
 */
proto.api.Response.prototype.getCreateChannel = function() {
  return /** @type{?proto.api.CreateChannelResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.CreateChannelResponse, 103));
};


/**
 * @param {?proto.api.CreateChannelResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setCreateChannel = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearCreateChannel = function() {
  return this.setCreateChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasCreateChannel = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional MarkAsReadResponse mark_as_read = 104;
 * @return {?proto.api.MarkAsReadResponse}
 */
proto.api.Response.prototype.getMarkAsRead = function() {
  return /** @type{?proto.api.MarkAsReadResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.MarkAsReadResponse, 104));
};


/**
 * @param {?proto.api.MarkAsReadResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setMarkAsRead = function(value) {
  return jspb.Message.setWrapperField(this, 104, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearMarkAsRead = function() {
  return this.setMarkAsRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasMarkAsRead = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional LeaveChannelResponse leave_channel = 105;
 * @return {?proto.api.LeaveChannelResponse}
 */
proto.api.Response.prototype.getLeaveChannel = function() {
  return /** @type{?proto.api.LeaveChannelResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.LeaveChannelResponse, 105));
};


/**
 * @param {?proto.api.LeaveChannelResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setLeaveChannel = function(value) {
  return jspb.Message.setWrapperField(this, 105, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearLeaveChannel = function() {
  return this.setLeaveChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasLeaveChannel = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional UpdateUserChannelResponse update_user_channel = 106;
 * @return {?proto.api.UpdateUserChannelResponse}
 */
proto.api.Response.prototype.getUpdateUserChannel = function() {
  return /** @type{?proto.api.UpdateUserChannelResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateUserChannelResponse, 106));
};


/**
 * @param {?proto.api.UpdateUserChannelResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdateUserChannel = function(value) {
  return jspb.Message.setWrapperField(this, 106, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdateUserChannel = function() {
  return this.setUpdateUserChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdateUserChannel = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional GetChannelResponse get_channel = 107;
 * @return {?proto.api.GetChannelResponse}
 */
proto.api.Response.prototype.getGetChannel = function() {
  return /** @type{?proto.api.GetChannelResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelResponse, 107));
};


/**
 * @param {?proto.api.GetChannelResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetChannel = function(value) {
  return jspb.Message.setWrapperField(this, 107, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetChannel = function() {
  return this.setGetChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetChannel = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional GetSellerChannelsResponse get_seller_channels = 108;
 * @return {?proto.api.GetSellerChannelsResponse}
 */
proto.api.Response.prototype.getGetSellerChannels = function() {
  return /** @type{?proto.api.GetSellerChannelsResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetSellerChannelsResponse, 108));
};


/**
 * @param {?proto.api.GetSellerChannelsResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetSellerChannels = function(value) {
  return jspb.Message.setWrapperField(this, 108, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetSellerChannels = function() {
  return this.setGetSellerChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetSellerChannels = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional GetUnreadCountResponse get_unread_count = 109;
 * @return {?proto.api.GetUnreadCountResponse}
 */
proto.api.Response.prototype.getGetUnreadCount = function() {
  return /** @type{?proto.api.GetUnreadCountResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetUnreadCountResponse, 109));
};


/**
 * @param {?proto.api.GetUnreadCountResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetUnreadCount = function(value) {
  return jspb.Message.setWrapperField(this, 109, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetUnreadCount = function() {
  return this.setGetUnreadCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetUnreadCount = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional CreatePromiseResponse create_promise = 110;
 * @return {?proto.api.CreatePromiseResponse}
 */
proto.api.Response.prototype.getCreatePromise = function() {
  return /** @type{?proto.api.CreatePromiseResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.CreatePromiseResponse, 110));
};


/**
 * @param {?proto.api.CreatePromiseResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setCreatePromise = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearCreatePromise = function() {
  return this.setCreatePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasCreatePromise = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional UpdatePromiseResponse update_promise = 111;
 * @return {?proto.api.UpdatePromiseResponse}
 */
proto.api.Response.prototype.getUpdatePromise = function() {
  return /** @type{?proto.api.UpdatePromiseResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdatePromiseResponse, 111));
};


/**
 * @param {?proto.api.UpdatePromiseResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdatePromise = function(value) {
  return jspb.Message.setWrapperField(this, 111, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdatePromise = function() {
  return this.setUpdatePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdatePromise = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional DeletePromiseResponse delete_promise = 112;
 * @return {?proto.api.DeletePromiseResponse}
 */
proto.api.Response.prototype.getDeletePromise = function() {
  return /** @type{?proto.api.DeletePromiseResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.DeletePromiseResponse, 112));
};


/**
 * @param {?proto.api.DeletePromiseResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setDeletePromise = function(value) {
  return jspb.Message.setWrapperField(this, 112, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearDeletePromise = function() {
  return this.setDeletePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasDeletePromise = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional GetBizAccountsResponse get_biz_accounts = 113;
 * @return {?proto.api.GetBizAccountsResponse}
 */
proto.api.Response.prototype.getGetBizAccounts = function() {
  return /** @type{?proto.api.GetBizAccountsResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetBizAccountsResponse, 113));
};


/**
 * @param {?proto.api.GetBizAccountsResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetBizAccounts = function(value) {
  return jspb.Message.setWrapperField(this, 113, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetBizAccounts = function() {
  return this.setGetBizAccounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetBizAccounts = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional GetBizAccountResponse get_biz_account = 114;
 * @return {?proto.api.GetBizAccountResponse}
 */
proto.api.Response.prototype.getGetBizAccount = function() {
  return /** @type{?proto.api.GetBizAccountResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetBizAccountResponse, 114));
};


/**
 * @param {?proto.api.GetBizAccountResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetBizAccount = function(value) {
  return jspb.Message.setWrapperField(this, 114, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetBizAccount = function() {
  return this.setGetBizAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetBizAccount = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional GetStickersResponse get_stickers = 115;
 * @return {?proto.api.GetStickersResponse}
 */
proto.api.Response.prototype.getGetStickers = function() {
  return /** @type{?proto.api.GetStickersResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetStickersResponse, 115));
};


/**
 * @param {?proto.api.GetStickersResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetStickers = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetStickers = function() {
  return this.setGetStickers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetStickers = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional GetSafeNumberResponse get_safe_number = 116;
 * @return {?proto.api.GetSafeNumberResponse}
 */
proto.api.Response.prototype.getGetSafeNumber = function() {
  return /** @type{?proto.api.GetSafeNumberResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetSafeNumberResponse, 116));
};


/**
 * @param {?proto.api.GetSafeNumberResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetSafeNumber = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetSafeNumber = function() {
  return this.setGetSafeNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetSafeNumber = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional UpdateBlockStatusResponse update_block_status = 117;
 * @return {?proto.api.UpdateBlockStatusResponse}
 */
proto.api.Response.prototype.getUpdateBlockStatus = function() {
  return /** @type{?proto.api.UpdateBlockStatusResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateBlockStatusResponse, 117));
};


/**
 * @param {?proto.api.UpdateBlockStatusResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdateBlockStatus = function(value) {
  return jspb.Message.setWrapperField(this, 117, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdateBlockStatus = function() {
  return this.setUpdateBlockStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdateBlockStatus = function() {
  return jspb.Message.getField(this, 117) != null;
};


/**
 * optional BanUserResponse ban_user = 118;
 * @return {?proto.api.BanUserResponse}
 */
proto.api.Response.prototype.getBanUser = function() {
  return /** @type{?proto.api.BanUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.BanUserResponse, 118));
};


/**
 * @param {?proto.api.BanUserResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setBanUser = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearBanUser = function() {
  return this.setBanUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasBanUser = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional GetRtcTokenResponse get_rtc_token = 119;
 * @return {?proto.api.GetRtcTokenResponse}
 */
proto.api.Response.prototype.getGetRtcToken = function() {
  return /** @type{?proto.api.GetRtcTokenResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetRtcTokenResponse, 119));
};


/**
 * @param {?proto.api.GetRtcTokenResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetRtcToken = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetRtcToken = function() {
  return this.setGetRtcToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetRtcToken = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional CancelVoiceChannelResponse cancel_voice_channel = 120;
 * @return {?proto.api.CancelVoiceChannelResponse}
 */
proto.api.Response.prototype.getCancelVoiceChannel = function() {
  return /** @type{?proto.api.CancelVoiceChannelResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.CancelVoiceChannelResponse, 120));
};


/**
 * @param {?proto.api.CancelVoiceChannelResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setCancelVoiceChannel = function(value) {
  return jspb.Message.setWrapperField(this, 120, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearCancelVoiceChannel = function() {
  return this.setCancelVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasCancelVoiceChannel = function() {
  return jspb.Message.getField(this, 120) != null;
};


/**
 * optional RejectVoiceChannelResponse reject_voice_channel = 121;
 * @return {?proto.api.RejectVoiceChannelResponse}
 */
proto.api.Response.prototype.getRejectVoiceChannel = function() {
  return /** @type{?proto.api.RejectVoiceChannelResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.RejectVoiceChannelResponse, 121));
};


/**
 * @param {?proto.api.RejectVoiceChannelResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setRejectVoiceChannel = function(value) {
  return jspb.Message.setWrapperField(this, 121, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearRejectVoiceChannel = function() {
  return this.setRejectVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasRejectVoiceChannel = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional GetChannelListHeaderResponse get_channel_list_header = 122;
 * @return {?proto.api.GetChannelListHeaderResponse}
 */
proto.api.Response.prototype.getGetChannelListHeader = function() {
  return /** @type{?proto.api.GetChannelListHeaderResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelListHeaderResponse, 122));
};


/**
 * @param {?proto.api.GetChannelListHeaderResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetChannelListHeader = function(value) {
  return jspb.Message.setWrapperField(this, 122, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetChannelListHeader = function() {
  return this.setGetChannelListHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetChannelListHeader = function() {
  return jspb.Message.getField(this, 122) != null;
};


/**
 * optional CloseChannelListHeaderResponse close_channel_list_header = 123;
 * @return {?proto.api.CloseChannelListHeaderResponse}
 */
proto.api.Response.prototype.getCloseChannelListHeader = function() {
  return /** @type{?proto.api.CloseChannelListHeaderResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.CloseChannelListHeaderResponse, 123));
};


/**
 * @param {?proto.api.CloseChannelListHeaderResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setCloseChannelListHeader = function(value) {
  return jspb.Message.setWrapperField(this, 123, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearCloseChannelListHeader = function() {
  return this.setCloseChannelListHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasCloseChannelListHeader = function() {
  return jspb.Message.getField(this, 123) != null;
};


/**
 * optional DeleteMessageResponse delete_message = 124;
 * @return {?proto.api.DeleteMessageResponse}
 */
proto.api.Response.prototype.getDeleteMessage = function() {
  return /** @type{?proto.api.DeleteMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.DeleteMessageResponse, 124));
};


/**
 * @param {?proto.api.DeleteMessageResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setDeleteMessage = function(value) {
  return jspb.Message.setWrapperField(this, 124, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearDeleteMessage = function() {
  return this.setDeleteMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasDeleteMessage = function() {
  return jspb.Message.getField(this, 124) != null;
};


/**
 * optional SendPersistentMenuPostbackResponse send_persistent_menu_postback = 125;
 * @return {?proto.api.SendPersistentMenuPostbackResponse}
 */
proto.api.Response.prototype.getSendPersistentMenuPostback = function() {
  return /** @type{?proto.api.SendPersistentMenuPostbackResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.SendPersistentMenuPostbackResponse, 125));
};


/**
 * @param {?proto.api.SendPersistentMenuPostbackResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setSendPersistentMenuPostback = function(value) {
  return jspb.Message.setWrapperField(this, 125, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearSendPersistentMenuPostback = function() {
  return this.setSendPersistentMenuPostback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasSendPersistentMenuPostback = function() {
  return jspb.Message.getField(this, 125) != null;
};


/**
 * optional GetAutoReplyMetadataResponse get_auto_reply_metadata = 126;
 * @return {?proto.api.GetAutoReplyMetadataResponse}
 */
proto.api.Response.prototype.getGetAutoReplyMetadata = function() {
  return /** @type{?proto.api.GetAutoReplyMetadataResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetAutoReplyMetadataResponse, 126));
};


/**
 * @param {?proto.api.GetAutoReplyMetadataResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetAutoReplyMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 126, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetAutoReplyMetadata = function() {
  return this.setGetAutoReplyMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetAutoReplyMetadata = function() {
  return jspb.Message.getField(this, 126) != null;
};


/**
 * optional UpdateAutoReplyMetadataResponse update_auto_reply_metadata = 127;
 * @return {?proto.api.UpdateAutoReplyMetadataResponse}
 */
proto.api.Response.prototype.getUpdateAutoReplyMetadata = function() {
  return /** @type{?proto.api.UpdateAutoReplyMetadataResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateAutoReplyMetadataResponse, 127));
};


/**
 * @param {?proto.api.UpdateAutoReplyMetadataResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdateAutoReplyMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 127, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdateAutoReplyMetadata = function() {
  return this.setUpdateAutoReplyMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdateAutoReplyMetadata = function() {
  return jspb.Message.getField(this, 127) != null;
};


/**
 * optional ListAutoRepliesResponse list_auto_replies = 128;
 * @return {?proto.api.ListAutoRepliesResponse}
 */
proto.api.Response.prototype.getListAutoReplies = function() {
  return /** @type{?proto.api.ListAutoRepliesResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.ListAutoRepliesResponse, 128));
};


/**
 * @param {?proto.api.ListAutoRepliesResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setListAutoReplies = function(value) {
  return jspb.Message.setWrapperField(this, 128, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearListAutoReplies = function() {
  return this.setListAutoReplies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasListAutoReplies = function() {
  return jspb.Message.getField(this, 128) != null;
};


/**
 * optional CreateAutoReplyResponse create_auto_reply = 129;
 * @return {?proto.api.CreateAutoReplyResponse}
 */
proto.api.Response.prototype.getCreateAutoReply = function() {
  return /** @type{?proto.api.CreateAutoReplyResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.CreateAutoReplyResponse, 129));
};


/**
 * @param {?proto.api.CreateAutoReplyResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setCreateAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 129, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearCreateAutoReply = function() {
  return this.setCreateAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasCreateAutoReply = function() {
  return jspb.Message.getField(this, 129) != null;
};


/**
 * optional GetAutoReplyResponse get_auto_reply = 130;
 * @return {?proto.api.GetAutoReplyResponse}
 */
proto.api.Response.prototype.getGetAutoReply = function() {
  return /** @type{?proto.api.GetAutoReplyResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetAutoReplyResponse, 130));
};


/**
 * @param {?proto.api.GetAutoReplyResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 130, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetAutoReply = function() {
  return this.setGetAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetAutoReply = function() {
  return jspb.Message.getField(this, 130) != null;
};


/**
 * optional UpdateAutoReplyResponse update_auto_reply = 131;
 * @return {?proto.api.UpdateAutoReplyResponse}
 */
proto.api.Response.prototype.getUpdateAutoReply = function() {
  return /** @type{?proto.api.UpdateAutoReplyResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateAutoReplyResponse, 131));
};


/**
 * @param {?proto.api.UpdateAutoReplyResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdateAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 131, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdateAutoReply = function() {
  return this.setUpdateAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdateAutoReply = function() {
  return jspb.Message.getField(this, 131) != null;
};


/**
 * optional DeleteAutoReplyResponse delete_auto_reply = 132;
 * @return {?proto.api.DeleteAutoReplyResponse}
 */
proto.api.Response.prototype.getDeleteAutoReply = function() {
  return /** @type{?proto.api.DeleteAutoReplyResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.DeleteAutoReplyResponse, 132));
};


/**
 * @param {?proto.api.DeleteAutoReplyResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setDeleteAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 132, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearDeleteAutoReply = function() {
  return this.setDeleteAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasDeleteAutoReply = function() {
  return jspb.Message.getField(this, 132) != null;
};


/**
 * optional CreateAnnouncementResponse create_announcement = 133;
 * @return {?proto.api.CreateAnnouncementResponse}
 */
proto.api.Response.prototype.getCreateAnnouncement = function() {
  return /** @type{?proto.api.CreateAnnouncementResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.CreateAnnouncementResponse, 133));
};


/**
 * @param {?proto.api.CreateAnnouncementResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setCreateAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 133, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearCreateAnnouncement = function() {
  return this.setCreateAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasCreateAnnouncement = function() {
  return jspb.Message.getField(this, 133) != null;
};


/**
 * optional DeleteAnnouncementResponse delete_announcement = 134;
 * @return {?proto.api.DeleteAnnouncementResponse}
 */
proto.api.Response.prototype.getDeleteAnnouncement = function() {
  return /** @type{?proto.api.DeleteAnnouncementResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.DeleteAnnouncementResponse, 134));
};


/**
 * @param {?proto.api.DeleteAnnouncementResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setDeleteAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 134, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearDeleteAnnouncement = function() {
  return this.setDeleteAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasDeleteAnnouncement = function() {
  return jspb.Message.getField(this, 134) != null;
};


/**
 * optional SetReactionResponse set_reaction = 135;
 * @return {?proto.api.SetReactionResponse}
 */
proto.api.Response.prototype.getSetReaction = function() {
  return /** @type{?proto.api.SetReactionResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.SetReactionResponse, 135));
};


/**
 * @param {?proto.api.SetReactionResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setSetReaction = function(value) {
  return jspb.Message.setWrapperField(this, 135, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearSetReaction = function() {
  return this.setSetReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasSetReaction = function() {
  return jspb.Message.getField(this, 135) != null;
};


/**
 * optional ListReactionResponse list_reaction = 136;
 * @return {?proto.api.ListReactionResponse}
 */
proto.api.Response.prototype.getListReaction = function() {
  return /** @type{?proto.api.ListReactionResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.ListReactionResponse, 136));
};


/**
 * @param {?proto.api.ListReactionResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setListReaction = function(value) {
  return jspb.Message.setWrapperField(this, 136, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearListReaction = function() {
  return this.setListReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasListReaction = function() {
  return jspb.Message.getField(this, 136) != null;
};


/**
 * optional GetFiltersResponse get_filters = 137;
 * @return {?proto.api.GetFiltersResponse}
 */
proto.api.Response.prototype.getGetFilters = function() {
  return /** @type{?proto.api.GetFiltersResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetFiltersResponse, 137));
};


/**
 * @param {?proto.api.GetFiltersResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetFilters = function(value) {
  return jspb.Message.setWrapperField(this, 137, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetFilters = function() {
  return this.setGetFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetFilters = function() {
  return jspb.Message.getField(this, 137) != null;
};


/**
 * optional GetMemberResponse get_member = 141;
 * @return {?proto.api.GetMemberResponse}
 */
proto.api.Response.prototype.getGetMember = function() {
  return /** @type{?proto.api.GetMemberResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetMemberResponse, 141));
};


/**
 * @param {?proto.api.GetMemberResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetMember = function(value) {
  return jspb.Message.setWrapperField(this, 141, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetMember = function() {
  return this.setGetMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetMember = function() {
  return jspb.Message.getField(this, 141) != null;
};


/**
 * optional GetMembersResponse get_members = 142;
 * @return {?proto.api.GetMembersResponse}
 */
proto.api.Response.prototype.getGetMembers = function() {
  return /** @type{?proto.api.GetMembersResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.GetMembersResponse, 142));
};


/**
 * @param {?proto.api.GetMembersResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setGetMembers = function(value) {
  return jspb.Message.setWrapperField(this, 142, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearGetMembers = function() {
  return this.setGetMembers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasGetMembers = function() {
  return jspb.Message.getField(this, 142) != null;
};


/**
 * optional SearchMembersResponse search_members = 143;
 * @return {?proto.api.SearchMembersResponse}
 */
proto.api.Response.prototype.getSearchMembers = function() {
  return /** @type{?proto.api.SearchMembersResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.SearchMembersResponse, 143));
};


/**
 * @param {?proto.api.SearchMembersResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setSearchMembers = function(value) {
  return jspb.Message.setWrapperField(this, 143, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearSearchMembers = function() {
  return this.setSearchMembers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasSearchMembers = function() {
  return jspb.Message.getField(this, 143) != null;
};


/**
 * optional SendButtonPostbackResponse send_button_postback = 150;
 * @return {?proto.api.SendButtonPostbackResponse}
 */
proto.api.Response.prototype.getSendButtonPostback = function() {
  return /** @type{?proto.api.SendButtonPostbackResponse} */ (
    jspb.Message.getWrapperField(this, proto.api.SendButtonPostbackResponse, 150));
};


/**
 * @param {?proto.api.SendButtonPostbackResponse|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setSendButtonPostback = function(value) {
  return jspb.Message.setWrapperField(this, 150, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearSendButtonPostback = function() {
  return this.setSendButtonPostback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasSendButtonPostback = function() {
  return jspb.Message.getField(this, 150) != null;
};


/**
 * optional NewMessageEvent new_message = 200;
 * @return {?proto.api.NewMessageEvent}
 */
proto.api.Response.prototype.getNewMessage = function() {
  return /** @type{?proto.api.NewMessageEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.NewMessageEvent, 200));
};


/**
 * @param {?proto.api.NewMessageEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setNewMessage = function(value) {
  return jspb.Message.setWrapperField(this, 200, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearNewMessage = function() {
  return this.setNewMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasNewMessage = function() {
  return jspb.Message.getField(this, 200) != null;
};


/**
 * optional ReadMessageEvent read_message = 201;
 * @return {?proto.api.ReadMessageEvent}
 */
proto.api.Response.prototype.getReadMessage = function() {
  return /** @type{?proto.api.ReadMessageEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.ReadMessageEvent, 201));
};


/**
 * @param {?proto.api.ReadMessageEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setReadMessage = function(value) {
  return jspb.Message.setWrapperField(this, 201, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearReadMessage = function() {
  return this.setReadMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasReadMessage = function() {
  return jspb.Message.getField(this, 201) != null;
};


/**
 * optional JoinMemberEvent join_member = 202;
 * @return {?proto.api.JoinMemberEvent}
 */
proto.api.Response.prototype.getJoinMember = function() {
  return /** @type{?proto.api.JoinMemberEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.JoinMemberEvent, 202));
};


/**
 * @param {?proto.api.JoinMemberEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setJoinMember = function(value) {
  return jspb.Message.setWrapperField(this, 202, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearJoinMember = function() {
  return this.setJoinMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasJoinMember = function() {
  return jspb.Message.getField(this, 202) != null;
};


/**
 * optional LeaveMemberEvent leave_member = 203;
 * @return {?proto.api.LeaveMemberEvent}
 */
proto.api.Response.prototype.getLeaveMember = function() {
  return /** @type{?proto.api.LeaveMemberEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.LeaveMemberEvent, 203));
};


/**
 * @param {?proto.api.LeaveMemberEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setLeaveMember = function(value) {
  return jspb.Message.setWrapperField(this, 203, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearLeaveMember = function() {
  return this.setLeaveMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasLeaveMember = function() {
  return jspb.Message.getField(this, 203) != null;
};


/**
 * optional RenewChannelEvent renew_channel = 204;
 * @return {?proto.api.RenewChannelEvent}
 */
proto.api.Response.prototype.getRenewChannel = function() {
  return /** @type{?proto.api.RenewChannelEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.RenewChannelEvent, 204));
};


/**
 * @param {?proto.api.RenewChannelEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setRenewChannel = function(value) {
  return jspb.Message.setWrapperField(this, 204, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearRenewChannel = function() {
  return this.setRenewChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasRenewChannel = function() {
  return jspb.Message.getField(this, 204) != null;
};


/**
 * optional UpdateBubbleGroupEvent update_bubble_group = 205;
 * @return {?proto.api.UpdateBubbleGroupEvent}
 */
proto.api.Response.prototype.getUpdateBubbleGroup = function() {
  return /** @type{?proto.api.UpdateBubbleGroupEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.UpdateBubbleGroupEvent, 205));
};


/**
 * @param {?proto.api.UpdateBubbleGroupEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdateBubbleGroup = function(value) {
  return jspb.Message.setWrapperField(this, 205, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdateBubbleGroup = function() {
  return this.setUpdateBubbleGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdateBubbleGroup = function() {
  return jspb.Message.getField(this, 205) != null;
};


/**
 * optional UpdateMessageEvent update_message = 206;
 * @return {?proto.api.UpdateMessageEvent}
 */
proto.api.Response.prototype.getUpdateMessage = function() {
  return /** @type{?proto.api.UpdateMessageEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.UpdateMessageEvent, 206));
};


/**
 * @param {?proto.api.UpdateMessageEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setUpdateMessage = function(value) {
  return jspb.Message.setWrapperField(this, 206, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearUpdateMessage = function() {
  return this.setUpdateMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasUpdateMessage = function() {
  return jspb.Message.getField(this, 206) != null;
};


/**
 * optional JoinVoiceChannelEvent join_voice_channel = 211;
 * @return {?proto.api.JoinVoiceChannelEvent}
 */
proto.api.Response.prototype.getJoinVoiceChannel = function() {
  return /** @type{?proto.api.JoinVoiceChannelEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.JoinVoiceChannelEvent, 211));
};


/**
 * @param {?proto.api.JoinVoiceChannelEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setJoinVoiceChannel = function(value) {
  return jspb.Message.setWrapperField(this, 211, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearJoinVoiceChannel = function() {
  return this.setJoinVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasJoinVoiceChannel = function() {
  return jspb.Message.getField(this, 211) != null;
};


/**
 * optional TerminateVoiceChannelEvent terminate_voice_channel = 212;
 * @return {?proto.api.TerminateVoiceChannelEvent}
 */
proto.api.Response.prototype.getTerminateVoiceChannel = function() {
  return /** @type{?proto.api.TerminateVoiceChannelEvent} */ (
    jspb.Message.getWrapperField(this, api_event_pb.TerminateVoiceChannelEvent, 212));
};


/**
 * @param {?proto.api.TerminateVoiceChannelEvent|undefined} value
 * @return {!proto.api.Response} returns this
*/
proto.api.Response.prototype.setTerminateVoiceChannel = function(value) {
  return jspb.Message.setWrapperField(this, 212, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Response} returns this
 */
proto.api.Response.prototype.clearTerminateVoiceChannel = function() {
  return this.setTerminateVoiceChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Response.prototype.hasTerminateVoiceChannel = function() {
  return jspb.Message.getField(this, 212) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    receiverId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    message: (f = msg.getMessage()) && api_model_pb.Message.toObject(includeInstance, f),
    data: jspb.Message.getFieldWithDefault(msg, 6, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sender: (f = msg.getSender()) && api_model_pb.User.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && api_model_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateChannelRequest}
 */
proto.api.CreateChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateChannelRequest;
  return proto.api.CreateChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateChannelRequest}
 */
proto.api.CreateChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    case 5:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 8:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 9:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReceiverId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 sender_id = 1;
 * @return {number}
 */
proto.api.CreateChannelRequest.prototype.getSenderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 receiver_id = 2;
 * @return {number}
 */
proto.api.CreateChannelRequest.prototype.getReceiverId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string resource_type = 3;
 * @return {string}
 */
proto.api.CreateChannelRequest.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 resource_id = 4;
 * @return {number}
 */
proto.api.CreateChannelRequest.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Message message = 5;
 * @return {?proto.api.Message}
 */
proto.api.CreateChannelRequest.prototype.getMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Message, 5));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.CreateChannelRequest} returns this
*/
proto.api.CreateChannelRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChannelRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string data = 6;
 * @return {string}
 */
proto.api.CreateChannelRequest.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool read_only = 7;
 * @return {boolean}
 */
proto.api.CreateChannelRequest.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional User sender = 8;
 * @return {?proto.api.User}
 */
proto.api.CreateChannelRequest.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 8));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.CreateChannelRequest} returns this
*/
proto.api.CreateChannelRequest.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChannelRequest.prototype.hasSender = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional User receiver = 9;
 * @return {?proto.api.User}
 */
proto.api.CreateChannelRequest.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 9));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.CreateChannelRequest} returns this
*/
proto.api.CreateChannelRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChannelRequest} returns this
 */
proto.api.CreateChannelRequest.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChannelRequest.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && api_model_pb.Channel.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && api_model_pb.Message.toObject(includeInstance, f),
    moreMenu: (f = msg.getMoreMenu()) && api_model_pb.MoreMenu.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateChannelResponse}
 */
proto.api.CreateChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateChannelResponse;
  return proto.api.CreateChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateChannelResponse}
 */
proto.api.CreateChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Channel;
      reader.readMessage(value,api_model_pb.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 4:
      var value = new api_model_pb.MoreMenu;
      reader.readMessage(value,api_model_pb.MoreMenu.deserializeBinaryFromReader);
      msg.setMoreMenu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
  f = message.getMoreMenu();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      api_model_pb.MoreMenu.serializeBinaryToWriter
    );
  }
};


/**
 * optional Channel channel = 1;
 * @return {?proto.api.Channel}
 */
proto.api.CreateChannelResponse.prototype.getChannel = function() {
  return /** @type{?proto.api.Channel} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Channel, 1));
};


/**
 * @param {?proto.api.Channel|undefined} value
 * @return {!proto.api.CreateChannelResponse} returns this
*/
proto.api.CreateChannelResponse.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChannelResponse} returns this
 */
proto.api.CreateChannelResponse.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChannelResponse.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Message message = 2;
 * @return {?proto.api.Message}
 */
proto.api.CreateChannelResponse.prototype.getMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Message, 2));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.CreateChannelResponse} returns this
*/
proto.api.CreateChannelResponse.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChannelResponse} returns this
 */
proto.api.CreateChannelResponse.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChannelResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MoreMenu more_menu = 4;
 * @return {?proto.api.MoreMenu}
 */
proto.api.CreateChannelResponse.prototype.getMoreMenu = function() {
  return /** @type{?proto.api.MoreMenu} */ (
    jspb.Message.getWrapperField(this, api_model_pb.MoreMenu, 4));
};


/**
 * @param {?proto.api.MoreMenu|undefined} value
 * @return {!proto.api.CreateChannelResponse} returns this
*/
proto.api.CreateChannelResponse.prototype.setMoreMenu = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChannelResponse} returns this
 */
proto.api.CreateChannelResponse.prototype.clearMoreMenu = function() {
  return this.setMoreMenu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChannelResponse.prototype.hasMoreMenu = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SendMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SendMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SendMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && api_model_pb.Message.toObject(includeInstance, f),
    readOnlyChannel: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SendMessageRequest}
 */
proto.api.SendMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SendMessageRequest;
  return proto.api.SendMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SendMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SendMessageRequest}
 */
proto.api.SendMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnlyChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SendMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SendMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SendMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
  f = message.getReadOnlyChannel();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Message message = 1;
 * @return {?proto.api.Message}
 */
proto.api.SendMessageRequest.prototype.getMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Message, 1));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.SendMessageRequest} returns this
*/
proto.api.SendMessageRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SendMessageRequest} returns this
 */
proto.api.SendMessageRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SendMessageRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool read_only_channel = 2;
 * @return {boolean}
 */
proto.api.SendMessageRequest.prototype.getReadOnlyChannel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SendMessageRequest} returns this
 */
proto.api.SendMessageRequest.prototype.setReadOnlyChannel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SendMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SendMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SendMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && api_model_pb.Message.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SendMessageResponse}
 */
proto.api.SendMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SendMessageResponse;
  return proto.api.SendMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SendMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SendMessageResponse}
 */
proto.api.SendMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SendMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SendMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SendMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional Message message = 1;
 * @return {?proto.api.Message}
 */
proto.api.SendMessageResponse.prototype.getMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Message, 1));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.SendMessageResponse} returns this
*/
proto.api.SendMessageResponse.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SendMessageResponse} returns this
 */
proto.api.SendMessageResponse.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SendMessageResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    withLastMessage: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    baseTime: (f = msg.getBaseTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    range: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pagingKey: (f = msg.getPagingKey()) && proto.api.GetChannelsRequest.PagingKey.toObject(includeInstance, f),
    filterId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelsRequest}
 */
proto.api.GetChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelsRequest;
  return proto.api.GetChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelsRequest}
 */
proto.api.GetChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithLastMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBaseTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {!proto.api.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    case 5:
      var value = new proto.api.GetChannelsRequest.PagingKey;
      reader.readMessage(value,proto.api.GetChannelsRequest.PagingKey.deserializeBinaryFromReader);
      msg.setPagingKey(value);
      break;
    case 6:
      var value = /** @type {!proto.api.FilterID} */ (reader.readEnum());
      msg.setFilterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithLastMessage();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBaseTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPagingKey();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.GetChannelsRequest.PagingKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.api.FilterID} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelsRequest.PagingKey.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelsRequest.PagingKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelsRequest.PagingKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelsRequest.PagingKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelsRequest.PagingKey}
 */
proto.api.GetChannelsRequest.PagingKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelsRequest.PagingKey;
  return proto.api.GetChannelsRequest.PagingKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelsRequest.PagingKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelsRequest.PagingKey}
 */
proto.api.GetChannelsRequest.PagingKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.GetChannelsRequest.PagingKey.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelsRequest.PagingKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelsRequest.PagingKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelsRequest.PagingKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelsRequest.PagingKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.GetChannelsRequest.PagingKey.Kind = {
  KIND_UNSPECIFIED: 0,
  KIND_NORMAL: 1,
  KIND_FAVORITE: 2
};

/**
 * optional Kind kind = 1;
 * @return {!proto.api.GetChannelsRequest.PagingKey.Kind}
 */
proto.api.GetChannelsRequest.PagingKey.prototype.getKind = function() {
  return /** @type {!proto.api.GetChannelsRequest.PagingKey.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.GetChannelsRequest.PagingKey.Kind} value
 * @return {!proto.api.GetChannelsRequest.PagingKey} returns this
 */
proto.api.GetChannelsRequest.PagingKey.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.GetChannelsRequest.PagingKey.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.GetChannelsRequest.PagingKey} returns this
*/
proto.api.GetChannelsRequest.PagingKey.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelsRequest.PagingKey} returns this
 */
proto.api.GetChannelsRequest.PagingKey.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsRequest.PagingKey.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool with_last_message = 1;
 * @return {boolean}
 */
proto.api.GetChannelsRequest.prototype.getWithLastMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.setWithLastMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp base_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.GetChannelsRequest.prototype.getBaseTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.GetChannelsRequest} returns this
*/
proto.api.GetChannelsRequest.prototype.setBaseTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.clearBaseTime = function() {
  return this.setBaseTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsRequest.prototype.hasBaseTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.api.GetChannelsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Range range = 4;
 * @return {!proto.api.Range}
 */
proto.api.GetChannelsRequest.prototype.getRange = function() {
  return /** @type {!proto.api.Range} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.api.Range} value
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PagingKey paging_key = 5;
 * @return {?proto.api.GetChannelsRequest.PagingKey}
 */
proto.api.GetChannelsRequest.prototype.getPagingKey = function() {
  return /** @type{?proto.api.GetChannelsRequest.PagingKey} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelsRequest.PagingKey, 5));
};


/**
 * @param {?proto.api.GetChannelsRequest.PagingKey|undefined} value
 * @return {!proto.api.GetChannelsRequest} returns this
*/
proto.api.GetChannelsRequest.prototype.setPagingKey = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.clearPagingKey = function() {
  return this.setPagingKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsRequest.prototype.hasPagingKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FilterID filter_id = 6;
 * @return {!proto.api.FilterID}
 */
proto.api.GetChannelsRequest.prototype.getFilterId = function() {
  return /** @type {!proto.api.FilterID} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.api.FilterID} value
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.setFilterId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.GetChannelsRequest} returns this
 */
proto.api.GetChannelsRequest.prototype.clearFilterId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsRequest.prototype.hasFilterId = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetChannelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    api_model_pb.Channel.toObject, includeInstance),
    unreadCount: (f = msg.getUnreadCount()) && api_model_pb.UnreadCount.toObject(includeInstance, f),
    nextBaseTime: (f = msg.getNextBaseTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    nextPagingKey: (f = msg.getNextPagingKey()) && proto.api.GetChannelsRequest.PagingKey.toObject(includeInstance, f),
    filterId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelsResponse}
 */
proto.api.GetChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelsResponse;
  return proto.api.GetChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelsResponse}
 */
proto.api.GetChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Channel;
      reader.readMessage(value,api_model_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 2:
      var value = new api_model_pb.UnreadCount;
      reader.readMessage(value,api_model_pb.UnreadCount.deserializeBinaryFromReader);
      msg.setUnreadCount(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextBaseTime(value);
      break;
    case 4:
      var value = new proto.api.GetChannelsRequest.PagingKey;
      reader.readMessage(value,proto.api.GetChannelsRequest.PagingKey.deserializeBinaryFromReader);
      msg.setNextPagingKey(value);
      break;
    case 5:
      var value = /** @type {!proto.api.FilterID} */ (reader.readEnum());
      msg.setFilterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getUnreadCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.UnreadCount.serializeBinaryToWriter
    );
  }
  f = message.getNextBaseTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNextPagingKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.GetChannelsRequest.PagingKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.api.FilterID} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * repeated Channel channels = 1;
 * @return {!Array<!proto.api.Channel>}
 */
proto.api.GetChannelsResponse.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.api.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.Channel, 1));
};


/**
 * @param {!Array<!proto.api.Channel>} value
 * @return {!proto.api.GetChannelsResponse} returns this
*/
proto.api.GetChannelsResponse.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Channel}
 */
proto.api.GetChannelsResponse.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetChannelsResponse} returns this
 */
proto.api.GetChannelsResponse.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional UnreadCount unread_count = 2;
 * @return {?proto.api.UnreadCount}
 */
proto.api.GetChannelsResponse.prototype.getUnreadCount = function() {
  return /** @type{?proto.api.UnreadCount} */ (
    jspb.Message.getWrapperField(this, api_model_pb.UnreadCount, 2));
};


/**
 * @param {?proto.api.UnreadCount|undefined} value
 * @return {!proto.api.GetChannelsResponse} returns this
*/
proto.api.GetChannelsResponse.prototype.setUnreadCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelsResponse} returns this
 */
proto.api.GetChannelsResponse.prototype.clearUnreadCount = function() {
  return this.setUnreadCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsResponse.prototype.hasUnreadCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp next_base_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.GetChannelsResponse.prototype.getNextBaseTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.GetChannelsResponse} returns this
*/
proto.api.GetChannelsResponse.prototype.setNextBaseTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelsResponse} returns this
 */
proto.api.GetChannelsResponse.prototype.clearNextBaseTime = function() {
  return this.setNextBaseTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsResponse.prototype.hasNextBaseTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GetChannelsRequest.PagingKey next_paging_key = 4;
 * @return {?proto.api.GetChannelsRequest.PagingKey}
 */
proto.api.GetChannelsResponse.prototype.getNextPagingKey = function() {
  return /** @type{?proto.api.GetChannelsRequest.PagingKey} */ (
    jspb.Message.getWrapperField(this, proto.api.GetChannelsRequest.PagingKey, 4));
};


/**
 * @param {?proto.api.GetChannelsRequest.PagingKey|undefined} value
 * @return {!proto.api.GetChannelsResponse} returns this
*/
proto.api.GetChannelsResponse.prototype.setNextPagingKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelsResponse} returns this
 */
proto.api.GetChannelsResponse.prototype.clearNextPagingKey = function() {
  return this.setNextPagingKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsResponse.prototype.hasNextPagingKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FilterID filter_id = 5;
 * @return {!proto.api.FilterID}
 */
proto.api.GetChannelsResponse.prototype.getFilterId = function() {
  return /** @type {!proto.api.FilterID} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.api.FilterID} value
 * @return {!proto.api.GetChannelsResponse} returns this
 */
proto.api.GetChannelsResponse.prototype.setFilterId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.GetChannelsResponse} returns this
 */
proto.api.GetChannelsResponse.prototype.clearFilterId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelsResponse.prototype.hasFilterId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSellerChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSellerChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSellerChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSellerChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    withLastMessage: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    resourceType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    smbId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    smbArticleId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSellerChannelsRequest}
 */
proto.api.GetSellerChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSellerChannelsRequest;
  return proto.api.GetSellerChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSellerChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSellerChannelsRequest}
 */
proto.api.GetSellerChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithLastMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmbId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmbArticleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSellerChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSellerChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSellerChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSellerChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithLastMessage();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSmbId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSmbArticleId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool with_last_message = 1;
 * @return {boolean}
 */
proto.api.GetSellerChannelsRequest.prototype.getWithLastMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetSellerChannelsRequest} returns this
 */
proto.api.GetSellerChannelsRequest.prototype.setWithLastMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string resource_type = 2;
 * @return {string}
 */
proto.api.GetSellerChannelsRequest.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetSellerChannelsRequest} returns this
 */
proto.api.GetSellerChannelsRequest.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 resource_id = 3;
 * @return {number}
 */
proto.api.GetSellerChannelsRequest.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetSellerChannelsRequest} returns this
 */
proto.api.GetSellerChannelsRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 smb_id = 4;
 * @return {number}
 */
proto.api.GetSellerChannelsRequest.prototype.getSmbId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetSellerChannelsRequest} returns this
 */
proto.api.GetSellerChannelsRequest.prototype.setSmbId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 smb_article_id = 5;
 * @return {number}
 */
proto.api.GetSellerChannelsRequest.prototype.getSmbArticleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetSellerChannelsRequest} returns this
 */
proto.api.GetSellerChannelsRequest.prototype.setSmbArticleId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetSellerChannelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSellerChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSellerChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSellerChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSellerChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    api_model_pb.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSellerChannelsResponse}
 */
proto.api.GetSellerChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSellerChannelsResponse;
  return proto.api.GetSellerChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSellerChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSellerChannelsResponse}
 */
proto.api.GetSellerChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Channel;
      reader.readMessage(value,api_model_pb.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSellerChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSellerChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSellerChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSellerChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Channel channels = 1;
 * @return {!Array<!proto.api.Channel>}
 */
proto.api.GetSellerChannelsResponse.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.api.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.Channel, 1));
};


/**
 * @param {!Array<!proto.api.Channel>} value
 * @return {!proto.api.GetSellerChannelsResponse} returns this
*/
proto.api.GetSellerChannelsResponse.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Channel}
 */
proto.api.GetSellerChannelsResponse.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetSellerChannelsResponse} returns this
 */
proto.api.GetSellerChannelsResponse.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    range: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMessagesRequest}
 */
proto.api.GetMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMessagesRequest;
  return proto.api.GetMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMessagesRequest}
 */
proto.api.GetMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {!proto.api.Range} */ (reader.readEnum());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.GetMessagesRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetMessagesRequest} returns this
 */
proto.api.GetMessagesRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.GetMessagesRequest.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetMessagesRequest} returns this
 */
proto.api.GetMessagesRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.api.GetMessagesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetMessagesRequest} returns this
 */
proto.api.GetMessagesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Range range = 4;
 * @return {!proto.api.Range}
 */
proto.api.GetMessagesRequest.prototype.getRange = function() {
  return /** @type {!proto.api.Range} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.api.Range} value
 * @return {!proto.api.GetMessagesRequest} returns this
 */
proto.api.GetMessagesRequest.prototype.setRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetMessagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMessagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMessagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMessagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMessagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    api_model_pb.Message.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMessagesResponse}
 */
proto.api.GetMessagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMessagesResponse;
  return proto.api.GetMessagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMessagesResponse}
 */
proto.api.GetMessagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMessagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMessagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMessagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Message messages = 1;
 * @return {!Array<!proto.api.Message>}
 */
proto.api.GetMessagesResponse.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.api.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.Message, 1));
};


/**
 * @param {!Array<!proto.api.Message>} value
 * @return {!proto.api.GetMessagesResponse} returns this
*/
proto.api.GetMessagesResponse.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Message}
 */
proto.api.GetMessagesResponse.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetMessagesResponse} returns this
 */
proto.api.GetMessagesResponse.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MarkAsReadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MarkAsReadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MarkAsReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MarkAsReadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MarkAsReadRequest}
 */
proto.api.MarkAsReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MarkAsReadRequest;
  return proto.api.MarkAsReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MarkAsReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MarkAsReadRequest}
 */
proto.api.MarkAsReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MarkAsReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MarkAsReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MarkAsReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MarkAsReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.MarkAsReadRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MarkAsReadRequest} returns this
 */
proto.api.MarkAsReadRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.MarkAsReadRequest.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.MarkAsReadRequest} returns this
 */
proto.api.MarkAsReadRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MarkAsReadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MarkAsReadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MarkAsReadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MarkAsReadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unreadCount: (f = msg.getUnreadCount()) && api_model_pb.UnreadCount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MarkAsReadResponse}
 */
proto.api.MarkAsReadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MarkAsReadResponse;
  return proto.api.MarkAsReadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MarkAsReadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MarkAsReadResponse}
 */
proto.api.MarkAsReadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.UnreadCount;
      reader.readMessage(value,api_model_pb.UnreadCount.deserializeBinaryFromReader);
      msg.setUnreadCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MarkAsReadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MarkAsReadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MarkAsReadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MarkAsReadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnreadCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.UnreadCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.MarkAsReadResponse.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MarkAsReadResponse} returns this
 */
proto.api.MarkAsReadResponse.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UnreadCount unread_count = 2;
 * @return {?proto.api.UnreadCount}
 */
proto.api.MarkAsReadResponse.prototype.getUnreadCount = function() {
  return /** @type{?proto.api.UnreadCount} */ (
    jspb.Message.getWrapperField(this, api_model_pb.UnreadCount, 2));
};


/**
 * @param {?proto.api.UnreadCount|undefined} value
 * @return {!proto.api.MarkAsReadResponse} returns this
*/
proto.api.MarkAsReadResponse.prototype.setUnreadCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.MarkAsReadResponse} returns this
 */
proto.api.MarkAsReadResponse.prototype.clearUnreadCount = function() {
  return this.setUnreadCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.MarkAsReadResponse.prototype.hasUnreadCount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LeaveChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LeaveChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LeaveChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LeaveChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LeaveChannelRequest}
 */
proto.api.LeaveChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LeaveChannelRequest;
  return proto.api.LeaveChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LeaveChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LeaveChannelRequest}
 */
proto.api.LeaveChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LeaveChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LeaveChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LeaveChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LeaveChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.LeaveChannelRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.LeaveChannelRequest} returns this
 */
proto.api.LeaveChannelRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.LeaveChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.LeaveChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.LeaveChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LeaveChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.LeaveChannelResponse}
 */
proto.api.LeaveChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.LeaveChannelResponse;
  return proto.api.LeaveChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.LeaveChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.LeaveChannelResponse}
 */
proto.api.LeaveChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.LeaveChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.LeaveChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.LeaveChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.LeaveChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.UpdateUserChannelRequest.oneofGroups_ = [[11,12,13]];

/**
 * @enum {number}
 */
proto.api.UpdateUserChannelRequest.OptionCase = {
  OPTION_NOT_SET: 0,
  FAVORITE: 11,
  DISABLED: 12,
  MUTE: 13
};

/**
 * @return {proto.api.UpdateUserChannelRequest.OptionCase}
 */
proto.api.UpdateUserChannelRequest.prototype.getOptionCase = function() {
  return /** @type {proto.api.UpdateUserChannelRequest.OptionCase} */(jspb.Message.computeOneofCase(this, proto.api.UpdateUserChannelRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateUserChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateUserChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateUserChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isMute: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    withLastMessage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    mute: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateUserChannelRequest}
 */
proto.api.UpdateUserChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateUserChannelRequest;
  return proto.api.UpdateUserChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateUserChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateUserChannelRequest}
 */
proto.api.UpdateUserChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMute(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithLastMessage(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateUserChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateUserChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateUserChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsMute();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getWithLastMessage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.UpdateUserChannelRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_favorite = 2;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_disabled = 3;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_mute = 4;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getIsMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setIsMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool with_last_message = 5;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getWithLastMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setWithLastMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool favorite = 11;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setFavorite = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.api.UpdateUserChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.clearFavorite = function() {
  return jspb.Message.setOneofField(this, 11, proto.api.UpdateUserChannelRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.hasFavorite = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool disabled = 12;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setDisabled = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.api.UpdateUserChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.clearDisabled = function() {
  return jspb.Message.setOneofField(this, 12, proto.api.UpdateUserChannelRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.hasDisabled = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool mute = 13;
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.setMute = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.api.UpdateUserChannelRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.UpdateUserChannelRequest} returns this
 */
proto.api.UpdateUserChannelRequest.prototype.clearMute = function() {
  return jspb.Message.setOneofField(this, 13, proto.api.UpdateUserChannelRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserChannelRequest.prototype.hasMute = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateUserChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateUserChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateUserChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && api_model_pb.Channel.toObject(includeInstance, f),
    favoriteTime: (f = msg.getFavoriteTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateUserChannelResponse}
 */
proto.api.UpdateUserChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateUserChannelResponse;
  return proto.api.UpdateUserChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateUserChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateUserChannelResponse}
 */
proto.api.UpdateUserChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Channel;
      reader.readMessage(value,api_model_pb.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFavoriteTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateUserChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateUserChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateUserChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getFavoriteTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Channel channel = 1;
 * @return {?proto.api.Channel}
 */
proto.api.UpdateUserChannelResponse.prototype.getChannel = function() {
  return /** @type{?proto.api.Channel} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Channel, 1));
};


/**
 * @param {?proto.api.Channel|undefined} value
 * @return {!proto.api.UpdateUserChannelResponse} returns this
*/
proto.api.UpdateUserChannelResponse.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateUserChannelResponse} returns this
 */
proto.api.UpdateUserChannelResponse.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserChannelResponse.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp favorite_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.UpdateUserChannelResponse.prototype.getFavoriteTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.UpdateUserChannelResponse} returns this
*/
proto.api.UpdateUserChannelResponse.prototype.setFavoriteTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateUserChannelResponse} returns this
 */
proto.api.UpdateUserChannelResponse.prototype.clearFavoriteTime = function() {
  return this.setFavoriteTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserChannelResponse.prototype.hasFavoriteTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    receiverId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    withLastMessage: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    force: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sender: (f = msg.getSender()) && api_model_pb.User.toObject(includeInstance, f),
    receiver: (f = msg.getReceiver()) && api_model_pb.User.toObject(includeInstance, f),
    withAnnouncement: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    chatRoomKey: jspb.Message.getFieldWithDefault(msg, 51, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelRequest}
 */
proto.api.GetChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelRequest;
  return proto.api.GetChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelRequest}
 */
proto.api.GetChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithLastMessage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    case 8:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 9:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithAnnouncement(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatRoomKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReceiverId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWithLastMessage();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getForce();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getWithAnnouncement();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getChatRoomKey();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
};


/**
 * optional int64 sender_id = 1;
 * @return {number}
 */
proto.api.GetChannelRequest.prototype.getSenderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 receiver_id = 2;
 * @return {number}
 */
proto.api.GetChannelRequest.prototype.getReceiverId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string resource_type = 3;
 * @return {string}
 */
proto.api.GetChannelRequest.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 resource_id = 4;
 * @return {number}
 */
proto.api.GetChannelRequest.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string channel_id = 5;
 * @return {string}
 */
proto.api.GetChannelRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool with_last_message = 6;
 * @return {boolean}
 */
proto.api.GetChannelRequest.prototype.getWithLastMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setWithLastMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool force = 7;
 * @return {boolean}
 */
proto.api.GetChannelRequest.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional User sender = 8;
 * @return {?proto.api.User}
 */
proto.api.GetChannelRequest.prototype.getSender = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 8));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.GetChannelRequest} returns this
*/
proto.api.GetChannelRequest.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelRequest.prototype.hasSender = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional User receiver = 9;
 * @return {?proto.api.User}
 */
proto.api.GetChannelRequest.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 9));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.GetChannelRequest} returns this
*/
proto.api.GetChannelRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelRequest.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool with_announcement = 10;
 * @return {boolean}
 */
proto.api.GetChannelRequest.prototype.getWithAnnouncement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setWithAnnouncement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string chat_room_key = 51;
 * @return {string}
 */
proto.api.GetChannelRequest.prototype.getChatRoomKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetChannelRequest} returns this
 */
proto.api.GetChannelRequest.prototype.setChatRoomKey = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && api_model_pb.Channel.toObject(includeInstance, f),
    persistentMenu: (f = msg.getPersistentMenu()) && api_model_pb.PersistentMenu.toObject(includeInstance, f),
    announcement: (f = msg.getAnnouncement()) && api_model_pb.Announcement.toObject(includeInstance, f),
    moreMenu: (f = msg.getMoreMenu()) && api_model_pb.MoreMenu.toObject(includeInstance, f),
    topBar: (f = msg.getTopBar()) && api_model_pb.TopBar.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelResponse}
 */
proto.api.GetChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelResponse;
  return proto.api.GetChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelResponse}
 */
proto.api.GetChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Channel;
      reader.readMessage(value,api_model_pb.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = new api_model_pb.PersistentMenu;
      reader.readMessage(value,api_model_pb.PersistentMenu.deserializeBinaryFromReader);
      msg.setPersistentMenu(value);
      break;
    case 3:
      var value = new api_model_pb.Announcement;
      reader.readMessage(value,api_model_pb.Announcement.deserializeBinaryFromReader);
      msg.setAnnouncement(value);
      break;
    case 4:
      var value = new api_model_pb.MoreMenu;
      reader.readMessage(value,api_model_pb.MoreMenu.deserializeBinaryFromReader);
      msg.setMoreMenu(value);
      break;
    case 5:
      var value = new api_model_pb.TopBar;
      reader.readMessage(value,api_model_pb.TopBar.deserializeBinaryFromReader);
      msg.setTopBar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPersistentMenu();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.PersistentMenu.serializeBinaryToWriter
    );
  }
  f = message.getAnnouncement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      api_model_pb.Announcement.serializeBinaryToWriter
    );
  }
  f = message.getMoreMenu();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      api_model_pb.MoreMenu.serializeBinaryToWriter
    );
  }
  f = message.getTopBar();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      api_model_pb.TopBar.serializeBinaryToWriter
    );
  }
};


/**
 * optional Channel channel = 1;
 * @return {?proto.api.Channel}
 */
proto.api.GetChannelResponse.prototype.getChannel = function() {
  return /** @type{?proto.api.Channel} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Channel, 1));
};


/**
 * @param {?proto.api.Channel|undefined} value
 * @return {!proto.api.GetChannelResponse} returns this
*/
proto.api.GetChannelResponse.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelResponse} returns this
 */
proto.api.GetChannelResponse.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelResponse.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PersistentMenu persistent_menu = 2;
 * @return {?proto.api.PersistentMenu}
 */
proto.api.GetChannelResponse.prototype.getPersistentMenu = function() {
  return /** @type{?proto.api.PersistentMenu} */ (
    jspb.Message.getWrapperField(this, api_model_pb.PersistentMenu, 2));
};


/**
 * @param {?proto.api.PersistentMenu|undefined} value
 * @return {!proto.api.GetChannelResponse} returns this
*/
proto.api.GetChannelResponse.prototype.setPersistentMenu = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelResponse} returns this
 */
proto.api.GetChannelResponse.prototype.clearPersistentMenu = function() {
  return this.setPersistentMenu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelResponse.prototype.hasPersistentMenu = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Announcement announcement = 3;
 * @return {?proto.api.Announcement}
 */
proto.api.GetChannelResponse.prototype.getAnnouncement = function() {
  return /** @type{?proto.api.Announcement} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Announcement, 3));
};


/**
 * @param {?proto.api.Announcement|undefined} value
 * @return {!proto.api.GetChannelResponse} returns this
*/
proto.api.GetChannelResponse.prototype.setAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelResponse} returns this
 */
proto.api.GetChannelResponse.prototype.clearAnnouncement = function() {
  return this.setAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelResponse.prototype.hasAnnouncement = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MoreMenu more_menu = 4;
 * @return {?proto.api.MoreMenu}
 */
proto.api.GetChannelResponse.prototype.getMoreMenu = function() {
  return /** @type{?proto.api.MoreMenu} */ (
    jspb.Message.getWrapperField(this, api_model_pb.MoreMenu, 4));
};


/**
 * @param {?proto.api.MoreMenu|undefined} value
 * @return {!proto.api.GetChannelResponse} returns this
*/
proto.api.GetChannelResponse.prototype.setMoreMenu = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelResponse} returns this
 */
proto.api.GetChannelResponse.prototype.clearMoreMenu = function() {
  return this.setMoreMenu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelResponse.prototype.hasMoreMenu = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TopBar top_bar = 5;
 * @return {?proto.api.TopBar}
 */
proto.api.GetChannelResponse.prototype.getTopBar = function() {
  return /** @type{?proto.api.TopBar} */ (
    jspb.Message.getWrapperField(this, api_model_pb.TopBar, 5));
};


/**
 * @param {?proto.api.TopBar|undefined} value
 * @return {!proto.api.GetChannelResponse} returns this
*/
proto.api.GetChannelResponse.prototype.setTopBar = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelResponse} returns this
 */
proto.api.GetChannelResponse.prototype.clearTopBar = function() {
  return this.setTopBar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelResponse.prototype.hasTopBar = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetUnreadCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetUnreadCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetUnreadCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUnreadCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetUnreadCountRequest}
 */
proto.api.GetUnreadCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetUnreadCountRequest;
  return proto.api.GetUnreadCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetUnreadCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetUnreadCountRequest}
 */
proto.api.GetUnreadCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetUnreadCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetUnreadCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetUnreadCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUnreadCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetUnreadCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetUnreadCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetUnreadCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUnreadCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unreadCount: (f = msg.getUnreadCount()) && api_model_pb.UnreadCount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetUnreadCountResponse}
 */
proto.api.GetUnreadCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetUnreadCountResponse;
  return proto.api.GetUnreadCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetUnreadCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetUnreadCountResponse}
 */
proto.api.GetUnreadCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.UnreadCount;
      reader.readMessage(value,api_model_pb.UnreadCount.deserializeBinaryFromReader);
      msg.setUnreadCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetUnreadCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetUnreadCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetUnreadCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUnreadCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnreadCount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.UnreadCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnreadCount unread_count = 1;
 * @return {?proto.api.UnreadCount}
 */
proto.api.GetUnreadCountResponse.prototype.getUnreadCount = function() {
  return /** @type{?proto.api.UnreadCount} */ (
    jspb.Message.getWrapperField(this, api_model_pb.UnreadCount, 1));
};


/**
 * @param {?proto.api.UnreadCount|undefined} value
 * @return {!proto.api.GetUnreadCountResponse} returns this
*/
proto.api.GetUnreadCountResponse.prototype.setUnreadCount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetUnreadCountResponse} returns this
 */
proto.api.GetUnreadCountResponse.prototype.clearUnreadCount = function() {
  return this.setUnreadCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetUnreadCountResponse.prototype.hasUnreadCount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreatePromiseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreatePromiseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreatePromiseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePromiseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    notifyBeforeMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreatePromiseRequest}
 */
proto.api.CreatePromiseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreatePromiseRequest;
  return proto.api.CreatePromiseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreatePromiseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreatePromiseRequest}
 */
proto.api.CreatePromiseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotifyBeforeMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreatePromiseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreatePromiseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreatePromiseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePromiseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotifyBeforeMinutes();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.CreatePromiseRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreatePromiseRequest} returns this
 */
proto.api.CreatePromiseRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.CreatePromiseRequest.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.CreatePromiseRequest} returns this
*/
proto.api.CreatePromiseRequest.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreatePromiseRequest} returns this
 */
proto.api.CreatePromiseRequest.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreatePromiseRequest.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 notify_before_minutes = 3;
 * @return {number}
 */
proto.api.CreatePromiseRequest.prototype.getNotifyBeforeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreatePromiseRequest} returns this
 */
proto.api.CreatePromiseRequest.prototype.setNotifyBeforeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreatePromiseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreatePromiseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreatePromiseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePromiseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promise: (f = msg.getPromise()) && api_model_pb.Promise.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreatePromiseResponse}
 */
proto.api.CreatePromiseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreatePromiseResponse;
  return proto.api.CreatePromiseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreatePromiseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreatePromiseResponse}
 */
proto.api.CreatePromiseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Promise;
      reader.readMessage(value,api_model_pb.Promise.deserializeBinaryFromReader);
      msg.setPromise(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreatePromiseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreatePromiseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreatePromiseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreatePromiseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromise();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Promise.serializeBinaryToWriter
    );
  }
};


/**
 * optional Promise promise = 1;
 * @return {?proto.api.Promise}
 */
proto.api.CreatePromiseResponse.prototype.getPromise = function() {
  return /** @type{?proto.api.Promise} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Promise, 1));
};


/**
 * @param {?proto.api.Promise|undefined} value
 * @return {!proto.api.CreatePromiseResponse} returns this
*/
proto.api.CreatePromiseResponse.prototype.setPromise = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreatePromiseResponse} returns this
 */
proto.api.CreatePromiseResponse.prototype.clearPromise = function() {
  return this.setPromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreatePromiseResponse.prototype.hasPromise = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdatePromiseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdatePromiseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdatePromiseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdatePromiseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    notifyBeforeMinutes: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdatePromiseRequest}
 */
proto.api.UpdatePromiseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdatePromiseRequest;
  return proto.api.UpdatePromiseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdatePromiseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdatePromiseRequest}
 */
proto.api.UpdatePromiseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotifyBeforeMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdatePromiseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdatePromiseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdatePromiseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdatePromiseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotifyBeforeMinutes();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.UpdatePromiseRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdatePromiseRequest} returns this
 */
proto.api.UpdatePromiseRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.api.UpdatePromiseRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdatePromiseRequest} returns this
 */
proto.api.UpdatePromiseRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.UpdatePromiseRequest.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.UpdatePromiseRequest} returns this
*/
proto.api.UpdatePromiseRequest.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdatePromiseRequest} returns this
 */
proto.api.UpdatePromiseRequest.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdatePromiseRequest.prototype.hasTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 notify_before_minutes = 4;
 * @return {number}
 */
proto.api.UpdatePromiseRequest.prototype.getNotifyBeforeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.UpdatePromiseRequest} returns this
 */
proto.api.UpdatePromiseRequest.prototype.setNotifyBeforeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdatePromiseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdatePromiseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdatePromiseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdatePromiseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promise: (f = msg.getPromise()) && api_model_pb.Promise.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdatePromiseResponse}
 */
proto.api.UpdatePromiseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdatePromiseResponse;
  return proto.api.UpdatePromiseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdatePromiseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdatePromiseResponse}
 */
proto.api.UpdatePromiseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Promise;
      reader.readMessage(value,api_model_pb.Promise.deserializeBinaryFromReader);
      msg.setPromise(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdatePromiseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdatePromiseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdatePromiseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdatePromiseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromise();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Promise.serializeBinaryToWriter
    );
  }
};


/**
 * optional Promise promise = 1;
 * @return {?proto.api.Promise}
 */
proto.api.UpdatePromiseResponse.prototype.getPromise = function() {
  return /** @type{?proto.api.Promise} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Promise, 1));
};


/**
 * @param {?proto.api.Promise|undefined} value
 * @return {!proto.api.UpdatePromiseResponse} returns this
*/
proto.api.UpdatePromiseResponse.prototype.setPromise = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdatePromiseResponse} returns this
 */
proto.api.UpdatePromiseResponse.prototype.clearPromise = function() {
  return this.setPromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdatePromiseResponse.prototype.hasPromise = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeletePromiseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeletePromiseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeletePromiseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeletePromiseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeletePromiseRequest}
 */
proto.api.DeletePromiseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeletePromiseRequest;
  return proto.api.DeletePromiseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeletePromiseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeletePromiseRequest}
 */
proto.api.DeletePromiseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeletePromiseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeletePromiseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeletePromiseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeletePromiseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.api.DeletePromiseRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DeletePromiseRequest} returns this
 */
proto.api.DeletePromiseRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.api.DeletePromiseRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeletePromiseRequest} returns this
 */
proto.api.DeletePromiseRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeletePromiseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeletePromiseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeletePromiseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeletePromiseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeletePromiseResponse}
 */
proto.api.DeletePromiseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeletePromiseResponse;
  return proto.api.DeletePromiseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeletePromiseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeletePromiseResponse}
 */
proto.api.DeletePromiseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeletePromiseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeletePromiseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeletePromiseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeletePromiseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetBizAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetBizAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetBizAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetBizAccountsRequest}
 */
proto.api.GetBizAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetBizAccountsRequest;
  return proto.api.GetBizAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetBizAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetBizAccountsRequest}
 */
proto.api.GetBizAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetBizAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetBizAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetBizAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetBizAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetBizAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetBizAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetBizAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bizAccountsList: jspb.Message.toObjectList(msg.getBizAccountsList(),
    api_model_pb.BizAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetBizAccountsResponse}
 */
proto.api.GetBizAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetBizAccountsResponse;
  return proto.api.GetBizAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetBizAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetBizAccountsResponse}
 */
proto.api.GetBizAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.BizAccount;
      reader.readMessage(value,api_model_pb.BizAccount.deserializeBinaryFromReader);
      msg.addBizAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetBizAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetBizAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetBizAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBizAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.BizAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BizAccount biz_accounts = 1;
 * @return {!Array<!proto.api.BizAccount>}
 */
proto.api.GetBizAccountsResponse.prototype.getBizAccountsList = function() {
  return /** @type{!Array<!proto.api.BizAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.BizAccount, 1));
};


/**
 * @param {!Array<!proto.api.BizAccount>} value
 * @return {!proto.api.GetBizAccountsResponse} returns this
*/
proto.api.GetBizAccountsResponse.prototype.setBizAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.BizAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.BizAccount}
 */
proto.api.GetBizAccountsResponse.prototype.addBizAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.BizAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetBizAccountsResponse} returns this
 */
proto.api.GetBizAccountsResponse.prototype.clearBizAccountsList = function() {
  return this.setBizAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetBizAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetBizAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetBizAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bizAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetBizAccountRequest}
 */
proto.api.GetBizAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetBizAccountRequest;
  return proto.api.GetBizAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetBizAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetBizAccountRequest}
 */
proto.api.GetBizAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBizAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetBizAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetBizAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetBizAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBizAccountId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 biz_account_id = 1;
 * @return {number}
 */
proto.api.GetBizAccountRequest.prototype.getBizAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetBizAccountRequest} returns this
 */
proto.api.GetBizAccountRequest.prototype.setBizAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetBizAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetBizAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetBizAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bizAccount: (f = msg.getBizAccount()) && api_model_pb.BizAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetBizAccountResponse}
 */
proto.api.GetBizAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetBizAccountResponse;
  return proto.api.GetBizAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetBizAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetBizAccountResponse}
 */
proto.api.GetBizAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.BizAccount;
      reader.readMessage(value,api_model_pb.BizAccount.deserializeBinaryFromReader);
      msg.setBizAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetBizAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetBizAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetBizAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetBizAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBizAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.BizAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BizAccount biz_account = 1;
 * @return {?proto.api.BizAccount}
 */
proto.api.GetBizAccountResponse.prototype.getBizAccount = function() {
  return /** @type{?proto.api.BizAccount} */ (
    jspb.Message.getWrapperField(this, api_model_pb.BizAccount, 1));
};


/**
 * @param {?proto.api.BizAccount|undefined} value
 * @return {!proto.api.GetBizAccountResponse} returns this
*/
proto.api.GetBizAccountResponse.prototype.setBizAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetBizAccountResponse} returns this
 */
proto.api.GetBizAccountResponse.prototype.clearBizAccount = function() {
  return this.setBizAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetBizAccountResponse.prototype.hasBizAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetStickersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetStickersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetStickersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetStickersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetStickersRequest}
 */
proto.api.GetStickersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetStickersRequest;
  return proto.api.GetStickersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetStickersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetStickersRequest}
 */
proto.api.GetStickersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetStickersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetStickersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetStickersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetStickersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetStickersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetStickersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetStickersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetStickersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetStickersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stickersList: jspb.Message.toObjectList(msg.getStickersList(),
    proto.api.GetStickersResponse.Sticker.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetStickersResponse}
 */
proto.api.GetStickersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetStickersResponse;
  return proto.api.GetStickersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetStickersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetStickersResponse}
 */
proto.api.GetStickersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.GetStickersResponse.Sticker;
      reader.readMessage(value,proto.api.GetStickersResponse.Sticker.deserializeBinaryFromReader);
      msg.addStickers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetStickersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetStickersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetStickersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetStickersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStickersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.GetStickersResponse.Sticker.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetStickersResponse.Sticker.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetStickersResponse.Sticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetStickersResponse.Sticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetStickersResponse.Sticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pictureId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    file: jspb.Message.getFieldWithDefault(msg, 5, ""),
    width: jspb.Message.getFieldWithDefault(msg, 6, 0),
    height: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetStickersResponse.Sticker}
 */
proto.api.GetStickersResponse.Sticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetStickersResponse.Sticker;
  return proto.api.GetStickersResponse.Sticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetStickersResponse.Sticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetStickersResponse.Sticker}
 */
proto.api.GetStickersResponse.Sticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFile(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetStickersResponse.Sticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetStickersResponse.Sticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetStickersResponse.Sticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetStickersResponse.Sticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPictureId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFile();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.api.GetStickersResponse.Sticker.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.GetStickersResponse.Sticker.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 image_id = 3;
 * @return {number}
 */
proto.api.GetStickersResponse.Sticker.prototype.getImageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setImageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string picture_id = 4;
 * @return {string}
 */
proto.api.GetStickersResponse.Sticker.prototype.getPictureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setPictureId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string file = 5;
 * @return {string}
 */
proto.api.GetStickersResponse.Sticker.prototype.getFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setFile = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 width = 6;
 * @return {number}
 */
proto.api.GetStickersResponse.Sticker.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 height = 7;
 * @return {number}
 */
proto.api.GetStickersResponse.Sticker.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetStickersResponse.Sticker} returns this
 */
proto.api.GetStickersResponse.Sticker.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated Sticker stickers = 1;
 * @return {!Array<!proto.api.GetStickersResponse.Sticker>}
 */
proto.api.GetStickersResponse.prototype.getStickersList = function() {
  return /** @type{!Array<!proto.api.GetStickersResponse.Sticker>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.GetStickersResponse.Sticker, 1));
};


/**
 * @param {!Array<!proto.api.GetStickersResponse.Sticker>} value
 * @return {!proto.api.GetStickersResponse} returns this
*/
proto.api.GetStickersResponse.prototype.setStickersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.GetStickersResponse.Sticker=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.GetStickersResponse.Sticker}
 */
proto.api.GetStickersResponse.prototype.addStickers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.GetStickersResponse.Sticker, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetStickersResponse} returns this
 */
proto.api.GetStickersResponse.prototype.clearStickersList = function() {
  return this.setStickersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSafeNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSafeNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSafeNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSafeNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    receiver: (f = msg.getReceiver()) && api_model_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSafeNumberRequest}
 */
proto.api.GetSafeNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSafeNumberRequest;
  return proto.api.GetSafeNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSafeNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSafeNumberRequest}
 */
proto.api.GetSafeNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSafeNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSafeNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSafeNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSafeNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.GetSafeNumberRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetSafeNumberRequest} returns this
 */
proto.api.GetSafeNumberRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User receiver = 2;
 * @return {?proto.api.User}
 */
proto.api.GetSafeNumberRequest.prototype.getReceiver = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.GetSafeNumberRequest} returns this
*/
proto.api.GetSafeNumberRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetSafeNumberRequest} returns this
 */
proto.api.GetSafeNumberRequest.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetSafeNumberRequest.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetSafeNumberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetSafeNumberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetSafeNumberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSafeNumberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiverSafeNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    releaseTime: (f = msg.getReleaseTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetSafeNumberResponse}
 */
proto.api.GetSafeNumberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetSafeNumberResponse;
  return proto.api.GetSafeNumberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetSafeNumberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetSafeNumberResponse}
 */
proto.api.GetSafeNumberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverSafeNumber(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReleaseTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetSafeNumberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetSafeNumberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetSafeNumberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetSafeNumberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiverSafeNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReleaseTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string receiver_safe_number = 1;
 * @return {string}
 */
proto.api.GetSafeNumberResponse.prototype.getReceiverSafeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetSafeNumberResponse} returns this
 */
proto.api.GetSafeNumberResponse.prototype.setReceiverSafeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp release_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.api.GetSafeNumberResponse.prototype.getReleaseTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.api.GetSafeNumberResponse} returns this
*/
proto.api.GetSafeNumberResponse.prototype.setReleaseTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetSafeNumberResponse} returns this
 */
proto.api.GetSafeNumberResponse.prototype.clearReleaseTime = function() {
  return this.setReleaseTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetSafeNumberResponse.prototype.hasReleaseTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateBlockStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateBlockStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateBlockStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBlockStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && api_model_pb.User.toObject(includeInstance, f),
    targetUser: (f = msg.getTargetUser()) && api_model_pb.User.toObject(includeInstance, f),
    blockStatus: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateBlockStatusRequest}
 */
proto.api.UpdateBlockStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateBlockStatusRequest;
  return proto.api.UpdateBlockStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateBlockStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateBlockStatusRequest}
 */
proto.api.UpdateBlockStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setTargetUser(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlockStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateBlockStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateBlockStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateBlockStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBlockStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getTargetUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBlockStatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.api.User}
 */
proto.api.UpdateBlockStatusRequest.prototype.getUser = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 1));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.UpdateBlockStatusRequest} returns this
*/
proto.api.UpdateBlockStatusRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateBlockStatusRequest} returns this
 */
proto.api.UpdateBlockStatusRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateBlockStatusRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User target_user = 2;
 * @return {?proto.api.User}
 */
proto.api.UpdateBlockStatusRequest.prototype.getTargetUser = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.UpdateBlockStatusRequest} returns this
*/
proto.api.UpdateBlockStatusRequest.prototype.setTargetUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateBlockStatusRequest} returns this
 */
proto.api.UpdateBlockStatusRequest.prototype.clearTargetUser = function() {
  return this.setTargetUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateBlockStatusRequest.prototype.hasTargetUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool block_status = 3;
 * @return {boolean}
 */
proto.api.UpdateBlockStatusRequest.prototype.getBlockStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateBlockStatusRequest} returns this
 */
proto.api.UpdateBlockStatusRequest.prototype.setBlockStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateBlockStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateBlockStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateBlockStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBlockStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateBlockStatusResponse}
 */
proto.api.UpdateBlockStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateBlockStatusResponse;
  return proto.api.UpdateBlockStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateBlockStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateBlockStatusResponse}
 */
proto.api.UpdateBlockStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateBlockStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateBlockStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateBlockStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateBlockStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BanUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BanUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BanUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BanUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && api_model_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BanUserRequest}
 */
proto.api.BanUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BanUserRequest;
  return proto.api.BanUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BanUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BanUserRequest}
 */
proto.api.BanUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BanUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BanUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BanUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BanUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.BanUserRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.BanUserRequest} returns this
 */
proto.api.BanUserRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.api.User}
 */
proto.api.BanUserRequest.prototype.getUser = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 2));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.BanUserRequest} returns this
*/
proto.api.BanUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.BanUserRequest} returns this
 */
proto.api.BanUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.BanUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.BanUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.BanUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.BanUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BanUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.BanUserResponse}
 */
proto.api.BanUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.BanUserResponse;
  return proto.api.BanUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.BanUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.BanUserResponse}
 */
proto.api.BanUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.BanUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.BanUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.BanUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.BanUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetRtcTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetRtcTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetRtcTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetRtcTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invite: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetRtcTokenRequest}
 */
proto.api.GetRtcTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetRtcTokenRequest;
  return proto.api.GetRtcTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetRtcTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetRtcTokenRequest}
 */
proto.api.GetRtcTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetRtcTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetRtcTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetRtcTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetRtcTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvite();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.GetRtcTokenRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetRtcTokenRequest} returns this
 */
proto.api.GetRtcTokenRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool invite = 2;
 * @return {boolean}
 */
proto.api.GetRtcTokenRequest.prototype.getInvite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.GetRtcTokenRequest} returns this
 */
proto.api.GetRtcTokenRequest.prototype.setInvite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetRtcTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetRtcTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetRtcTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetRtcTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uidsMap: (f = msg.getUidsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetRtcTokenResponse}
 */
proto.api.GetRtcTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetRtcTokenResponse;
  return proto.api.GetRtcTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetRtcTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetRtcTokenResponse}
 */
proto.api.GetRtcTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = msg.getUidsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetRtcTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetRtcTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetRtcTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetRtcTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUidsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeUint32);
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.api.GetRtcTokenResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetRtcTokenResponse} returns this
 */
proto.api.GetRtcTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.api.GetRtcTokenResponse.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetRtcTokenResponse} returns this
 */
proto.api.GetRtcTokenResponse.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<int64, uint32> uids = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.api.GetRtcTokenResponse.prototype.getUidsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.GetRtcTokenResponse} returns this
 */
proto.api.GetRtcTokenResponse.prototype.clearUidsMap = function() {
  this.getUidsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CancelVoiceChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CancelVoiceChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CancelVoiceChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CancelVoiceChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CancelVoiceChannelRequest}
 */
proto.api.CancelVoiceChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CancelVoiceChannelRequest;
  return proto.api.CancelVoiceChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CancelVoiceChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CancelVoiceChannelRequest}
 */
proto.api.CancelVoiceChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.TerminateVoiceChannelReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CancelVoiceChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CancelVoiceChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CancelVoiceChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CancelVoiceChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.CancelVoiceChannelRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CancelVoiceChannelRequest} returns this
 */
proto.api.CancelVoiceChannelRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TerminateVoiceChannelReason reason = 2;
 * @return {!proto.api.TerminateVoiceChannelReason}
 */
proto.api.CancelVoiceChannelRequest.prototype.getReason = function() {
  return /** @type {!proto.api.TerminateVoiceChannelReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.TerminateVoiceChannelReason} value
 * @return {!proto.api.CancelVoiceChannelRequest} returns this
 */
proto.api.CancelVoiceChannelRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CancelVoiceChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CancelVoiceChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CancelVoiceChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CancelVoiceChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CancelVoiceChannelResponse}
 */
proto.api.CancelVoiceChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CancelVoiceChannelResponse;
  return proto.api.CancelVoiceChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CancelVoiceChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CancelVoiceChannelResponse}
 */
proto.api.CancelVoiceChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CancelVoiceChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CancelVoiceChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CancelVoiceChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CancelVoiceChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.CancelVoiceChannelResponse.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CancelVoiceChannelResponse} returns this
 */
proto.api.CancelVoiceChannelResponse.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.RejectVoiceChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.RejectVoiceChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.RejectVoiceChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RejectVoiceChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.RejectVoiceChannelRequest}
 */
proto.api.RejectVoiceChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.RejectVoiceChannelRequest;
  return proto.api.RejectVoiceChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.RejectVoiceChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.RejectVoiceChannelRequest}
 */
proto.api.RejectVoiceChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!proto.api.TerminateVoiceChannelReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.RejectVoiceChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.RejectVoiceChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.RejectVoiceChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RejectVoiceChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.RejectVoiceChannelRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.RejectVoiceChannelRequest} returns this
 */
proto.api.RejectVoiceChannelRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TerminateVoiceChannelReason reason = 2;
 * @return {!proto.api.TerminateVoiceChannelReason}
 */
proto.api.RejectVoiceChannelRequest.prototype.getReason = function() {
  return /** @type {!proto.api.TerminateVoiceChannelReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.TerminateVoiceChannelReason} value
 * @return {!proto.api.RejectVoiceChannelRequest} returns this
 */
proto.api.RejectVoiceChannelRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.RejectVoiceChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.RejectVoiceChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.RejectVoiceChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RejectVoiceChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.RejectVoiceChannelResponse}
 */
proto.api.RejectVoiceChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.RejectVoiceChannelResponse;
  return proto.api.RejectVoiceChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.RejectVoiceChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.RejectVoiceChannelResponse}
 */
proto.api.RejectVoiceChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.RejectVoiceChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.RejectVoiceChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.RejectVoiceChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.RejectVoiceChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.RejectVoiceChannelResponse.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.RejectVoiceChannelResponse} returns this
 */
proto.api.RejectVoiceChannelResponse.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelListHeaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelListHeaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelListHeaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelListHeaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelListHeaderRequest}
 */
proto.api.GetChannelListHeaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelListHeaderRequest;
  return proto.api.GetChannelListHeaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelListHeaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelListHeaderRequest}
 */
proto.api.GetChannelListHeaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelListHeaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelListHeaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelListHeaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelListHeaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChannelListHeaderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChannelListHeaderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChannelListHeaderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelListHeaderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelListHeader: (f = msg.getChannelListHeader()) && api_model_pb.ChannelListHeader.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChannelListHeaderResponse}
 */
proto.api.GetChannelListHeaderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChannelListHeaderResponse;
  return proto.api.GetChannelListHeaderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChannelListHeaderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChannelListHeaderResponse}
 */
proto.api.GetChannelListHeaderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.ChannelListHeader;
      reader.readMessage(value,api_model_pb.ChannelListHeader.deserializeBinaryFromReader);
      msg.setChannelListHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChannelListHeaderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChannelListHeaderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChannelListHeaderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChannelListHeaderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelListHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.ChannelListHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChannelListHeader channel_list_header = 1;
 * @return {?proto.api.ChannelListHeader}
 */
proto.api.GetChannelListHeaderResponse.prototype.getChannelListHeader = function() {
  return /** @type{?proto.api.ChannelListHeader} */ (
    jspb.Message.getWrapperField(this, api_model_pb.ChannelListHeader, 1));
};


/**
 * @param {?proto.api.ChannelListHeader|undefined} value
 * @return {!proto.api.GetChannelListHeaderResponse} returns this
*/
proto.api.GetChannelListHeaderResponse.prototype.setChannelListHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetChannelListHeaderResponse} returns this
 */
proto.api.GetChannelListHeaderResponse.prototype.clearChannelListHeader = function() {
  return this.setChannelListHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetChannelListHeaderResponse.prototype.hasChannelListHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CloseChannelListHeaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CloseChannelListHeaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CloseChannelListHeaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CloseChannelListHeaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CloseChannelListHeaderRequest}
 */
proto.api.CloseChannelListHeaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CloseChannelListHeaderRequest;
  return proto.api.CloseChannelListHeaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CloseChannelListHeaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CloseChannelListHeaderRequest}
 */
proto.api.CloseChannelListHeaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CloseChannelListHeaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CloseChannelListHeaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CloseChannelListHeaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CloseChannelListHeaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.CloseChannelListHeaderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CloseChannelListHeaderRequest} returns this
 */
proto.api.CloseChannelListHeaderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CloseChannelListHeaderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CloseChannelListHeaderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CloseChannelListHeaderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CloseChannelListHeaderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CloseChannelListHeaderResponse}
 */
proto.api.CloseChannelListHeaderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CloseChannelListHeaderResponse;
  return proto.api.CloseChannelListHeaderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CloseChannelListHeaderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CloseChannelListHeaderResponse}
 */
proto.api.CloseChannelListHeaderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CloseChannelListHeaderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CloseChannelListHeaderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CloseChannelListHeaderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CloseChannelListHeaderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deleteByRole: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteMessageRequest}
 */
proto.api.DeleteMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteMessageRequest;
  return proto.api.DeleteMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteMessageRequest}
 */
proto.api.DeleteMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {!proto.api.DeleteMessageRequest.DeleteByRole} */ (reader.readEnum());
      msg.setDeleteByRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeleteByRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.DeleteMessageRequest.DeleteByRole = {
  DELETE_BY_UNSPECIFIED: 0,
  DELETE_BY_SENDER: 0,
  DELETE_BY_OWNER: 1
};

/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.DeleteMessageRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteMessageRequest} returns this
 */
proto.api.DeleteMessageRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.api.DeleteMessageRequest.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.DeleteMessageRequest} returns this
 */
proto.api.DeleteMessageRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DeleteByRole delete_by_role = 3;
 * @return {!proto.api.DeleteMessageRequest.DeleteByRole}
 */
proto.api.DeleteMessageRequest.prototype.getDeleteByRole = function() {
  return /** @type {!proto.api.DeleteMessageRequest.DeleteByRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.api.DeleteMessageRequest.DeleteByRole} value
 * @return {!proto.api.DeleteMessageRequest} returns this
 */
proto.api.DeleteMessageRequest.prototype.setDeleteByRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && api_model_pb.Message.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteMessageResponse}
 */
proto.api.DeleteMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteMessageResponse;
  return proto.api.DeleteMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteMessageResponse}
 */
proto.api.DeleteMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Message;
      reader.readMessage(value,api_model_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional Message message = 1;
 * @return {?proto.api.Message}
 */
proto.api.DeleteMessageResponse.prototype.getMessage = function() {
  return /** @type{?proto.api.Message} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Message, 1));
};


/**
 * @param {?proto.api.Message|undefined} value
 * @return {!proto.api.DeleteMessageResponse} returns this
*/
proto.api.DeleteMessageResponse.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DeleteMessageResponse} returns this
 */
proto.api.DeleteMessageResponse.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DeleteMessageResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SendPersistentMenuPostbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SendPersistentMenuPostbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SendPersistentMenuPostbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendPersistentMenuPostbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SendPersistentMenuPostbackRequest}
 */
proto.api.SendPersistentMenuPostbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SendPersistentMenuPostbackRequest;
  return proto.api.SendPersistentMenuPostbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SendPersistentMenuPostbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SendPersistentMenuPostbackRequest}
 */
proto.api.SendPersistentMenuPostbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SendPersistentMenuPostbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SendPersistentMenuPostbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SendPersistentMenuPostbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendPersistentMenuPostbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.SendPersistentMenuPostbackRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SendPersistentMenuPostbackRequest} returns this
 */
proto.api.SendPersistentMenuPostbackRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.api.SendPersistentMenuPostbackRequest.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SendPersistentMenuPostbackRequest} returns this
 */
proto.api.SendPersistentMenuPostbackRequest.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SendPersistentMenuPostbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SendPersistentMenuPostbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SendPersistentMenuPostbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendPersistentMenuPostbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SendPersistentMenuPostbackResponse}
 */
proto.api.SendPersistentMenuPostbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SendPersistentMenuPostbackResponse;
  return proto.api.SendPersistentMenuPostbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SendPersistentMenuPostbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SendPersistentMenuPostbackResponse}
 */
proto.api.SendPersistentMenuPostbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SendPersistentMenuPostbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SendPersistentMenuPostbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SendPersistentMenuPostbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendPersistentMenuPostbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAutoReplyMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAutoReplyMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAutoReplyMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAutoReplyMetadataRequest}
 */
proto.api.GetAutoReplyMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAutoReplyMetadataRequest;
  return proto.api.GetAutoReplyMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAutoReplyMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAutoReplyMetadataRequest}
 */
proto.api.GetAutoReplyMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAutoReplyMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAutoReplyMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAutoReplyMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAutoReplyMetadataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAutoReplyMetadataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAutoReplyMetadataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyMetadataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoReplyMetadata: (f = msg.getAutoReplyMetadata()) && api_model_pb.AutoReplyMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAutoReplyMetadataResponse}
 */
proto.api.GetAutoReplyMetadataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAutoReplyMetadataResponse;
  return proto.api.GetAutoReplyMetadataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAutoReplyMetadataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAutoReplyMetadataResponse}
 */
proto.api.GetAutoReplyMetadataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReplyMetadata;
      reader.readMessage(value,api_model_pb.AutoReplyMetadata.deserializeBinaryFromReader);
      msg.setAutoReplyMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAutoReplyMetadataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAutoReplyMetadataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAutoReplyMetadataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyMetadataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoReplyMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.AutoReplyMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoReplyMetadata auto_reply_metadata = 1;
 * @return {?proto.api.AutoReplyMetadata}
 */
proto.api.GetAutoReplyMetadataResponse.prototype.getAutoReplyMetadata = function() {
  return /** @type{?proto.api.AutoReplyMetadata} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReplyMetadata, 1));
};


/**
 * @param {?proto.api.AutoReplyMetadata|undefined} value
 * @return {!proto.api.GetAutoReplyMetadataResponse} returns this
*/
proto.api.GetAutoReplyMetadataResponse.prototype.setAutoReplyMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetAutoReplyMetadataResponse} returns this
 */
proto.api.GetAutoReplyMetadataResponse.prototype.clearAutoReplyMetadata = function() {
  return this.setAutoReplyMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetAutoReplyMetadataResponse.prototype.hasAutoReplyMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAutoReplyMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAutoReplyMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAutoReplyMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAutoReplyMetadataRequest}
 */
proto.api.UpdateAutoReplyMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAutoReplyMetadataRequest;
  return proto.api.UpdateAutoReplyMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAutoReplyMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAutoReplyMetadataRequest}
 */
proto.api.UpdateAutoReplyMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAutoReplyMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAutoReplyMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAutoReplyMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.api.UpdateAutoReplyMetadataRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.UpdateAutoReplyMetadataRequest} returns this
 */
proto.api.UpdateAutoReplyMetadataRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAutoReplyMetadataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAutoReplyMetadataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAutoReplyMetadataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyMetadataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoReplyMetadata: (f = msg.getAutoReplyMetadata()) && api_model_pb.AutoReplyMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAutoReplyMetadataResponse}
 */
proto.api.UpdateAutoReplyMetadataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAutoReplyMetadataResponse;
  return proto.api.UpdateAutoReplyMetadataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAutoReplyMetadataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAutoReplyMetadataResponse}
 */
proto.api.UpdateAutoReplyMetadataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReplyMetadata;
      reader.readMessage(value,api_model_pb.AutoReplyMetadata.deserializeBinaryFromReader);
      msg.setAutoReplyMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAutoReplyMetadataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAutoReplyMetadataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAutoReplyMetadataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyMetadataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoReplyMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.AutoReplyMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoReplyMetadata auto_reply_metadata = 1;
 * @return {?proto.api.AutoReplyMetadata}
 */
proto.api.UpdateAutoReplyMetadataResponse.prototype.getAutoReplyMetadata = function() {
  return /** @type{?proto.api.AutoReplyMetadata} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReplyMetadata, 1));
};


/**
 * @param {?proto.api.AutoReplyMetadata|undefined} value
 * @return {!proto.api.UpdateAutoReplyMetadataResponse} returns this
*/
proto.api.UpdateAutoReplyMetadataResponse.prototype.setAutoReplyMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAutoReplyMetadataResponse} returns this
 */
proto.api.UpdateAutoReplyMetadataResponse.prototype.clearAutoReplyMetadata = function() {
  return this.setAutoReplyMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAutoReplyMetadataResponse.prototype.hasAutoReplyMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAutoReplyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAutoReplyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAutoReplyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAutoReplyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && api_model_pb.AutoReplyQuestion.toObject(includeInstance, f),
    answer: (f = msg.getAnswer()) && api_model_pb.AutoReplyAnswer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAutoReplyRequest}
 */
proto.api.CreateAutoReplyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAutoReplyRequest;
  return proto.api.CreateAutoReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAutoReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAutoReplyRequest}
 */
proto.api.CreateAutoReplyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReplyQuestion;
      reader.readMessage(value,api_model_pb.AutoReplyQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 2:
      var value = new api_model_pb.AutoReplyAnswer;
      reader.readMessage(value,api_model_pb.AutoReplyAnswer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAutoReplyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAutoReplyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAutoReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAutoReplyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.AutoReplyQuestion.serializeBinaryToWriter
    );
  }
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.AutoReplyAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoReplyQuestion question = 1;
 * @return {?proto.api.AutoReplyQuestion}
 */
proto.api.CreateAutoReplyRequest.prototype.getQuestion = function() {
  return /** @type{?proto.api.AutoReplyQuestion} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReplyQuestion, 1));
};


/**
 * @param {?proto.api.AutoReplyQuestion|undefined} value
 * @return {!proto.api.CreateAutoReplyRequest} returns this
*/
proto.api.CreateAutoReplyRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAutoReplyRequest} returns this
 */
proto.api.CreateAutoReplyRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAutoReplyRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AutoReplyAnswer answer = 2;
 * @return {?proto.api.AutoReplyAnswer}
 */
proto.api.CreateAutoReplyRequest.prototype.getAnswer = function() {
  return /** @type{?proto.api.AutoReplyAnswer} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReplyAnswer, 2));
};


/**
 * @param {?proto.api.AutoReplyAnswer|undefined} value
 * @return {!proto.api.CreateAutoReplyRequest} returns this
*/
proto.api.CreateAutoReplyRequest.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAutoReplyRequest} returns this
 */
proto.api.CreateAutoReplyRequest.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAutoReplyRequest.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAutoReplyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAutoReplyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAutoReplyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAutoReplyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoReply: (f = msg.getAutoReply()) && api_model_pb.AutoReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAutoReplyResponse}
 */
proto.api.CreateAutoReplyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAutoReplyResponse;
  return proto.api.CreateAutoReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAutoReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAutoReplyResponse}
 */
proto.api.CreateAutoReplyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReply;
      reader.readMessage(value,api_model_pb.AutoReply.deserializeBinaryFromReader);
      msg.setAutoReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAutoReplyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAutoReplyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAutoReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAutoReplyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoReply();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.AutoReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoReply auto_reply = 1;
 * @return {?proto.api.AutoReply}
 */
proto.api.CreateAutoReplyResponse.prototype.getAutoReply = function() {
  return /** @type{?proto.api.AutoReply} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReply, 1));
};


/**
 * @param {?proto.api.AutoReply|undefined} value
 * @return {!proto.api.CreateAutoReplyResponse} returns this
*/
proto.api.CreateAutoReplyResponse.prototype.setAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAutoReplyResponse} returns this
 */
proto.api.CreateAutoReplyResponse.prototype.clearAutoReply = function() {
  return this.setAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAutoReplyResponse.prototype.hasAutoReply = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListAutoRepliesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListAutoRepliesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListAutoRepliesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAutoRepliesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListAutoRepliesRequest}
 */
proto.api.ListAutoRepliesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListAutoRepliesRequest;
  return proto.api.ListAutoRepliesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListAutoRepliesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListAutoRepliesRequest}
 */
proto.api.ListAutoRepliesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListAutoRepliesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListAutoRepliesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListAutoRepliesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAutoRepliesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListAutoRepliesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListAutoRepliesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListAutoRepliesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListAutoRepliesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAutoRepliesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoRepliesList: jspb.Message.toObjectList(msg.getAutoRepliesList(),
    api_model_pb.AutoReply.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListAutoRepliesResponse}
 */
proto.api.ListAutoRepliesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListAutoRepliesResponse;
  return proto.api.ListAutoRepliesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListAutoRepliesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListAutoRepliesResponse}
 */
proto.api.ListAutoRepliesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReply;
      reader.readMessage(value,api_model_pb.AutoReply.deserializeBinaryFromReader);
      msg.addAutoReplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListAutoRepliesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListAutoRepliesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListAutoRepliesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListAutoRepliesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoRepliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.AutoReply.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutoReply auto_replies = 1;
 * @return {!Array<!proto.api.AutoReply>}
 */
proto.api.ListAutoRepliesResponse.prototype.getAutoRepliesList = function() {
  return /** @type{!Array<!proto.api.AutoReply>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.AutoReply, 1));
};


/**
 * @param {!Array<!proto.api.AutoReply>} value
 * @return {!proto.api.ListAutoRepliesResponse} returns this
*/
proto.api.ListAutoRepliesResponse.prototype.setAutoRepliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AutoReply=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AutoReply}
 */
proto.api.ListAutoRepliesResponse.prototype.addAutoReplies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AutoReply, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListAutoRepliesResponse} returns this
 */
proto.api.ListAutoRepliesResponse.prototype.clearAutoRepliesList = function() {
  return this.setAutoRepliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAutoReplyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAutoReplyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAutoReplyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAutoReplyRequest}
 */
proto.api.GetAutoReplyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAutoReplyRequest;
  return proto.api.GetAutoReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAutoReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAutoReplyRequest}
 */
proto.api.GetAutoReplyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAutoReplyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAutoReplyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAutoReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.GetAutoReplyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetAutoReplyRequest} returns this
 */
proto.api.GetAutoReplyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAutoReplyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAutoReplyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAutoReplyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoReply: (f = msg.getAutoReply()) && api_model_pb.AutoReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAutoReplyResponse}
 */
proto.api.GetAutoReplyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAutoReplyResponse;
  return proto.api.GetAutoReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAutoReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAutoReplyResponse}
 */
proto.api.GetAutoReplyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReply;
      reader.readMessage(value,api_model_pb.AutoReply.deserializeBinaryFromReader);
      msg.setAutoReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAutoReplyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAutoReplyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAutoReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAutoReplyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoReply();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.AutoReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoReply auto_reply = 1;
 * @return {?proto.api.AutoReply}
 */
proto.api.GetAutoReplyResponse.prototype.getAutoReply = function() {
  return /** @type{?proto.api.AutoReply} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReply, 1));
};


/**
 * @param {?proto.api.AutoReply|undefined} value
 * @return {!proto.api.GetAutoReplyResponse} returns this
*/
proto.api.GetAutoReplyResponse.prototype.setAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetAutoReplyResponse} returns this
 */
proto.api.GetAutoReplyResponse.prototype.clearAutoReply = function() {
  return this.setAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetAutoReplyResponse.prototype.hasAutoReply = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAutoReplyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAutoReplyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAutoReplyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    question: (f = msg.getQuestion()) && api_model_pb.AutoReplyQuestion.toObject(includeInstance, f),
    answer: (f = msg.getAnswer()) && api_model_pb.AutoReplyAnswer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAutoReplyRequest}
 */
proto.api.UpdateAutoReplyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAutoReplyRequest;
  return proto.api.UpdateAutoReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAutoReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAutoReplyRequest}
 */
proto.api.UpdateAutoReplyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new api_model_pb.AutoReplyQuestion;
      reader.readMessage(value,api_model_pb.AutoReplyQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 3:
      var value = new api_model_pb.AutoReplyAnswer;
      reader.readMessage(value,api_model_pb.AutoReplyAnswer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAutoReplyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAutoReplyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAutoReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_model_pb.AutoReplyQuestion.serializeBinaryToWriter
    );
  }
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      api_model_pb.AutoReplyAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UpdateAutoReplyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateAutoReplyRequest} returns this
 */
proto.api.UpdateAutoReplyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AutoReplyQuestion question = 2;
 * @return {?proto.api.AutoReplyQuestion}
 */
proto.api.UpdateAutoReplyRequest.prototype.getQuestion = function() {
  return /** @type{?proto.api.AutoReplyQuestion} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReplyQuestion, 2));
};


/**
 * @param {?proto.api.AutoReplyQuestion|undefined} value
 * @return {!proto.api.UpdateAutoReplyRequest} returns this
*/
proto.api.UpdateAutoReplyRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAutoReplyRequest} returns this
 */
proto.api.UpdateAutoReplyRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAutoReplyRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AutoReplyAnswer answer = 3;
 * @return {?proto.api.AutoReplyAnswer}
 */
proto.api.UpdateAutoReplyRequest.prototype.getAnswer = function() {
  return /** @type{?proto.api.AutoReplyAnswer} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReplyAnswer, 3));
};


/**
 * @param {?proto.api.AutoReplyAnswer|undefined} value
 * @return {!proto.api.UpdateAutoReplyRequest} returns this
*/
proto.api.UpdateAutoReplyRequest.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAutoReplyRequest} returns this
 */
proto.api.UpdateAutoReplyRequest.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAutoReplyRequest.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAutoReplyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAutoReplyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAutoReplyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoReply: (f = msg.getAutoReply()) && api_model_pb.AutoReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAutoReplyResponse}
 */
proto.api.UpdateAutoReplyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAutoReplyResponse;
  return proto.api.UpdateAutoReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAutoReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAutoReplyResponse}
 */
proto.api.UpdateAutoReplyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.AutoReply;
      reader.readMessage(value,api_model_pb.AutoReply.deserializeBinaryFromReader);
      msg.setAutoReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAutoReplyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAutoReplyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAutoReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAutoReplyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoReply();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.AutoReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoReply auto_reply = 1;
 * @return {?proto.api.AutoReply}
 */
proto.api.UpdateAutoReplyResponse.prototype.getAutoReply = function() {
  return /** @type{?proto.api.AutoReply} */ (
    jspb.Message.getWrapperField(this, api_model_pb.AutoReply, 1));
};


/**
 * @param {?proto.api.AutoReply|undefined} value
 * @return {!proto.api.UpdateAutoReplyResponse} returns this
*/
proto.api.UpdateAutoReplyResponse.prototype.setAutoReply = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAutoReplyResponse} returns this
 */
proto.api.UpdateAutoReplyResponse.prototype.clearAutoReply = function() {
  return this.setAutoReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAutoReplyResponse.prototype.hasAutoReply = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAutoReplyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAutoReplyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAutoReplyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAutoReplyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAutoReplyRequest}
 */
proto.api.DeleteAutoReplyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAutoReplyRequest;
  return proto.api.DeleteAutoReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAutoReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAutoReplyRequest}
 */
proto.api.DeleteAutoReplyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAutoReplyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAutoReplyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAutoReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAutoReplyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteAutoReplyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteAutoReplyRequest} returns this
 */
proto.api.DeleteAutoReplyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAutoReplyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAutoReplyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAutoReplyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAutoReplyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAutoReplyResponse}
 */
proto.api.DeleteAutoReplyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAutoReplyResponse;
  return proto.api.DeleteAutoReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAutoReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAutoReplyResponse}
 */
proto.api.DeleteAutoReplyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAutoReplyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAutoReplyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAutoReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAutoReplyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAnnouncementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAnnouncementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAnnouncementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAnnouncementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAnnouncementRequest}
 */
proto.api.CreateAnnouncementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAnnouncementRequest;
  return proto.api.CreateAnnouncementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAnnouncementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAnnouncementRequest}
 */
proto.api.CreateAnnouncementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAnnouncementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAnnouncementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAnnouncementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAnnouncementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.CreateAnnouncementRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAnnouncementRequest} returns this
 */
proto.api.CreateAnnouncementRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.api.CreateAnnouncementRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAnnouncementRequest} returns this
 */
proto.api.CreateAnnouncementRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 message_id = 3;
 * @return {number}
 */
proto.api.CreateAnnouncementRequest.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CreateAnnouncementRequest} returns this
 */
proto.api.CreateAnnouncementRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.CreateAnnouncementRequest} returns this
 */
proto.api.CreateAnnouncementRequest.prototype.clearMessageId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAnnouncementRequest.prototype.hasMessageId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAnnouncementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAnnouncementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAnnouncementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAnnouncementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    announcement: (f = msg.getAnnouncement()) && api_model_pb.Announcement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAnnouncementResponse}
 */
proto.api.CreateAnnouncementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAnnouncementResponse;
  return proto.api.CreateAnnouncementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAnnouncementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAnnouncementResponse}
 */
proto.api.CreateAnnouncementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Announcement;
      reader.readMessage(value,api_model_pb.Announcement.deserializeBinaryFromReader);
      msg.setAnnouncement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAnnouncementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAnnouncementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAnnouncementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAnnouncementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnouncement();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Announcement.serializeBinaryToWriter
    );
  }
};


/**
 * optional Announcement announcement = 1;
 * @return {?proto.api.Announcement}
 */
proto.api.CreateAnnouncementResponse.prototype.getAnnouncement = function() {
  return /** @type{?proto.api.Announcement} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Announcement, 1));
};


/**
 * @param {?proto.api.Announcement|undefined} value
 * @return {!proto.api.CreateAnnouncementResponse} returns this
*/
proto.api.CreateAnnouncementResponse.prototype.setAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAnnouncementResponse} returns this
 */
proto.api.CreateAnnouncementResponse.prototype.clearAnnouncement = function() {
  return this.setAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAnnouncementResponse.prototype.hasAnnouncement = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAnnouncementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAnnouncementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAnnouncementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAnnouncementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAnnouncementRequest}
 */
proto.api.DeleteAnnouncementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAnnouncementRequest;
  return proto.api.DeleteAnnouncementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAnnouncementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAnnouncementRequest}
 */
proto.api.DeleteAnnouncementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAnnouncementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAnnouncementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAnnouncementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAnnouncementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.DeleteAnnouncementRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteAnnouncementRequest} returns this
 */
proto.api.DeleteAnnouncementRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.api.DeleteAnnouncementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteAnnouncementRequest} returns this
 */
proto.api.DeleteAnnouncementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAnnouncementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAnnouncementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAnnouncementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAnnouncementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAnnouncementResponse}
 */
proto.api.DeleteAnnouncementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAnnouncementResponse;
  return proto.api.DeleteAnnouncementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAnnouncementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAnnouncementResponse}
 */
proto.api.DeleteAnnouncementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAnnouncementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAnnouncementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAnnouncementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAnnouncementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SetReactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SetReactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SetReactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SetReactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = msg.getTarget()) && api_model_pb.ReactionTarget.toObject(includeInstance, f),
    emojiId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SetReactionRequest}
 */
proto.api.SetReactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SetReactionRequest;
  return proto.api.SetReactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SetReactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SetReactionRequest}
 */
proto.api.SetReactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.ReactionTarget;
      reader.readMessage(value,api_model_pb.ReactionTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SetReactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SetReactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SetReactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SetReactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.ReactionTarget.serializeBinaryToWriter
    );
  }
  f = message.getEmojiId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ReactionTarget target = 1;
 * @return {?proto.api.ReactionTarget}
 */
proto.api.SetReactionRequest.prototype.getTarget = function() {
  return /** @type{?proto.api.ReactionTarget} */ (
    jspb.Message.getWrapperField(this, api_model_pb.ReactionTarget, 1));
};


/**
 * @param {?proto.api.ReactionTarget|undefined} value
 * @return {!proto.api.SetReactionRequest} returns this
*/
proto.api.SetReactionRequest.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SetReactionRequest} returns this
 */
proto.api.SetReactionRequest.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SetReactionRequest.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string emoji_id = 2;
 * @return {string}
 */
proto.api.SetReactionRequest.prototype.getEmojiId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SetReactionRequest} returns this
 */
proto.api.SetReactionRequest.prototype.setEmojiId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SetReactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SetReactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SetReactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SetReactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reaction: (f = msg.getReaction()) && api_model_pb.Reaction.toObject(includeInstance, f),
    created: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SetReactionResponse}
 */
proto.api.SetReactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SetReactionResponse;
  return proto.api.SetReactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SetReactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SetReactionResponse}
 */
proto.api.SetReactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Reaction;
      reader.readMessage(value,api_model_pb.Reaction.deserializeBinaryFromReader);
      msg.setReaction(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SetReactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SetReactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SetReactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SetReactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.Reaction.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Reaction reaction = 1;
 * @return {?proto.api.Reaction}
 */
proto.api.SetReactionResponse.prototype.getReaction = function() {
  return /** @type{?proto.api.Reaction} */ (
    jspb.Message.getWrapperField(this, api_model_pb.Reaction, 1));
};


/**
 * @param {?proto.api.Reaction|undefined} value
 * @return {!proto.api.SetReactionResponse} returns this
*/
proto.api.SetReactionResponse.prototype.setReaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SetReactionResponse} returns this
 */
proto.api.SetReactionResponse.prototype.clearReaction = function() {
  return this.setReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SetReactionResponse.prototype.hasReaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool created = 2;
 * @return {boolean}
 */
proto.api.SetReactionResponse.prototype.getCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.SetReactionResponse} returns this
 */
proto.api.SetReactionResponse.prototype.setCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListReactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListReactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListReactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListReactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = msg.getTarget()) && api_model_pb.ReactionTarget.toObject(includeInstance, f),
    emojiId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pagingKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListReactionRequest}
 */
proto.api.ListReactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListReactionRequest;
  return proto.api.ListReactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListReactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListReactionRequest}
 */
proto.api.ListReactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.ReactionTarget;
      reader.readMessage(value,api_model_pb.ReactionTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPagingKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListReactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListReactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListReactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListReactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.ReactionTarget.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional ReactionTarget target = 1;
 * @return {?proto.api.ReactionTarget}
 */
proto.api.ListReactionRequest.prototype.getTarget = function() {
  return /** @type{?proto.api.ReactionTarget} */ (
    jspb.Message.getWrapperField(this, api_model_pb.ReactionTarget, 1));
};


/**
 * @param {?proto.api.ReactionTarget|undefined} value
 * @return {!proto.api.ListReactionRequest} returns this
*/
proto.api.ListReactionRequest.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListReactionRequest.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string emoji_id = 3;
 * @return {string}
 */
proto.api.ListReactionRequest.prototype.getEmojiId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.setEmojiId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.clearEmojiId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListReactionRequest.prototype.hasEmojiId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string paging_key = 4;
 * @return {string}
 */
proto.api.ListReactionRequest.prototype.getPagingKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.setPagingKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.clearPagingKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListReactionRequest.prototype.hasPagingKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.api.ListReactionRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ListReactionRequest} returns this
 */
proto.api.ListReactionRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListReactionRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListReactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListReactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListReactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListReactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListReactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reactionsList: jspb.Message.toObjectList(msg.getReactionsList(),
    api_model_pb.Reaction.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPagingKey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListReactionResponse}
 */
proto.api.ListReactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListReactionResponse;
  return proto.api.ListReactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListReactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListReactionResponse}
 */
proto.api.ListReactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Reaction;
      reader.readMessage(value,api_model_pb.Reaction.deserializeBinaryFromReader);
      msg.addReactions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPagingKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListReactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListReactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListReactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListReactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.Reaction.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated Reaction reactions = 1;
 * @return {!Array<!proto.api.Reaction>}
 */
proto.api.ListReactionResponse.prototype.getReactionsList = function() {
  return /** @type{!Array<!proto.api.Reaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.Reaction, 1));
};


/**
 * @param {!Array<!proto.api.Reaction>} value
 * @return {!proto.api.ListReactionResponse} returns this
*/
proto.api.ListReactionResponse.prototype.setReactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Reaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Reaction}
 */
proto.api.ListReactionResponse.prototype.addReactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Reaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListReactionResponse} returns this
 */
proto.api.ListReactionResponse.prototype.clearReactionsList = function() {
  return this.setReactionsList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.api.ListReactionResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListReactionResponse} returns this
 */
proto.api.ListReactionResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_paging_key = 3;
 * @return {string}
 */
proto.api.ListReactionResponse.prototype.getNextPagingKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ListReactionResponse} returns this
 */
proto.api.ListReactionResponse.prototype.setNextPagingKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.ListReactionResponse} returns this
 */
proto.api.ListReactionResponse.prototype.clearNextPagingKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListReactionResponse.prototype.hasNextPagingKey = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagingKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMembersRequest}
 */
proto.api.GetMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMembersRequest;
  return proto.api.GetMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMembersRequest}
 */
proto.api.GetMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPagingKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.GetMembersRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetMembersRequest} returns this
 */
proto.api.GetMembersRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string paging_key = 2;
 * @return {string}
 */
proto.api.GetMembersRequest.prototype.getPagingKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetMembersRequest} returns this
 */
proto.api.GetMembersRequest.prototype.setPagingKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.GetMembersRequest} returns this
 */
proto.api.GetMembersRequest.prototype.clearPagingKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetMembersRequest.prototype.hasPagingKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.api.GetMembersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetMembersRequest} returns this
 */
proto.api.GetMembersRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.GetMembersRequest} returns this
 */
proto.api.GetMembersRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetMembersRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetMembersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMembersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMembersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMembersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMembersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    api_model_pb.User.toObject, includeInstance),
    nextPagingKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMembersResponse}
 */
proto.api.GetMembersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMembersResponse;
  return proto.api.GetMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMembersResponse}
 */
proto.api.GetMembersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPagingKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMembersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMembersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMembersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated User members = 1;
 * @return {!Array<!proto.api.User>}
 */
proto.api.GetMembersResponse.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.User, 1));
};


/**
 * @param {!Array<!proto.api.User>} value
 * @return {!proto.api.GetMembersResponse} returns this
*/
proto.api.GetMembersResponse.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.User}
 */
proto.api.GetMembersResponse.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetMembersResponse} returns this
 */
proto.api.GetMembersResponse.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * optional string next_paging_key = 2;
 * @return {string}
 */
proto.api.GetMembersResponse.prototype.getNextPagingKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetMembersResponse} returns this
 */
proto.api.GetMembersResponse.prototype.setNextPagingKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.GetMembersResponse} returns this
 */
proto.api.GetMembersResponse.prototype.clearNextPagingKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetMembersResponse.prototype.hasNextPagingKey = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMemberRequest}
 */
proto.api.GetMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMemberRequest;
  return proto.api.GetMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMemberRequest}
 */
proto.api.GetMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.api.User.Type} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.GetMemberRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetMemberRequest} returns this
 */
proto.api.GetMemberRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.api.GetMemberRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetMemberRequest} returns this
 */
proto.api.GetMemberRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional User.Type user_type = 3;
 * @return {!proto.api.User.Type}
 */
proto.api.GetMemberRequest.prototype.getUserType = function() {
  return /** @type {!proto.api.User.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.api.User.Type} value
 * @return {!proto.api.GetMemberRequest} returns this
 */
proto.api.GetMemberRequest.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    member: (f = msg.getMember()) && api_model_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetMemberResponse}
 */
proto.api.GetMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetMemberResponse;
  return proto.api.GetMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetMemberResponse}
 */
proto.api.GetMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User member = 1;
 * @return {?proto.api.User}
 */
proto.api.GetMemberResponse.prototype.getMember = function() {
  return /** @type{?proto.api.User} */ (
    jspb.Message.getWrapperField(this, api_model_pb.User, 1));
};


/**
 * @param {?proto.api.User|undefined} value
 * @return {!proto.api.GetMemberResponse} returns this
*/
proto.api.GetMemberResponse.prototype.setMember = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.GetMemberResponse} returns this
 */
proto.api.GetMemberResponse.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.GetMemberResponse.prototype.hasMember = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchMembersRequest}
 */
proto.api.SearchMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchMembersRequest;
  return proto.api.SearchMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchMembersRequest}
 */
proto.api.SearchMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.api.SearchMembersRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchMembersRequest} returns this
 */
proto.api.SearchMembersRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nickname = 2;
 * @return {string}
 */
proto.api.SearchMembersRequest.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SearchMembersRequest} returns this
 */
proto.api.SearchMembersRequest.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.api.SearchMembersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.SearchMembersRequest} returns this
 */
proto.api.SearchMembersRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.SearchMembersRequest} returns this
 */
proto.api.SearchMembersRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SearchMembersRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.SearchMembersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SearchMembersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SearchMembersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SearchMembersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchMembersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    api_model_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SearchMembersResponse}
 */
proto.api.SearchMembersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SearchMembersResponse;
  return proto.api.SearchMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SearchMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SearchMembersResponse}
 */
proto.api.SearchMembersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.User;
      reader.readMessage(value,api_model_pb.User.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SearchMembersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SearchMembersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SearchMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SearchMembersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User members = 1;
 * @return {!Array<!proto.api.User>}
 */
proto.api.SearchMembersResponse.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.User, 1));
};


/**
 * @param {!Array<!proto.api.User>} value
 * @return {!proto.api.SearchMembersResponse} returns this
*/
proto.api.SearchMembersResponse.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.User}
 */
proto.api.SearchMembersResponse.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.SearchMembersResponse} returns this
 */
proto.api.SearchMembersResponse.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SendButtonPostbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SendButtonPostbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SendButtonPostbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendButtonPostbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SendButtonPostbackRequest}
 */
proto.api.SendButtonPostbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SendButtonPostbackRequest;
  return proto.api.SendButtonPostbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SendButtonPostbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SendButtonPostbackRequest}
 */
proto.api.SendButtonPostbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SendButtonPostbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SendButtonPostbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SendButtonPostbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendButtonPostbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.api.SendButtonPostbackRequest.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SendButtonPostbackRequest} returns this
 */
proto.api.SendButtonPostbackRequest.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SendButtonPostbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SendButtonPostbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SendButtonPostbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendButtonPostbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    buttonPostbackAction: (f = msg.getButtonPostbackAction()) && api_model_pb.ButtonPostbackAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SendButtonPostbackResponse}
 */
proto.api.SendButtonPostbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SendButtonPostbackResponse;
  return proto.api.SendButtonPostbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SendButtonPostbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SendButtonPostbackResponse}
 */
proto.api.SendButtonPostbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.ButtonPostbackAction;
      reader.readMessage(value,api_model_pb.ButtonPostbackAction.deserializeBinaryFromReader);
      msg.setButtonPostbackAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SendButtonPostbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SendButtonPostbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SendButtonPostbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SendButtonPostbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getButtonPostbackAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_model_pb.ButtonPostbackAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional ButtonPostbackAction button_postback_action = 1;
 * @return {?proto.api.ButtonPostbackAction}
 */
proto.api.SendButtonPostbackResponse.prototype.getButtonPostbackAction = function() {
  return /** @type{?proto.api.ButtonPostbackAction} */ (
    jspb.Message.getWrapperField(this, api_model_pb.ButtonPostbackAction, 1));
};


/**
 * @param {?proto.api.ButtonPostbackAction|undefined} value
 * @return {!proto.api.SendButtonPostbackResponse} returns this
*/
proto.api.SendButtonPostbackResponse.prototype.setButtonPostbackAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.SendButtonPostbackResponse} returns this
 */
proto.api.SendButtonPostbackResponse.prototype.clearButtonPostbackAction = function() {
  return this.setButtonPostbackAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.SendButtonPostbackResponse.prototype.hasButtonPostbackAction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetFiltersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetFiltersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetFiltersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetFiltersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetFiltersRequest}
 */
proto.api.GetFiltersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetFiltersRequest;
  return proto.api.GetFiltersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetFiltersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetFiltersRequest}
 */
proto.api.GetFiltersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetFiltersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetFiltersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetFiltersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetFiltersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetFiltersResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetFiltersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetFiltersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetFiltersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetFiltersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceFiltersList: jspb.Message.toObjectList(msg.getServiceFiltersList(),
    api_model_pb.Filter.toObject, includeInstance),
    commonFiltersList: jspb.Message.toObjectList(msg.getCommonFiltersList(),
    api_model_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetFiltersResponse}
 */
proto.api.GetFiltersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetFiltersResponse;
  return proto.api.GetFiltersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetFiltersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetFiltersResponse}
 */
proto.api.GetFiltersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_model_pb.Filter;
      reader.readMessage(value,api_model_pb.Filter.deserializeBinaryFromReader);
      msg.addServiceFilters(value);
      break;
    case 2:
      var value = new api_model_pb.Filter;
      reader.readMessage(value,api_model_pb.Filter.deserializeBinaryFromReader);
      msg.addCommonFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetFiltersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetFiltersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetFiltersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetFiltersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      api_model_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getCommonFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      api_model_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Filter service_filters = 1;
 * @return {!Array<!proto.api.Filter>}
 */
proto.api.GetFiltersResponse.prototype.getServiceFiltersList = function() {
  return /** @type{!Array<!proto.api.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.api.Filter>} value
 * @return {!proto.api.GetFiltersResponse} returns this
*/
proto.api.GetFiltersResponse.prototype.setServiceFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Filter}
 */
proto.api.GetFiltersResponse.prototype.addServiceFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetFiltersResponse} returns this
 */
proto.api.GetFiltersResponse.prototype.clearServiceFiltersList = function() {
  return this.setServiceFiltersList([]);
};


/**
 * repeated Filter common_filters = 2;
 * @return {!Array<!proto.api.Filter>}
 */
proto.api.GetFiltersResponse.prototype.getCommonFiltersList = function() {
  return /** @type{!Array<!proto.api.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_model_pb.Filter, 2));
};


/**
 * @param {!Array<!proto.api.Filter>} value
 * @return {!proto.api.GetFiltersResponse} returns this
*/
proto.api.GetFiltersResponse.prototype.setCommonFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Filter}
 */
proto.api.GetFiltersResponse.prototype.addCommonFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetFiltersResponse} returns this
 */
proto.api.GetFiltersResponse.prototype.clearCommonFiltersList = function() {
  return this.setCommonFiltersList([]);
};


/**
 * @enum {number}
 */
proto.api.Status = {
  UNKNOWN: 0,
  OK: 1,
  INVALID_REQUEST_NAME: 2,
  INTERNAL_SERVER_ERROR: 3,
  FORBIDDEN: 4,
  BAD_REQUEST: 5,
  NOT_FOUND: 11,
  BLOCKED_USER: 12,
  BLOCKED_BY_ME: 13,
  TOO_LONG_CONTENT: 14,
  BLOCKED_STATUS_USER: 15,
  NOT_FOUND_RECEIVER: 16,
  DO_NOT_DISTURB: 17,
  BANNED_USER: 18
};

/**
 * @enum {number}
 */
proto.api.Range = {
  GREATER: 0,
  GREATER_EQUAL: 1,
  EQUAL: 2,
  LESS: 3,
  LESS_EQUAL: 4
};

goog.object.extend(exports, proto.api);
