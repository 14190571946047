import decoder from 'jwt-decode'
import Cookies from 'js-cookie'

import { IS_PROD } from 'constants/config'
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from 'utils/localStorage'
import { cookieSave } from 'utils/cookieWrapper'

export const WEB_ACCESS_TOKEN_FOR_SHARE = '_watfs'
export const WEB_REFRESH_TOKEN_FOR_SHARE = '_wrtfs'
export interface jwtProps {
  did: string
  exp: number
  iat: number
  iss: string
  sub: string
  uid: number
  unk: string
}
const jwtDecoder = (str: string) => {
  try {
    const decodeToken: jwtProps = decoder(str)
    return decodeToken
  } catch (e) {
    return null
  }
}

function saveAccessToken(token: string) {
  const decodeToken = jwtDecoder(token)
  if (decodeToken) {
    cookieSave({ name: WEB_ACCESS_TOKEN_FOR_SHARE, value: token, cookieType: 'shared' })
    cookieSave({ name: ACCESS_TOKEN_NAME, value: token, cookieType: 'origin' })
  }
}
function saveRefreshToken(token: string) {
  const decodeToken = jwtDecoder(token)
  if (decodeToken) {
    cookieSave({ name: WEB_REFRESH_TOKEN_FOR_SHARE, value: token, cookieType: 'shared' })
    cookieSave({ name: REFRESH_TOKEN_NAME, value: token, cookieType: 'origin' })
  }
}

function loadToken(name: string): string {
  return Cookies.get(name) || ''
}

function getDecodeAccessToken(): jwtProps | undefined {
  const token = loadToken(ACCESS_TOKEN_NAME) || loadToken(WEB_ACCESS_TOKEN_FOR_SHARE)
  return jwtDecoder(token) || undefined
}

function getDeocdeRefreshToken(): jwtProps | undefined {
  const token = loadToken(REFRESH_TOKEN_NAME) || loadToken(WEB_REFRESH_TOKEN_FOR_SHARE)
  return jwtDecoder(token) || undefined
}

function getRefToken(): string {
  return loadToken(REFRESH_TOKEN_NAME) || loadToken(WEB_REFRESH_TOKEN_FOR_SHARE)
}

function getAccToken(): string {
  return loadToken(ACCESS_TOKEN_NAME) || loadToken(WEB_ACCESS_TOKEN_FOR_SHARE)
}

function validateToken(token: jwtProps): boolean {
  const currentSec = Math.min(new Date().getTime() / 1000)
  return token.exp > currentSec
}

function deleteToken() {
  // Cookies.remove(ACCESS_TOKEN_NAME, { domain: '.daangn.com' })
  // Cookies.remove(REFRESH_TOKEN_NAME, { domain: '.daangn.com' })
  Cookies.remove(ACCESS_TOKEN_NAME, { domain: window.location.hostname })
  Cookies.remove(WEB_ACCESS_TOKEN_FOR_SHARE, { domain: `${IS_PROD ? '' : '.alpha'}.daangn.com` })
  Cookies.remove(REFRESH_TOKEN_NAME, { domain: window.location.hostname })
  Cookies.remove(WEB_REFRESH_TOKEN_FOR_SHARE, { domain: `${IS_PROD ? '' : '.alpha'}.daangn.com` })
}

export {
  jwtDecoder,
  getDecodeAccessToken,
  getDeocdeRefreshToken,
  getRefToken,
  getAccToken,
  validateToken,
  deleteToken,
  saveAccessToken,
  saveRefreshToken,
}
