import { store } from 'store'
import { ReduxPopup, PortalModalTypes } from 'types/ModalTypes'
import { muteStore } from 'utils/cacheStore'
import { setLocalStorage, getLocalStorage, CHANNEL_MUTE_LIST, AUTO_REPLY_INTRO } from 'utils/localStorage'

export const muteControl = (channelId: string, mute: boolean) => {
  if (mute) {
    muteStore.set(channelId)
    openToast(`채팅 알림을 받지 않아요.`)
  } else {
    muteStore.delete(channelId)
    openToast(`채팅 알림을 받아요.`)
  }
  store.dispatch({ type: 'utils/setMuteChangeCount' })
  setLocalStorage({ key: CHANNEL_MUTE_LIST, value: muteStore.dataList })
}

if (getLocalStorage(CHANNEL_MUTE_LIST)) {
  muteStore.init(getLocalStorage(CHANNEL_MUTE_LIST))
}

export const forceRouting = (path: string) => {
  store.dispatch({ type: 'utils/setForceRoute', payload: path })
}

const openLoader = () => {
  store.dispatch({ type: 'utils/setLoader', payload: true })
}
const closeLoader = () => {
  store.dispatch({ type: 'utils/setLoader', payload: false })
}

const openPopup = ({ content, coord, channelId }: ReduxPopup) => {
  store.dispatch({
    type: 'utils/setPopup',
    payload: {
      content,
      isShow: true,
      coord,
      channelId: channelId,
    },
  })
}
const closePopup = () => {
  const popUpstate = store.getState().utilStore.popup
  if (popUpstate.isShow) {
    store.dispatch({
      type: 'utils/setPopup',
      payload: {
        isShow: false,
      },
    })
  }
}
const openModal = ({ content }: PortalModalTypes) => {
  store.dispatch({
    type: 'utils/setModal',
    payload: {
      content,
      isShow: true,
    },
  })
}
const closeModal = () => {
  store.dispatch({
    type: 'utils/setModal',
    payload: {
      isShow: false,
    },
  })
}

let toastTimer: NodeJS.Timeout
const openToast = (message: string) => {
  store.dispatch({
    type: 'utils/setSendToast',
    payload: {
      isShow: true,
      content: message || '',
    },
  })
  if (toastTimer) {
    clearTimeout(toastTimer)
  }
  toastTimer = setTimeout(() => {
    store.dispatch({
      type: 'utils/setSendToast',
      payload: {
        isShow: false,
      },
    })
  }, 3000)
}

export const AutoReplyFirstSubmit = () => {
  if(!getLocalStorage(AUTO_REPLY_INTRO)){
    setLocalStorage({ key: AUTO_REPLY_INTRO, value: true })
  }
}

export { openLoader, closeLoader, openToast, openPopup, closePopup, openModal, closeModal }
