import { EmptyWrapper } from './emptyStyle'
import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'

const BlockUser = () => {
  const isShow = useSelector((state: StoreState) => state.utilStore.isBlocked)
  return isShow ? (
    <EmptyWrapper>
      <div className="image-wrapper">
        <img className="empty-image" src="/image/empty_network.png" alt="blocked user" />
        <div className="empty-text-wrapper">
          <div className="text-title">이용정지 되어 웹에서 당근 채팅을 이용할 수 없습니다.</div>
          <div className="text-body">비매너 평가를 받은 자세한 이유가 궁금하다면, 모바일 앱에서 받은 비매너를 확인할 수 있어요.</div>
        </div>
      </div>
    </EmptyWrapper>
  ) : null
}

export default BlockUser
