import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { Provider } from 'react-redux'
import { store } from './store'
import { IS_PROD, SENTRY_DSN } from 'constants/config'
import packageJson from '../package.json'

if (IS_PROD) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `rocket-chat-web-spa@${packageJson.version}`,
    whitelistUrls: [/chat\.daangn\.com/],
    ignoreErrors: ['NetFunnel', 'Network Error', 'Loading chunk', 'Request failed', 'NotAllowedError'],
    sampleRate: 1,
  })
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>,
  )
}

declare global {
  interface Window {
    gtag: any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

reportWebVitals()
