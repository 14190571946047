import { socketStore } from 'utils/socket/socketStore'
import { unreadCountRequest, channelUpdate } from 'utils/protobuf/query'

// export const muteControl = (channelId: string, mute: boolean) => {
//   const socket = socketStore.chatSocket
//   if (socket?.readyState === 1) {
//     socket.send(channelUpdate({ channelId: channelId, isMute: mute }).serializeBinary())
//   }
// }

export const favoriteControl = (channelId: string, favorite: boolean) => {
  const socket = socketStore.chatSocket
  if (socket?.readyState === 1) {
    socket.send(channelUpdate({ channelId: channelId, isFavorite: favorite }).serializeBinary())
  }
}

export const binarySend = (binary: Uint8Array) => {
  const socket = socketStore.chatSocket
  if (socket?.readyState === 1) {
    socket.send(binary)
  }
}

export const unreadCountSend = () => {
  const socket = socketStore.chatSocket
  if (socket?.readyState === 1) {
    socket.send(unreadCountRequest().serializeBinary())
  }
}
