import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { StoreState } from 'store/ducks'
import { deleteAutoReply, getAutoReplyList, updateReplyMeta } from 'utils/protobuf/query'
import { socketStore } from 'utils/socket/socketStore'
import { openToast } from 'utils/localDispatcher'
import { AddPlusRegular, TrashRegular } from 'utils/SVGIcons'
import { replySchema } from 'types/PersistentTypes'

import KarrotButton from 'components/common/KarrotButton'
import SwitchCheckbox from 'components/common/SwitchCheckbox'
import { ReplyBody, ReplyFooter } from './chatReplyStyle'
import Modal from 'components/common/Modal'
import ReplyDeletePopup from './ReplyDeletePopup'
import { gtagAutoReplyWrapper } from 'utils/eventWrapper'

const flagObj = {
  userAction: false,
}
interface ChatReplyListProps {
  handleRouter: (schema: replySchema) => void
  setSelectedReply: React.Dispatch<React.SetStateAction<string>>
}
const MAX_AUTO_REPLY = 30

const ChatReplyList = ({ handleRouter, setSelectedReply }: ChatReplyListProps) => {
  const autoReplyList = useSelector((state: StoreState) => state.utilStore.autoReplyList)
  const socket = socketStore.chatSocket
  useEffect(() => {
    if (socket?.readyState === 1) {
      const message = getAutoReplyList().serializeBinary()
      socket.send(message)
    }
  }, [socket])

  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const closeModal = useCallback(() => {
    setModalObj((origin) => ({ ...origin, isShow: false }))
  }, [])
  const deleteReplyRequest = useCallback(
    (replyId: string) => {
      if (socket?.readyState === 1) {
        const message = deleteAutoReply(replyId).serializeBinary()
        socket.send(message)
      }
    },
    [socket],
  )
  const handleDelete = (e: React.SyntheticEvent, replyId: string) => {
    e.stopPropagation()
    const deleteReplyWrapper = () => {
      deleteReplyRequest(replyId)
      closeModal()
    }
    setModalObj({
      isShow: true,
      content: <ReplyDeletePopup closeModal={closeModal} deleteReply={deleteReplyWrapper} />,
    })
  }
  const handleSelectReply = (replyId: string) => {
    setSelectedReply(replyId)
    handleRouter('update')
  }

  const autoReplyActive = useSelector((state: StoreState) => state.utilStore.autoReplyMeta.active)
  const handleAutoReply = () => {
    if (socket?.readyState === 1) {
      flagObj.userAction = true
      const message = updateReplyMeta({ active: !autoReplyActive }).serializeBinary()
      socket.send(message)
    }
  }
  useEffect(() => {
    if (flagObj.userAction) {
      if (autoReplyActive) {
        openToast(`자동응답을 활성화했어요.`)
      } else {
        openToast(`자동응답을 비활성화했어요.`)
      }
      flagObj.userAction = false
    }
  }, [autoReplyActive])

  useEffect(() => {
    gtagAutoReplyWrapper('show_chat_autoreply_list', {})
  }, [])
  return (
    <>
      <div className="reply-header">
        <span>자동응답 목록</span>
        <div className="reply-subtitle">{`최대 ${MAX_AUTO_REPLY}개까지 등록할 수 있어요.`}</div>
      </div>
      <ReplyBody>
        {autoReplyList.length > 0 ? (
          autoReplyList.map((replyItem) => (
            <div className="reply-row" key={replyItem.id} onClick={() => handleSelectReply(replyItem.id)}>
              <div>{replyItem.question?.text}</div>
              <span onClick={(e) => handleDelete(e, replyItem.id)}>
                <TrashRegular />
              </span>
            </div>
          ))
        ) : (
          <div className="reply-empty">
            <div className="empty-header">등록한 자동응답이 없어요.</div>
            <div className="empty-subheader">반복되는 문의는 자동응답으로 등록해 보세요.</div>
          </div>
        )}
      </ReplyBody>
      <ReplyFooter>
        <SwitchCheckbox
          checked={autoReplyActive}
          setChecked={handleAutoReply}
          direction="right"
          className="checkbox-label"
        >
          <span className="checkbox-text">자동응답 활성화</span>
        </SwitchCheckbox>
        {autoReplyList.length >= MAX_AUTO_REPLY ? (
          <KarrotButton className="disabled">
            <AddPlusRegular />
            <span className="with-icon-button-text">자동응답 등록하기</span>
          </KarrotButton>
        ) : (
          <KarrotButton variant="primary" onClick={() => handleRouter('submit')}>
            <AddPlusRegular />
            <span className="with-icon-button-text">자동응답 등록하기</span>
          </KarrotButton>
        )}
      </ReplyFooter>
      <Modal isVisible={modalObj.isShow} content={modalObj.content} onClose={closeModal} />
    </>
  )
}

export default ChatReplyList
