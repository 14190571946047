import { memo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'
import { SidebarHeader } from './ChannelListStyle'

const ChannelListHeader = () => {
  const nickname = useSelector((state: StoreState) => state.authStore.nickname)
  return (
    <SidebarHeader>
      <div className="nickname-area">{nickname}</div>
    </SidebarHeader>
  )
}

export default memo(ChannelListHeader)
