import { IS_PROD } from 'constants/config'
import { autoReplyItem } from 'types/PersistentTypes'

interface PushStoreProps {
  channelId: string
  title: string
  body: string
  icon: string
  messageId: number
  set: Function
}
export const lastPushInfo: PushStoreProps = {
  channelId: '',
  title: '',
  body: '',
  icon: '',
  messageId: 0,
  set: (title: string, body: string, icon: string, messageId: number, channelId: string) => {
    lastPushInfo.title = title
    lastPushInfo.body = body
    lastPushInfo.icon = icon
    lastPushInfo.messageId = messageId
    lastPushInfo.channelId = channelId
  },
}

const LOCAL_MUTE_CHANNEL_LIMIT = IS_PROD ? 2000 : 5
interface muteStoreData {
  [key: string]: number
}
export const muteStore = {
  dataList: {} as muteStoreData,
  set: (channelId: string) => {
    const channelArr = Object.keys(muteStore.dataList)
    if (channelArr.length >= LOCAL_MUTE_CHANNEL_LIMIT) {
      const minimalKey = Object.keys(muteStore.dataList).reduce((a, b) =>
        muteStore.dataList[a] < muteStore.dataList[b] ? a : b,
      )
      delete muteStore.dataList[minimalKey]
    }
    muteStore.dataList[channelId] = new Date().getTime()
  },
  delete: (channelId: string) => {
    delete muteStore.dataList[channelId]
  },
  init: (data: muteStoreData) => {
    muteStore.dataList = data
  },
}

// 모든 자동 응답 질문을 갖고 있으면서, 새로운 메시지가 왔을 때 message.silence 및 기존에 갖고 있던 메시지와 비교해 노티를 띄울지 결정하기 위한 스토어
export const autoReplyStore = {
  dataList: {} as {
    [key: string]: autoReplyItem[]
  },
  set: (bizAccountId: number, value: autoReplyItem[]) => {
    autoReplyStore.dataList[bizAccountId] = [...value]
  },
}

export const lastInsertBox = {
  dataList: {} as {
    [key: string]: string
  },
  set: (channelId: string, value: string) => {
    lastInsertBox.dataList[channelId] = value
  },
  remove: (channelId: string) => {
    delete lastInsertBox.dataList[channelId]
  },
}
