import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { StoreState } from 'store/ducks'
import Modal from 'components/common/Modal'
import { UpArrowIcon, DownArrowIcon } from 'utils/SVGIcons'
import { ProfileParamConverter } from 'utils/snippet'
import LogoutModalComp from './LogoutModalComp'
import { UserHeaderWrapper } from './UserAreaStyle'
import {
  setLocalStorage,
  getLocalStorage,
  NORMAL_MESSAGE_NOTIFICATION,
  BIZ_PROFILE_MESSAGE_NOTIFICATION,
} from 'utils/localStorage'
import { openToast } from 'utils/localDispatcher'
import SwitchCheckbox from 'components/common/SwitchCheckbox'

const ChannelListHeader = () => {
  const user = useSelector((state: StoreState) => state.authStore?.user)
  const userProfile = useSelector((state: StoreState) => state.authStore.profileImage)
  const bizAccount = useSelector((state: StoreState) => state.authStore.bizAccount)
  const [showMenu, setShowMenu] = useState(false)
  const closeMenu = () => setShowMenu(false)
  const toggleMenu = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setShowMenu((origin) => !origin)
  }
  useEffect(() => {
    if (showMenu) {
      window.addEventListener('click', closeMenu)
    }
    return () => window.removeEventListener('click', closeMenu)
  }, [showMenu])
  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const closeModal = () => setModalObj((origin) => ({ ...origin, isShow: false }))
  const handleLogout = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setModalObj({
      isShow: true,
      content: <LogoutModalComp closeModal={closeModal} />,
    })
  }

  const [normalMessage, setNormalMessage] = useState<boolean>(getLocalStorage(NORMAL_MESSAGE_NOTIFICATION))
  const handleNormalMessage = () => {
    if (normalMessage) {
      openToast(`채팅 알림을 받지 않아요.`)
      setLocalStorage({ key: NORMAL_MESSAGE_NOTIFICATION, value: false })
      setNormalMessage(false)
    } else {
      openToast(`채팅 알림을 받아요.`)
      setLocalStorage({ key: NORMAL_MESSAGE_NOTIFICATION, value: true })
      setNormalMessage(true)
    }
  }
  const [bizProfileMessgae, setBizProfileMessgae] = useState<boolean>(getLocalStorage(BIZ_PROFILE_MESSAGE_NOTIFICATION))
  const handleBizProfileMessgae = () => {
    if (bizProfileMessgae) {
      openToast(`채팅 알림을 받지 않아요.`)
      setLocalStorage({ key: BIZ_PROFILE_MESSAGE_NOTIFICATION, value: false })
      setBizProfileMessgae(false)
    } else {
      openToast(`채팅 알림을 받아요.`)
      setLocalStorage({ key: BIZ_PROFILE_MESSAGE_NOTIFICATION, value: true })
      setBizProfileMessgae(true)
    }
  }

  const userName = user?.nick || user?.nickname
  return userName ? (
    <>
      <UserHeaderWrapper onClick={toggleMenu} className={`common-bg-hover ${showMenu ? 'selected' : ''}`}>
        <img className="menu-profile-img" src={ProfileParamConverter(userProfile)} alt={userName} />
        <div className="nickname-area">
          {userName}
          <span className="menu-icon">{showMenu ? <UpArrowIcon /> : <DownArrowIcon />}</span>
        </div>
        {showMenu ? (
          <div className="menu-container" onClick={(e) => e.stopPropagation()}>
            {bizAccount.length > 0 ? (
              <div className="menu-list-container">
                <div className="menu-title">알림 설정</div>
                <SwitchCheckbox
                  checked={normalMessage}
                  setChecked={handleNormalMessage}
                  className={'common-bg-hover menu-item'}
                >
                  <span>일반 채팅</span>
                </SwitchCheckbox>
                <SwitchCheckbox
                  checked={bizProfileMessgae}
                  setChecked={handleBizProfileMessgae}
                  className={'common-bg-hover menu-item'}
                >
                  <span>비즈니스 채팅</span>
                </SwitchCheckbox>
              </div>
            ) : (
              <div className="menu-list-container">
                <SwitchCheckbox
                  checked={normalMessage}
                  setChecked={handleNormalMessage}
                  className={'common-bg-hover menu-item single-menu'}
                >
                  <span>채팅 알림</span>
                </SwitchCheckbox>
              </div>
            )}

            <div className="last-menu">
              <div tabIndex={0} className="common-bg-hover menu-item logout-menu" onClick={handleLogout}>
                로그아웃
              </div>
            </div>
          </div>
        ) : null}
      </UserHeaderWrapper>
      <Modal isVisible={modalObj.isShow} onClose={closeModal} content={modalObj.content} />
    </>
  ) : null
}

export default ChannelListHeader
