import { CommonPopup } from 'components/common/commonStyle'
import KarrotButton from 'components/common/KarrotButton'

interface ReplyDeletePopupProps {
  closeModal: () => void
  deleteReply: () => void
}
const ReplyDeletePopup = ({ closeModal, deleteReply }: ReplyDeletePopupProps) => {
  return (
    <CommonPopup>
      <div className="popup-header">자동응답을 삭제할까요?</div>
      <div className="popup-body">삭제하면 복구할 수 없어요.</div>
      <div className="popup-footer">
        <KarrotButton className="footer-button" onClick={closeModal}>
          취소
        </KarrotButton>
        <KarrotButton className="footer-button" onClick={deleteReply} variant="primary">
          삭제
        </KarrotButton>
      </div>
    </CommonPopup>
  )
}

export default ReplyDeletePopup
