import KarrotButton from 'components/common/KarrotButton'
import React, { useEffect, useState } from 'react'
import { autoReplyFormProps, replySchema } from 'types/PersistentTypes'
import { socketStore } from 'utils/socket/socketStore'
import { ReplyBody, ReplyFooter } from './chatReplyStyle'

import ChatReplyPreview from './ChatReplyPreview'
import { updateAutoReply } from 'utils/protobuf/query'
import { store } from 'store'
import { CameraRegular, CloseCircle, InfoIcon } from 'utils/SVGIcons'
import { imageUploadReqest } from 'utils/httpAPI'

const ANSWER_LIMIT = 500

interface ChatReplyUpdateProps {
  selectedReply: string
  handleRouter: (schema: replySchema) => void
  modalClose: () => void
  setModalObj: React.Dispatch<React.SetStateAction<{ isShow: boolean; content: JSX.Element }>>
}
const ChatReplyUpdate = ({ selectedReply, handleRouter, modalClose, setModalObj }: ChatReplyUpdateProps) => {
  const socket = socketStore.chatSocket
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (socket?.readyState === 1) {
      const message = updateAutoReply(
        selectedReply,
        autoReplyState.question,
        autoReplyState.answer,
        autoReplyState.imageObj.id ? autoReplyState.imageObj : false,
      ).serializeBinary()
      socket.send(message)
      handleRouter('list')
    }
  }
  const [autoReplyState, setAutoReplyState] = useState<autoReplyFormProps>({
    question: '',
    answer: '',
    imageObj: { id: '', url: '', width: 0, height: 0 },
  })
  const [emptyInputState, setEmptyInputState] = useState({
    question: false,
    answer: false,
  })
  useEffect(() => {
    const replyList = store.getState().utilStore.autoReplyList
    const replyItem = replyList.find((item) => item.id === selectedReply)
    if (replyItem) {
      setAutoReplyState({
        question: replyItem.question?.text || '',
        answer: replyItem.answer?.text || '',
        imageObj: replyItem.answer?.imagesList[0] || { id: '', url: '', width: 0, height: 0 },
      })
    }
  }, [selectedReply])
  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setAutoReplyState((origin) => ({ ...origin, question: value }))
    setEmptyInputState((origin) => ({ ...origin, question: value.length === 0 }))
    setUpdateInit(true)
  }
  const handleAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setAutoReplyState((origin) => ({ ...origin, answer: value }))
    setEmptyInputState((origin) => ({ ...origin, answer: value.length === 0 }))
    setUpdateInit(true)
  }

  const handlePreview = () => {
    setModalObj({
      isShow: true,
      content: (
        <ChatReplyPreview
          question={autoReplyState.question}
          answer={autoReplyState.answer}
          imageObj={autoReplyState.imageObj}
          modalClose={modalClose}
        />
      ),
    })
  }
  const handleInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.target.files
    if (files) {
      const channelId = store.getState().chatStore.selectChannelId
      const userData = store.getState().authStore.user
      imageUploadReqest({ fileItem: files[0], userData, channelId: `${channelId}` }).then((res) => {
        if (res) {
          setAutoReplyState((origin) => ({
            ...origin,
            imageObj: {
              id: res.id,
              url: res.url,
              width: res.width,
              height: res.height,
            },
          }))
        }
      })
      e.target.value = ''
      setUpdateInit(true)
    }
  }
  const handleDeleteImage = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setAutoReplyState((origin) => ({ ...origin, imageObj: { id: '', url: '', width: 0, height: 0 } }))
    setUpdateInit(true)
  }
  const [updateInit, setUpdateInit] = useState(false)
  const editable =
    updateInit &&
    autoReplyState.question.replace(/\s/gi, '').length > 0 &&
    autoReplyState.question.length <= 20 &&
    autoReplyState.answer.replace(/\s/gi, '').length > 0 &&
    autoReplyState.answer.length <= ANSWER_LIMIT
  return (
    <>
      <div className="reply-header">자동응답 수정</div>
      <ReplyBody>
        <div className="reply-body-wrapper">
          <label className="reply-label-wrapper">
            <span className="reply-label-text">질문</span>
            <input
              className={`reply-input ${autoReplyState.question.length > 20 ? 'invalid-input' : ''}`}
              type="text"
              placeholder="자주 묻는 질문 또는 제목을 입력해 주세요."
              value={autoReplyState.question}
              onChange={handleQuestionChange}
            />
            {autoReplyState.question.length > 20 ? (
              <div className="invalid-row">
                <InfoIcon size={14} />
                <span>최대 20자까지 입력할 수 있어요.</span>
              </div>
            ) : emptyInputState.question ? (
              <div className="invalid-row">
                <InfoIcon size={14} />
                <span>내용을 입력해 주세요.</span>
              </div>
            ) : null}
          </label>
          <label className="reply-label-wrapper">
            <span className="reply-label-text">답장</span>
            <textarea
              className={`reply-textarea ${autoReplyState.answer.length > ANSWER_LIMIT ? 'invalid-textarea' : ''}`}
              placeholder="질문에 대한 답이나 안내할 내용을 써 주세요."
              value={autoReplyState.answer}
              onChange={handleAnswerChange}
            ></textarea>
            <span className="reply-text-limit">{`${autoReplyState.answer.length}/${ANSWER_LIMIT}`}</span>
            {autoReplyState.answer.length > ANSWER_LIMIT ? (
              <div className="invalid-row">
                <InfoIcon size={14} />
                <span>{`최대 ${ANSWER_LIMIT}자까지 입력할 수 있어요.`}</span>
              </div>
            ) : emptyInputState.answer ? (
              <div className="invalid-row">
                <InfoIcon size={14} />
                <span>내용을 입력해 주세요.</span>
              </div>
            ) : null}
          </label>
          <div className="reply-label-wrapper">
            <span className="reply-label-text">답장 내 이미지</span>
            <label className="reply-file-wrapper">
              {autoReplyState.imageObj.url ? (
                <>
                  <img className="reply-input-image" src={autoReplyState.imageObj.url} alt="" />
                  <span className="remove-icon-wrapper" onClick={handleDeleteImage}>
                    <CloseCircle />
                  </span>
                </>
              ) : (
                <>
                  <CameraRegular />
                  <span className="reply-file-text">0/1</span>
                </>
              )}
              <input
                className="reply-input-file"
                type="file"
                onChange={handleInputFile}
                accept="image/png, image/jpeg, image/gif"
              />
            </label>
          </div>
        </div>
      </ReplyBody>
      <ReplyFooter>
        <KarrotButton onClick={handlePreview}>미리보기</KarrotButton>
        <div>
          <KarrotButton onClick={() => handleRouter('list')}>취소</KarrotButton>
          {editable ? (
            <KarrotButton className="reply-last-button" variant="primary" onClick={handleSubmit}>
              수정완료
            </KarrotButton>
          ) : (
            <KarrotButton className="reply-last-button disable-button" aria-disabled="true">
              수정완료
            </KarrotButton>
          )}
        </div>
      </ReplyFooter>
    </>
  )
}

export default ChatReplyUpdate
