import { NoticeTypes } from 'types/ChatTypes'
import { NoticeWrapper } from './ChatHeaderStyle'
import InlineTemplate from 'components/chatMain/chatMessage/InlineTemplate'

interface ChatHeaderNoticeProps {
  notice: NoticeTypes
}
const ChatHeaderNotice = ({ notice }: ChatHeaderNoticeProps) => {
  if (notice.type === 'warning') {
    return (
      <NoticeWrapper>
        <div className="warning">
          <InlineTemplate richText={notice.message} />
        </div>
      </NoticeWrapper>
    )
  } else {
    return null
  }
}

export default ChatHeaderNotice
