import { SwitchCheckboxWrapper } from './commonStyle'

interface SwitchCheckboxProps {
  checked: boolean
  setChecked: () => void
  className?: string
  children: React.ReactNode
  direction?: 'left' | 'right'
}
const SwitchCheckbox = ({ checked, setChecked, className, children, direction }: SwitchCheckboxProps) => {
  return (
    <label className={className} tabIndex={0}>
      {!direction || direction === 'left' ? children : null}
      <SwitchCheckboxWrapper className={checked ? 'chekc-true' : ''}>
        <input className="input-chekcbox" type="checkbox" checked={checked} onChange={setChecked} />
      </SwitchCheckboxWrapper>
      {direction === 'right' ? children : null}
    </label>
  )
}

export default SwitchCheckbox
