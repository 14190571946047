import { FooterWrapper } from './footerStyle'
import FooterBanner from './FooterBanner'

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterBanner />
    </FooterWrapper>
  )
}

export default Footer
