import { useDispatch } from 'react-redux'

import { OptionModalStyle } from './ChatOptionStyle'
import { ButtonInModal } from 'components/common/commonStyle'

import { LeaveChannelQuery } from 'utils/protobuf/query'
import { socketStore } from 'utils/socket/socketStore'
import { getProfileRootPath } from 'utils/snippet'
import { closePopup } from 'utils/localDispatcher'
import { useNavigateRef } from 'hooks/useNavigateRef'

interface ExitChannelProps {
  closeModal: Function
  channelId: string
}
const ExitChannel = ({ closeModal, channelId }: ExitChannelProps) => {
  const socket = socketStore.chatSocket
  const navigate = useNavigateRef()
  const dispatch = useDispatch()
  const handleLeaveChannel = () => {
    if (socket?.readyState === 1) {
      socket.send(
        LeaveChannelQuery({
          channelId: channelId,
        }).serializeBinary(),
      )
      closePopup()
      dispatch({
        type: 'chat/leaveChannel',
        payload: { channelId: channelId },
      })
      closeModal()
      const targetPath = getProfileRootPath()
      navigate.current(targetPath)
    }
  }
  const handleClose = () => closeModal()
  return (
    <OptionModalStyle>
      <div className="content">채팅방을 나가면 채팅 내역을 더이상 확인할 수 없어요. 정말 나가시겠어요?</div>
      <div className="button-wrapper">
        <ButtonInModal className="common-bg-hover" onClick={handleClose}>
          취소
        </ButtonInModal>
        <ButtonInModal className="confirm" onClick={handleLeaveChannel}>
          확인
        </ButtonInModal>
      </div>
    </OptionModalStyle>
  )
}

export default ExitChannel
