import { memo } from 'react'
import { PreviewText } from './ChannelListStyle'
import { AlarmPreventIcon, OfficialCertIcon } from 'utils/SVGIcons'
import { agoFormatDate } from 'utils/dateParser'

interface ChannelMessageProps {
  lastMessageId: number
  nickname: string
  memberCount?: number
  lastMessage: string
  displayRegion?: string
  lastChatTime: number
  isPromise?: boolean
  isReserve?: boolean
  isMute: boolean
  isFavorite: boolean
  isVerified?: boolean
}
const ChannelMessage = ({
  lastMessageId,
  nickname,
  memberCount,
  lastMessage,
  displayRegion,
  lastChatTime,
  isPromise,
  isReserve,
  isMute,
  isFavorite,
  isVerified,
}: ChannelMessageProps) => (
  <PreviewText>
    <div className="preview-title-wrap">
      <span className="preview-nickname">{nickname}</span>
      {isVerified ? (
        <span className="badge-wrapper">
          <OfficialCertIcon />
        </span>
      ) : null}
      {memberCount ? <span className="member-count">{memberCount}</span> : null}
      <div className="sub-text">
        {displayRegion && !isVerified ? (
          <>
            <span>{displayRegion}</span>
            <span> &middot; </span>
          </>
        ) : null}
        <span>{agoFormatDate(lastChatTime)}</span>
      </div>
    </div>
    <div className="preview-description">
      {isPromise ? <span className="promise-tag">약속</span> : null}
      {isReserve ? <span className="reserve-tag">예약</span> : null}
      {isMute ? (
        <span className="mute-wrapper">
          <AlarmPreventIcon />
        </span>
      ) : null}
      <span className="description-text">{lastMessage}</span>
    </div>
  </PreviewText>
)

function areEqual(prevProps: ChannelMessageProps, nextProps: ChannelMessageProps) {
  return (
    prevProps.lastMessageId === nextProps.lastMessageId &&
    prevProps.nickname === nextProps.nickname &&
    prevProps.isMute === nextProps.isMute &&
    prevProps.isFavorite === nextProps.isFavorite &&
    prevProps.lastMessage === nextProps.lastMessage
  )
}

export default memo(ChannelMessage, areEqual)
