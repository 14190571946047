import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { StoreState } from 'store/ducks'

//#region styled
const StyledBouncing = styled.div`
  color: #d1d3d8;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  margin-top: -5px;

  &,
  &::before,
  &::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: spinner-animation 1.8s infinite ease-in-out;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
  }
  &::before {
    left: -3.5em;
    animation-delay: -0.16s;
  }
  &::after {
    left: 3.5em;
    animation-delay: 0.16s;
  }
  @keyframes spinner-animation {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`

const WrapperStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--seed-semantic-color-paper-default);
  z-index: 10;
`
//#endregion styled

const LoadingSpinner = () => {
  const isShow = useSelector((state: StoreState) => state.utilStore.showLoader)
  return isShow ? (
    <WrapperStyled>
      <StyledBouncing>
        <div className="loading-item"></div>
        <div className="loading-item"></div>
        <div className="loading-item"></div>
      </StyledBouncing>
    </WrapperStyled>
  ) : null
}

// https://loading.io/css/
// https://tobiasahlin.com/spinkit/
export default LoadingSpinner
