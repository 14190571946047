import { IS_PROD } from './config'

export const SAMPLE_PROFILE_IMAGE = 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png'
export const SAMPLE_BIZ_PROFILE_IMAGE = 'https://dnvefa72aowie.cloudfront.net/origin/profile/biz_profile_default.png'

export const SOCKET_RETRY_DEFAULT_TIME = 1000
export const SOCKET_RETRY_MAX_TIME = 1000 * 10
export const TIMESTAMP_DIGIT = 1e9

export const SIDEBAR_WIDTH = 72
export const APP_PADDING_TOP = '64px'

export const FAQ_URL = 'https://www.daangn.com/wv/faqs?kind=karrotchat'

export const HEADER_HEIGHT = '64px'
export const FOOTER_HEIGHT = '44px'
export const MAIN_WIDTH = '1200px'

export const DAANGN_BUSINESS_URL = IS_PROD
  ? `https://business.daangn.com/profile/login`
  : 'https://business.alpha.daangn.com/profile/login'


export const getCustomerUrl = ({ baId, receiverId }: { baId: number; receiverId: number }) => {
  return `https://business${IS_PROD ? '' : '.alpha'}.daangn.com/profile/${baId}/customer/${receiverId}`
}
