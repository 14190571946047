import { MessageTypes } from 'types/ChatTypes'
import { gtagChannelWrapper } from 'utils/eventUtils'
import { TempMessageWrapper, IconTemplate } from './ChatMessageStyle'
import InlineTemplate from './InlineTemplate'

interface TemplateMessageProps {
  messageItem: MessageTypes
}
const TemplateMessage = ({ messageItem }: TemplateMessageProps) => {
  if (messageItem.templateType === 2) {
    // 당근이
    const content = messageItem.genericTemplate
    // IMAGE_ASPECT_RATIO_UNSPECIFIED = 0, IMAGE_ASPECT_RATIO_HORIZONTAL = 1, IMAGE_ASPECT_RATIO_SQUARE = 2,
    const aspectRatio = content?.imageAspectRatio
    return (
      <TempMessageWrapper>
        {content?.imageUrl ? (
          <img
            className={`temp-image ${aspectRatio === 2 ? 'aspect-ratio-suqre' : ''}`}
            src={content.imageUrl}
            alt={content.text}
          />
        ) : null}
        <div className="temp-message-wrap">
          <div className="thumbnail-wrap">
            {content?.startThumbnailUrl ? (
              <img className="thumbnail-image start-thumbnail" src={content.startThumbnailUrl} alt={content.text} />
            ) : null}
            <div className="content-wrapper">
              {content?.title ? <div className="content-title">{content.title}</div> : null}
              {content?.text ? (
                <div className="content-text">
                  <InlineTemplate richText={content?.text || ''} />
                </div>
              ) : null}
            </div>
            {content?.endThumbnailUrl ? (
              <img className="thumbnail-image end-thumbnail" src={content.endThumbnailUrl} alt={content.text} />
            ) : null}
          </div>
          <div>
            {content?.buttonsList.map((_, idx) => (
              <div key={idx} className="temp-button">
                모바일에서 확인해주세요.
              </div>
            ))}
          </div>
        </div>
      </TempMessageWrapper>
    )
  } else if (messageItem.templateType === 3) {
    const content = messageItem.locationTemplate
    const sendLocationShareIndex = () => {
      gtagChannelWrapper('share_location', messageItem.type, messageItem.id)
    }
    return (
      <TempMessageWrapper>
        <img className="temp-image" src={content?.imageUrl} alt="shared location" />
        <a
          href={`https://www.google.co.kr/maps/search/${content?.latitude},${content?.longitude}/17z?hl=ko`}
          target="_blank"
          rel="noreferrer"
          className="temp-message-wrap"
          onClick={sendLocationShareIndex}
        >
          <div className="temp-button">위치 보기</div>
        </a>
      </TempMessageWrapper>
    )
  } else if (messageItem.templateType === 4) {
    const content = messageItem.iconTemplate
    return (
      <IconTemplate>
        <img className="icon-image" src={content?.imageUrl} alt="call" />
        <div>{content?.text}</div>
      </IconTemplate>
    )
  } else if (messageItem.templateType === 5) {
    const content = messageItem.inlineTemplate
    return <InlineTemplate richText={content?.richText || ''} />
  } else {
    return (
      <TempMessageWrapper className="no-support-message">
        <div className="message-box">지원하지 않는 메시지입니다.</div>
      </TempMessageWrapper>
    )
  }
}

export default TemplateMessage
