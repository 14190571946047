import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { handleSendMessageProps } from 'types/ChatTypes'
import { imgTypeCheck } from 'types/typeChecker'
import { StoreState } from 'store/ducks'
import { sendMessageWrapper, convertImgList } from 'utils/protobuf/query'
import { sendMessageProps } from 'types/ChatTypes'
import { imageUploadReqest } from 'utils/httpAPI'
// import { FileUploadBack } from './ChatSendStyle'
import { socketStore } from 'utils/socket/socketStore'
import { messageCheck } from 'utils/snippet'

import ChatMessageList from './ChatMessageList'
import ChatSend from './chatSend/ChatSend'
import Modal from 'components/common/Modal'
import BadWordsPopup from './chatSend/BadWordsPopup'
import { openToast } from 'utils/localDispatcher'
import { gtagInputBoxWrapper } from 'utils/eventWrapper'
interface ChatNormalRoomProps {
  channelId: string
}
const ChatNormalRoom = ({ channelId }: ChatNormalRoomProps) => {
  const socket = socketStore.chatSocket
  const userData = useSelector((state: StoreState) => state.authStore.user)
  const targetRoom = useSelector((state: StoreState) => state.chatStore.selectChannelobj)
  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const closeModal = () => setModalObj((origin) => ({ ...origin, isShow: false }))
  const handleSendMessage = useCallback(
    ({ message, pictureId, imageUrl, type, imageList, setMessage, sendInput }: handleSendMessageProps) => {
      if (socket?.readyState === 1) {
        const messagePacket: sendMessageProps = {
          channelId: `${channelId}`,
          sender: targetRoom?.me,
          receiver: targetRoom?.receiver,
          type: type,
          channelType: targetRoom?.type || 1,
        }
        if (imgTypeCheck(type)) {
          if (type === 'GALL') {
            messagePacket.imageList = imageList
          } else {
            messagePacket.pictureId = pictureId
            messagePacket.imageUrl = imageUrl
          }
          messagePacket.messageText = message
          const binary = sendMessageWrapper(messagePacket).serializeBinary()
          socket.send(binary)
        } else {
          const badList = messageCheck(`${message}`)
          if (badList.length > 0) {
            messagePacket.messageText = message
            const binary = sendMessageWrapper(messagePacket).serializeBinary()
            setModalObj((_) => ({
              isShow: true,
              content: (
                <BadWordsPopup closeModal={closeModal} binary={binary} setMessage={setMessage} sendInput={sendInput} />
              ),
            }))
          } else {
            messagePacket.messageText = message
            const binary = sendMessageWrapper(messagePacket).serializeBinary()
            socket.send(binary)
            setMessage && setMessage('')
          }
        }
      }
    },
    [channelId, socket, targetRoom],
  )
  // file send wrapper
  const fileSendExcuter = useCallback(
    (files: FileList) => {
      if (files?.length > 0) {
        if (files.length > 1) {
          const uploadList = []
          for (let i = 0; i < files.length; i++) {
            uploadList.push(imageUploadReqest({ fileItem: files[i], userData, channelId: `${channelId}` }))
          }
          Promise.all(uploadList).then((res) => {
            handleSendMessage({ type: 'GALL', imageList: convertImgList(res) })
          })
        } else {
          imageUploadReqest({ fileItem: files[0], userData, channelId: `${channelId}` }).then((res) => {
            if (res) {
              handleSendMessage({ pictureId: res.id, type: 'IMAG', imageUrl: res.upload_url })
            }
          })
        }
      }
    },
    [userData, channelId, handleSendMessage],
  )

  // DnD
  // const [dragging, setDragging] = useState(false)
  // const [dragCount, setDragCount] = useState(0)
  // const dragInner = (e: React.DragEvent) => {
  //   e.preventDefault()
  //   setDragCount(1)
  //   if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
  //     setDragging(true)
  //   }
  // }
  // const dragOuter = (e: React.DragEvent) => {
  //   e.preventDefault()
  //   setDragCount(0)
  //   if (dragCount === 0) {
  //     setDragging(false)
  //   }
  // }
  // const fileDrop = useCallback(
  //   (e: React.DragEvent) => {
  //     e.preventDefault()
  //     setDragCount(0)
  //     const targetFiles = e.dataTransfer.files
  //     fileSendExcuter(targetFiles)
  //     setDragging(false)
  //   },
  //   [fileSendExcuter],
  // )
  const handleInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.target.files
    if (files) {
      if (files.length > 10) {
        openToast('이미지는 최대 10개까지 전송할 수 있어요.')
      } else {
        fileSendExcuter(files)
      }
    }
    e.target.value = ''
    gtagInputBoxWrapper('picture')
  }
  return (
    <div
      className="chat-normal-room"
      // onDrop={fileDrop}
      // onDragEnter={dragInner}
      // onDragLeave={dragOuter}
      // onDragOver={(e) => {
      //   e.preventDefault()
      // }}
    >
      {/* {dragging && (
        <FileUploadBack>
          <div>file upload</div>
        </FileUploadBack>
      )} */}
      <ChatMessageList />
      <ChatSend handleSendMessage={handleSendMessage} handleInputFile={handleInputFile} targetRoom={targetRoom} />
      <Modal isVisible={modalObj.isShow} onClose={closeModal} content={modalObj.content} />
    </div>
  )
}

export default ChatNormalRoom
