import dayjs from 'dayjs'
require('dayjs/locale/ko')
dayjs.locale('ko')

export const ENVIRONMENT = process.env.REACT_APP_ENV || ''

export const ROCKET_CHAT_URL = process.env.REACT_APP_ROCKET_CHAT_URL || ''
export const KARROTAUTH_PUBLIC = process.env.REACT_APP_KARROTAUTH_PUBLIC || ''
export const KARROTAUTH_HTTP = process.env.REACT_APP_KARROTAUTH_HTTP || ''
export const BIG_PICTURE_URL = process.env.REACT_APP_BIG_PICTURE_URL || ''
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''
export const XPROPERTY_URL = process.env.REACT_APP_XPROPERTY_URL || ''
export const BUSINESS_AUTH_URL = process.env.REACT_APP_BUSINESS_AUTH_URL || ''
export const BIZ_PLATFORM_CENTER = process.env.REACT_APP_BIZ_PLATFORM_CENTER || ''

export const IS_PROD = ENVIRONMENT === 'production'
export const IS_SERVER = typeof window === 'undefined'
export const DAANGN_WEBAPP_URL = IS_PROD ? `https://daangn.com` : `http://alpha.daangn.com`
export const DAANGN_BUSINESS_URL = IS_PROD ? `https://business.daangn.com` : `https://business.alpha.daangn.com`
export const WEB_CHAT_URL = IS_PROD ? `https://chat.daangn.com` : `https://chat.alpha.daangn.com`

export const AUTH_HEADER_KEY = 'x-auth-token'

export const ONBOARDING_FINISH = 'onboarding'

const ua = navigator.userAgent.toLowerCase()
export const IS_SAFARI = ua.indexOf('safari') !== -1 && !(ua.indexOf('chrome') > -1)
