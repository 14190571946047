import { store } from 'store'
import { openToast } from 'utils/localDispatcher'
import { NOTIFICATION_BANNER, setLocalStorage, getLocalStorage } from 'utils/localStorage'
import { gtagWrapper } from 'utils/eventWrapper'

interface browserPushTypes {
  title: string
  body: string
  icon: string
  data: {
    messageId: number
    channelId: string
  }
}
export const showBrowserPushNotification = ({ title, body, icon, data }: browserPushTypes) => {
  const options = { body, icon, data }
  navigator.serviceWorker.ready.then((registration) => {
    try {
      registration
        .showNotification(title, options)
        .then(() => registration.getNotifications())
        .then((notifications) => {
          setTimeout(() => notifications.forEach((notification) => notification.close()), 5000)
        })
    } catch (e) {}
  })
}

export const requestPushPermission = () => {
  try {
    if (Notification?.permission === 'granted') {
    }
  } catch (e) {}
  // safari 일부 버전에서 requestPermission를 deprecate된 callback 함수로 주면 then이 없다고 에러남
  try {
    Notification?.requestPermission().then((res) => {
      const originState = getLocalStorage(NOTIFICATION_BANNER)
      if (res === 'granted') {
        openToast('알림이 허용되었어요')
        setLocalStorage({ key: NOTIFICATION_BANNER, value: { state: 'third', expire: new Date().getTime() + 1000 } })
      }
      gtagWrapper('event', 'click_pc_chat_noti_permission', { banner: originState?.state, action: res, banner_repeat: originState?.closeCount })
      store.dispatch({ type: 'utils/setPermissionChange' })
    })
  } catch (e) {}
}
