import { getLocalStorage, LAST_ACTION, setLocalStorage } from 'utils/localStorage'
import { getDecodeAccessToken } from 'utils/auth/tokenUtils'
import { IS_PROD } from 'constants/config'
import { ONE_DAY, ONE_WEEK } from 'constants/dateUnit'
import { store } from 'store'

export const lastActionUpdate = () => {
  const currentSec = Math.floor(new Date().getTime() / 1000)
  const accessToken = getDecodeAccessToken()
  if (accessToken && accessToken.exp > currentSec) {
    setLocalStorage({
      key: LAST_ACTION,
      value: new Date().getTime(),
    })
  }
}

export const checkExpireAction = () => {
  const lastAction = getLocalStorage(LAST_ACTION) || 0
  const currentTime = new Date().getTime()
  const diff = currentTime - Number(lastAction)
  // 오로지 토큰 유효기간으로 제어
  return false

  // 토큰의 만료 기한과 무관하게 기획에서 요청했던 클라이언트 제어 세션 만료
  // eslint-disable-next-line
  if (IS_PROD) {
    return diff > ONE_DAY
  } else {
    return diff > ONE_WEEK
  }
}

export const handleVisibilityChange = () => {
  if (!checkExpireAction()) {
    if (document.visibilityState === 'visible') {
      lastActionUpdate()
      if (document.hasFocus()) {
        store.dispatch({ type: 'utils/setVisible' })
      }
    }
  }
}
