import styled from '@emotion/styled'

const ChannelListOptionWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #e8e8e8;
  border-radius: 50%;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  background-color: var(--seed-semantic-color-paper-default);
  color: var(--seed-scale-color-gray-500);
  z-index: 100;
  font-size: 14px;

  .option-controller {
    display: inline-flex;
  }
`

export { ChannelListOptionWrapper }
