import styled from '@emotion/styled'

const UserHeaderWrapper = styled.button`
  position: relative;
  display: flex;
  height: 44px;
  padding: 0 12px;
  align-items: center;
  border-radius: 4px;

  &.selected {
    background-color: var(--seed-scale-color-gray-200);
  }
  .nickname-area {
    color: var(--seed-scale-color-gray-700);
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
  }
  .menu-icon {
    display: inline-flex;
    align-items: center;
    margin-left: 9px;
    vertical-align: middle;
  }
  .menu-profile-img {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid var(--profile-image-border);
  }
  .menu-container {
    position: absolute;
    top: 48px;
    right: 10px;
    width: 220px;
    border: 1px solid var(--seed-scale-color-gray-600);
    border-radius: 6px;
    background: var(--seed-scale-color-gray-50);

    will-change: opacity;
    animation: fadeIn ease-in 0.3s;
    .menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-radius: 6px;
      font-size: 14px;
      color: var(--seed-scale-color-gray-900);
      & ~ .menu-item {
        margin-top: 4px;
      }
      &.single-menu {
        font-size: 16px;
        font-weight: 700;
      }
      &.logout-menu {
        font-size: 16px;
      }
    }
    .menu-list-container {
      padding: 10px;
    }
    .last-menu {
      border-top: 1px solid var(--seed-semantic-color-divider-1);
      padding: 3px 10px 11px 10px;
      font-weight: 700;
    }
    .menu-title {
      display: flex;
      padding: 10px 10px 15px 10px;
      font-size: 16px;
      font-weight: 700;
    }
    .menu-profile-text {
      margin-left: 8px;
      line-height: 150%;
      font-size: 16px;
      font-weight: bold;
      align-items: center;
      color: var(--seed-scale-color-gray-900);
    }
  }
`

const LogoutModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  width: 336px;
  height: 160px;

  font-size: 16px;
  line-height: 150%;

  will-change: opacity;
  animation: fadeIn ease 0.5s;
  .button-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
`

export { UserHeaderWrapper, LogoutModalBody }
