import { EmptyWrapper } from './emptyStyle'

const Maintenance = ({ message }: { message: string }) => {
  return (
    <EmptyWrapper>
      <div className="image-wrapper">
        <img className="empty-image" src="/image/empty_network.png" alt="blocked user" />
        <div className="empty-text-wrapper">
          <div className="text-title">{message}</div>
        </div>
      </div>
    </EmptyWrapper>
  )
}

export default Maintenance
