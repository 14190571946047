import Cookies from 'js-cookie'

import { ChannelTypes } from 'types/ChatTypes'
import { SAMPLE_BIZ_PROFILE_IMAGE, SAMPLE_PROFILE_IMAGE } from 'constants/resource'
import { ONBOARDING_FINISH } from 'constants/config'
import { store } from 'store'
import { initObj } from 'constants/apiConst'
import { cookieSave } from 'utils/cookieWrapper'
import { setLocalStorage, getLocalStorage, LAST_SOUND } from 'utils/localStorage'
import { logLevel } from 'utils/consoleLevel'

const MIN_SOUND_INTERVAL = 100

export const numberInputComma = (x: unknown) => {
  return x ? (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
}

export const tryAtoB = (str: string): string => {
  try {
    return atob(str)
  } catch {
    return ''
  }
}

const charList = [...Array(26)].map((_, index) => {
  return String.fromCharCode('A'.charCodeAt(0) + index)
})
export const genChannelPrefix = (num: number) => {
  return charList[num % 26 || 6]
}

export const PromiseCheck = (channel: ChannelTypes): boolean => {
  if (channel.promise) {
    if (channel.promise?.time) {
      const sec = channel.promise.time.seconds
      return sec > new Date().getTime() / 1000
    } else {
      return false
    }
  } else {
    return false
  }
}

export const ReserveCheck = (channel: ChannelTypes, isPromise: boolean): boolean => {
  const articleObj = channel.article
  return !isPromise && Number(articleObj?.reservedUserId) > 0 && articleObj?.status === 'reserved'
}

export const ProfileParamConverter = (url: string) => {
  return url ? url.replace('s=256x256', 's=80x80').replace('s=640x640', 's=80x80') : SAMPLE_PROFILE_IMAGE
}

export const BizProfileParamConverter = (url: string) => {
  return url ? url.replace('s=256x256', 's=80x80').replace('s=640x640', 's=80x80') : SAMPLE_BIZ_PROFILE_IMAGE
}

export const ArticleParamConverter = (url: string) => {
  return url ? url.replace('s=1440x1440', 's=300x300') : ''
}

const audio = new Audio('/main_daangn.mp3')
audio.volume = 0.3
const writeLastSound = () => setLocalStorage({ key: LAST_SOUND, value: new Date().getTime() })
export const audioPlay = () => {
  const soundNoti = store.getState().utilStore.soundNoti
  const lastSoundTime = getLocalStorage(LAST_SOUND) || 0
  // audio state 4 is full download
  if (audio.readyState === 4 && soundNoti && lastSoundTime + MIN_SOUND_INTERVAL < new Date().getTime()) {
    writeLastSound()
    try {
      // need interaction
      audio.play()
    } catch (e) {
      logLevel.log('not allow')
    }
  }
}

export const setOnboading = () => {
  cookieSave({ name: ONBOARDING_FINISH, value: new Date().getTime().toString(), expires: 365, cookieType: 'origin' })
}
export const loadOnboading = () => {
  return Cookies.get(ONBOARDING_FINISH) || ''
}

export const messageCheck = (message: string) => {
  if (initObj.badWords) {
    const regex = new RegExp(`(${initObj.badWords})`, 'gi')
    const res = message.match(regex)
    return res || []
  } else {
    return []
  }
}

export const getLocalFromNavigator = () => {
  const lang = navigator.language
  if (lang.indexOf('US') > -1 || lang.indexOf('CA') > -1) {
    return 'ca'
  } else if (lang.indexOf('uk') > -1) {
    return 'uk'
  } else if (lang.indexOf('ja') > -1) {
    return 'jp'
  } else {
    return 'kr'
  }
}

const regionList = ['ca', 'uk', 'jp', 'kr']
export const getSupportRegion = () => {
  try {
    const params = new URLSearchParams(window.location.search)
    const urlLang = params.get('lang') || ''
    const lower = urlLang.toLowerCase()
    return regionList.indexOf(lower) > -1 ? urlLang : 'kr'
  } catch (e) {
    return 'kr'
  }
}

export const getCurrentProfileChannelList = () => {
  return store.getState().chatStore.channelList
}

export const getChannelIdFromUrl = () => {
  const pathList = window.location.pathname.split('/')
  const targetPath = pathList[1] === 'business' ? pathList[3] : pathList[2]
  return targetPath ? tryAtoB(targetPath.slice(1)) : ''
}

export const getProfileRootPath = () => {
  const pathList = window.location.pathname.split('/')
  return pathList[1] === 'business' ? `/${[pathList[1], pathList[2]].join('/')}` : `/`
}

export const textToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (e) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }
}

export const getUrlParams = () => {
  const [, accountType, bizAccountId] = window.location.pathname.split('/')
  return {
    bizAccountId: Number(bizAccountId),
    accountType,
  }
}

export const checkAppScheme = (link: string) => {
  return (
    link.indexOf('karrot://') > -1 ||
    link.indexOf('karrot.alpha://') > -1 ||
    link.indexOf('towneers://') > -1 ||
    link.indexOf('towneers.alpha://') > -1
  )
}
