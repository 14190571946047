import { useState } from 'react'
import { ImgType } from 'types/ChatTypes'
import { SlideArrowIcon } from 'utils/SVGIcons'
import { ImageGalleryWrapper, MoveButton } from './ChatMessageStyle'

interface ImageGalleryProps {
  imageList: Array<ImgType>
  currentIdx: number
}
const ImageGallery = ({ imageList, currentIdx }: ImageGalleryProps) => {
  const [selectedIndex, setSelectedIndex] = useState(currentIdx)
  const handleSelectImg = (num: number) => {
    setSelectedIndex((origin) => Math.min(imageList.length - 1, Math.max(0, origin + num)))
  }
  return (
    <>
      <ImageGalleryWrapper>
        <div
          className="image-wrapper"
          style={{
            transform: `translateX(${-100 * selectedIndex}%)`,
          }}
        >
          {imageList.map((item) => (
            <img key={item.id} className="gallery-image" src={item.url} alt="" />
          ))}
        </div>
        <div className="page-text">
          <span className="current-page">{selectedIndex + 1}</span>
          {` / ${imageList.length}`}
        </div>
      </ImageGalleryWrapper>
      <MoveButton
        className="move-button prev-button"
        onClick={() => {
          handleSelectImg(-1)
        }}
      >
        <SlideArrowIcon />
      </MoveButton>
      <MoveButton
        className="move-button next-button"
        onClick={() => {
          handleSelectImg(1)
        }}
      >
        <SlideArrowIcon />
      </MoveButton>
    </>
  )
}

export default ImageGallery
