import { createReducer, createAction } from '@reduxjs/toolkit'

import { BizAccountTypes } from 'types/UserType'
import { getAccToken } from 'utils/auth/tokenUtils'

export const setToken = createAction<{ token: string; region: string }>('user/setToken')
export const setBizToken = createAction<string>('user/bizSetToken')
export const setOnlyUserData = createAction<{ id: number; nick: string }>('user/setOnlyUserData')
export const setBizAccount = createAction<BizAccountTypes[]>('user/setBizAccount')
export const setSelectUser = createAction<{
  id: number
  type: 'normal' | 'business'
  nickname: string
}>('user/setSelectUser')
export const setAccessToken = createAction<string>('user/setAccessToken')
export const setProfileImage = createAction<string>('user/setProfileImage')
export const setNickname = createAction<string>('user/setNickname')

interface initialStateProps {
  user: any
  token: string
  region: string
  bizToken: string
  bizAccount: BizAccountTypes[]
  selectUser: number
  selectType: 'normal' | 'business'
  nickname: string
  profileImage: string
  storeAccessToken: string
}

const initialState: initialStateProps = {
  user: {},
  token: '',
  region: '',
  bizToken: '',
  bizAccount: [],
  selectUser: 0,
  selectType: 'normal',
  nickname: '',
  profileImage: '',
  storeAccessToken: getAccToken() || '',
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setToken, (state, action) => {
      const data = action.payload
      state.token = data.token
      state.region = data.region
    })
    .addCase(setBizToken, (state, action) => {
      state.bizToken = action.payload
    })
    .addCase(setNickname, (state, action) => {
      state.nickname = action.payload
    })
    .addCase(setOnlyUserData, (state, action) => {
      const userData = { ...action.payload }
      state.user = {
        ...state.user,
        ...userData,
      }
      if (!state.selectUser) {
        state.selectUser = userData.id
        state.selectType = 'normal'
        state.nickname = userData.nick
      }
    })
    .addCase(setBizAccount, (state, action) => {
      const accountList = action.payload
      state.bizAccount = [...accountList]
    })
    .addCase(setSelectUser, (state, action) => {
      state.selectUser = action.payload.id
      state.selectType = action.payload.type
      state.nickname = action.payload.nickname
    })
    .addCase(setAccessToken, (state, action) => {
      const accessToken = action.payload
      state.storeAccessToken = accessToken
    })
    .addCase(setProfileImage, (state, action) => {
      const profileImage = action.payload
      state.profileImage = profileImage
    })
})
