import styled from '@emotion/styled'
import UserArea from './UserArea'
import { IS_PROD } from 'constants/config'
import { MAIN_WIDTH } from 'constants/resource'

const Header = () => {
  return (
    <WrappedHeader>
      <div className="container">
        <a href={`https://${IS_PROD ? '' : 'alpha.'}daangn.com`}>
          <Logo src="/image/Logo_korean.png" alt="당근 로고" />
        </a>
        <UserArea />
      </div>
    </WrappedHeader>
  )
}

const WrappedHeader = styled.header`
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  border-bottom: 1px solid var(--seed-semantic-color-divider-2);
  display: flex;
  justify-content: center;
  background-color: var(--seed-semantic-color-paper-default);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${MAIN_WIDTH};
    padding: 0 8px;
  }
`

const Logo = styled.img`
  height: 36px;
`

export default Header
