import { useState } from 'react'

import { InlineTemplateStyle } from './ChatMessageStyle'
import Modal from 'components/common/Modal'
import { gtagEmit } from 'utils/eventUtils'
import { store } from 'store'
import { checkAppScheme } from 'utils/snippet'
import { openToast } from 'utils/localDispatcher'

interface richTextTypes {
  type?: 'a' | 'b' | 'strikethrough'
  href?: string
  text?: string
}
interface RichReducerTypes {
  result: Array<richTextTypes>
  currentObj: richTextTypes
}
interface InlineTemplateProps {
  richText: string
}
const InlineTemplate = ({ richText }: InlineTemplateProps) => {
  const elementSplitArr = richText.split(new RegExp('(<[/]?[ab(strikethrough)]+[^>]*>)', 'gi'))
  const initRichObj: RichReducerTypes = {
    result: [],
    currentObj: {},
  }
  const RichTextObj = elementSplitArr.reduce((acc, cur) => {
    if (cur === '<b>') {
      acc.currentObj.type = 'b'
    } else if (cur === '</b>' || cur === '</a>' || cur === '</strikethrough>') {
      acc.result.push({ ...acc.currentObj })
      acc.currentObj = {}
    } else if (cur.indexOf('<a') === 0) {
      const href = new RegExp(/href="(.*?)"/gi).exec(cur)
      acc.currentObj.type = 'a'
      acc.currentObj.href = href ? href[1] : ''
    } else if (cur === '<strikethrough>') {
      acc.currentObj.type = 'strikethrough'
    } else {
      acc.currentObj.text = cur
      if (!acc.currentObj.type) {
        acc.result.push({ ...acc.currentObj })
        acc.currentObj = {}
      }
    }
    return acc
  }, initRichObj)
  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const closeModal = () => setModalObj((origin) => ({ ...origin, isShow: false }))
  const preventLink = (e: React.SyntheticEvent, href: string) => {
    e.preventDefault()
    openToast('앱에서 확인해 주세요')
    const selectChannelobj = store.getState().chatStore.selectChannelobj
    gtagEmit('event', 'click_pc_message', {
      button_action: href,
      resource_type: selectChannelobj?.resourceType,
      channel_id: selectChannelobj?.id,
    })
  }
  return (
    <InlineTemplateStyle>
      {RichTextObj.result.map((item: any, idx) => {
        if (item.type === 'b') {
          return <strong key={idx}>{item.text}</strong>
        } else if (item.type === 'a') {
          const link = item.href
          const appCheck = checkAppScheme(link)
          return appCheck ? (
            <span key={idx} className="inline-link" onClick={(e) => preventLink(e, item.href)}>
              {item.text}
            </span>
          ) : (
            <a className="inline-link" key={idx} href={item.href} target="_blank" rel="noreferrer">
              {item.text}
            </a>
          )
        } else if(item.type === 'strikethrough') {
          return <s key={idx}>{item.text}</s>
        } else {
          return <span key={idx}>{item.text}</span>
        }
      })}
      <Modal isVisible={modalObj.isShow} onClose={closeModal} content={modalObj.content} />
    </InlineTemplateStyle>
  )
}

export default InlineTemplate
