import KarrotButton from 'components/common/KarrotButton'
import { useEffect } from 'react'
import { replySchema } from 'types/PersistentTypes'
import { gtagAutoReplyWrapper } from 'utils/eventWrapper'
import { ReplyIntroWrapper } from './chatReplyStyle'

interface ChatReplyIntroProps {
  handleRouter: (schema: replySchema) => void
}
const ChatReplyIntro = ({ handleRouter }: ChatReplyIntroProps) => {
  const handleClick = () => {
    handleRouter('submit')
  }
  useEffect(() => {
    gtagAutoReplyWrapper('show_chat_autoreply_onboarding', { where: 'input_box' })
  }, [])
  return (
    <ReplyIntroWrapper>
      <div className="intro-header">
        <div className="intro-title">반복되는 질문과 답장, 자동응답으로 간편하게</div>
        <div className="intro-subtitle">고객에게 등록한 질문에 대한 답장을 자동으로 발송할 수 있어요.</div>
      </div>
      <div className="intro-body">
        <div className="first-case">
          <div className="message-row">
            <img className="profile-image" src="/image/auto_reply_profile01.png" alt="sample profile" />
            <div className="message-item">주차 가능한 가게일까요?</div>
          </div>
          <div className="message-row">
            <div className="message-item send-message">
              <div>주차는 최대 30대까지 가능하며,</div>
              <div>추가로 인근 공영 주차장이 있어요.</div>
            </div>
          </div>
        </div>
        <div className="second-case">
          <div className="message-row">
            <img className="profile-image" src="/image/auto_reply_profile02.png" alt="sample profile" />
            <div className="message-item">예약금 규정은 어떻게 되나요?</div>
          </div>
          <div className="message-row">
            <div className="message-item send-message">
              <div>예약 후 30분 내 입금 시 예약 완료되며,</div>
              <div>당일 변경, 취소 시 환불 불가해요. 취소</div>
              <div>를 원하시면 최소 1일 전에 연락주세요.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="intro-footer">
        <KarrotButton variant="primary" onClick={handleClick} className="intro-button">
          자동응답 등록하기
        </KarrotButton>
      </div>
    </ReplyIntroWrapper>
  )
}

export default ChatReplyIntro
