import React, { useEffect, useState, memo } from 'react'
import { useSelector } from 'react-redux'

import Modal from 'components/common/Modal'
import ExitChannel from './ExitChannel'
import BlockUser from './BlockUser'
import { socketStore } from 'utils/socket/socketStore'
import { userBlock } from 'utils/protobuf/query'
import { ChatOptionStyle } from './ChatOptionStyle'
import { ChannelTypes } from 'types/ChatTypes'
import { MoreButton } from 'utils/SVGIcons'
import { StoreState } from 'store/ducks'
import { muteControl } from 'utils/localDispatcher'
import { muteStore } from 'utils/cacheStore'
import { gtagEmit } from 'utils/eventUtils'

interface ChatOptionProps {
  selectChannelobj: ChannelTypes
}
const ChatOptions = ({ selectChannelobj }: ChatOptionProps) => {
  const socket = socketStore.chatSocket
  const blockState = useSelector((state: StoreState) => state.chatStore.channelBlock)
  // eslint-disable-next-line
  const isMute = muteStore.dataList[selectChannelobj.id] ? true : false
  useSelector((state: StoreState) => state.utilStore.muteChange)
  const [modalObj, setModalObj] = useState({
    isShow: false,
    content: <div></div>,
  })
  const closeModal = () => setModalObj((origin) => ({ ...origin, isShow: false }))
  const blockChannel = (status: boolean) => {
    socket?.send(
      userBlock({
        status: status,
        user: selectChannelobj.me,
        targetUser: selectChannelobj.receiver,
      }).serializeBinary(),
    )
    closeModal()
    gtagEmit('event', 'click_pc_channel_more', {
      button_action: 'unblock',
      resource_type: selectChannelobj.resourceType,
    })
  }
  const handleBlockUser = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setModalObj({
      isShow: true,
      content: <BlockUser closeModal={closeModal} selectChannelobj={selectChannelobj} blockChannel={blockChannel} />,
    })
    gtagEmit('event', 'click_pc_channel_more', { button_action: 'block', resource_type: selectChannelobj.resourceType })
  }
  const handleLeaveChannel = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setModalObj({
      isShow: true,
      content: <ExitChannel closeModal={closeModal} channelId={selectChannelobj.id} />,
    })
    gtagEmit('event', 'click_pc_channel_more', { button_action: 'leave', resource_type: selectChannelobj.resourceType })
  }

  const onMute = (e: React.SyntheticEvent) => {
    e.preventDefault()
    muteControl(selectChannelobj.id, true)
    gtagEmit('event', 'click_pc_channel_more', { button_action: 'mute', resource_type: selectChannelobj.resourceType })
  }
  const offMute = () => {
    muteControl(selectChannelobj.id, false)
    gtagEmit('event', 'click_pc_channel_more', {
      button_action: 'unmute',
      resource_type: selectChannelobj.resourceType,
    })
  }

  const [optionShow, setOptionShow] = useState(false)
  const handleShowOption = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setOptionShow((origin) => {
      if (!origin) {
        gtagEmit('event', 'click_pc_channel_more', {
          button_action: 'more',
          resource_type: selectChannelobj.resourceType,
        })
      }
      return !origin
    })
  }

  const closeOption = () => setOptionShow(false)
  useEffect(() => {
    if (!optionShow) return

    window.addEventListener('click', closeOption)
    return () => window.removeEventListener('click', closeOption)
  }, [optionShow])
  const destroyed = selectChannelobj.receiver?.status === 'destroyed' || selectChannelobj.isDisabled
  return (
    <ChatOptionStyle>
      <div className="more-button-wrapper common-bg-hover" onClick={handleShowOption}>
        <MoreButton />
      </div>
      {optionShow ? (
        <div className="option-container" onClick={(e) => e.stopPropagation()}>
          {isMute ? (
            <div className="option-item common-bg-hover" onClick={offMute}>
              알림음 켜기
            </div>
          ) : (
            <div className="option-item common-bg-hover" onClick={onMute}>
              알림음 끄기
            </div>
          )}
          {selectChannelobj.type === 1 && !destroyed ? (
            blockState ? (
              <div className="option-item common-bg-hover" onClick={() => blockChannel(false)}>
                차단 해제하기
              </div>
            ) : (
              <div className="option-item common-bg-hover" onClick={handleBlockUser}>
                대화상대 차단하기
              </div>
            )
          ) : null}
          <div className="option-item common-bg-hover" onClick={handleLeaveChannel}>
            채팅방 나가기
          </div>
        </div>
      ) : null}
      <Modal isVisible={modalObj.isShow} onClose={closeModal} content={modalObj.content} />
    </ChatOptionStyle>
  )
}

function chatOptionEqual(prevProps: ChatOptionProps, nextProps: ChatOptionProps) {
  return prevProps.selectChannelobj.id === nextProps.selectChannelobj.id
}

export default memo(ChatOptions, chatOptionEqual)
