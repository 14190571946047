import { MessageTypes } from 'types/ChatTypes'

interface MessageUnreadProps {
  senderChat: boolean
  messageItem: MessageTypes
}
const MessageUnread = ({ senderChat, messageItem }: MessageUnreadProps) => {
  const readCount = messageItem.seenMap.filter((seenItem) => seenItem[1].seconds > 0).length
  const userCount = messageItem.seenMap.length
  if (userCount > 2) {
    if (readCount === 1) {
      return <span className="unread-text">전송됨</span>
    } else if (userCount - readCount === 0) {
      return <span className="unread-text">모두 읽음</span>
    } else {
      return <span className="unread-text">읽음 {readCount - 1}</span>
    }
  } else if (!senderChat) {
    return readCount > 1 ? <span className="unread-text">읽음</span> : <span className="unread-text">전송됨</span>
  } else {
    return null
  }
}

export default MessageUnread
